/** Specifies the number of significant digits to display */
export const TOKEN_PRECISION: number = 4 as const;
export const FIAT_PRECISION: number = 2 as const;

/** Screen adaptation tooltip width */
export const INFO_ICON_WIDTH: number = 783;

/** Screen adaptation the distance of element to viewport right*/
export const DISTANCE_TO_RIGHT: number = 200;

/** The threshold for number of digits before we shorten the amount to display */
export const SHORTENED_AMOUNT_THRESHOLD = 7;
