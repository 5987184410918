import assert from "assert";

import { Decimal18 } from "./Decimal18";
import { StabilityDeposit } from "./StabilityDeposit";
import { Vault, VaultWithPendingRedistribution, UserVault } from "./Vault";
import { Fees } from "./Fees";
import { GOKStake } from "./GOKStake";
import { CollateralDecimal } from "./CollateralDecimal";

/**
 * State variables read from the blockchain.
 *
 * @public
 */
export interface AstridDaoStoreBaseState {
  /** Number of Vaults that are currently open. */
  numberOfVaults: number;

  /** User's native currency balance (e.g. Astar). */
  accountBalance: Decimal18;

  /** User's GAItoken balance. */
  gaiBalance: Decimal18;

  /** User's xcGAItoken balance. */
  xcGaiBalance: Decimal18;

  xcGaiAllowance: CollateralDecimal;

  gaiAllowance: CollateralDecimal;

  /** User's GOK token balance. */
  gokBalance: Decimal18;

  /** User's Wrapped ERC20 token balance. (e.g. WASTR, DOT, KSM, USDC, BUSD, etc.) */
  wrappedTokenBalance: CollateralDecimal;

  /** The vault contract's allowance of user's wrapped tokens. */
  wrappedTokenAllowance: CollateralDecimal;

  /**
   * Amount of leftover collateral available for withdrawal to the user.
   *
   * @remarks
   * See {@link ReadableAstridDao.getCollateralSurplusBalance | getCollateralSurplusBalance()} for
   * more information.
   */
  collateralSurplusBalance: CollateralDecimal;

  /** Current price of the native currency (e.g. Ether) in USD. */
  price: Decimal18;

  /** Total amount of GAIcurrently deposited in the Stability Pool. */
  gaiInStabilityPool: Decimal18;

  /** Total collateral and debt in the AstridDAO system. */
  total: Vault;

  /**
   * Total collateral and debt per stake that has been liquidated through redistribution.
   *
   * @remarks
   * Needed when dealing with instances of {@link VaultWithPendingRedistribution}.
   */
  totalRedistributed: Vault;

  /**
   * User's Vault in its state after the last direct modification.
   *
   * @remarks
   * The current state of the user's Vault can be found as
   * {@link AstridDaoStoreDerivedState.vault | vault}.
   */
  vaultBeforeRedistribution: VaultWithPendingRedistribution;

  /** User's stability deposit. */
  stabilityDeposit: StabilityDeposit;

  /** Remaining GOK that will be collectively rewarded to stability depositors. */
  remainingStabilityPoolGOKReward: Decimal18;

  /** @internal */
  _feesInNormalMode: Fees;

  /** User's GOK stake. */
  gokStake: GOKStake;

  /** Total amount of Weighted GOK currently staked. */
  totalWeightedStakedGOK: Decimal18;

  /** Total amount of Unweighted GOK currently staked. */
  totalUnweightedStakedGOK: Decimal18;

  /** Total count of User's active locked stakes. */
  totalLockedStakesCount: number;

  /** @internal */
  _riskiestVaultBeforeRedistribution: VaultWithPendingRedistribution;
}

/**
 * State variables derived from {@link AstridDaoStoreBaseState}.
 *
 * @public
 */
export interface AstridDaoStoreDerivedState {
  /** Current state of user's Vault */
  vault: UserVault;

  /** Calculator for current fees. */
  fees: Fees;

  /**
   * Current borrowing rate.
   *
   * @remarks
   * A value between 0 and 1.
   *
   * @example
   * For example a value of 0.01 amounts to a borrowing fee of 1% of the borrowed amount.
   */
  borrowingRate: Decimal18;

  /**
   * Current redemption rate.
   *
   * @remarks
   * Note that the actual rate paid by a redemption transaction will depend on the amount of GAI
   * being redeemed.
   *
   * Use {@link Fees.redemptionRate} to calculate a precise redemption rate.
   */
  redemptionRate: Decimal18;

  /**
   * Whether there are any Vaults with collateral ratio below the
   * {@link _MINIMUM_COLLATERAL_RATIO | minimum}.
   */
  haveUndercollateralizedVaults: boolean;
}

/**
 * Type of {@link AstridDaoStore}'s {@link AstridDaoStore.state | state}.
 *
 * @remarks
 * It combines all properties of {@link AstridDaoStoreBaseState} and {@link AstridDaoStoreDerivedState}
 * with optional extra state added by the particular `AstridDaoStore` implementation.
 *
 * The type parameter `T` may be used to type the extra state.
 *
 * @public
 */
export type AstridDaoStoreState<T = unknown> = AstridDaoStoreBaseState &
  AstridDaoStoreDerivedState &
  T;

/**
 * Parameters passed to {@link AstridDaoStore} listeners.
 *
 * @remarks
 * Use the {@link AstridDaoStore.subscribe | subscribe()} function to register a listener.

 * @public
 */
export interface AstridDaoStoreListenerParams<T = unknown> {
  /** The entire previous state. */
  newState: AstridDaoStoreState<T>;

  /** The entire new state. */
  oldState: AstridDaoStoreState<T>;

  /** Only the state variables that have changed. */
  stateChange: Partial<AstridDaoStoreState<T>>;
}

const strictEquals = <T>(a: T, b: T) => a === b;
const eq = <T extends { eq(that: T): boolean }>(a: T, b: T) => a.eq(b);
const equals = <T extends { equals(that: T): boolean }>(a: T, b: T) => a.equals(b);
const wrap =
  <A extends unknown[], R>(f: (...args: A) => R) =>
  (...args: A) =>
    f(...args);

const difference = <T>(a: T, b: T) =>
  Object.fromEntries(
    Object.entries(a).filter(([key, value]) => value !== (b as Record<string, unknown>)[key])
  ) as Partial<T>;

/**
 * Abstract base class of AstridDAO data store implementations.
 *
 * @remarks
 * The type parameter `T` may be used to type extra state added to {@link AstridDaoStoreState} by the
 * subclass.
 *
 * Implemented by {@link @astrid-dao/lib-ethers#BlockPolledAstridDaoStore}.
 *
 * @public
 */
export abstract class AstridDaoStore<T = unknown> {
  /** Turn console logging on/off. */
  logging = true;

  /**
   * Called after the state is fetched for the first time.
   *
   * @remarks
   * See {@link AstridDaoStore.start | start()}.
   */
  onLoaded?: () => void;

  /** @internal */
  protected _loaded = false;

  private _baseState?: AstridDaoStoreBaseState;
  private _derivedState?: AstridDaoStoreDerivedState;
  private _extraState?: T;

  private _updateTimeoutId: ReturnType<typeof setTimeout> | undefined;
  private _listeners = new Set<(params: AstridDaoStoreListenerParams<T>) => void>();

  /**
   * The current store state.
   *
   * @remarks
   * Should not be accessed before the store is loaded. Assign a function to
   * {@link AstridDaoStore.onLoaded | onLoaded} to get a callback when this happens.
   *
   * See {@link AstridDaoStoreState} for the list of properties returned.
   */
  get state(): AstridDaoStoreState<T> {
    return Object.assign({}, this._baseState, this._derivedState, this._extraState);
  }

  /** @internal */
  protected abstract _doStart(): () => void;

  /**
   * Start monitoring the blockchain for AstridDAO state changes.
   *
   * @remarks
   * The {@link AstridDaoStore.onLoaded | onLoaded} callback will be called after the state is fetched
   * for the first time.
   *
   * Use the {@link AstridDaoStore.subscribe | subscribe()} function to register listeners.
   *
   * @returns Function to stop the monitoring.
   */
  start(): () => void {
    const doStop = this._doStart();

    return () => {
      doStop();

      this._cancelUpdateIfScheduled();
    };
  }

  private _cancelUpdateIfScheduled() {
    if (this._updateTimeoutId !== undefined) {
      clearTimeout(this._updateTimeoutId);
    }
  }

  private _scheduleUpdate() {
    this._cancelUpdateIfScheduled();

    this._updateTimeoutId = setTimeout(() => {
      this._updateTimeoutId = undefined;
      this._update();
    }, 60000);
  }

  private _logUpdate<U>(name: string, next: U, show?: (next: U) => string): U {
    if (this.logging) {
      console.log(`${name} updated to ${show ? show(next) : next}`);
    }

    return next;
  }

  private _updateIfChanged<U>(
    equals: (a: U, b: U) => boolean,
    name: string,
    prev: U,
    next?: U,
    show?: (next: U) => string
  ): U {
    return next !== undefined && !equals(prev, next) ? this._logUpdate(name, next, show) : prev;
  }

  private _silentlyUpdateIfChanged<U>(equals: (a: U, b: U) => boolean, prev: U, next?: U): U {
    return next !== undefined && !equals(prev, next) ? next : prev;
  }

  private _updateFees(name: string, prev: Fees, next?: Fees): Fees {
    if (next && !next.equals(prev)) {
      // Filter out fee update spam that happens on every new block by only logging when string
      // representation changes.
      if (`${next}` !== `${prev}`) {
        this._logUpdate(name, next);
      }
      return next;
    } else {
      return prev;
    }
  }

  private _reduce(
    baseState: AstridDaoStoreBaseState,
    baseStateUpdate: Partial<AstridDaoStoreBaseState>
  ): AstridDaoStoreBaseState {
    return {
      numberOfVaults: this._updateIfChanged(
        strictEquals,
        "numberOfVaults",
        baseState.numberOfVaults,
        baseStateUpdate.numberOfVaults
      ),

      accountBalance: this._updateIfChanged(
        eq,
        "accountBalance",
        baseState.accountBalance,
        baseStateUpdate.accountBalance
      ),

      wrappedTokenBalance: this._updateIfChanged(
        eq,
        "wrappedTokenBalance",
        baseState.wrappedTokenBalance,
        baseStateUpdate.wrappedTokenBalance
      ),

      gaiBalance: this._updateIfChanged(
        eq,
        "gaiBalance",
        baseState.gaiBalance,
        baseStateUpdate.gaiBalance
      ),

      xcGaiBalance: this._updateIfChanged(
        eq,
        "xcGaiBalance",
        baseState.xcGaiBalance,
        baseStateUpdate.xcGaiBalance
      ),

      xcGaiAllowance: this._updateIfChanged(
        eq,
        "xcGaiAllowance",
        baseState.xcGaiAllowance,
        baseStateUpdate.xcGaiAllowance
      ),

      gaiAllowance: this._updateIfChanged(
        eq,
        "gaiAllowance",
        baseState.gaiAllowance,
        baseStateUpdate.gaiAllowance
      ),

      gokBalance: this._updateIfChanged(
        eq,
        "gokBalance",
        baseState.gokBalance,
        baseStateUpdate.gokBalance
      ),

      wrappedTokenAllowance: this._updateIfChanged(
        eq,
        "wrappedTokenAllowance",
        baseState.wrappedTokenAllowance,
        baseStateUpdate.wrappedTokenAllowance
      ),

      collateralSurplusBalance: this._updateIfChanged(
        eq,
        "collateralSurplusBalance",
        baseState.collateralSurplusBalance,
        baseStateUpdate.collateralSurplusBalance
      ),

      price: this._updateIfChanged(eq, "price", baseState.price, baseStateUpdate.price),

      gaiInStabilityPool: this._updateIfChanged(
        eq,
        "gaiInStabilityPool",
        baseState.gaiInStabilityPool,
        baseStateUpdate.gaiInStabilityPool
      ),

      total: this._updateIfChanged(equals, "total", baseState.total, baseStateUpdate.total),

      totalRedistributed: this._updateIfChanged(
        equals,
        "totalRedistributed",
        baseState.totalRedistributed,
        baseStateUpdate.totalRedistributed
      ),

      vaultBeforeRedistribution: this._updateIfChanged(
        equals,
        "vaultBeforeRedistribution",
        baseState.vaultBeforeRedistribution,
        baseStateUpdate.vaultBeforeRedistribution
      ),

      stabilityDeposit: this._updateIfChanged(
        equals,
        "stabilityDeposit",
        baseState.stabilityDeposit,
        baseStateUpdate.stabilityDeposit
      ),

      remainingStabilityPoolGOKReward: this._silentlyUpdateIfChanged(
        eq,
        baseState.remainingStabilityPoolGOKReward,
        baseStateUpdate.remainingStabilityPoolGOKReward
      ),

      _feesInNormalMode: this._silentlyUpdateIfChanged(
        equals,
        baseState._feesInNormalMode,
        baseStateUpdate._feesInNormalMode
      ),

      gokStake: this._updateIfChanged(
        equals,
        "gokStake",
        baseState.gokStake,
        baseStateUpdate.gokStake
      ),

      totalWeightedStakedGOK: this._updateIfChanged(
        eq,
        "totalWeightedStakedGOK",
        baseState.totalWeightedStakedGOK,
        baseStateUpdate.totalWeightedStakedGOK
      ),

      totalUnweightedStakedGOK: this._updateIfChanged(
        eq,
        "totalUnweightedStakedGOK",
        baseState.totalUnweightedStakedGOK,
        baseStateUpdate.totalUnweightedStakedGOK
      ),

      totalLockedStakesCount: this._updateIfChanged(
        strictEquals,
        "totalLockedStakesCount",
        baseState.totalLockedStakesCount,
        baseStateUpdate.totalLockedStakesCount
      ),

      _riskiestVaultBeforeRedistribution: this._silentlyUpdateIfChanged(
        equals,
        baseState._riskiestVaultBeforeRedistribution,
        baseStateUpdate._riskiestVaultBeforeRedistribution
      )
    };
  }

  private _derive({
    vaultBeforeRedistribution,
    totalRedistributed,
    _feesInNormalMode,
    total,
    price,
    _riskiestVaultBeforeRedistribution
  }: AstridDaoStoreBaseState): AstridDaoStoreDerivedState {
    const fees = _feesInNormalMode._setRecoveryMode(total.collateralRatioIsBelowCritical(price));

    return {
      vault: vaultBeforeRedistribution.applyRedistribution(totalRedistributed),
      fees,
      borrowingRate: fees.borrowingRate(),
      redemptionRate: fees.redemptionRate(),
      haveUndercollateralizedVaults: _riskiestVaultBeforeRedistribution
        .applyRedistribution(totalRedistributed)
        .collateralRatioIsBelowMinimum(price)
    };
  }

  private _reduceDerived(
    derivedState: AstridDaoStoreDerivedState,
    derivedStateUpdate: AstridDaoStoreDerivedState
  ): AstridDaoStoreDerivedState {
    return {
      fees: this._updateFees("fees", derivedState.fees, derivedStateUpdate.fees),

      vault: this._updateIfChanged(equals, "vault", derivedState.vault, derivedStateUpdate.vault),

      borrowingRate: this._silentlyUpdateIfChanged(
        eq,
        derivedState.borrowingRate,
        derivedStateUpdate.borrowingRate
      ),

      redemptionRate: this._silentlyUpdateIfChanged(
        eq,
        derivedState.redemptionRate,
        derivedStateUpdate.redemptionRate
      ),

      haveUndercollateralizedVaults: this._updateIfChanged(
        strictEquals,
        "haveUndercollateralizedVaults",
        derivedState.haveUndercollateralizedVaults,
        derivedStateUpdate.haveUndercollateralizedVaults
      )
    };
  }

  /** @internal */
  protected abstract _reduceExtra(extraState: T, extraStateUpdate: Partial<T>): T;

  private _notify(params: AstridDaoStoreListenerParams<T>) {
    // Iterate on a copy of `_listeners`, to avoid notifying any new listeners subscribed by
    // existing listeners, as that could result in infinite loops.
    //
    // Before calling a listener from our copy of `_listeners`, check if it has been removed from
    // the original set. This way we avoid calling listeners that have already been unsubscribed
    // by an earlier listener callback.
    [...this._listeners].forEach(listener => {
      if (this._listeners.has(listener)) {
        listener(params);
      }
    });
  }

  /**
   * Register a state change listener.
   *
   * @param listener - Function that will be called whenever state changes.
   * @returns Function to unregister this listener.
   */
  subscribe(listener: (params: AstridDaoStoreListenerParams<T>) => void): () => void {
    const uniqueListener = wrap(listener);

    this._listeners.add(uniqueListener);

    return () => {
      this._listeners.delete(uniqueListener);
    };
  }

  /** @internal */
  protected _load(baseState: AstridDaoStoreBaseState, extraState?: T): void {
    assert(!this._loaded);

    this._baseState = baseState;
    this._derivedState = this._derive(baseState);
    this._extraState = extraState;
    this._loaded = true;

    this._scheduleUpdate();

    if (this.onLoaded) {
      this.onLoaded();
    }
  }

  /** @internal */
  protected _update(
    baseStateUpdate?: Partial<AstridDaoStoreBaseState>,
    extraStateUpdate?: Partial<T>
  ): void {
    assert(this._baseState && this._derivedState);

    const oldState = this.state;

    if (baseStateUpdate) {
      this._baseState = this._reduce(this._baseState, baseStateUpdate);
    }

    // Always running this lets us derive state based on passage of time, like baseRate decay
    this._derivedState = this._reduceDerived(this._derivedState, this._derive(this._baseState));

    if (extraStateUpdate) {
      assert(this._extraState);
      this._extraState = this._reduceExtra(this._extraState, extraStateUpdate);
    }

    this._scheduleUpdate();

    this._notify({
      newState: this.state,
      oldState,
      stateChange: difference(this.state, oldState)
    });
  }
}
