import { Decimalish } from "./Decimal18";
import { VaultAdjustmentParams, VaultCreationParams } from "./Vault";

import {
  BaseCollateralDepositChangeDetails,
  CollateralGainTransferDetails,
  LiquidationDetails,
  RedemptionDetails,
  StabilityDepositChangeDetails,
  StabilityPoolGainsWithdrawalDetails,
  TransactableAstridDao,
  VaultAdjustmentDetails,
  VaultClosureDetails,
  VaultCreationDetails
} from "./TransactableAstridDao";
import { BigNumber } from "@ethersproject/bignumber";

/**
 * A transaction that has already been sent.
 *
 * @remarks
 * Implemented by {@link @astrid-dao/lib-ethers#SentEthersAstridDaoTransaction}.
 *
 * @public
 */
export interface SentAstridDaoTransaction<
  S = unknown,
  T extends AstridDaoReceipt = AstridDaoReceipt
> {
  /** Implementation-specific sent transaction object. */
  readonly rawSentTransaction: S;

  /**
   * Check whether the transaction has been mined, and whether it was successful.
   *
   * @remarks
   * Unlike {@link @astrid-dao/lib-base#SentAstridDaoTransaction.waitForReceipt | waitForReceipt()},
   * this function doesn't wait for the transaction to be mined.
   */
  getReceipt(): Promise<T>;

  /**
   * Wait for the transaction to be mined, and check whether it was successful.
   *
   * @returns Either a {@link @astrid-dao/lib-base#FailedReceipt} or a
   *          {@link @astrid-dao/lib-base#SuccessfulReceipt}.
   */
  waitForReceipt(): Promise<Extract<T, MinedReceipt>>;
}

/**
 * Indicates that the transaction hasn't been mined yet.
 *
 * @remarks
 * Returned by {@link SentAstridDaoTransaction.getReceipt}.
 *
 * @public
 */
export type PendingReceipt = { status: "pending" };

/** @internal */
export const _pendingReceipt: PendingReceipt = { status: "pending" };

/**
 * Indicates that the transaction has been mined, but it failed.
 *
 * @remarks
 * The `rawReceipt` property is an implementation-specific transaction receipt object.
 *
 * Returned by {@link SentAstridDaoTransaction.getReceipt} and
 * {@link SentAstridDaoTransaction.waitForReceipt}.
 *
 * @public
 */
export type FailedReceipt<R = unknown> = { status: "failed"; rawReceipt: R };

/** @internal */
export const _failedReceipt = <R>(rawReceipt: R): FailedReceipt<R> => ({
  status: "failed",
  rawReceipt
});

/**
 * Indicates that the transaction has succeeded.
 *
 * @remarks
 * The `rawReceipt` property is an implementation-specific transaction receipt object.
 *
 * The `details` property may contain more information about the transaction.
 * See the return types of {@link TransactableAstridDao} functions for the exact contents of `details`
 * for each type of AstridDao transaction.
 *
 * Returned by {@link SentAstridDaoTransaction.getReceipt} and
 * {@link SentAstridDaoTransaction.waitForReceipt}.
 *
 * @public
 */
export type SuccessfulReceipt<R = unknown, D = unknown> = {
  status: "succeeded";
  rawReceipt: R;
  details: D;
};

/** @internal */
export const _successfulReceipt = <R, D>(
  rawReceipt: R,
  details: D,
  toString?: () => string
): SuccessfulReceipt<R, D> => ({
  status: "succeeded",
  rawReceipt,
  details,
  ...(toString ? { toString } : {})
});

/**
 * Either a {@link FailedReceipt} or a {@link SuccessfulReceipt}.
 *
 * @public
 */
export type MinedReceipt<R = unknown, D = unknown> = FailedReceipt<R> | SuccessfulReceipt<R, D>;

/**
 * One of either a {@link PendingReceipt}, a {@link FailedReceipt} or a {@link SuccessfulReceipt}.
 *
 * @public
 */
export type AstridDaoReceipt<R = unknown, D = unknown> = PendingReceipt | MinedReceipt<R, D>;

/** @internal */
export type _SendableFrom<T, R, S> = {
  [M in keyof T]: T[M] extends (...args: infer A) => Promise<infer D>
    ? (...args: A) => Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, D>>>
    : never;
};

/**
 * Send AstridDao transactions.
 *
 * @remarks
 * The functions return an object implementing {@link SentAstridDaoTransaction}, which can be used
 * to monitor the transaction and get its details when it succeeds.
 *
 * Implemented by {@link @astrid-dao/lib-ethers#SendableEthersAstridDao}.
 *
 * @public
 */
export interface SendableAstridDao<R = unknown, S = unknown>
  extends _SendableFrom<TransactableAstridDao, R, S> {
  // Methods re-declared for documentation purposes

  /** {@inheritDoc TransactableAstridDao.openVault} */
  openVault(
    params: VaultCreationParams<Decimalish>,
    maxBorrowingRate?: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, VaultCreationDetails>>>;

  /** {@inheritDoc TransactableAstridDao.closeVault} */
  closeVault(): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, VaultClosureDetails>>>;

  /** {@inheritDoc TransactableAstridDao.adjustVault} */
  adjustVault(
    params: VaultAdjustmentParams<Decimalish>,
    maxBorrowingRate?: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, VaultAdjustmentDetails>>>;

  /** {@inheritDoc TransactableAstridDao.depositBaseCollateral} */
  depositBaseCollateral(
    amount: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, BaseCollateralDepositChangeDetails>>>;

  /** {@inheritDoc TransactableAstridDao.withdrawToBaseCollateral} */
  withdrawToBaseCollateral(
    amount: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, BaseCollateralDepositChangeDetails>>>;

  /** {@inheritDoc TransactableAstridDao.depositCollateral} */
  depositCollateral(
    amount: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, VaultAdjustmentDetails>>>;

  /** {@inheritDoc TransactableAstridDao.withdrawCollateral} */
  withdrawCollateral(
    amount: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, VaultAdjustmentDetails>>>;

  /** {@inheritDoc TransactableAstridDao.borrowGai} */
  borrowGai(
    amount: Decimalish,
    maxBorrowingRate?: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, VaultAdjustmentDetails>>>;

  /** {@inheritDoc TransactableAstridDao.repayGai} */
  repayGai(
    amount: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, VaultAdjustmentDetails>>>;

  /** @internal */
  setPrice(price: Decimalish): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, void>>>;

  /** {@inheritDoc TransactableAstridDao.liquidate} */
  liquidate(
    address: string | string[]
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, LiquidationDetails>>>;

  /** {@inheritDoc TransactableAstridDao.liquidateUpTo} */
  liquidateUpTo(
    maximumNumberOfVaultsToLiquidate: number
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, LiquidationDetails>>>;

  /** {@inheritDoc TransactableAstridDao.depositGaiInStabilityPool} */
  depositGaiInStabilityPool(
    amount: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, StabilityDepositChangeDetails>>>;

  /** {@inheritDoc TransactableAstridDao.withdrawGaiFromStabilityPool} */
  withdrawGaiFromStabilityPool(
    amount: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, StabilityDepositChangeDetails>>>;

  /** {@inheritDoc TransactableAstridDao.withdrawGainsFromStabilityPool} */
  withdrawGainsFromStabilityPool(): Promise<
    SentAstridDaoTransaction<S, AstridDaoReceipt<R, StabilityPoolGainsWithdrawalDetails>>
  >;

  /** {@inheritDoc TransactableAstridDao.transferCollateralGainToVault} */
  transferCollateralGainToVault(): Promise<
    SentAstridDaoTransaction<S, AstridDaoReceipt<R, CollateralGainTransferDetails>>
  >;

  /** {@inheritDoc TransactableAstridDao.sendGai} */
  sendGai(
    toAddress: string,
    amount: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, void>>>;

  /** {@inheritDoc TransactableAstridDao.sendGOK} */
  sendGOK(
    toAddress: string,
    amount: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, void>>>;

  /** {@inheritDoc TransactableAstridDao.redeemGai} */
  redeemGai(
    amount: Decimalish,
    maxRedemptionRate?: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, RedemptionDetails>>>;

  /** {@inheritDoc TransactableAstridDao.claimCollateralSurplus} */
  claimCollateralSurplus(): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, void>>>;

  /** {@inheritDoc TransactableAstridDao.stakeGOK} */
  stakeGOK(
    amount: Decimalish,
    lockedUntil: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, void>>>;

  /** {@inheritDoc TransactableAstridDao.unstakeGOK} */
  unstakeGOK(id: BigNumber): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, void>>>;

  /** {@inheritDoc TransactableAstridDao.withdrawGainsFromStaking} */
  withdrawGainsFromStaking(): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, void>>>;

  /** {@inheritDoc TransactableAstridDao.approveWrappedTokens} */
  approveWrappedTokens(
    allowance?: Decimalish
  ): Promise<SentAstridDaoTransaction<S, AstridDaoReceipt<R, void>>>;
}
