import { CollateralDecimal, Decimal18 } from "@astrid-dao/lib-base";

import { DashboardDecimal18 } from "../components/Dashboard/models/decimals/DashboardDecimal18";
import { SHORTENED_AMOUNT_THRESHOLD, TOKEN_PRECISION } from "../constants";
import { shortenAmount } from "./shorten";

export const getNumberOfDigits = (number: number): number => {
  return Math.max(Math.floor(Math.log10(Math.abs(number))), 0) + 1;
};

export const getAmountToDisplay = (
  amount: Decimal18 | CollateralDecimal | DashboardDecimal18 | string,
  digitThreshold: number = SHORTENED_AMOUNT_THRESHOLD
): [staticAmountToDisplay: string, shouldShortenAmount: boolean] => {
  let staticAmountToDisplay = amount;
  let shouldShortenAmount = false;

  if (typeof amount === "string") {
    const amountWithoutCommas = amount.replaceAll(",", "");
    const numberOfDigits = getNumberOfDigits(Number(amountWithoutCommas));

    shouldShortenAmount = numberOfDigits >= digitThreshold;
    staticAmountToDisplay = shouldShortenAmount ? shortenAmount(amount) : amount;
  } else {
    const rawAmount = amount.toString(TOKEN_PRECISION);
    const numberOfDigits = getNumberOfDigits(Number(rawAmount));

    shouldShortenAmount = numberOfDigits >= digitThreshold;

    staticAmountToDisplay = shouldShortenAmount
      ? amount.shorten({ shouldShowRawValue: true })
      : rawAmount;
  }

  return [staticAmountToDisplay, shouldShortenAmount];
};
