import { CHAIN_ID, GasEstimator } from "@astrid-dao/lib-base";
import { BigNumber, utils } from "ethers";
import { useAccount, useNetwork } from "wagmi";

import { astridDaoFrontendConfigProxy } from "./config";
import { ChainId } from "./config/chains";

const singletonGasEstimator = new GasEstimator(ChainId.Astar);
const GAS_PRICE = {
  MIN: BigNumber.from("3000000000"),
  MAX: BigNumber.from("10000000000")
} as const;
const ETHERS_TRANSACTION_OVERRIDES = {
  gasPrice: GAS_PRICE.MIN
};

export const updateGasEstimatorChainId = (id: number) => {
  singletonGasEstimator.updateChainId(id);
};

export const ethersTransactionOverridesProxy = new Proxy(ETHERS_TRANSACTION_OVERRIDES, {
  get: (object, property) => {
    // DEBUG: Uncomment for debugging
    // console.log(`${new Date()} | The ${String(property)} is ${Reflect.get(object, property)}`);

    return Reflect.get(object, property);
  },

  set: (object, property, value: BigNumber) => {
    if (property === "gasPrice") {
      if (!value) {
        console.log("Please provide a valid gas price.");

        return false;
      }
    }

    // if (value.gt(GAS_PRICE.MAX)) {
    //   value = GAS_PRICE.MAX;
    // }
    // DEBUG: Uncomment for debugging
    console.log(
      `${new Date()} | Setting ${String(property)} to ${formatGasPriceInGwei(value.toString())}`
    );

    return Reflect.set(object, property, value);
  }
});

singletonGasEstimator.subscribe(({ didPriceChange, estimatedGasPrice }) => {
  if (didPriceChange) {
    ethersTransactionOverridesProxy.gasPrice = BigNumber.from(estimatedGasPrice.average)
      .mul(130)
      .div(100);
  }
});

function formatGasPriceInGwei(price: string): number {
  const gasInGwei = utils.formatUnits(price, "gwei");

  return Number(gasInGwei);
}
