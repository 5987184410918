import { EvmPriceServiceConnection, Price, PriceFeed } from "@pythnetwork/pyth-evm-js";
import { Provider, Signer } from "@wagmi/core";
import { USE_TESTNET } from "../config";
import IPythAbi from "../rpc-contract-service/abi/IPyth.json";
import { currencyPricePythMap } from "../rpc-contract-service/contracts";
import { COLLAT } from "../strings";
import { getCollateralOracle, SupportedCollaterals } from "@astrid-dao/lib-base";
import { BigNumber, Contract, ethers } from "ethers";
import { Box, Close, Text, Flex, Spinner } from "theme-ui";
import ReactDOM from "react-dom";
import MosaicCard from "../components/MosaicCard";
import "./TipBox.css";
import { ethersTransactionOverridesProxy } from "../gasEstimator";

interface BoxProps {
  isShow: boolean;
  title: string;
  content: string[];
  onLoading?: boolean;
}

const TipBox: React.FC<BoxProps> = ({ isShow, title, content, onLoading }) => {
  if (!isShow) return null;

  return (
    <MosaicCard
      sx={{
        position: "fixed",
        right: ["16px", "16px", "82px"],
        bottom: ["24px", "24px", "52px"],
        zIndex: 10000,
        width: "314px",
        height: "240px"
      }}
    >
      <Flex
        sx={{
          display: "flex",
          padding: "16px 0px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "12px",
          px: 4
        }}
      >
        <Flex>
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
              flex: "1 0 0"
            }}
          >
            <Text variant="mediumH3" sx={{ color: "var(--text-text-4, #4E5969)" }}>
              {title}
            </Text>
          </Flex>

          {onLoading ? (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="tip-box-loader"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11 1.69231C5.8595 1.69231 1.69231 5.8595 1.69231 11C1.69231 16.1405 5.8595 20.3077 11 20.3077C16.1405 20.3077 20.3077 16.1405 20.3077 11H22C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0V1.69231Z"
                fill="#FE207E"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_6504_29340)">
                <path
                  d="M11.4281 15.9281C10.9172 16.4391 10.0828 16.4391 9.57187 15.9281L6.57187 12.9281C6.06094 12.4172 6.06094 11.5828 6.57187 11.0719C7.08281 10.5609 7.91719 10.5609 8.42813 11.0719L10.5 13.1437L15.5719 8.07187C16.0828 7.56094 16.9172 7.56094 17.4281 8.07187C17.9391 8.58281 17.9391 9.41719 17.4281 9.92813L11.4281 15.9281ZM24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12ZM12 2.25C6.61406 2.25 2.25 6.61406 2.25 12C2.25 17.3859 6.61406 21.75 12 21.75C17.3859 21.75 21.75 17.3859 21.75 12C21.75 6.61406 17.3859 2.25 12 2.25Z"
                  fill="#00D09E"
                />
              </g>
              <defs>
                <clipPath id="clip0_6504_29340">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </Flex>

        {content.map((paragraph, index) => (
          <Text
            variant="regular14"
            sx={{ color: "var(--text-text-3, #86909C)", fontSize: "14px", lineHeight: "18px" }}
          >
            {paragraph}
          </Text>
        ))}
      </Flex>
    </MosaicCard>
  );
};

let tipBoxId: string | null = null;

export function showUpdatePriceTip(isShow: boolean) {
  if (tipBoxId && !isShow) {
    // first fetch the original box
    const originalBox = document.getElementById(tipBoxId);
    // inform your TipBox the loading state changed
    ReactDOM.render(
      <TipBox
        isShow={true}
        title="To update oracle price feed on Manta"
        content={[
          "Due to the Pyth oracle set up on Manta Pacific, users are required to initiate a preliminary call in the wallet to update the price feed before proceeding to the next step.",
          "We appreciate your understanding for the inconvenience."
        ]}
        onLoading={false}
      />,
      originalBox
    );
    // remove the component after 6 seconds
    setTimeout(() => {
      if (originalBox) {
        ReactDOM.unmountComponentAtNode(originalBox);
        originalBox.remove();
        tipBoxId = null;
      }
    }, 6000); // 6000 ms = 6 seconds
  } else if (!tipBoxId && isShow) {
    const box = document.createElement("div");
    tipBoxId = `tipBox-${Date.now()}`;
    box.id = tipBoxId;
    document.body.appendChild(box);

    ReactDOM.render(
      <TipBox
        isShow={isShow}
        title="To update oracle price feed on Manta"
        content={[
          "Due to the Pyth oracle set up on Manta Pacific, users are required to initiate a preliminary call in the wallet to update the price feed before proceeding to the next step.",
          "We appreciate your understanding for the inconvenience."
        ]}
        onLoading={isShow}
      />,
      box
    );
  }
}

const hermesConnectionTestnet = new EvmPriceServiceConnection("https://hermes-beta.pyth.network/"); // See Hermes endpoints section below for other endpoints

const hermesConnectionMainnet = new EvmPriceServiceConnection("https://hermes.pyth.network/"); // See Hermes endpoints section below for other endpoints

const PYTH_CONTRACT_ADDRESS_TESTNET = "0xA2aa501b19aff244D90cc15a4Cf739D2725B5729";
const PYTH_CONTRACT_ADDRESS_MAINNET = "0xA2aa501b19aff244D90cc15a4Cf739D2725B5729";

/**
 * Fetches the latest price feeds for the given ids from the Hermes connection.
 * @param ids - An array of string ids for the price feeds to fetch.
 * @returns A Promise that resolves to an array of PriceFeed objects.
 * @throws An error if the price feeds are not available.
 */
export async function fetchPriceFeeds(ids: string[]): Promise<PriceFeed[]> {
  let connection = USE_TESTNET ? hermesConnectionTestnet : hermesConnectionMainnet;
  const pythPriceFeed = await connection.getLatestPriceFeeds(ids);
  if (!pythPriceFeed || !pythPriceFeed[0]) {
    throw new Error("Price feeds are not available");
  }
  return pythPriceFeed;
}

export async function fetchPriceAndUpdate(
  signer: Signer | undefined | null
): Promise<{ success: boolean; reason?: string }> {
  if (!getCollateralOracle(USE_TESTNET).PYTH.includes(COLLAT() as SupportedCollaterals)) {
    return Promise.resolve({ success: true });
  }

  if (!signer) return Promise.resolve({ success: false });

  let connection = USE_TESTNET ? hermesConnectionTestnet : hermesConnectionMainnet;

  const config = currencyPricePythMap.get(COLLAT() as SupportedCollaterals);
  console.log("fetchPriceAndUpdate", config);
  if (!config) {
    return Promise.resolve({ success: false, reason: "Config not correctly." });
  }
  try {
    showUpdatePriceTip(true);

    const priceUpdateData = await connection.getPriceFeedsUpdateData([config!.params.id]);
    if (!priceUpdateData || !priceUpdateData[0]) {
      showUpdatePriceTip(false);
      return Promise.resolve({ success: false, reason: "Price feeds are not available" });
    }

    const contract = new Contract(
      USE_TESTNET ? PYTH_CONTRACT_ADDRESS_TESTNET : PYTH_CONTRACT_ADDRESS_MAINNET,
      IPythAbi,
      signer
    );
    let oneMinuteFreshPrice: Price | undefined;
    try {
      oneMinuteFreshPrice = await contract.getPriceNoOlderThan(config!.params.id, 60);
    } catch (e) {
      console.log("getPriceNoOlderThan failed. Need a new price", e);
      oneMinuteFreshPrice = undefined;
    }

    if (!oneMinuteFreshPrice) {
      const updateFee: BigNumber = await contract.getUpdateFee(priceUpdateData);
      console.log("updateFee", updateFee, "priceUpdateData", priceUpdateData);

      const gasLimit = await contract.estimateGas.updatePriceFeeds(priceUpdateData, {
        value: updateFee.toNumber()
      });
      const tx = await contract.updatePriceFeeds(priceUpdateData, {
        value: updateFee.toNumber(),
        gasLimit: gasLimit.mul(BigNumber.from("15")).div(BigNumber.from("10")),
        gasPrice: ethersTransactionOverridesProxy.gasPrice
      });

      console.log("Transaction Hash:", tx.hash);

      const receipt = await tx.wait();

      console.log("Transaction Receipt:", receipt);
    }
  } catch (e) {
    console.error(e);
    showUpdatePriceTip(false);
    return Promise.resolve({ success: false, reason: e?.toString() });
  }

  showUpdatePriceTip(false);
  return Promise.resolve({ success: true });
}
