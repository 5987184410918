import { BigNumber } from "@ethersproject/bignumber";
import { CollateralDecimal } from "./CollateralDecimal";
import { Decimal18, Decimalish } from "./Decimal18";

export type LockedStake = {
  id: BigNumber;
  amount: Decimal18;
  lockedUntil: Decimal18;
  stakeWeight: Decimal18;
  nextId: BigNumber;
  prevId: BigNumber;
};

/**
 * Represents the change between two states of an GOK Stake.
 *
 * @public
 */
export type GOKStakeChange<T> =
  | { stakeGOK: T; unstakeGOK?: undefined; lockedUntil: T }
  | { stakeGOK?: undefined; unstakeGOK: T; unstakeAllGOK: boolean; lockedUntil: undefined };

/** 
 * Represents a user's GOK stake and accrued gains.
 * 
 * @remarks
 * Returned by the {@link ReadableAstridDao.getGOKStake | getGOKStake()} function.

 * @public
 */
export class GOKStake {
  /** The amount of GOK that's staked. */
  readonly stakedGOK: Decimal18;

  /** Collateral gain available to withdraw. */
  readonly collateralGain: CollateralDecimal;

  /** GAIgain available to withdraw. */
  readonly gaiGain: Decimal18;

  /** Timestamp to reach before the locked stakes are available for unstaking. */
  readonly lockedUntil: Decimal18;

  /** The amount of unweighted GOK that's staked. */
  readonly unweightedStakes: Decimal18;

  /** The amount of weighted GOK that's staked. */
  readonly weightedStakes: Decimal18;

  /** @internal */
  constructor(
    stakedGOK = Decimal18.ZERO,
    collateralGain = CollateralDecimal.ZERO,
    gaiGain = Decimal18.ZERO,
    lockedUntil = Decimal18.ZERO,
    weightedStakes = Decimal18.ZERO,
    unweightedStakes = Decimal18.ZERO
  ) {
    this.stakedGOK = stakedGOK;
    this.collateralGain = collateralGain;
    this.gaiGain = gaiGain;
    this.lockedUntil = lockedUntil;
    this.weightedStakes = weightedStakes;
    this.unweightedStakes = unweightedStakes;
  }

  get isEmpty(): boolean {
    return (
      this.stakedGOK.isZero &&
      this.collateralGain.isZero &&
      this.gaiGain.isZero &&
      this.lockedUntil.isZero &&
      this.weightedStakes.isZero &&
      this.unweightedStakes.isZero
    );
  }

  /** @internal */
  toString(): string {
    return (
      `{ stakedGOK: ${this.stakedGOK}` +
      `, collateralGain: ${this.collateralGain}` +
      `, lockedUntil: ${this.lockedUntil}` +
      `, weightedStakes: ${this.weightedStakes}` +
      `, unweightedStakes: ${this.unweightedStakes}` +
      `, gaiGain: ${this.gaiGain} }`
    );
  }

  /**
   * Compare to another instance of `GOKStake`.
   */
  equals(that: GOKStake): boolean {
    return (
      this.stakedGOK.eq(that.stakedGOK) &&
      this.collateralGain.eq(that.collateralGain) &&
      this.gaiGain.eq(that.gaiGain) &&
      this.lockedUntil.eq(that.lockedUntil) &&
      this.weightedStakes.eq(that.weightedStakes) &&
      this.unweightedStakes.eq(that.unweightedStakes)
    );
  }

  /**
   * Calculate the difference between this `GOKStake` and `thatStakedGOK`.
   *
   * @returns An object representing the change, or `undefined` if the staked amounts are equal.
   */
  whatChanged(
    thatStakedGOK: Decimalish,
    thatLockedUntil: Decimalish
  ): GOKStakeChange<Decimal18> | undefined {
    thatStakedGOK = Decimal18.from(thatStakedGOK);
    thatLockedUntil = Decimal18.from(thatLockedUntil);

    if (thatStakedGOK.lt(this.stakedGOK)) {
      return {
        unstakeGOK: this.stakedGOK.sub(thatStakedGOK),
        unstakeAllGOK: thatStakedGOK.isZero,
        lockedUntil: undefined
      };
    }

    if (thatStakedGOK.gt(this.stakedGOK)) {
      return {
        stakeGOK: thatStakedGOK.sub(this.stakedGOK),
        lockedUntil: thatLockedUntil
      };
    }
  }

  /**
   * Apply a {@link GOKStakeChange} to this `GOKStake`.
   *
   * @returns The new staked GOK amount.
   */
  apply(change: GOKStakeChange<Decimalish> | undefined): Decimal18 {
    if (!change) {
      return this.stakedGOK;
    }

    if (change.lockedUntil && !change.stakeGOK) {
      return this.lockedUntil.add(change.lockedUntil);
    }

    if (change.unstakeGOK !== undefined) {
      return change.unstakeAllGOK || this.stakedGOK.lte(change.unstakeGOK)
        ? Decimal18.ZERO
        : this.stakedGOK.sub(change.unstakeGOK);
    } else {
      return this.stakedGOK.add(change.stakeGOK);
    }
  }
}
