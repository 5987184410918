import { SupportedCollaterals } from "@astrid-dao/lib-base";
import { PRECISION_IN_, collateralDecimalPerCollateralType } from "../../../../config";

export type CollateralDecimalPerCollateralType = Record<SupportedCollaterals, number>;

export class CollateralDecimalService {
  static getPrecisionByCollateralType(collateralType: SupportedCollaterals): number {
    return collateralDecimalPerCollateralType?.[collateralType] ?? PRECISION_IN_.WAD;
  }
}
