import { BigNumber } from "@ethersproject/bignumber";

import {
  CollateralDecimal,
  Decimal18,
  SupportedCollaterals,
  _COLLATERAL_DECIMAL_PRECISION
} from "@astrid-dao/lib-base";

export const numberify = (bigNumber: BigNumber): number => bigNumber.toNumber();

export const decimalify18 = (bigNumber: BigNumber): Decimal18 =>
  Decimal18.fromBigNumberString(bigNumber.toHexString());

export const decimalifyCollateralDecimal = (bigNumber: BigNumber): CollateralDecimal =>
  CollateralDecimal.fromBigNumberString(bigNumber.toHexString());

export const convertBigNumberPriceToDisplayAcceptablePrice = (bigNumber: BigNumber): Decimal18 => {
  const priceToDescale = Decimal18.fromBigNumberString(bigNumber.toHexString());
  const DECIMAL_DESCALER = 10 ** (18 - _COLLATERAL_DECIMAL_PRECISION);

  return priceToDescale.div(DECIMAL_DESCALER);
};

export const convertBigNumberPriceToDisplayAcceptablePriceRedStoneClassical = (
  bigNumber: BigNumber,
  collateralDecimalPrecision: number
): Decimal18 => {
  const priceToDescale = Decimal18.fromBigNumberString(bigNumber.toHexString());
  const DECIMAL_DESCALER = 10 ** (18 - collateralDecimalPrecision);

  return priceToDescale.div(DECIMAL_DESCALER);
};

export const convertBigNumberPriceToDisplayAcceptablePriceRedStone = (
  bigNumber: BigNumber
): Decimal18 => {
  const priceToDescale = Decimal18.fromBigNumberString(bigNumber.toHexString());
  const DECIMAL_DESCALER = 10 ** (18 - 8);

  return priceToDescale.mul(DECIMAL_DESCALER);
};

export const convertDisplayPriceToContractAcceptablePrice = (price: Decimal18): Decimal18 => {
  const DECIMAL_SCALER = 10 ** (18 - _COLLATERAL_DECIMAL_PRECISION);

  return price.mul(DECIMAL_SCALER);
};

export const panic = <T>(e: unknown): T => {
  throw e;
};

export type Resolved<T> = T extends Promise<infer U> ? U : T;
export type ResolvedValues<T> = { [P in keyof T]: Resolved<T[P]> };

export const promiseAllValues = <T>(object: T): Promise<ResolvedValues<T>> => {
  const keys = Object.keys(object);

  return Promise.all(Object.values(object)).then(values =>
    Object.fromEntries(values.map((value, i) => [keys[i], value]))
  ) as Promise<ResolvedValues<T>>;
};

export function getCurrentTimeInSecondsWithBuffer(buffer = 0): number {
  return Math.round(Date.now() / 1000) + buffer;
}
