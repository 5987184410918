import { useEffect, useState } from "react";

import { Chain, useNetwork } from "wagmi";

import { ChainId } from "../config/chains";

const mantaChainId = 169;
const mantaTestnetChainId = 3441006;

export const mapChainIdToAstar = (id: number) => {
  if (id === mantaTestnetChainId) {
    return ChainId.Shibuya;
  } else if (id === mantaChainId) {
    return ChainId.Astar;
  } else {
    return id;
  }
};

export default function useWrappedChain() {
  const [wrapperChain, updateWrapperChain] = useState<
    { wrappedChain: Chain; realChain: Chain } | undefined
  >(undefined);
  const { chain } = useNetwork();

  useEffect(() => {
    if (chain) {
      updateWrapperChain({
        realChain: chain,
        wrappedChain: { ...chain, id: mapChainIdToAstar(chain.id) }
      });
    }
  }, [chain]);
  return wrapperChain;
}
