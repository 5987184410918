import type { NetworkConfig } from "./types";

export enum ChainId {
  Shibuya = 81,
  Astar = 592
}

export function isSupportChain(chainId: number): chainId is ChainId {
  return Object.values(ChainId).includes(chainId);
}

export const CHAIN_CONFIG: Record<ChainId, NetworkConfig> = {
  [ChainId.Shibuya]: {
    id: 3441006,
    name: "Manta Sepolia Testnet",
    network: "manta-sepolia-testnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: {
      default: {
        http: ["https://pacific-info.sepolia-testnet.manta.network/"],
        webSocket: [`wss://pacific-rpc.sepolia-testnet.manta.network/ws`]
      },
      public: {
        http: ["https://pacific-info.sepolia-testnet.manta.network/"],
        webSocket: [`wss://pacific-rpc.sepolia-testnet.manta.network/ws`]
      }
    },
    blockExplorers: {
      default: {
        name: "manta-testnet Explorer",
        url: "https://pacific-explorer.manta.network/"
      }
    },
    testnet: true
  },

  [ChainId.Astar]: {
    id: 169,
    name: "Manta",
    network: "manta",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: {
      default: {
        http: ["https://pacific-rpc.manta.network/http"],
        webSocket: [`wss://pacific-rpc.manta.network/ws`]
      },
      public: {
        http: ["https://pacific-rpc.manta.network/http"],
        webSocket: [`wss://pacific-rpc.manta.network/ws`]
      }
    },
    blockExplorers: {
      default: {
        name: "Manta Pacific Explorer",
        url: "https://pacific-explorer.manta.network/"
      }
    }
  }
};
