interface Price {
  price: string;
  conf: string;
  expo: number;
  publish_time: number;
}

interface Feed {
  id: string;
  price: Price;
  ema_price: Price;
}

export async function fetchPriceFeeds(ids: string[], isDev: boolean): Promise<Feed[]> {
  const url = new URL(
    `${
      isDev ? "https://hermes-beta.pyth.network/" : "https://hermes.pyth.network/"
    }api/latest_price_feeds`
  );
  ids.forEach(id => url.searchParams.append("ids[]", id));

  const response = await fetch(url.toString());
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}
