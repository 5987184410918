import { createContext, useContext } from "react";

import useSWR from "swr";

import { BaseProp } from "../@types/base-types";
import { DashboardDecimal18 } from "../components/Dashboard/models/decimals/DashboardDecimal18";
import { POLLING_INTERVAL } from "../config";
import { laggy } from "../data/swr-middleware";
import { CollateralPriceMap, getCollateralPriceMap } from "../rpc-contract-service/service";

const URL_FETCH_GAI_PRICE =
  "https://api.dexscreener.com/latest/dex/tokens/0xcd91716ef98798A85E79048B78287B13ae6b99b2";
// const URL_FETCH_ATID_PRICE =
//   "https://api.diadata.org/v1/assetQuotation/Astar/0x5271D85CE4241b310C0B34b7C2f1f036686A6d7C";

const fetchPrice = async () => {
  try {
    const allPrices = await Promise.all([
      // window.fetch(URL_FETCH_ATID_PRICE),
      window.fetch(URL_FETCH_GAI_PRICE)
    ]);

    // const atidPrice = (await allPrices[0].json()).Price;
    // TODO: As we don't have a price for GOK yet, we are hardcoding it to 0.01 USD per GOK. Refer to https://astrid-tech.slack.com/archives/C03UUSXU82W/p1698951922787009?thread_ts=1698949138.277669&cid=C03UUSXU82W
    const gokPrice = 0.01;
    const gaiPrice = (await allPrices?.[0]?.json())?.pairs?.[0]?.priceUsd ?? 1;
    return {
      "gok-token": { usd: gokPrice },
      "gai-stablecoin-api": { usd: gaiPrice },
      "gai-stablecoin": { usd: 1 }
    };
  } catch (e) {
    console.error(e);
  }
  return {
    "gok-token": { usd: 0.01 },
    "gai-stablecoin": { usd: 1 },
    "gai-stablecoin-api": { usd: 1 }
  };
};

export const fetchTokenPrices = async (
  tokens: Readonly<string[]>,
  currencies: Readonly<string[]>,
  isTestnet: boolean
): Promise<CollateralPriceMap> => {
  const collateralPriceMap = await getCollateralPriceMap({ isTestnet });
  const response = await fetchPrice();

  collateralPriceMap.set(
    "GOK",
    process.env.REACT_APP_USE_MOCK_GOK_PRICE === "true"
      ? DashboardDecimal18.from(process.env.REACT_APP_MOCK_GOK_PRICE ?? "0")
      : DashboardDecimal18.from(response["gok-token"].usd ?? DashboardDecimal18.ZERO)
  );
  collateralPriceMap.set(
    "GAI",
    DashboardDecimal18.from(response["gai-stablecoin"].usd ?? DashboardDecimal18.ZERO)
  );
  collateralPriceMap.set(
    "GAI-API",
    DashboardDecimal18.from(response["gai-stablecoin-api"].usd ?? DashboardDecimal18.ZERO)
  );
  collateralPriceMap.set(
    "xcGAI",
    DashboardDecimal18.from(response["gai-stablecoin"].usd ?? DashboardDecimal18.ZERO)
  );
  return collateralPriceMap;
};

const useTokenPriceData = (tokens: Readonly<string[]>, isTestnet: boolean) => {
  return useSWR(() => [tokens, ["usd"] as const, isTestnet], fetchTokenPrices, {
    use: [laggy],
    suspense: true,
    refreshInterval: POLLING_INTERVAL,
    revalidateOnFocus: false
  });
};

const TokenPricesContext = createContext<CollateralPriceMap | undefined>(undefined);

export const TokenPricesProvider: React.FC<{ isTestnet: boolean } & BaseProp> = ({
  children,
  isTestnet
}) => {
  const { data } = useTokenPriceData(["astriddao-token", "gai-stablecoin"], isTestnet);

  return <TokenPricesContext.Provider value={data}>{children}</TokenPricesContext.Provider>;
};

export const useTokenPrices = () => {
  const tokenPricesContext = useContext(TokenPricesContext);

  if (!tokenPricesContext) {
    throw new Error("You must provide an TokenPricesContext via TokenPricesProvider");
  }

  return tokenPricesContext;
};
