import { Box, ThemeUIStyleObject } from "theme-ui";

export const IconDisconnectedWallet = ({ color }: { color: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6889_20427)">
        <path
          d="M20.0832 12.9146C20.4832 12.5146 20.4832 11.865 20.0832 11.465L15.9872 7.36904C15.5872 6.96904 14.9376 6.96904 14.5376 7.36904C14.1376 7.76904 14.1376 8.41864 14.5376 8.81864L16.8864 11.1674H10.144C9.5776 11.1674 9.12 11.625 9.12 12.1914C9.12 12.7578 9.5776 13.2154 10.144 13.2154H16.8864L14.5376 15.5642C14.1376 15.9642 14.1376 16.6138 14.5376 17.0138C14.9376 17.4138 15.5872 17.4138 15.9872 17.0138L20.0832 12.9178V12.9146ZM9.12 7.07144C9.6864 7.07144 10.144 6.61384 10.144 6.04744C10.144 5.48104 9.6864 5.02344 9.12 5.02344H7.072C5.376 5.02344 4 6.39944 4 8.09544V16.2874C4 17.9834 5.376 19.3594 7.072 19.3594H9.12C9.6864 19.3594 10.144 18.9018 10.144 18.3354C10.144 17.769 9.6864 17.3114 9.12 17.3114H7.072C6.5056 17.3114 6.048 16.8538 6.048 16.2874V8.09544C6.048 7.52904 6.5056 7.07144 7.072 7.07144H9.12Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6889_20427">
          <rect width="16.384" height="16.384" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

type SvgIcons = typeof IconDisconnectedWallet;

type SvgIconProps = {
  icon: SvgIcons;
  alt?: string;
  color?: string;
  hoverColor?: string;
  boxSx?: ThemeUIStyleObject;
  imageSx?: ThemeUIStyleObject;
  onClick?: () => void;
};

export function InfoIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22C17.5234 22 22 17.5234 22 12C22 6.47656 17.5234 2 12 2C6.47656 2 2 6.47656 2 12C2 17.5234 6.47656 22 12 22ZM10.4375 15.125H11.375V12.625H10.4375C9.91797 12.625 9.5 12.207 9.5 11.6875C9.5 11.168 9.91797 10.75 10.4375 10.75H12.3125C12.832 10.75 13.25 11.168 13.25 11.6875V15.125H13.5625C14.082 15.125 14.5 15.543 14.5 16.0625C14.5 16.582 14.082 17 13.5625 17H10.4375C9.91797 17 9.5 16.582 9.5 16.0625C9.5 15.543 9.91797 15.125 10.4375 15.125ZM12 9.5C11.3086 9.5 10.75 8.94141 10.75 8.25C10.75 7.55859 11.3086 7 12 7C12.6914 7 13.25 7.55859 13.25 8.25C13.25 8.94141 12.6914 9.5 12 9.5Z"
        fill="#FE207E"
      />
    </svg>
  );
}

export default function SvgIcon({
  icon,
  alt,
  color,
  hoverColor,
  boxSx,
  imageSx,
  onClick
}: SvgIconProps) {
  return (
    <Box
      sx={{
        width: "40px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
        backgroundColor: "transparent",
        cursor: "pointer",
        ...boxSx,
        "&:hover": {
          backgroundColor: hoverColor ?? "G08"
        }
      }}
      onClick={onClick}
    >
      {icon({ color: color ?? "#777777" })}
    </Box>
  );
}
