import React from "react";

import { Container, Flex } from "theme-ui";

import { Balance } from "./Balance";
import { Logo } from "./LogoArea";
import { PlatformCoinPrice } from "./PlatformCoinPrice";
import { SideNav } from "./SideNav";

export const Header: React.FC = () => {
  // const scrollPosition = useScrollPosition();

  return (
    <Container
      variant="header"
      sx={{
        background: "functionBlue"
      }}
    >
      <Flex
        sx={{
          gap: 3,
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <Flex sx={{ flex: 1, gap: "8px", alignItems: "center" }}>
          <SideNav />
          <Logo />
        </Flex>
        <Flex sx={{ justifyContent: "flex-end" }}>
          {/* <ConvertWastrButton /> */}
          <PlatformCoinPrice />
          {/* <Balance ignoreClick /> */}
          <Balance showWalletAddress />
        </Flex>
      </Flex>
    </Container>
  );
};
