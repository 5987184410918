import { Block, BlockTag } from "@ethersproject/abstract-provider";
import { Signer } from "@ethersproject/abstract-signer";

import {
  Decimal18,
  hasKey,
  CollateralConstantsFromDeployment,
  CHAIN_ID,
  SupportedCollaterals
} from "@astrid-dao/lib-base";

import devDeploymentOrNull from "../deployments/dev.json";

import { numberify, panic } from "./_utils";
import { EthersProvider, EthersSigner } from "./types";

import {
  _connectToContracts,
  _AstridDaoContractAddresses,
  _AstridDaoContracts,
  _AstridDaozkEVMDeploymentJSON
} from "./contracts";

import { _connectToMulticall, _Multicall } from "./_Multicall";
import assert from "assert";
import { DEPLOYMENTS_FILES } from "./config";

// NOTE: DO NOT REMOVE! This fixes error TS2531: Object is possibly 'null' on build time.
const dev = devDeploymentOrNull as _AstridDaozkEVMDeploymentJSON | null;

const chainIdDeploymentMap: {
  [chainId: number]: {
    [collateralName: string]: _AstridDaozkEVMDeploymentJSON | null | any;
  };
} = {
  ...DEPLOYMENTS_FILES,
  ...(dev !== null
    ? {
        [CHAIN_ID.dev]: {
          DEV: dev
        }
      }
    : {})
} as const;

function getDeployment(
  collateralName: SupportedCollaterals,
  chainId: number
): _AstridDaozkEVMDeploymentJSON {
  const defaultDeployment = {} as _AstridDaozkEVMDeploymentJSON;
  const deploymentMap = chainIdDeploymentMap[chainId];
  const deploymentJSON = deploymentMap?.[collateralName] ?? defaultDeployment;

  return deploymentJSON;
}

/** Set on environment initialization before app start */
let _collateralName: SupportedCollaterals;

const setCollateralName = (name: SupportedCollaterals) => {
  _collateralName = name;
};

export const getDeploymentConstantsFor = (
  chainId: number,
  collateralName: SupportedCollaterals
): CollateralConstantsFromDeployment => {
  const errors: Error[] = [];

  if (!collateralName) {
    errors.push(new Error("getDeploymentConstantsFor(): Collateral name required."));
  }

  // Inteded side-effect to provide collateral name to the file
  setCollateralName(collateralName);

  if (!getDeployment(collateralName, chainId)) {
    errors.push(
      new Error(`getDeploymentConstantsFor(): Collateral name (${collateralName}) is not supported.`)
    );
  }

  const deploymentJson =
    getDeployment(collateralName, chainId) ?? ({} as _AstridDaozkEVMDeploymentJSON);

  ["MCR", "CCR", "decimals"].forEach(constantToCheck => {
    if (!hasKey(deploymentJson, constantToCheck)) {
      errors.push(
        new Error(
          `getDeploymentConstantsFor(): Collateral name (${collateralName}) doesn't have required property ${constantToCheck}.`
        )
      );
    }
  });

  const unscaledMCR = Number(deploymentJson?.MCR ?? 0) / 10 ** 18;
  const unscaledCCR = Number(deploymentJson?.CCR ?? 0) / 10 ** 18;
  const decimals = Number(deploymentJson?.decimals ?? 0);

  return {
    MCR: unscaledMCR,
    CCR: unscaledCCR,
    decimals,
    errors
  };
};

declare const brand: unique symbol;

const branded = <T>(t: Omit<T, typeof brand>): T => t as T;

/**
 * Information about a connection to the AstridDAO protocol.
 *
 * @remarks
 * Provided for debugging / informational purposes.
 *
 * Exposed through {@link ReadableEthersAstridDao.connection} and {@link EthersAstridDao.connection}.
 *
 * @public
 */
export interface EthersAstridDaoConnection extends EthersAstridDaoConnectionOptionalParams {
  /** Ethers `Provider` used for connecting to the network. */
  readonly provider: EthersProvider;

  /** Collateral Name */
  readonly collateralName?: string;

  /** Ethers `Signer` used for sending transactions. */
  readonly signer?: EthersSigner;

  /** Chain ID of the connected network. */
  readonly chainId: number;

  /** Version of the AstridDAO contracts (Git commit hash). */
  readonly version: string;

  /** Decimal of collateral */
  readonly decimals: string;

  /** Date when the AstridDAO contracts were deployed. */
  readonly deploymentDate: Date;

  /** Number of block in which the first AstridDAO contract was deployed. */
  readonly startBlock: number;

  /** Time period (in seconds) after `deploymentDate` during which redemptions are disabled. */
  readonly bootstrapPeriod: number;

  /** Total amount of ATID allocated for rewarding stability depositors. */
  readonly totalStabilityPoolTokenReward: Decimal18;

  /** A mapping of AstridDAO contracts' names to their addresses. */
  readonly addresses: Record<string, string>;

  /** Pyth Contract Address and Price Feed ID */
  readonly pythConfigs: {
    pythContract: string;
    priceFeedID: string;
    collateralPriceScaleFactor: number;
  };

  /** @internal */
  readonly _priceFeedIsTestnet: boolean;

  /** @internal */
  readonly _isDev: boolean;

  /** @internal */
  readonly [brand]: unique symbol;
}

/** @internal */
export interface _InternalEthersAstridDaoConnection extends EthersAstridDaoConnection {
  readonly addresses: _AstridDaoContractAddresses;
  readonly _contracts: _AstridDaoContracts;
  readonly _multicall?: _Multicall;
}

const connectionFrom = (
  provider: EthersProvider,
  signer: EthersSigner | undefined,
  _contracts: _AstridDaoContracts,
  _multicall: _Multicall | undefined,
  {
    deploymentDate,
    totalStabilityPoolTokenReward: totalStabilityPoolTokenReward,
    ...deployment
  }: _AstridDaozkEVMDeploymentJSON,
  optionalParams?: EthersAstridDaoConnectionOptionalParams
): _InternalEthersAstridDaoConnection => {
  if (
    optionalParams &&
    optionalParams.useStore !== undefined &&
    !validStoreOptions.includes(optionalParams.useStore)
  ) {
    throw new Error(`Invalid useStore value ${optionalParams.useStore}`);
  }

  return branded({
    provider,
    signer,
    _contracts,
    _multicall,
    deploymentDate: new Date(deploymentDate),
    totalStabilityPoolTokenReward: Decimal18.from(totalStabilityPoolTokenReward),
    ...deployment,
    ...optionalParams
  });
};

/** @internal */
export const _getContracts = (connection: EthersAstridDaoConnection): _AstridDaoContracts =>
  (connection as _InternalEthersAstridDaoConnection)._contracts;

const getMulticall = (connection: EthersAstridDaoConnection): _Multicall | undefined =>
  (connection as _InternalEthersAstridDaoConnection)._multicall;

const getTimestampFromBlock = ({ timestamp }: Block) => timestamp;

/** @internal */
export const _getBlockTimestamp = (
  connection: EthersAstridDaoConnection,
  blockTag: BlockTag = "latest"
): Promise<number> =>
  // Get the timestamp via a contract call whenever possible, to make it batchable with other calls
  getMulticall(connection)?.getCurrentBlockTimestamp({ blockTag }).then(numberify) ??
  _getProvider(connection).getBlock(blockTag).then(getTimestampFromBlock);

/** @internal */
export const _requireSigner = (connection: EthersAstridDaoConnection): EthersSigner =>
  connection.signer ?? panic(new Error("Must be connected through a Signer"));

/** @internal */
export const _getProvider = (connection: EthersAstridDaoConnection): EthersProvider =>
  connection.provider;

// TODO parameterize error message?
/** @internal */
export const _requireAddress = (
  connection: EthersAstridDaoConnection,
  overrides?: { from?: string }
): string =>
  overrides?.from ?? connection.userAddress ?? panic(new Error("A user address is required"));

/** @internal */
export const _usingStore = (
  connection: EthersAstridDaoConnection
): connection is EthersAstridDaoConnection & { useStore: EthersAstridDaoStoreOption } =>
  connection.useStore !== undefined;

/**
 * Thrown when trying to connect to a network where AstridDAO is not deployed.
 *
 * @remarks
 * Thrown by {@link ReadableEthersAstridDao.(connect:2)} and {@link EthersAstridDao.(connect:2)}.
 *
 * @public
 */
export class UnsupportedNetworkError extends Error {
  /** Chain ID of the unsupported network. */
  readonly chainId: number;

  /** @internal */
  constructor(chainId: number) {
    super(`Unsupported network (chainId = ${chainId})`);
    this.name = "UnsupportedNetworkError";
    this.chainId = chainId;
  }
}

const getProviderAndSigner = (
  signerOrProvider: EthersSigner | EthersProvider
): [provider: EthersProvider, signer: EthersSigner | undefined] => {
  const provider = Signer.isSigner(signerOrProvider)
    ? signerOrProvider.provider ?? panic(new Error("Signer must have a Provider"))
    : signerOrProvider;

  assert(provider);

  const signer = Signer.isSigner(signerOrProvider) ? signerOrProvider : undefined;

  return [provider as EthersProvider, signer];
};

/** @internal */
export const _connectToDeployment = (
  deployment: _AstridDaozkEVMDeploymentJSON,
  signerOrProvider: EthersSigner | EthersProvider,
  optionalParams?: EthersAstridDaoConnectionOptionalParams
): EthersAstridDaoConnection =>
  connectionFrom(
    ...getProviderAndSigner(signerOrProvider),
    _connectToContracts(signerOrProvider, deployment),
    undefined,
    deployment,
    optionalParams
  );

/**
 * Possible values for the optional
 * {@link EthersAstridDaoConnectionOptionalParams.useStore | useStore}
 * connection parameter.
 *
 * @remarks
 * Currently, the only supported value is `"blockPolled"`, in which case a
 * {@link BlockPolledAstridDaoStore} will be created.
 *
 * @public
 */
export type EthersAstridDaoStoreOption = "blockPolled";

const validStoreOptions = ["blockPolled"];

/**
 * Optional parameters of {@link ReadableEthersAstridDao.(connect:2)} and
 * {@link EthersAstridDao.(connect:2)}.
 *
 * @public
 */
export interface EthersAstridDaoConnectionOptionalParams {
  /**
   * Address whose Vault, Stability Deposit, ATID Stake and balances will be read by default.
   *
   * @remarks
   * For example {@link EthersAstridDao.getVault | getVault(address?)} will return the Vault owned by
   * `userAddress` when the `address` parameter is omitted.
   *
   * Should be omitted when connecting through a {@link EthersSigner | Signer}. Instead `userAddress`
   * will be automatically determined from the `Signer`.
   */
  readonly userAddress?: string;

  /**
   * Create a {@link @astrid-dao/lib-base#AstridDaoStore} and expose it as the `store` property.
   *
   * @remarks
   * When set to one of the available {@link EthersAstridDaoStoreOption | options},
   * {@link ReadableEthersAstridDao.(connect:2) | ReadableEthersAstridDao.connect()} will return a
   * {@link ReadableEthersAstridDaoWithStore}, while
   * {@link EthersAstridDao.(connect:2) | EthersAstridDao.connect()} will return an
   * {@link EthersAstridDaoWithStore}.
   *
   * Note that the store won't start monitoring the blockchain until its
   * {@link @astrid-dao/lib-base#AstridDaoStore.start | start()} function is called.
   */
  readonly useStore?: EthersAstridDaoStoreOption;
}

/** @internal */
export function _connectByChainId<T>(
  provider: EthersProvider,
  signer: EthersSigner | undefined,
  chainId: number,
  collateralName: SupportedCollaterals,
  optionalParams: EthersAstridDaoConnectionOptionalParams & { useStore: T }
): EthersAstridDaoConnection & { useStore: T };

/** @internal */
export function _connectByChainId(
  provider: EthersProvider,
  signer: EthersSigner | undefined,
  chainId: number,
  collateralName: SupportedCollaterals,
  optionalParams?: EthersAstridDaoConnectionOptionalParams
): EthersAstridDaoConnection;

/** @internal */
export function _connectByChainId(
  provider: EthersProvider,
  signer: EthersSigner | undefined,
  chainId: number,
  collateralName: SupportedCollaterals,
  optionalParams?: EthersAstridDaoConnectionOptionalParams
): EthersAstridDaoConnection {
  const deploymentJSON = getDeployment(collateralName, chainId);

  return connectionFrom(
    provider,
    signer,
    _connectToContracts(signer ?? provider, deploymentJSON),
    _connectToMulticall(signer ?? provider, chainId),
    deploymentJSON,
    optionalParams
  );
}

/** @internal */
export const _connect = async (
  signerOrProvider: EthersSigner | EthersProvider,
  optionalParams?: EthersAstridDaoConnectionOptionalParams
): Promise<EthersAstridDaoConnection> => {
  const [provider, signer] = getProviderAndSigner(signerOrProvider);
  if (signer) {
    if (optionalParams?.userAddress !== undefined) {
      throw new Error("Can't override userAddress when connecting through Signer");
    }

    optionalParams = {
      ...optionalParams,
      userAddress: await signer.getAddress()
    };
  }

  const { chainId } = await provider.getNetwork();

  return _connectByChainId(provider, signer, chainId, _collateralName, optionalParams);
};
