import { Decimal18 } from "@astrid-dao/lib-base";
import { Box, Button, Flex, Image, Text } from "theme-ui";
import { useTokenPrices } from "../hooks/useTokenPrices";
import { getCurrencyIcon } from "../currencyIcons";
import { useState } from "react";

interface PopoverProps {
  children: React.ReactNode;
  content: string;
}

const Popover: React.FC<PopoverProps> = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      sx={{ position: "relative" }}
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{children}</Box>
      {isVisible && (
        <Box
          sx={{
            zIndex: 99999,
            position: "absolute",
            mt: "16px",
            p: "10px",
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
            borderRadius: "8px",
            background: "var(--fill-fill-1, #F7F8FA)",
            width: "230px",
            color: "text5",
            transform: "translateX(-46%)",
            "::before": {
              content: '""',
              position: "absolute",
              top: "-16px",
              left: "50%",
              transform: "translateX(-50%)",
              borderWidth: "8px",
              borderStyle: "solid",
              borderColor: "transparent transparent #F7F8FA transparent"
            }
          }}
        >
          {content}
        </Box>
      )}
    </Box>
  );
};

export const PlatformCoinPrice: React.FC<{}> = () => {
  return (
    <Flex
      sx={{
        display: ["none", "none", "flex"],
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        bg: "b20",
        width: "131px",
        height: "40px",
        gap: "8px"
      }}
    >
      <Image
        sx={{
          height: "24px",
          width: "24px",
          background: "#FFFFFF",
          padding: "2px",
          borderRadius: "24px"
        }}
        src={getCurrencyIcon("GOK")}
      />

      <Text
        variant="mediumH3"
        sx={{
          color: "W100",
          ml: "6px"
        }}
      >
        $0.01
      </Text>
      <Popover content="We have not launched the GOK token publicly, so the GOK token doesn't have a market price right now. Therefore we calculate APR based on the estimated token price, $ 0.01. For more information, please visit our documentation. ">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_6069_24096)">
            <path
              d="M8 0.5C3.85742 0.5 0.5 3.85742 0.5 8C0.5 12.1426 3.85742 15.5 8 15.5C12.1426 15.5 15.5 12.1426 15.5 8C15.5 3.85742 12.1426 0.5 8 0.5ZM8 14.0938C4.63965 14.0938 1.90625 11.3601 1.90625 8C1.90625 4.63994 4.63965 1.90625 8 1.90625C11.3604 1.90625 14.0938 4.63994 14.0938 8C14.0938 11.3601 11.3604 14.0938 8 14.0938ZM8 10.3438C7.47266 10.3438 7.0625 10.7539 7.0625 11.2812C7.0625 11.8086 7.44629 12.2188 8 12.2188C8.50098 12.2188 8.9375 11.8086 8.9375 11.2812C8.9375 10.7539 8.50098 10.3438 8 10.3438ZM8.96973 4.25H7.47266C6.33008 4.25 5.42188 5.1582 5.42188 6.30078C5.42188 6.68164 5.74414 7.00391 6.125 7.00391C6.50586 7.00391 6.82812 6.68164 6.82812 6.30078C6.82812 5.94922 7.09473 5.65625 7.44629 5.65625H8.94336C9.32129 5.65625 9.64062 5.94922 9.64062 6.30078C9.64062 6.53516 9.52344 6.71387 9.31836 6.83105L7.64844 7.85352C7.41406 8 7.29688 8.23438 7.29688 8.46875V8.9375C7.29688 9.31836 7.61914 9.64062 8 9.64062C8.38086 9.64062 8.70312 9.31836 8.70312 8.9375V8.87891L10.0244 8.05859C10.6396 7.67773 11.0205 7.00391 11.0205 6.30078C11.0469 5.1582 10.1387 4.25 8.96973 4.25Z"
              fill="white"
              fillOpacity="0.4"
            />
          </g>
          <defs>
            <clipPath id="clip0_6069_24096">
              <rect width="15" height="15" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
          </defs>
        </svg>
      </Popover>
    </Flex>
  );
};
