import { BigNumber } from "@ethersproject/bignumber";

import { DashboardCollateralDecimal } from "./DashboardCollateralDecimal";
import { DashboardDecimal18 } from "./DashboardDecimal18";

export const MAX_UINT_256 = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
export const ONE = BigNumber.from(1);
export const TEN = BigNumber.from(10);
export const stringRepresentationFormat = /^[0-9]*(\.[0-9]*)?(e[-+]?[0-9]+)?([∞])?$/;
export const trailingZeros = /0*$/;
export const magnitudes = ["", "K", "M", "B", "T"];

export const getDigits = (numDigits: number): BigNumber => TEN.pow(numDigits);

/**
 * Types that can be converted into a DashboardDecimal18 or CollateralDecimal.
 *
 * @public
 */
export type BaseDecimalish = DashboardDecimal18 | number | string | DashboardCollateralDecimal;

export interface ShortenOptions {
  /** A flag to format the DashboardDecimal18 to raw values */
  shouldShowRawValue?: boolean;
  /** Specifies the precision */
  rawValuePrecision?: number;
}

export const decimalify18 = (bigNumber: BigNumber): DashboardDecimal18 => {
  return DashboardDecimal18.fromBigNumberString(bigNumber.toHexString());
};

export const scaleUnitOfPrecisionToWad = (decimal: DashboardDecimal18, precision: number) => {
  const scale = BigNumber.from(10).pow(18 - precision);

  return decimal.mul(scale.toString());
};

export const scaleWadToUnitOfPrecision = (decimal: DashboardDecimal18, precision: number) => {
  const scale = BigNumber.from(10).pow(18 - precision);

  return decimal.div(scale.toString());
};
