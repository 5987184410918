import { Decimal18, Decimalish } from "./Decimal18";
/** @internal */ export type _BaseCollateralDeposit<T> = { depositBaseCollateral: T };
/** @internal */ export type _BaseCollateralWithdrawal<T> = { withdrawToBaseCollateral: T };

/** @internal */ export type _NoBaseCollateralDeposit = Partial<_BaseCollateralDeposit<undefined>>;
/** @internal */ export type _NoBaseCollateralWithdrawal = Partial<
  _BaseCollateralWithdrawal<undefined>
>;

/**
 * Represents the change between two ERC20 Token Conversion Change states.
 *
 * @public
 */
export type ERC20TokenConversionChange<T = unknown> =
  | (_BaseCollateralDeposit<T> & _NoBaseCollateralWithdrawal)
  | (_BaseCollateralWithdrawal<T> & _NoBaseCollateralDeposit);

const valueIsDefined = <T>(entry: [string, T | undefined]): entry is [string, T] =>
  entry[1] !== undefined;

type AllowedKey<T> = Exclude<
  {
    [P in keyof T]: T[P] extends undefined ? never : P;
  }[keyof T],
  undefined
>;

const allowedERC20TokenConversionChangeKeys: AllowedKey<ERC20TokenConversionChange>[] = [
  "depositBaseCollateral",
  "withdrawToBaseCollateral"
];

const decimalize = <T>([k, v]: [T, Decimalish]): [T, Decimal18] => [k, Decimal18.from(v)];
const nonZero = <T>([, v]: [T, Decimal18]): boolean => !v.isZero;

function checkAllowedKeys<T>(
  entries: [string, T][]
): asserts entries is [AllowedKey<ERC20TokenConversionChange>, T][] {
  const badKeys = entries
    .filter(([k]) => !(allowedERC20TokenConversionChangeKeys as string[]).includes(k))
    .map(([k]) => `'${k}'`);

  if (badKeys.length > 0) {
    throw new Error(`ERC20TokenConversionChange: property ${badKeys.join(", ")} not allowed`);
  }
}

const erc20TokenConversionChangeFrom = <T>({
  depositBaseCollateral,
  withdrawToBaseCollateral
}: Partial<Record<AllowedKey<ERC20TokenConversionChange>, T>>):
  | ERC20TokenConversionChange<T>
  | undefined => {
  if (depositBaseCollateral !== undefined && withdrawToBaseCollateral !== undefined) {
    throw new Error(
      "ERC20TokenConversionChange: 'depositBaseCollateral' and 'withdrawToBaseCollateral' " +
        "can't be present at the same time"
    );
  }

  if (depositBaseCollateral !== undefined) {
    return { depositBaseCollateral };
  }

  if (withdrawToBaseCollateral !== undefined) {
    return { withdrawToBaseCollateral };
  }
};

const erc20TokenConversionChangeFromEntries = <T>(
  entries: [AllowedKey<ERC20TokenConversionChange>, T][]
): ERC20TokenConversionChange<T> => {
  const params = Object.fromEntries(entries) as Partial<
    Record<AllowedKey<ERC20TokenConversionChange>, T>
  >;

  const erc20TokenConversionChange = erc20TokenConversionChangeFrom(params);

  if (erc20TokenConversionChange !== undefined) {
    return { ...erc20TokenConversionChange };
  }

  if (erc20TokenConversionChange !== undefined) {
    return erc20TokenConversionChange;
  }

  throw new Error("ERC20TokenConversionChange: must include at least one non-zero parameter");
};

/** @internal */
export const _normalizeERC20TokenConversionChange = (
  params: Record<string, Decimalish>
): ERC20TokenConversionChange<Decimal18> => {
  const definedEntries = Object.entries(params).filter(valueIsDefined);
  checkAllowedKeys(definedEntries);
  const nonZeroEntries = definedEntries.map(decimalize).filter(nonZero);

  return erc20TokenConversionChangeFromEntries(nonZeroEntries);
};

/**
 * ERC20 Token Conversion Change and Withdrawal
 *
 * @public
 */
export class ERC20TokenConversion {
  /** Amount of ERC20 Token Conversion Change at the time of the last direct modification. */
  readonly initialBaseCollateral: Decimal18;

  /** Amount of ERC20 Token Conversion Change Balance. */
  readonly currentBaseCollateral: Decimal18;

  /** @internal */
  constructor(initialBaseCollateral: Decimal18, currentBaseCollateral: Decimal18) {
    this.initialBaseCollateral = initialBaseCollateral;
    this.currentBaseCollateral = currentBaseCollateral;

    if (this.currentBaseCollateral.gt(this.initialBaseCollateral)) {
      throw new Error("currentBaseCollateral can't be greater than initialBaseCollateral");
    }
  }

  get isEmpty(): boolean {
    return this.initialBaseCollateral.isZero && this.currentBaseCollateral.isZero;
  }

  /** @internal */
  toString(): string {
    return (
      `{ initialBaseCollateral: ${this.initialBaseCollateral}` +
      `, currentBaseCollateral: ${this.currentBaseCollateral}`
    );
  }

  /**
   * Compare to another instance of `BaseCollateralDeposit`.
   */
  equals(that: ERC20TokenConversion): boolean {
    return (
      this.initialBaseCollateral.eq(that.initialBaseCollateral) &&
      this.currentBaseCollateral.eq(that.currentBaseCollateral)
    );
  }

  /**
   * Calculate the difference between the `currentBaseCollateral` in this  and `thatBaseCollateral`.
   *
   * @returns An object representing the change, or `undefined` if the deposited amounts are equal.
   */
  whatChanged(thatBaseCollateral: Decimalish): ERC20TokenConversionChange<Decimal18> | undefined {
    thatBaseCollateral = Decimal18.from(thatBaseCollateral);

    if (thatBaseCollateral.lt(this.currentBaseCollateral)) {
      return {
        withdrawToBaseCollateral: this.currentBaseCollateral.sub(thatBaseCollateral)
      };
    }

    if (thatBaseCollateral.gt(this.currentBaseCollateral)) {
      return { depositBaseCollateral: thatBaseCollateral.sub(this.currentBaseCollateral) };
    }
  }

  /**
   * Apply a {@link ERC20TokenConversionChange} to this ERC20TokenConversionChange.
   *
   * @returns The new deposited base collateral amount.
   */
  apply(change: ERC20TokenConversionChange<Decimalish>): Decimal18 {
    if (!change) {
      return this.currentBaseCollateral;
    }

    if (change.withdrawToBaseCollateral !== undefined) {
      return this.currentBaseCollateral.lte(change.withdrawToBaseCollateral)
        ? Decimal18.ZERO
        : this.currentBaseCollateral.sub(change.withdrawToBaseCollateral);
    } else {
      return this.currentBaseCollateral.add(change.depositBaseCollateral);
    }
  }
}
