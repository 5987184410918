import { useLocation } from "react-router-dom";
import { Box, Container, Flex, Text } from "theme-ui";

import { Link } from "../Link";
import { SelectCollateral } from "../SelectCollateral";
import { SideBarExternalLinks } from "./SideBarExternalLinks";
import { SideBarNav } from "./SideBarNav";

export const navIconStyles = {
  marginRight: "8px",
  height: "18px",
  width: "18px",
  alignSelf: "center"
};

export const SideBar: React.FC = () => {
  const location = useLocation();

  const currentPath = location.pathname;

  const isCurrentMenu = currentPath === "/dashboard";

  return (
    <Container
      variant="sidebar-block"
      sx={{
        "::-webkit-scrollbar": {
          display: "none"
        }
      }}
    >
      <Flex variant="layout.sidebar" sx={{ p: 6, gap: 5 }}>
        <Flex sx={{ flexDirection: "column", width: "100%" }}>
          <Flex sx={{ flexDirection: "column", width: "100%", mt: 5 }}>
            <Link to="/dashboard">
              <Flex
                sx={{
                  background: isCurrentMenu ? "#4B92FF" : "transparent",
                  borderRadius: "card",
                  width: "100%",
                  height: "48px",
                  alignItems: "center",
                  pl: 3,
                  ":hover": {
                    background: "G05"
                  },
                  gap: "8px"
                }}
              >
                {isCurrentMenu && (
                  <Box sx={{ background: "#FE207E", width: "4px", height: "16px" }} />
                )}
                <Text
                  variant="mediumH3"
                  sx={{
                    color: isCurrentMenu ? "W100" : "W60"
                  }}
                >
                  Dashboard
                </Text>
              </Flex>
            </Link>

            <SelectCollateral />
          </Flex>

          <SideBarNav currentPath={currentPath} />
        </Flex>

        {/* <SideBarExternalLinks currentPath={currentPath} /> */}
      </Flex>
    </Container>
  );
};
