import STONEMantaTestnetDeployment from "../deployments/STONE.mantaSepoliaTestnet.json";
import wUSDMMantaTestnetDeployment from "../deployments/wUSDM.mantaSepoliaTestnet.json";
import USDCMantaTestnetDeployment from "../deployments/USDC.mantaSepoliaTestnet.json";
import TIAMantaTestnetDeployment from "../deployments/TIA.mantaSepoliaTestnet.json";
import MANTAMantaTestnetDeployment from "../deployments/MANTA.mantaSepoliaTestnet.json";
import WETHMantaTestnetDeployment from "../deployments/WETH.mantaSepoliaTestnet.json";
import MANTAMantaDeployment from "../deployments/MANTA.manta.json";
import USDCMantaDeployment from "../deployments/USDC.manta.json";
import WETHMantaDeployment from "../deployments/WETH.manta.json";
import USDTMantaDeployment from "../deployments/USDT.manta.json";
import TIAMantaDeployment from "../deployments/TIA.manta.json";
import STONEMantaDeployment from "../deployments/STONE.manta.json";
import wUSDMDeployment from "../deployments/wUSDM.manta.json";

export const DEPLOYMENTS_FILES = {
  // mantaTestnet
  3441006: {
    STONE: STONEMantaTestnetDeployment,
    wUSDM: wUSDMMantaTestnetDeployment,
    USDC: USDCMantaTestnetDeployment,
    TIA: TIAMantaTestnetDeployment,
    MANTA: MANTAMantaTestnetDeployment,
    WETH: WETHMantaTestnetDeployment
  },
  // manta
  169: {
    USDC: USDCMantaDeployment,
    WETH: WETHMantaDeployment,
    USDT: USDTMantaDeployment,
    TIA: TIAMantaDeployment,
    MANTA: MANTAMantaDeployment,
    STONE: STONEMantaDeployment,
    wUSDM: wUSDMDeployment
  }
};
