import { BlockTag } from "@ethersproject/abstract-provider";

import {
  CollateralGainTransferDetails,
  Decimal18,
  Decimalish,
  FailedReceipt,
  Fees,
  LiquidationDetails,
  AstridDaoStore,
  GOKStake,
  RedemptionDetails,
  StabilityDeposit,
  StabilityDepositChangeDetails,
  StabilityPoolGainsWithdrawalDetails,
  TransactableAstridDao,
  TransactionFailedError,
  Vault,
  VaultAdjustmentDetails,
  VaultAdjustmentParams,
  VaultClosureDetails,
  VaultCreationDetails,
  VaultCreationParams,
  VaultListingParams,
  VaultWithPendingRedistribution,
  UserVault,
  BaseCollateralDepositChangeDetails,
  LockedStake,
  LockedStakesParams,
  CollateralDecimal
} from "@astrid-dao/lib-base";

import {
  EthersAstridDaoConnection,
  EthersAstridDaoConnectionOptionalParams,
  EthersAstridDaoStoreOption,
  _connect,
  _usingStore
} from "./EthersAstridDaoConnection";

import {
  EthersCallOverrides,
  EthersProvider,
  EthersSigner,
  EthersTransactionOverrides,
  EthersTransactionReceipt
} from "./types";

import {
  BorrowingOperationOptionalParams,
  PopulatableEthersAstridDao as PopulatableEthersAstridDao,
  SentEthersAstridDaoTransaction as SentEthersAstridDaoTransaction
} from "./PopulatableEthersAstridDao";
import {
  ReadableEthersAstridDao,
  ReadableEthersAstridDaoWithStore as ReadableEthersAstridDaoWithStore
} from "./ReadableEthersAstridDao";
import { SendableEthersAstridDao } from "./SendableEthersAstridDao";
import { BlockPolledAstridDaoStore } from "./BlockPolledAstridDaoStore";
import { BigNumber } from "ethers";

/**
 * Thrown by {@link EthersAstridDao} in case of transaction failure.
 *
 * @public
 */
export class EthersTransactionFailedError extends TransactionFailedError<
  FailedReceipt<EthersTransactionReceipt>
> {
  constructor(message: string, failedReceipt: FailedReceipt<EthersTransactionReceipt>) {
    super("EthersTransactionFailedError", message, failedReceipt);
  }
}

const waitForSuccess = async <T>(tx: SentEthersAstridDaoTransaction<T>) => {
  const receipt = await tx.waitForReceipt();

  if (receipt.status !== "succeeded") {
    throw new EthersTransactionFailedError("Transaction failed", receipt);
  }

  return receipt.details;
};

/**
 * Convenience class that combines multiple interfaces of the library in one object.
 *
 * @public
 */
export class EthersAstridDao implements ReadableEthersAstridDao, TransactableAstridDao {
  /** Information about the connection to the AstridDAO protocol. */
  readonly connection: EthersAstridDaoConnection;

  /** Can be used to create populated (unsigned) transactions. */
  readonly populate: PopulatableEthersAstridDao;

  /** Can be used to send transactions without waiting for them to be mined. */
  readonly send: SendableEthersAstridDao;

  private _readable: ReadableEthersAstridDao;

  /** @internal */
  constructor(readable: ReadableEthersAstridDao) {
    this._readable = readable;
    this.connection = readable.connection;
    this.populate = new PopulatableEthersAstridDao(readable);
    this.send = new SendableEthersAstridDao(this.populate);
  }

  /** @internal */
  static _from(
    connection: EthersAstridDaoConnection & { useStore: "blockPolled" }
  ): EthersAstridDaoWithStore<BlockPolledAstridDaoStore>;

  /** @internal */
  static _from(connection: EthersAstridDaoConnection): EthersAstridDao;

  /** @internal */
  static _from(connection: EthersAstridDaoConnection): EthersAstridDao {
    if (_usingStore(connection)) {
      return new _EthersAstridDaoWithStore(ReadableEthersAstridDao._from(connection));
    } else {
      return new EthersAstridDao(ReadableEthersAstridDao._from(connection));
    }
  }

  /** @internal */
  static connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams: EthersAstridDaoConnectionOptionalParams & { useStore: "blockPolled" }
  ): Promise<EthersAstridDaoWithStore<BlockPolledAstridDaoStore>>;

  /**
   * Connect to the AstridDAO protocol and create an `EthersAstridDao` object.
   *
   * @param signerOrProvider - Ethers `Signer` or `Provider` to use for connecting to the Ethereum
   *                           network.
   * @param optionalParams - Optional parameters that can be used to customize the connection.
   */
  static connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams?: EthersAstridDaoConnectionOptionalParams
  ): Promise<EthersAstridDao>;

  static async connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams?: EthersAstridDaoConnectionOptionalParams
  ): Promise<EthersAstridDao> {
    return EthersAstridDao._from(await _connect(signerOrProvider, optionalParams));
  }

  /**
   * Check whether this `EthersAstridDao` is an {@link EthersAstridDaoWithStore}.
   */
  hasStore(): this is EthersAstridDaoWithStore;

  /**
   * Check whether this `EthersAstridDao` is an
   * {@link EthersAstridDaoWithStore}\<{@link BlockPolledAstridDaoStore}\>.
   */
  hasStore(store: "blockPolled"): this is EthersAstridDaoWithStore<BlockPolledAstridDaoStore>;

  hasStore(): boolean {
    return false;
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getTotalRedistributed} */
  getTotalRedistributed(overrides?: EthersCallOverrides): Promise<Vault> {
    return this._readable.getTotalRedistributed(overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getVaultBeforeRedistribution} */
  getVaultBeforeRedistribution(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<VaultWithPendingRedistribution> {
    return this._readable.getVaultBeforeRedistribution(address, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getVault} */
  getVault(address?: string, overrides?: EthersCallOverrides): Promise<UserVault> {
    return this._readable.getVault(address, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getNumberOfVaults} */
  getNumberOfVaults(overrides?: EthersCallOverrides): Promise<number> {
    return this._readable.getNumberOfVaults(overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getPrice} */
  getPrice(overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._readable.getPrice(overrides);
  }

  /** @internal */
  _getActivePool(overrides?: EthersCallOverrides): Promise<Vault> {
    return this._readable._getActivePool(overrides);
  }

  /** @internal */
  _getDefaultPool(overrides?: EthersCallOverrides): Promise<Vault> {
    return this._readable._getDefaultPool(overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getTotal} */
  getTotal(overrides?: EthersCallOverrides): Promise<Vault> {
    return this._readable.getTotal(overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getStabilityDeposit} */
  getStabilityDeposit(address?: string, overrides?: EthersCallOverrides): Promise<StabilityDeposit> {
    return this._readable.getStabilityDeposit(address, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getRemainingStabilityPoolGOKReward} */
  getRemainingStabilityPoolGOKReward(overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._readable.getRemainingStabilityPoolGOKReward(overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getGaiInStabilityPool} */
  getGaiInStabilityPool(overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._readable.getGaiInStabilityPool(overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getGaiBalance} */
  getGaiBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._readable.getGaiBalance(address, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getXcGaiBalance} */
  getXcGaiBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._readable.getXcGaiBalance(address, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getGOKBalance} */
  getGOKBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._readable.getGOKBalance(address, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getWrappedTokenBalance} */
  getWrappedTokenBalance(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<CollateralDecimal> {
    return this._readable.getWrappedTokenBalance(address, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getWrappedTokenAllownace} */
  getWrappedTokenAllowance(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<CollateralDecimal> {
    return this._readable.getWrappedTokenAllowance(address, overrides);
  }

  getXcGaiAllowance(address?: string, overrides?: EthersCallOverrides): Promise<CollateralDecimal> {
    return this._readable.getXcGaiAllowance(address, overrides);
  }
  getGaiAllowance(address?: string, overrides?: EthersCallOverrides): Promise<CollateralDecimal> {
    return this._readable.getGaiAllowance(address, overrides);
  }
  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getCollateralSurplusBalance} */
  getCollateralSurplusBalance(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<CollateralDecimal> {
    return this._readable.getCollateralSurplusBalance(address, overrides);
  }

  /** @internal */
  getVaults(
    params: VaultListingParams & { beforeRedistribution: true },
    overrides?: EthersCallOverrides
  ): Promise<VaultWithPendingRedistribution[]>;

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.(getVaults:2)} */
  getVaults(params: VaultListingParams, overrides?: EthersCallOverrides): Promise<UserVault[]>;

  getVaults(params: VaultListingParams, overrides?: EthersCallOverrides): Promise<UserVault[]> {
    return this._readable.getVaults(params, overrides);
  }

  /** @internal */
  _getBlockTimestamp(blockTag?: BlockTag): Promise<number> {
    return this._readable._getBlockTimestamp(blockTag);
  }

  /** @internal */
  _getFeesFactory(
    overrides?: EthersCallOverrides
  ): Promise<(blockTimestamp: number, recoveryMode: boolean) => Fees> {
    return this._readable._getFeesFactory(overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getFees} */
  getFees(overrides?: EthersCallOverrides): Promise<Fees> {
    return this._readable.getFees(overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getGOKStake} */
  getGOKStake(address?: string, overrides?: EthersCallOverrides): Promise<GOKStake> {
    return this._readable.getGOKStake(address, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getTotalWeightedStakedGOK} */
  getTotalWeightedStakedGOK(overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._readable.getTotalWeightedStakedGOK(overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getTotalUnweightedStakedGOK} */
  getTotalUnweightedStakedGOK(overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._readable.getTotalUnweightedStakedGOK(overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getFirstActiveLockedStakeID} */
  async getFirstActiveLockedStakeID(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<BigNumber> {
    return this._readable.getFirstActiveLockedStakeID(address, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getLockedStakes} */
  async getLockedStakes(
    params: LockedStakesParams,
    overrides?: EthersCallOverrides
  ): Promise<LockedStake[]> {
    return this._readable.getLockedStakes(params, overrides);
  }

  async getTotalLockedStakesCount(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<number> {
    return this._readable.getTotalLockedStakesCount(address, overrides);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.openVault}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  openVault(
    params: VaultCreationParams<Decimalish, Decimalish>,
    maxBorrowingRateOrOptionalParams?: Decimalish | BorrowingOperationOptionalParams,
    overrides?: EthersTransactionOverrides
  ): Promise<VaultCreationDetails> {
    return this.send
      .openVault(params, maxBorrowingRateOrOptionalParams, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.closeVault}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  closeVault(overrides?: EthersTransactionOverrides): Promise<VaultClosureDetails> {
    return this.send.closeVault(overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.adjustVault}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  adjustVault(
    params: VaultAdjustmentParams<Decimalish>,
    maxBorrowingRateOrOptionalParams?: Decimalish | BorrowingOperationOptionalParams,
    overrides?: EthersTransactionOverrides
  ): Promise<VaultAdjustmentDetails> {
    return this.send
      .adjustVault(params, maxBorrowingRateOrOptionalParams, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.depositBaseCollateral}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  depositBaseCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<BaseCollateralDepositChangeDetails> {
    return this.send.depositBaseCollateral(amount, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.withdrawToBaseCollateral}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  withdrawToBaseCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<BaseCollateralDepositChangeDetails> {
    return this.send.withdrawToBaseCollateral(amount, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.depositCollateral}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  depositCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<VaultAdjustmentDetails> {
    return this.send.depositCollateral(amount, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.withdrawCollateral}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  withdrawCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<VaultAdjustmentDetails> {
    return this.send.withdrawCollateral(amount, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.borrowGai}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  borrowGai(
    amount: Decimalish,
    maxBorrowingRate?: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<VaultAdjustmentDetails> {
    return this.send.borrowGai(amount, maxBorrowingRate, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.repayGai}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  repayGai(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<VaultAdjustmentDetails> {
    return this.send.repayGai(amount, overrides).then(waitForSuccess);
  }

  /** @internal */
  setPrice(price: Decimalish, overrides?: EthersTransactionOverrides): Promise<void> {
    return this.send.setPrice(price, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.liquidate}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  liquidate(
    address: string | string[],
    overrides?: EthersTransactionOverrides
  ): Promise<LiquidationDetails> {
    return this.send.liquidate(address, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.liquidateUpTo}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  liquidateUpTo(
    maximumNumberOfVaultsToLiquidate: number,
    overrides?: EthersTransactionOverrides
  ): Promise<LiquidationDetails> {
    return this.send.liquidateUpTo(maximumNumberOfVaultsToLiquidate, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.depositGaiInStabilityPool}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  depositGaiInStabilityPool(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<StabilityDepositChangeDetails> {
    return this.send.depositGaiInStabilityPool(amount, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.withdrawGaiFromStabilityPool}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  withdrawGaiFromStabilityPool(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<StabilityDepositChangeDetails> {
    return this.send.withdrawGaiFromStabilityPool(amount, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.withdrawGainsFromStabilityPool}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  withdrawGainsFromStabilityPool(
    overrides?: EthersTransactionOverrides
  ): Promise<StabilityPoolGainsWithdrawalDetails> {
    return this.send.withdrawGainsFromStabilityPool(overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.transferCollateralGainToVault}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  transferCollateralGainToVault(
    overrides?: EthersTransactionOverrides
  ): Promise<CollateralGainTransferDetails> {
    return this.send.transferCollateralGainToVault(overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.sendGai}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  sendGai(
    toAddress: string,
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.sendGai(toAddress, amount, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.sendGOK}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  sendGOK(
    toAddress: string,
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.sendGOK(toAddress, amount, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.redeemGai}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  redeemGai(
    amount: Decimalish,
    maxRedemptionRate?: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<RedemptionDetails> {
    return this.send.redeemGai(amount, maxRedemptionRate, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.claimCollateralSurplus}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  claimCollateralSurplus(overrides?: EthersTransactionOverrides): Promise<void> {
    return this.send.claimCollateralSurplus(overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.stakeGOK}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  stakeGOK(
    amount: Decimalish,
    lockedUntil: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.stakeGOK(amount, lockedUntil, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.unstakeGOK}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  unstakeGOK(id: BigNumber, overrides?: EthersTransactionOverrides): Promise<void> {
    return this.send.unstakeGOK(id, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.withdrawGainsFromStaking}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  withdrawGainsFromStaking(overrides?: EthersTransactionOverrides): Promise<void> {
    return this.send.withdrawGainsFromStaking(overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#TransactableAstridDao.approveUniTokens}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  approveWrappedTokens(
    allowance?: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.approveWrappedTokens(allowance, overrides).then(waitForSuccess);
  }
}

/**
 * Variant of {@link EthersAstridDao} that exposes a {@link @astrid-dao/lib-base#AstridDaoStore}.
 *
 * @public
 */
export interface EthersAstridDaoWithStore<T extends AstridDaoStore = AstridDaoStore>
  extends EthersAstridDao {
  /** An object that implements AstridDaoStore. */
  readonly store: T;
}

class _EthersAstridDaoWithStore<T extends AstridDaoStore = AstridDaoStore>
  extends EthersAstridDao
  implements EthersAstridDaoWithStore<T>
{
  readonly store: T;

  constructor(readable: ReadableEthersAstridDaoWithStore<T>) {
    super(readable);

    this.store = readable.store;
  }

  hasStore(store?: EthersAstridDaoStoreOption): boolean {
    return store === undefined || store === this.connection.useStore;
  }
}
