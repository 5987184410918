import React from "react";

import { library, IconName, IconProp, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import {
  faClipboard,
  faQuestionCircle,
  faCopy,
  faHourglassHalf,
  faCircleCheck
} from "@fortawesome/free-regular-svg-icons";
import {
  faUndo,
  faLock,
  faBriefcase,
  faPlus,
  faMagnifyingGlassDollar,
  faAward,
  faUnlockKeyhole,
  faCircleNotch,
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
  faTrash,
  faChartLine,
  faRedo,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faUserCircle,
  faWallet,
  faExternalLinkAlt,
  faClipboardCheck,
  faCog,
  faCheck,
  faPlug,
  faExclamationCircle,
  faAngleUp,
  faAngleDoubleUp,
  faAngleDown,
  faAngleDoubleDown,
  faPen,
  faHandPaper,
  faHeartbeat,
  faBars,
  faArrowRightFromBracket,
  faVault,
  faChartSimple,
  faCartShopping,
  faGaugeHigh,
  faSeedling,
  faSackXmark,
  faCirclePlus,
  faHandHoldingDollar,
  faMoneyBillTransfer,
  faArrowRight,
  faArrowDown,
  faArrowDownUpAcrossLine,
  faArrowsRotate,
  faPlugCircleXmark,
  faCircleInfo,
  faArrowRightArrowLeft,
  faRetweet,
  faCircleNodes,
  faRepeat,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

library.add(
  faCircleInfo,
  faArrowsRotate,
  faUndo,
  faHourglassHalf,
  faArrowDownUpAcrossLine,
  faMoneyBillTransfer,
  faHandHoldingDollar,
  faCirclePlus,
  faCopy,
  faSackXmark,
  faSeedling,
  faCartShopping,
  faVault,
  faChartSimple,
  faArrowRightFromBracket,
  faLock,
  faXmark,
  faGaugeHigh,
  faBriefcase,
  faArrowRight,
  faPlugCircleXmark,
  faPlus,
  faMagnifyingGlassDollar,
  faAward,
  faCircleNotch,
  faCheck,
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
  faTrash,
  faChartLine,
  faRedo,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faClipboard,
  faClipboardCheck,
  faUserCircle,
  faWallet,
  faExternalLinkAlt,
  faCog,
  faPlug,
  faExclamationCircle,
  faAngleUp,
  faAngleDoubleUp,
  faAngleDown,
  faAngleDoubleDown,
  faPen,
  faHandPaper,
  faHeartbeat,
  faBars,
  faArrowDown,
  faQuestionCircle,
  faUnlockKeyhole,
  faArrowRightArrowLeft,
  faRetweet,
  faCircleCheck,
  faCircleNodes,
  faRepeat
);

const getIcon = (name: IconName, prefix?: IconPrefix): IconProp => {
  switch (name) {
    case "clipboard":
      return ["far", "clipboard"];
    case "question-circle":
      return ["far", "question-circle"];
    default:
      return prefix ? [prefix, name] : name;
  }
};

export type IconProps = Pick<
  FontAwesomeIconProps,
  "style" | "size" | "color" | "spin" | "flip" | "rotation"
> & {
  name: IconName;
  prefix?: IconPrefix;
  hoveredColor?: string;
};

export const Icon: React.FC<IconProps> = ({ name, prefix, style, color, hoveredColor, ...rest }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <FontAwesomeIcon
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={style}
      color={isHovered && hoveredColor ? hoveredColor : color}
      icon={getIcon(name, prefix)}
      {...rest}
    />
  );
};
