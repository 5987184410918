import { CollateralDecimal } from "./CollateralDecimal";
import { Decimal18 } from "./Decimal18";
import { getDigits } from "./decimals";

export function hasKey<K extends string>(o: Record<K, any>, k: K): o is Record<K, any> {
  return k in o;
}

const ONE_MILLION = 1000000 as const;
const ONE_SECOND = 1000 as const;

export type CRITICAL_COLLATERAL_RATIO = "CRITICAL_COLLATERAL_RATIO";
export type MINIMUM_COLLATERAL_RATIO = "MINIMUM_COLLATERAL_RATIO";
export type COLLATERAL_DECIMAL_PRECISION = "COLLATERAL_DECIMAL_PRECISION";

export type EnvInitializationErrors = {
  readonly errors?: Error[];
};

export type CollateralConstants = Record<
  CRITICAL_COLLATERAL_RATIO | MINIMUM_COLLATERAL_RATIO,
  Decimal18
> &
  Record<COLLATERAL_DECIMAL_PRECISION, number> &
  EnvInitializationErrors;

export const RPC_URL_TESTNET = "https://pacific-rpc.sepolia-testnet.manta.network/http";
export const RPC_URL = "https://pacific-rpc.manta.network/http";

export const DEV_MULTICALL_ADDRESS = "0xe12FA0a01107959d8db1c96c16D89c31E19dc55A";
export const PROD_MULTICALL_ADDRESS = "0xcA11bde05977b3631167028862bE2a173976CA11";

// NOTE: We can add more to the list of app environments to support on-chain
// governance testing. We can set it like so REACT_APP_ENV=shibuya-{on-chain-gov}
// in package.json to specify which deployment JSON to access from lib-ethers.
// See more here: https://github.com/AstridDao/frontend/pull/159#discussion_r863467015
export type AppEnvironment =
  | "astar-usdc"
  | "astar-weth"
  | "astar-tia"
  | "astar-usdt"
  | "astar-manta"
  | "astar-stone"
  | "astar-wusdm"
  | "shibuya-stone"
  | "shibuya-usdc"
  | "shibuya-wusdm"
  | "shibuya-manta"
  | "shibuya-weth"
  | "shibuya-tia";

export type SupportedCollaterals = "USDC" | "USDT" | "TIA" | "WETH" | "STONE" | "wUSDM" | "MANTA";

export function getCollateralOracle(isDev: boolean): {
  PYTH: SupportedCollaterals[] | string[];
  REDSTONE_CLASSICAL: SupportedCollaterals[] | string[];
} {
  return isDev
    ? {
        PYTH: [],
        REDSTONE_CLASSICAL: ["USDC", "STONE", "wUSDM", "MANTA", "TIA", "WETH"]
      }
    : {
        PYTH: ["USDT", "USDC", "TIA", "WETH", "MANTA"],
        REDSTONE_CLASSICAL: ["STONE", "wUSDM"]
      };
}

/**
 * Reward weight for each collateral
 *
 * @remarks
 * This will change when we support more collateral types
 *
 * @public
 */
export const REWARD_WEIGHT_PER_COLLATERAL = new Map<SupportedCollaterals, number>([
  ["USDC", 0.05],
  ["WETH", 0.2],
  ["TIA", 0.2],
  ["USDT", 0.05],
  ["MANTA", 0.2],
  ["STONE", 0.25],
  ["wUSDM", 0.05]
]);

export const CHAIN_ID: Readonly<Record<string, number>> = {
  dev: 17,
  shibuya: 81,
  astar: 592
};

export type CollateralConstantsFromDeployment = {
  readonly MCR: number;
  readonly CCR: number;
  readonly decimals: number;
} & EnvInitializationErrors;

/**
 * Total collateral ratio below which recovery mode is triggered.
 *
 * @internal
 */
export let _CRITICAL_COLLATERAL_RATIO: Decimal18;

/**
 * Collateral ratio below which a Vault can be liquidated in normal mode.
 *
 * @internal
 */
export let _MINIMUM_COLLATERAL_RATIO: Decimal18;

/**
 * The collateral type's decimal precision
 *
 * @internal
 */
export let _COLLATERAL_DECIMAL_PRECISION: number;

const setInternalCollateralRatioConstants = ({
  MCR,
  CCR,
  collateralDecimalPrecision
}: {
  MCR: Decimal18;
  CCR: Decimal18;
  collateralDecimalPrecision: number;
}) => {
  _MINIMUM_COLLATERAL_RATIO = MCR;
  _CRITICAL_COLLATERAL_RATIO = CCR;
  _COLLATERAL_DECIMAL_PRECISION = collateralDecimalPrecision;
};

/**
 * Initializes the {@link _MINIMUM_COLLATERAL_RATIO | MCR}, {@link _CRITICAL_COLLATERAL_RATIO | CCR}, and
 * {@link _COLLATERAL_DECIMAL_PRECISION | collateral decimal precision} before the app component gets rendered
 * to dynamically provide the correct constants based on the App environment's collateral type, e.g. WBTC, WETC, or WASTR
 *
 * @remarks
 * This is currently a temporary solution to provide the ability to set the variables and use the same codebase for
 * different collateral types. See more here https://github.com/AstridDao/frontend/issues/113
 *
 * @public
 */
export const initializeConstants = (
  collateralName: AppEnvironment,
  collateralConstantsFromDeployment: CollateralConstantsFromDeployment
): CollateralConstants => {
  const errors: Error[] = [];

  if (!collateralName) {
    errors.push(new Error("initializeConstants: Collateral name required."));
  }

  ["MCR", "CCR", "decimals"].forEach(constantToCheck => {
    if (!hasKey(collateralConstantsFromDeployment, constantToCheck)) {
      errors.push(
        new Error(
          `initializeConstants: Collateral name (${collateralName}) doesn't have required property ${constantToCheck}.`
        )
      );
    }
  });

  const { CCR, MCR, decimals } = collateralConstantsFromDeployment;

  const scaledMCR = Decimal18.from(MCR ?? 0);
  const scaledCCR = Decimal18.from(CCR ?? 0);

  if (scaledMCR.isZero || scaledCCR.isZero || Boolean(decimals)) {
    errors.push(new Error("initializeConstants: CCR, MCR, and decimals were not set correctly!"));
  }

  // This side effect is intended to expose constants to lib-base and lib-ethers packages
  setInternalCollateralRatioConstants({
    MCR: scaledMCR,
    CCR: scaledCCR,
    collateralDecimalPrecision: decimals
  });

  CollateralDecimal.PRECISION = _COLLATERAL_DECIMAL_PRECISION;
  CollateralDecimal.DIGITS = getDigits(_COLLATERAL_DECIMAL_PRECISION);

  if (!CollateralDecimal.PRECISION) {
    errors.push(
      new Error("initializeConstants: CollateralDecimal.PRECISION was not set correctly!")
    );
  }

  if (!CollateralDecimal.DIGITS) {
    errors.push(new Error("initializeConstants: CollateralDecimal.DIGITS was not set correctly!"));
  }

  return {
    CRITICAL_COLLATERAL_RATIO: scaledCCR,
    MINIMUM_COLLATERAL_RATIO: scaledMCR,
    COLLATERAL_DECIMAL_PRECISION: decimals,
    errors
  };
};

/**
 * Amount of GAI that's reserved for compensating the liquidator of a Vault.
 *
 * @public
 */
export const GAI_LIQUIDATION_RESERVE = Decimal18.from(10);

/**
 * A Vault must always have at least this much debt on top of the
 * {@link GAI_LIQUIDATION_RESERVE | liquidation reserve}.
 *
 * @remarks
 * Any transaction that would result in a Vault with less net debt than this will be reverted.
 *
 * @public
 */
export const GAI_MINIMUM_NET_DEBT = Decimal18.from(100);

/**
 * A Vault must always have at least this much debt.
 *
 * @remarks
 * Any transaction that would result in a Vault with less debt than this will be reverted.
 *
 * @public
 */
export const GAI_MINIMUM_DEBT = GAI_LIQUIDATION_RESERVE.add(GAI_MINIMUM_NET_DEBT);

/**
 * Value that the {@link Fees.borrowingRate | borrowing rate} will never decay below.
 *
 * @remarks
 * Note that the borrowing rate can still be lower than this during recovery mode, when it's
 * overridden by zero.
 *
 * @public
 */
export const MINIMUM_BORROWING_RATE = Decimal18.from(0.005);

/**
 * Value that the {@link Fees.borrowingRate | borrowing rate} will never exceed.
 *
 * @public
 */
export const MAXIMUM_BORROWING_RATE = Decimal18.from(0.05);

/**
 * Value that the {@link Fees.redemptionRate | redemption rate} will never decay below.
 *
 * @public
 */
export const MINIMUM_REDEMPTION_RATE = Decimal18.from(0.005);

/**
 * Half of the total allocated for GOK staking rewards (1% of 1 Billion)
 *
 * @remarks
 * Released in 2 years. No vesting. See more:
 * https://astriddao.gitbook.io/documentation/faq/gok-token/rewards-and-distribution
 *
 * @public
 */
export const HALF_OF_TOTAL_GOK_STAKING_INCENTIVES = 10 * ONE_MILLION;

/**
 * Deposit Incentives for the initial 3 months
 *
 * @remarks
 * 6% for the initial 3 months, 2% for the XCM festival, released to users with 3-year linear vesting.
 * See more https://astriddao.gitbook.io/documentation/faq/gok-token/rewards-and-distribution
 *
 * @public
 */
export const TOTAL_DEPOSIT_INCENTIVES = 60 * ONE_MILLION;

/**
 * Scheduled GOK distribution ratio per year (20%)
 *
 * @remarks
 * See GOK distribution schedule for more info:
 * https://astriddao.gitbook.io/documentation/faq/gok-token/rewards-and-distribution
 *
 * @public
 */
export const DISTRIBUTION_RATIO_SCHEDULED_PER_YEAR = 0.2;

/**
 * Percentage threshold for GOK staking APR. If it goes over this threshold, we show Infinity symbol (∞).
 *
 * @remarks
 * See GOK distribution schedule for more info:
 * https://astriddao.gitbook.io/documentation/faq/gok-token/rewards-and-distribution
 *
 * @public
 */
export const GOK_STAKING_APR_PERCENTAGE_THRESHOLD = 10000;

export const GAS_ESTIMATOR_POLL_INTERVAL = 30 * ONE_SECOND;
export const GAS_PRICE_SPEED = "average" as const;

export const SECONDS_IN_ONE_MONTH = 2628000 as const;
export const MILLISECOND = 1000 as const;
export const GANACHE_PORT = 8288;
