import { JsonFragment, LogDescription } from "@ethersproject/abi";
import { BigNumber } from "@ethersproject/bignumber";
import { Log } from "@ethersproject/abstract-provider";

import {
  Contract,
  ContractInterface,
  ContractFunction,
  Overrides,
  CallOverrides,
  PopulatedTransaction,
  ContractTransaction
} from "@ethersproject/contracts";

import activePoolAbi from "../abi/ActivePool.json";
import borrowerOperationsAbi from "../abi/BorrowerOperations.json";
import vaultManagerAbi from "../abi/VaultManager.json";
import gaiTokenAbi from "../abi/GAIToken.json";
import govTokenAbi from "../abi/GovToken.json";
import collSurplusPoolAbi from "../abi/CollSurplusPool.json";
import communityIssuanceAbi from "../abi/CommunityIssuance.json";
import defaultPoolAbi from "../abi/DefaultPool.json";
import gokTokenAbi from "../abi/GOKToken.json";
import hintHelpersAbi from "../abi/HintHelpers.json";
// import lockupContractFactoryAbi from "../abi/LockupContractFactory.json";
import gokStakingAbi from "../abi/GOKStaking.json";
import multiVaultGetterAbi from "../abi/MultiVaultGetter.json";
import multiStakeGetterAbi from "../abi/MultiStakeGetter.json";
// import priceFeedAbi from "../abi/PriceFeed.json";
import priceFeedPythAbi from "../abi/PriceFeedPyth.json";
import priceFeedRedStoneAbi from "../abi/PriceFeedRedStoneClassicLayerBank.json";
import sortedVaultsAbi from "../abi/SortedVaults.json";
import stabilityPoolAbi from "../abi/StabilityPool.json";
import gasPoolAbi from "../abi/GasPool.json";
import WASTRAbi from "../abi/WASTR.json";

import gokStakingTesterAbi from "../abi/GOKStakingTester.json";
import gokTokenTesterAbi from "../abi/GOKTokenTester.json";
import communityIssuanceTesterAbi from "../abi/CommunityIssuanceTester.json";
import govTokenTesterAbi from "../abi/GovTokenTester.json";
import gaiTokenTesterAbi from "../abi/GAITokenTester.json";
import activePoolTesterAbi from "../abi/ActivePoolTester.json";
import borrowerOperationsTesterAbi from "../abi/BorrowerOperationsTester.json";
import defaultPoolTesterAbi from "../abi/DefaultPoolTester.json";
import stabilityPoolTesterAbi from "../abi/StabilityPoolTester.json";
import vaultManagerTesterAbi from "../abi/VaultManagerTester.json";
import WASTRTesterAbi from "../abi/WASTRTester.json";
import IERC20Abi from "../abi/IERC20.json";
import IERC20PlusAbi from "../abi/IERC20Plus.json";
import IXcGaiWrapperAbi from "../abi/XcGaiWrapper.json";
import IPyth from "../abi/IPyth.json";
import IStakeStone from "../abi/StakeStone.json";

import {
  ActivePool,
  BorrowerOperations,
  VaultManager,
  GAIToken,
  CollSurplusPool,
  CommunityIssuance,
  DefaultPool,
  GovToken,
  GOKToken,
  HintHelpers,
  // LockupContractFactory,
  GOKStaking,
  MultiVaultGetter,
  PriceFeedPyth,
  PriceFeedRedStoneClassicLayerBank as PriceFeedRedStone,
  PriceFeedTestnet,
  SortedVaults,
  StabilityPool,
  GasPool,
  WASTR,
  WASTRTester,
  // IActivePool,
  // IAstridBase,
  // IGOKStaking,
  // IGOKToken,
  // IGAIToken,
  // IBorrowerOperations,
  // ICollSurplusPool,
  // ICommunityIssuance,
  // IDefaultPool,
  // IGovToken,
  // ILockupContractFactory,
  // IPool,
  IPriceFeed,
  // ISortedVaults,
  // IStabilityPool,
  // IVaultManager,
  // IWASTR,
  ActivePoolTester,
  BorrowerOperationsTester,
  VaultManagerTester,
  GAITokenTester,
  GovTokenTester,
  CommunityIssuanceTester,
  DefaultPoolTester,
  GOKTokenTester,
  GOKStakingTester,
  StabilityPoolTester,
  MultiStakeGetter,
  IERC20,
  IERC20Plus,
  XcGaiWrapper,
  IPyth as IPythTypes,
  StakeStone as IStakeStoneTypes
} from "../types";

import { EthersProvider, EthersSigner } from "./types";
import { SupportedCollaterals, getCollateralOracle } from "@astrid-dao/lib-base";

export interface _TypedLogDescription<T> extends Omit<LogDescription, "args"> {
  args: T;
}

type BucketOfFunctions = Record<string, (...args: unknown[]) => never>;

// Removes unsafe index signatures from an wrapped Astar contract type
export type _TypeSafeContract<T> = Pick<
  T,
  {
    [P in keyof T]: BucketOfFunctions extends T[P] ? never : P;
  } extends {
    [_ in keyof T]: infer U;
  }
    ? U
    : never
>;

type EstimatedContractFunction<R = unknown, A extends unknown[] = unknown[], O = Overrides> = (
  overrides: O,
  adjustGas: (gas: BigNumber) => BigNumber,
  ...args: A
) => Promise<R>;

type CallOverridesArg = [overrides?: CallOverrides];

type TypedContract<T extends Contract, U, V> = _TypeSafeContract<T> &
  U & {
    [P in keyof V]: V[P] extends (...args: infer A) => unknown
      ? (...args: A) => Promise<ContractTransaction>
      : never;
  } & {
    readonly callStatic: {
      [P in keyof V]: V[P] extends (...args: [...infer A, never]) => infer R
        ? (...args: [...A, ...CallOverridesArg]) => R
        : never;
    };

    readonly estimateGas: {
      [P in keyof V]: V[P] extends (...args: infer A) => unknown
        ? (...args: A) => Promise<BigNumber>
        : never;
    };

    readonly populateTransaction: {
      [P in keyof V]: V[P] extends (...args: infer A) => unknown
        ? (...args: A) => Promise<PopulatedTransaction>
        : never;
    };

    readonly estimateAndPopulate: {
      [P in keyof V]: V[P] extends (...args: [...infer A, infer O | undefined]) => unknown
        ? EstimatedContractFunction<PopulatedTransaction, A, O>
        : never;
    };
  };

const buildEstimatedFunctions = <T>(
  estimateFunctions: Record<string, ContractFunction<BigNumber>>,
  functions: Record<string, ContractFunction<T>>
): Record<string, EstimatedContractFunction<T>> =>
  Object.fromEntries(
    Object.keys(estimateFunctions).map(functionName => [
      functionName,
      async (overrides, adjustEstimate, ...args) => {
        if (overrides.gasLimit === undefined) {
          const estimatedGas = await estimateFunctions[functionName](...args, overrides);

          overrides = {
            ...overrides,
            gasLimit: adjustEstimate(
              estimatedGas.mul(BigNumber.from("15")).div(BigNumber.from("10"))
            )
          };
        }

        return functions[functionName](...args, overrides);
      }
    ])
  );

export class _AstridDaoContract extends Contract {
  readonly estimateAndPopulate: Record<string, EstimatedContractFunction<PopulatedTransaction>>;

  constructor(
    addressOrName: string,
    contractInterface: ContractInterface,
    signerOrProvider?: EthersSigner | EthersProvider
  ) {
    super(addressOrName, contractInterface, signerOrProvider);

    // this.estimateAndCall = buildEstimatedFunctions(this.estimateGas, this);
    this.estimateAndPopulate = buildEstimatedFunctions(this.estimateGas, this.populateTransaction);
  }

  extractEvents(logs: Log[], name: string): _TypedLogDescription<unknown>[] {
    return logs
      .filter(log => log.address === this.address)
      .map(log => this.interface.parseLog(log))
      .filter(e => e.name === name);
  }
}

/** @internal */
export type _TypedAstridDaoContract<T = unknown, U = unknown> = TypedContract<
  _AstridDaoContract,
  T,
  U
>;

/** @internal */
// export interface _AstridDaoContracts {
//   activePool: ActivePool;
//   borrowerOperations: BorrowerOperations;
//   vaultManager: VaultManager;
//   gaiToken: GAIToken;
//   collSurplusPool: CollSurplusPool;
//   communityIssuance: CommunityIssuance;
//   defaultPool: DefaultPool;
//   gokToken: GOKToken;
//   hintHelpers: HintHelpers;
//   lockupContractFactory: LockupContractFactory;
//   gokStaking: GOKStaking;
//   multiVaultGetter: MultiVaultGetter;
//   priceFeed: PriceFeed | PriceFeedTestnet;
//   sortedVaults: SortedVaults;
//   stabilityPool: StabilityPool;
//   gasPool: GasPool;
//   WASTR: WASTR;
// }

export interface Addressable {
  address: string;
}

/** @internal */
export interface _AstridDaoContracts {
  activePool: ActivePool;
  borrowerOperations: BorrowerOperations;
  vaultManager: VaultManager;
  gaiToken: GAIToken;
  govToken: GovToken;
  collSurplusPool: CollSurplusPool;
  communityIssuance: CommunityIssuance;
  defaultPool: DefaultPool;
  gokToken: GOKToken;
  hintHelpers: HintHelpers;
  // lockupContractFactory: LockupContractFactory;
  gokStaking: GOKStaking;
  multiVaultGetter: MultiVaultGetter;
  multiStakeGetter: MultiStakeGetter;
  priceFeed: PriceFeedPyth | PriceFeedRedStone;
  sortedVaults: SortedVaults;
  stabilityPool: StabilityPool;
  gasPool: GasPool;
  WASTR: WASTR; // Keeps for backward compat. If not WASTR instance, this would be the same addr as colToken
  colToken: IERC20;
  xcGaiToken: IERC20Plus;
  xcGaiWrapper: XcGaiWrapper;
  pyth: IPythTypes;
  stakeStone: IStakeStoneTypes;
}

export type _AstridDaoContractsAddressable = {
  [K in keyof _AstridDaoContracts]: _AstridDaoContracts[K] & Addressable;
};

/** @internal */
export interface _AstridDaoTesterContracts {
  activePool: ActivePoolTester;
  borrowerOperations: BorrowerOperationsTester;
  vaultManager: VaultManagerTester;
  gaiToken: GAITokenTester;
  govToken: GovTokenTester;
  collSurplusPool: CollSurplusPool;
  communityIssuance: CommunityIssuanceTester;
  defaultPool: DefaultPoolTester;
  gokToken: GOKTokenTester;
  hintHelpers: HintHelpers;
  // lockupContractFactory: LockupContractFactory;
  gokStaking: GOKStakingTester;
  multiVaultGetter: MultiVaultGetter;
  multiStakeGetter: MultiStakeGetter;
  priceFeed: PriceFeedTestnet;
  sortedVaults: SortedVaults;
  stabilityPool: StabilityPoolTester;
  gasPool: GasPool;
  WASTR: WASTRTester; // Keeps for backward compat. If not WASTR instance, this would be the same addr as colToken
  colToken: IERC20;
  xcGaiToken: IERC20Plus;
  xcGaiWrapper: XcGaiWrapper;
}

export type _AstridDaoTesterContractsAddressable = {
  [K in keyof _AstridDaoTesterContracts]: _AstridDaoTesterContracts[K] & Addressable;
};

/** @internal */
// export const _wAstrTokenIsMock = (WASTRToken: IWASTR | WASTRTester): WASTRToken is WASTRTester =>
//   "mint" in WASTRToken;

type AstridDaoContractsKey = keyof _AstridDaoContracts;

/** @internal */
export type _AstridDaoContractAddresses = Record<AstridDaoContractsKey, string>;

type AstridDaoContractAbis = Record<AstridDaoContractsKey, JsonFragment[]>;

// const getAbi = (priceFeedIsTestnet: boolean, uniTokenIsMock: boolean): AstridDaoContractAbis => ({
export const getAbi = (
  isDev = false,
  priceFeedIsTestnet = true,
  isPythOracle = false
): AstridDaoContractAbis => ({
  activePool: isDev ? activePoolTesterAbi : activePoolAbi,
  borrowerOperations: isDev ? borrowerOperationsTesterAbi : borrowerOperationsAbi,
  vaultManager: isDev ? vaultManagerTesterAbi : vaultManagerAbi,
  gaiToken: isDev ? gaiTokenTesterAbi : gaiTokenAbi,
  govToken: isDev ? govTokenTesterAbi : govTokenAbi,
  communityIssuance: isDev ? communityIssuanceTesterAbi : communityIssuanceAbi,
  defaultPool: isDev ? defaultPoolTesterAbi : defaultPoolAbi,
  gokToken: isDev ? gokTokenTesterAbi : gokTokenAbi,
  hintHelpers: hintHelpersAbi,
  // lockupContractFactory: lockupContractFactoryAbi,
  gokStaking: isDev ? gokStakingTesterAbi : gokStakingAbi,
  multiStakeGetter: multiStakeGetterAbi,
  multiVaultGetter: multiVaultGetterAbi,
  priceFeed: isPythOracle ? priceFeedPythAbi : priceFeedRedStoneAbi,
  sortedVaults: sortedVaultsAbi,
  stabilityPool: isDev ? stabilityPoolTesterAbi : stabilityPoolAbi,
  gasPool: gasPoolAbi,
  collSurplusPool: collSurplusPoolAbi,
  WASTR: isDev ? WASTRTesterAbi : WASTRAbi,
  colToken: IERC20Abi,
  xcGaiToken: IERC20PlusAbi,
  xcGaiWrapper: IXcGaiWrapperAbi,
  pyth: IPyth,
  stakeStone: IStakeStone
});

const mapAstridDaoContracts = <T, U>(
  contracts: Record<AstridDaoContractsKey, T>,
  f: (t: T, key: AstridDaoContractsKey) => U
) =>
  Object.fromEntries(
    Object.entries(contracts).map(([key, t]) => [key, f(t, key as AstridDaoContractsKey)])
  ) as Record<AstridDaoContractsKey, U>;

/** @internal */
export interface _AstridDaozkEVMDeploymentJSON {
  readonly chainId: number;
  readonly addresses: _AstridDaoContractAddresses;
  readonly version: string;
  readonly deploymentDate: number;
  readonly startBlock: number;
  readonly bootstrapPeriod: number;
  readonly totalStabilityPoolTokenReward: string;
  readonly _priceFeedIsTestnet: boolean;
  readonly _isDev: boolean;
  readonly collateralName: string;
  readonly CCR: string;
  readonly MCR: string;
  readonly decimals: string;
  readonly isMockPyth: boolean;
  readonly pythConfigs: {
    pythContract: string;
    priceFeedID: string;
    collateralPriceScaleFactor: number;
  };
}

/** @internal */
export const _connectToContracts = (
  signerOrProvider: EthersSigner | EthersProvider,
  { addresses, collateralName, _isDev, _priceFeedIsTestnet }: _AstridDaozkEVMDeploymentJSON
): _AstridDaoContracts => {
  const abi = getAbi(
    _isDev,
    _priceFeedIsTestnet,
    getCollateralOracle(_isDev).PYTH.includes(collateralName as SupportedCollaterals)
  );

  return {
    ...mapAstridDaoContracts(
      addresses,
      (address, key) =>
        new _AstridDaoContract(address, abi[key], signerOrProvider) as _TypedAstridDaoContract
    ),
    stakeStone: new _AstridDaoContract(
      addresses.colToken,
      IStakeStone,
      signerOrProvider
    ) as _TypedAstridDaoContract
  } as _AstridDaoContracts;
};

export const _connectToContractsMaybeTest = (
  signerOrProvider: EthersSigner | EthersProvider,
  { addresses, _isDev, _priceFeedIsTestnet, collateralName }: _AstridDaozkEVMDeploymentJSON
): _AstridDaoContractsAddressable => {
  const abi = getAbi(
    _isDev,
    _priceFeedIsTestnet,
    getCollateralOracle(_isDev).PYTH.includes(collateralName as SupportedCollaterals)
  );

  return mapAstridDaoContracts(
    addresses,
    (address, key) =>
      new _AstridDaoContract(address, abi[key], signerOrProvider) as _TypedAstridDaoContract
  ) as _AstridDaoContractsAddressable;
};

export const _connectToSingleContract = (
  signerOrProvider: EthersSigner | EthersProvider,
  abiName: keyof AstridDaoContractAbis,
  address: string,
  isDev: boolean,
  priceFeedIsTestnet: boolean
): _AstridDaoContract & Addressable => {
  const abi = getAbi(isDev, priceFeedIsTestnet);
  return new _AstridDaoContract(address, abi[abiName], signerOrProvider);
};

export const _connectToContractRawABI = (
  signerOrProvider: EthersSigner | EthersProvider,
  abi: JsonFragment[],
  address: string
): _AstridDaoContract & Addressable => {
  return new _AstridDaoContract(address, abi, signerOrProvider);
};
