import {
  CollateralDecimal,
  Decimal18,
  Vault,
  VaultWithPendingRedistribution
} from "@astrid-dao/lib-base";
import { BigNumber } from "ethers";
import { decimalify18, decimalifyCollateralDecimal } from "./_utils";
import { BackendVaultStatus, userVaultStatusFrom } from "./ReadableEthersAstridDao";

export const getVault = (col: BigNumber, debt: BigNumber): Vault => {
  const [activeCollateral, activeDebt] = [decimalifyCollateralDecimal(col), decimalify18(debt)];
  return new Vault(activeCollateral, activeDebt);
};

export const getTotalRedistributed = (L_GAIDebt: BigNumber, L_COL: BigNumber): Vault => {
  const [debt, collateral] = [decimalify18(L_GAIDebt), decimalifyCollateralDecimal(L_COL)];

  const e18 = Decimal18.from("10").pow(18);
  const ce18 = CollateralDecimal.from("10").pow(18);
  return new Vault(collateral.div(ce18), debt.div(e18));
};

export const getVaultBeforRedistribution = (
  address: string,
  vault: {
    debt: BigNumber;
    coll: BigNumber;
    stake: BigNumber;
    status: number;
    arrayIndex: BigNumber;
  },
  snapshot: { COL: BigNumber; GAIDebt: BigNumber }
): VaultWithPendingRedistribution => {
  if (vault.status === BackendVaultStatus.active) {
    return new VaultWithPendingRedistribution(
      address,
      userVaultStatusFrom(vault.status),
      decimalifyCollateralDecimal(vault.coll),
      decimalify18(vault.debt),
      decimalifyCollateralDecimal(vault.stake),
      new Vault(decimalifyCollateralDecimal(snapshot.COL), decimalify18(snapshot.GAIDebt))
    );
  } else {
    return new VaultWithPendingRedistribution(address, userVaultStatusFrom(vault.status));
  }
};
