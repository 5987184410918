import React, { useEffect, useRef, useState } from "react";

import {
  AstridDaoStoreState,
  CollateralDecimal,
  Decimal18,
  SupportedCollaterals
} from "@astrid-dao/lib-base";
import { Box, Button, Card, Container, Divider, Flex, Image, Text } from "theme-ui";
import { useAccount, useDisconnect } from "wagmi";

import iconCoinbase from "../assets/images/icon-coinbase.svg";
import iconMetaMask from "../assets/images/icon-metamask.svg";
import iconSafe from "../assets/images/icon-safe.svg";
import iconWalletConnect from "../assets/images/wallet-connect-logo.svg";
import { SELECTABLE_COLLATERAL_TYPES } from "../config";
import { TOKEN_PRECISION } from "../constants";
import { iconCurrencyMap } from "../currencyIcons";
import { useAstridDaoSelector } from "../hooks/useAstridDaoSelector";
import useTokenBalance from "../hooks/useTokenBalance";
import { useTokenPrices } from "../hooks/useTokenPrices";
import { useAstridDao } from "../providers/AstridDaoProvider";
import {
  AccountBalanceMap,
  Currencies,
  getAccountBalancePerCollateral
} from "../rpc-contract-service/service";
import { COIN, COLLAT, GT } from "../strings";
import { useTheme } from "../theme";
import { getAmountToDisplay } from "../utils/digits";
import { addERC20TokenToMetamask, AddToMetamaskParams } from "../utils/metamask";
import { shortenAddress } from "../utils/shorten";
import { CollateralDecimalService } from "./Dashboard/models/decimals/CollateralDecimalService";
import { DashboardDecimal18 } from "./Dashboard/models/decimals/DashboardDecimal18";
import { Icon } from "./Icon";
import { LoadingOverlay } from "./LoadingOverlay";
import MosaicButton from "./MosaicButton";
import SvgIcon, { IconDisconnectedWallet } from "./SvgIcon";
import { Tooltip } from "./Tooltip";

const select = ({
  accountBalance,
  gaiBalance,
  xcGaiBalance,
  gokBalance,
  wrappedTokenBalance
}: AstridDaoStoreState) => ({
  accountBalance,
  gaiBalance,
  xcGaiBalance,
  gokBalance,
  wrappedTokenBalance
});

// const itemWidth = "324px";
const itemWidth = "100%";

const SHORTENED_AMOUNT_THRESHOLD = 6;

export const GAI_TOKEN_IMAGE_URL =
  "https://raw.githubusercontent.com/AstridDAO/media-kit/ebf9d00f2cbd6cd1d582feb7728da55dde508a10/gai-and-gok-token-logos/gai_icon_light.svg";

export const xcGAI_TOKEN_IMAGE_URL =
  "https://raw.githubusercontent.com/AstridDAO/media-kit/93f4afca2fe55abba3d7d758f8e320f47538d343/gai-and-gok-token-logos/xcGAI_logo_light.svg";

export const GOK_TOKEN_IMAGE_URL =
  "https://raw.githubusercontent.com/AstridDAO/media-kit/ebf9d00f2cbd6cd1d582feb7728da55dde508a10/gai-and-gok-token-logos/gok_icon_light.svg";
const DEFAULT_TOKEN_PRECISION = 18;

export const Balance: React.FC<{
  showWalletAddress?: boolean;
  ignoreClick?: boolean;
}> = ({ showWalletAddress, ignoreClick }) => {
  const { theme } = useTheme();
  const [hoveredItem, setHoveredItem] = useState<string | undefined>();
  const { account: address, contractsConfig, deactivate, isTestnet } = useAstridDao();
  const { connector } = useAccount();
  const { disconnect } = useDisconnect({
    onSuccess(context) {
      console.log("Disconnected", context);
      deactivate();
    },
    onError(error, context) {
      console.log("Disconnect error", error, context);
      deactivate();
    }
  });
  const tokenPricePerCollateral = useTokenPrices();
  const { gaiBalance, gokBalance, wrappedTokenBalance } = useAstridDaoSelector(select);
  const [isUserAccountOpen, setUserAccountOpen] = useState(false);
  const [gokStakingRewards, setGOKStakingRewards] = useState<number | undefined>(undefined);
  const [accountBalances, setAccountBalances] = useState<AccountBalanceMap | undefined>();
  const [walletIcon, setWalletIcon] = useState<string>(iconMetaMask);
  const erc20TokenParams = (contractsConfig?.collaterals ?? [])
    .map(collateralContracts => {
      const { name, address } = collateralContracts;
      const key = name as SupportedCollaterals;

      return [
        name,
        {
          tokenSymbol: name,
          tokenAddress: address,
          tokenPrecision: CollateralDecimalService.getPrecisionByCollateralType(key),
          tokenImage: ""
        }
      ];
    })
    .concat([
      [
        "GAI",
        {
          tokenSymbol: "GAI",
          tokenAddress: contractsConfig.baseContracts.gaiToken,
          tokenPrecision: DEFAULT_TOKEN_PRECISION,
          tokenImage: GAI_TOKEN_IMAGE_URL
        }
      ],
      // [
      //   "xcGAI",
      //   {
      //     tokenSymbol: "xcGAI",
      //     tokenAddress: contractsConfig.baseContracts.xcGaiToken,
      //     tokenPrecision: DEFAULT_TOKEN_PRECISION,
      //     tokenImage: xcGAI_TOKEN_IMAGE_URL
      //   }
      // ],
      [
        "GOK",
        {
          tokenSymbol: "GOK",
          tokenAddress: contractsConfig.baseContracts.gokToken,
          tokenPrecision: DEFAULT_TOKEN_PRECISION,
          tokenImage: GOK_TOKEN_IMAGE_URL
        }
      ]
    ]) as [[Currencies, AddToMetamaskParams]];

  const erc20TokenToMetamaskMap = new Map<Currencies, AddToMetamaskParams>(erc20TokenParams);

  const overlayRef = useRef<HTMLDivElement>(null);

  const [copied, setCopied] = useState<string>();

  useEffect(() => {
    if (isUserAccountOpen) {
      getAccountBalancePerCollateral(address, COLLAT()).then(setAccountBalances);
    }
  }, [isUserAccountOpen, address]);

  useEffect(() => {
    if (copied !== undefined) {
      let cancelled = false;

      setTimeout(() => {
        if (!cancelled) {
          setCopied(undefined);
        }
      }, 2000);

      return () => {
        cancelled = true;
      };
    }
  }, [copied]);

  useEffect(() => {
    switch (connector?.id) {
      case "walletConnect":
        setWalletIcon(iconWalletConnect);
        break;
      case "safe":
        setWalletIcon(iconSafe);
        break;
      case "coinbaseWallet":
        setWalletIcon(iconCoinbase);
        break;
      default:
        setWalletIcon(iconMetaMask);
    }
  }, [connector]);

  const userAccounts: [string, Decimal18 | CollateralDecimal | DashboardDecimal18][] = [
    // [BASE_COLLAT, accountBalance],
    [COLLAT(), wrappedTokenBalance],
    [GT, gokBalance],
    [COIN, gaiBalance]
    // [xcCOIN, xcGaiBalance]
  ];

  accountBalances?.forEach((value, key) => {
    userAccounts.push([key, value]);
  });

  if (accountBalances?.size) {
    userAccounts.sort(([a], [b]) => {
      return (
        // @ts-ignore
        SELECTABLE_COLLATERAL_TYPES.indexOf([a][0]) - SELECTABLE_COLLATERAL_TYPES.indexOf([b][0])
      );
    });
  }

  const wrappedTokenBalanceToDisplay = useTokenBalance(COLLAT() as SupportedCollaterals);
  const customBoundary = useRef<HTMLInputElement>(null);

  console.log("accountBalances", accountBalances);
  return (
    <Flex
      sx={{
        justifyContent: "flex-end",
        alignItems: "center",
        overflow: "hidden"
      }}
    >
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "40px",
          ml: "10px",
          borderRadius: "16px",
          cursor: ignoreClick ? "default" : "pointer"
        }}
        onClick={() =>
          ignoreClick ? console.log("do nothing") : setUserAccountOpen(!isUserAccountOpen)
        }
      >
        {showWalletAddress ? (
          <MosaicButton type="primary" sx={{ height: "40px", width: "180px" }}>
            <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
              <Text
                sx={{
                  color: "var(--white-w-100, #FFF)",
                  textAlign: "center",
                  fontFamily: "VT323",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "20px"
                }}
              >
                {wrappedTokenBalance.shorten()} {COLLAT()}
              </Text>
              <Text
                sx={{
                  mt: "-5px",
                  color: "var(--white-w-60, rgba(255, 255, 255, 0.60))",
                  textAlign: "center",
                  fontFamily: "VT323",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px" // You may need to adjust this value based on your design requirements
                }}
              >
                Your wallet balance
              </Text>
            </Flex>
          </MosaicButton>
        ) : (
          <Flex
            sx={{
              borderRadius: "8px !important",
              bg: "b20",
              height: "40px",
              flexDirection: "row",
              alignItems: "center",
              px: 4
            }}
          >
            <Image width={24} height={24} src={iconCurrencyMap[COLLAT() as SupportedCollaterals]} />

            <Text
              variant="mediumH3"
              sx={{
                color: "W100",
                ml: "6px"
              }}
            >
              {wrappedTokenBalanceToDisplay.prettify(TOKEN_PRECISION)}
            </Text>

            <Text
              variant="mediumH3"
              sx={{
                color: "W100",
                ml: "6px"
              }}
            >
              {COLLAT()}
            </Text>
          </Flex>
        )}
      </Flex>

      {isUserAccountOpen && (
        <Container
          variant="infoOverlay"
          ref={overlayRef}
          onClick={e => {
            if (e.target === overlayRef.current) {
              setUserAccountOpen(false);
            }
          }}
          sx={{
            bg: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(15px)"
          }}
        >
          <Card ref={customBoundary} variant="wallet">
            <Flex
              sx={{
                width: itemWidth,
                height: "auto",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                pl: 3
              }}
            >
              <Text variant="text.msTitle20" sx={{ color: "B100" }}>
                Wallet
              </Text>

              <Button
                variant="icon"
                sx={{ width: "40px", height: "40px" }}
                onClick={() => setUserAccountOpen(false)}
              >
                <Icon name="times" size="xl" color={theme.colors.B40} />
              </Button>
            </Flex>

            <Flex
              sx={{
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 6,
                pl: 3,
                width: itemWidth
              }}
            >
              <Text variant="text.mediumH2" sx={{ color: "B100" }}>
                {shortenAddress(address)}
              </Text>
              <Tooltip message={copied ? "copied" : "copy"} placement="top">
                <Button
                  variant="icon"
                  sx={{ width: "40px", height: "40px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(address);
                    setCopied(address);
                  }}
                >
                  <Icon
                    prefix={copied === address ? "fas" : "far"}
                    name={copied === address ? "clipboard-check" : "copy"}
                    size="1x"
                    color={theme.colors.B40}
                  />
                </Button>
              </Tooltip>

              <Tooltip message="Disconnect Wallet" placement="top">
                <SvgIcon
                  onClick={() => {
                    // may cause a error
                    // https://github.com/WalletConnect/walletconnect-monorepo/issues/1772
                    try {
                      disconnect();
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                  color={theme.colors.B40}
                  icon={IconDisconnectedWallet}
                />
              </Tooltip>
            </Flex>

            <Flex
              sx={{
                mt: 5,
                alignItems: "flex-start",
                flexDirection: "column",
                width: itemWidth
              }}
            >
              <Box sx={{ pl: 3 }}>
                <Text
                  variant="text.mediumH4"
                  sx={{
                    color: "B100"
                  }}
                >
                  Wallet Balance
                </Text>
              </Box>

              <Box
                sx={{
                  overflowY: "scroll",
                  height: "auto",
                  maxHeight: ["100%", "50vh", "50vh"],
                  scrollbarWidth: "thin",
                  "::-webkit-scrollbar": {
                    width: "20px"
                  }
                }}
              >
                {userAccounts
                  .filter(item => {
                    // hide xcGAI
                    if (isTestnet) {
                      return true;
                    } else {
                      return item[0] !== "xcGAI";
                    }
                  })
                  .map(([currency, balance], i) => {
                    const [balanceToDisplay, shouldShowTooltip] = getAmountToDisplay(
                      balance,
                      SHORTENED_AMOUNT_THRESHOLD
                    );

                    console.log(
                      "key = ",
                      currency,
                      tokenPricePerCollateral.get(currency as SupportedCollaterals)?.toString(),
                      tokenPricePerCollateral.get("MANTA")?.toString()
                    );
                    const tokenValueInUsd =
                      // Default GAI price in tokenPrice is 1
                      // use GAI-API to use realtime price
                      currency === "GAI"
                        ? balance
                            .mul(
                              (
                                tokenPricePerCollateral.get("GAI-API") ?? DashboardDecimal18.ZERO
                              ).toString()
                            )
                            .prettify()
                        : balance
                            .mul(
                              (
                                tokenPricePerCollateral.get(currency as SupportedCollaterals) ??
                                DashboardDecimal18.ZERO
                              ).toString()
                            )
                            .prettify();
                    // const tooltip = (balance ?? Decimal18.ZERO).prettify();

                    return (
                      <Container
                        key={`${i}-${currency}`}
                        variant="wallet-balance"
                        onMouseEnter={() => {
                          setHoveredItem(currency);
                        }}
                        onMouseLeave={() => {
                          setHoveredItem(undefined);
                        }}
                      >
                        <Flex
                          sx={{
                            justifyContent: "flex-start",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "12px",
                            width: "100%"
                            // width: "105px"
                          }}
                        >
                          <Image
                            loading="eager"
                            width="32px"
                            height="32px"
                            alt={`${currency} Logo`}
                            src={iconCurrencyMap[currency as SupportedCollaterals]}
                          />
                          <Text
                            variant="text.mediumH3"
                            sx={{
                              color: "B100"
                            }}
                          >
                            {currency}
                          </Text>
                        </Flex>

                        {hoveredItem === currency && currency !== "ASTR" ? (
                          <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
                            <Text variant="text.regularBodySmall" sx={{ color: "B40" }}>
                              Add token to wallet
                            </Text>
                            <Button
                              sx={{ ml: 2 }}
                              variant="icon"
                              onClick={() => {
                                const params = erc20TokenToMetamaskMap.get(currency as Currencies)!;

                                return addERC20TokenToMetamask(params);
                              }}
                            >
                              {/* <Tooltip
                                placement="top"
                                strategy="fixed"
                                message={`Add ${currency} to Metamask`}
                              >
                                <span> */}
                              <Icon
                                name={"plus"}
                                size="lg"
                                color={theme.colors.B40}
                                hoveredColor={theme.colors.B100}
                              />
                              {/* </span> */}
                              {/* </Tooltip> */}
                            </Button>
                          </Flex>
                        ) : (
                          <Flex
                            sx={{
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              p: 0,
                              m: 0
                            }}
                          >
                            <Flex
                              sx={{
                                flexDirection: "column",
                                alignItems: "flex-end",
                                justifyContent: "center",
                                p: 0,
                                gap: 1,
                                height: "38px",
                                maxWidth: "auto",
                                fontSize: 3,
                                fontWeight: "medium",
                                lineHeight: "19px"
                              }}
                            >
                              <Flex>
                                <Text variant="text.mediumBodyLarge" sx={{ color: "B100" }}>
                                  {balanceToDisplay}
                                </Text>
                              </Flex>
                              <Text variant="text.regularBodySmall" sx={{ color: "B40" }}>
                                ${tokenValueInUsd}
                              </Text>
                            </Flex>
                          </Flex>
                        )}
                      </Container>
                    );
                  })}
              </Box>
            </Flex>

            <Divider
              sx={{
                mt: 2,
                mx: 3,
                bg: "G12",
                height: "1px",
                width: "calc(100% - 24px)",
                display: ["block", "block", "none"]
              }}
            />
            {accountBalances?.size === undefined && <LoadingOverlay />}
          </Card>
        </Container>
      )}
    </Flex>
  );
};
