import { astridDaoFrontendConfigProxy } from "./config";

export const COIN = "GAI";
export const xcCOIN = "xcGAI";
export const GT = "GOK";
export const VE_GT = "veGOK";
export const BASE_COLLAT = "MANTA";
export const COLLAT = () =>
  ["DEV", "SBY"].includes(astridDaoFrontendConfigProxy.collateralName)
    ? `W${astridDaoFrontendConfigProxy.collateralName}`
    : astridDaoFrontendConfigProxy.collateralName;
