import { Text, Flex, Box, Image, Grid } from "theme-ui";

import JSX_withParallax from "../components/Landing/ParallaxJSXWrapper";

import bgCardGrey2 from "../assets/images/bg-card-grey-2.svg";
import bgCard from "../assets/images/bg-card.svg";
import iconCloud from "../assets/images/icon-cloud-1.svg";
import iconComet from "../assets/images/icon-comet.svg";
import iconParters from "../assets/images/icon-parters.svg";
import iconStar1 from "../assets/images/icon-star-1.svg";
import iconStar2 from "../assets/images/icon-star-2.svg";
import iconStar3 from "../assets/images/icon-star-3.svg";
import iconStar from "../assets/images/icon-star.svg";
import boxList1 from "../assets/images/landing/list-1.svg";
import boxList2 from "../assets/images/landing/Powered by Py.svg"; // pyth
import boxList3 from "../assets/images/landing/list-3.svg";
import boxList4 from "../assets/images/landing/list-4.svg";
import boxList5 from "../assets/images/landing/list-5.svg";
import iconGok3 from "../assets/images/landing/gok-3.svg";
import iconMoney from "../assets/images/landing/mokey.svg";
import logo from "../assets/logo.svg";
import iconMenu from "../assets/images/landing/menu.svg";
import WalletConnectButton from "../components/ConnectWalletButton";

import "./LandingPage.css";
import MainHero from "../components/Landing/MainHero";
import MosaicCard from "../components/MosaicCard";
import { useState } from "react";
import useSWR from "swr";
import { usdCurrencyFormatter } from "../rpc-contract-service/utils/utils";
import { BigNumber } from "ethers";
import { Decimal18 } from "@astrid-dao/lib-base";

const DEFI_LLAMA_TVL_API = "https://api.llama.fi/tvl/goku-money";
const DEX_SCREENER_API =
  "https://api.dexscreener.com/latest/dex/tokens/0xcd91716ef98798a85e79048b78287b13ae6b99b2";

const LandingPage = () => {
  const fetcher = (url: string) => fetch(url).then(res => res.json());
  const { data: tvl } = useSWR(DEFI_LLAMA_TVL_API, fetcher);
  const { data: fdvRes } = useSWR(DEX_SCREENER_API, fetcher);

  const fdvPair = fdvRes?.pairs?.find((item: any) => item["dexId"] === "quickswap");
  const fdv = fdvPair?.fdv;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const topbarList = [
    {
      title: "Docs",
      link: "https://goku-money.gitbook.io/goku-money/"
    },
    {
      title: "Twitter",
      link: "https://twitter.com/goku_stable"
    },

    {
      title: "Discord",
      link: "https://discord.gg/nWW23hHngN"
    },
    {
      title: "Blog",
      link: "https://medium.com/@gokumoney"
    },

    {
      title: "Telegram",
      link: "https://t.me/goku_money"
    },

    {
      title: "Github",
      link: "https://github.com/Goku-Money"
    }
  ];
  const footerList = [
    // {
    //   title: "Email Us",
    //   link: "mailto:hey@goku money"
    // },
    {
      title: "Twitter",
      link: "https://twitter.com/goku_stable"
    },
    {
      title: "Discord",
      link: "https://discord.gg/nWW23hHngN"
    },
    {
      title: "Telegram",
      link: "https://t.me/goku_money"
    },
    {
      title: "Docs",
      link: "https://goku-money.gitbook.io/goku-money/"
    },

    {
      title: "Medium",
      link: "https://medium.com/@gokumoney"
    },

    {
      title: "Github",
      link: "https://github.com/Goku-Money"
    }
  ];

  const WrappedComet = JSX_withParallax(
    <Box
      sx={{
        width: ["48px", "100px", "125px"],
        position: "absolute",
        bottom: "-50vh"
      }}
    >
      <Image
        src={iconComet}
        alt="comet"
        style={{
          width: "100%"
        }}
      />
    </Box>,
    0.05,
    { left: "100%" }
  );

  const WrappedStar = JSX_withParallax(
    <Box sx={{ position: "absolute", left: "10%", bottom: "-50vh", width: ["48px", "auto"] }}>
      <Image src={iconStar} alt="star" style={{ width: "100%" }} />
    </Box>,
    0.01,
    {
      left: "-25%"
    }
  );

  const WrappedStar1 = JSX_withParallax(
    <Image
      src={iconStar1}
      alt="star-1"
      sx={{ position: "absolute", left: "155px", bottom: ["-130vh"], display: ["none", "block"] }}
    />,
    0.02,
    {
      left: "20%"
    }
  );
  const WrappedStar2 = JSX_withParallax(
    <Image
      src={iconStar2}
      alt="star-2"
      sx={{ position: "absolute", right: "190px", bottom: ["-115vh"], display: ["none", "block"] }}
    />,
    0.025,
    {
      left: "80%"
    }
  );

  return (
    <Flex
      sx={{
        bg: "#1D2129",
        width: "100vw",
        flexDirection: "column",
        overflowX: "hidden"
      }}
    >
      {/* header */}
      <Flex
        sx={{
          zIndex: 9000,
          px: [4, 6],
          flexDirection: "row",
          height: "80px",
          bg: "text5",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Flex sx={{ alignItems: "center" }}>
          <Image
            src={iconMenu}
            alt="menu"
            sx={{ display: ["block", "block", "none"], width: "24px", mr: 2 }}
            onClick={toggleMenu}
          />
          <Image
            src={logo}
            alt="logo"
            sx={{
              width: ["120px", "120px", "auto"]
            }}
          />
          <Flex as="nav" sx={{ ml: "50px", display: ["none", "none", "flex"] }}>
            {topbarList.map(item => (
              <Box
                sx={{
                  textAlign: "center",
                  "&:hover": { backgroundColor: "#7DBAB0" },
                  width: "97px",
                  height: "48px",
                  cursor: "pointer",
                  mr: "17px"
                }}
              >
                <a
                  style={{ lineHeight: "48px", color: "#fff", fontSize: "24px" }}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text className="header-menu">{item.title}</Text>
                </a>
              </Box>
            ))}
          </Flex>
        </Flex>
        <Flex sx={{ alignItems: "center" }}>
          <Box sx={{ ml: "7px" }}>
            <WalletConnectButton
              sx={{ width: ["100px", "100px", "180px"], height: ["30px", "30px", "40px"] }}
            />
          </Box>
        </Flex>
        {isMenuOpen && (
          <Flex
            as="nav"
            sx={{
              flexDirection: "column",
              display: ["flex", "flex", "none"],
              position: "fixed",
              top: "90px",
              width: "80%",
              zIndex: 99999,
              background: "text5",
              p: 6,
              borderRadius: "8px",
              borderColor: "text4",
              borderWidth: "1px",
              borderStyle: "solid"
            }}
          >
            {topbarList.map(item => (
              <Box
                sx={
                  {
                    //...your styles
                  }
                }
              >
                <a
                  style={
                    {
                      //...your styles
                    }
                  }
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text className="header-menu">{item.title}</Text>
                </a>
              </Box>
            ))}
          </Flex>
        )}
      </Flex>

      {/* hero main */}
      <MainHero />

      {/* hero data */}
      <Flex
        sx={{
          pt: "130px",
          width: ["90%", "90%", "740px"],
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 12,
          position: "relative"
        }}
      >
        <Text
          className="main-title"
          sx={{ textAlign: "center", fontSize: ["32px", "32px", "58.018px"] }}
        >
          Borrow GAI, a fully backed stablecoin hard-pegged to the US Dollar.
        </Text>
        <Image
          src={iconGok3}
          alt="gok-3"
          className="floating"
          sx={{ width: ["120px", "120px", "auto"] }}
        />
        <Text className="content" sx={{ textAlign: "center", width: ["90%", "90%", "678px"] }}>
          The ability to instantly redeem $GAI for collaterals at face value and the ability to
          instantly liquidate under-collateralized accounts from stability pool, altogether hard-peg
          GAI to USD.
        </Text>

        <Flex sx={{ gap: 5, flexDirection: ["column", "column", "row"] }}>
          <Flex
            sx={{
              flexDirection: "column",
              background: `url(${bgCard}) no-repeat center center`,
              backgroundSize: "cover",
              width: "360px",
              height: "260px",
              justifyContent: "center",
              gap: 5,
              pl: "36px"
            }}
          >
            <Text className="data-text" sx={{ lineHeight: "90px" }}>
              {fdv ? `$${Decimal18.from(fdv).shorten()}` : "..."}
            </Text>
            <Text
              className="content"
              sx={{
                height: "36px"
              }}
            >
              Stablecoins issued by Goku Money
            </Text>
          </Flex>

          <Flex
            sx={{
              flexDirection: "column",
              background: `url(${bgCard}) no-repeat center center`,
              backgroundSize: "cover",
              width: "360px",
              height: "260px",
              justifyContent: "center",
              gap: 5,
              pl: "36px"
            }}
          >
            <Text
              className="data-text"
              sx={{
                lineHeight: "90px"
              }}
            >
              {tvl ? `$${Decimal18.from(tvl).shorten()}` : "..."}
            </Text>
            <Text
              className="content"
              sx={{
                height: "36px"
              }}
            >
              Total value locked in vaults,<br></br> stability pools and staking pools.
            </Text>
          </Flex>
        </Flex>

        <WrappedStar />
        <WrappedComet />
      </Flex>

      {/* hero-zero-interest-rate */}
      <Flex
        sx={{
          flexDirection: "column",
          width: "100vw",
          pt: "280px",
          pb: "180px",
          alignItems: "center",
          justifyContent: "center",
          position: "relative"
        }}
      >
        <Flex sx={{ flexDirection: "column", width: ["90%", "90%", "812px"], pb: "200px" }}>
          <Text
            className="main-title"
            sx={{ textAlign: "center", fontSize: ["32px", "32px", "58.018px"] }}
          >
            Borrow GAI against your favorite crypto assets at Zero Interest Rate
          </Text>
          <Text
            className="sub-title-2"
            sx={{ textAlign: "center", mt: 6, width: ["100%", "100%", "738px"] }}
          >
            GoKu Money offers the best borrowing conditions on the market. A small, one-time fee is
            charged to borrow GAI instead of highly variable interest rates.
          </Text>
        </Flex>

        <WrappedStar1 />
        <WrappedStar2 />

        <Image
          src={iconMoney}
          alt="mokey"
          style={{
            position: "absolute",
            bottom: "0px"
          }}
        />
      </Flex>

      {/* hero 1GAI = 1USD */}
      <Flex
        sx={{
          width: "100vw",
          background: "linear-gradient(180deg, rgba(9, 105, 161, 0.00) 0%, #3C70D3 47.9%), #191828",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          py: "130px"
        }}
      >
        <img
          src={iconStar3}
          alt="star-3"
          style={{ position: "absolute", top: "36px", transform: "translateX(-150px)" }}
        />
        <MosaicCard sx={{ width: ["90vw", "90vw", "auto"] }}>
          <Grid
            sx={{
              width: ["100%", "100%", "1200px"],
              height: ["auto", "auto", "495px"],
              columnGap: "60px",
              rowGap: "20px",
              paddingTop: ["24px", "24px", "72px"],
              paddingBottom: ["24px", "24px", "0px"],
              columns: [1, 1, 2],
              gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"]
            }}
          >
            <Flex
              sx={{ gap: 4, flexDirection: "column", alignItems: ["center", "center", "flex-end"] }}
            >
              <Text
                className="card-title"
                sx={{
                  color: "primary6",
                  fontSize: ["24px", "24px", "48px"]
                }}
              >
                1 GAI ≈ 1 USD
              </Text>
              <Text
                className="card-content"
                sx={{
                  color: "text5",
                  width: ["100%", "100%", "450px"],
                  textAlign: ["center", "center", "right"]
                }}
              >
                Borrow GAI, a fully backed stablecoin hard-pegged to the US Dollar. Use GAI however
                you like in the DeFi world.
              </Text>
            </Flex>

            <Flex
              sx={{
                gap: 4,
                flexDirection: "column",
                alignItems: ["center", "center", "flex-start"]
              }}
            >
              <Text
                sx={{
                  color: "primary6",
                  textAlign: "left",
                  fontSize: ["24px", "24px", "48px"]
                }}
                className="card-title"
              >
                High Capital Efficiency
              </Text>
              <Text
                className="card-content"
                sx={{
                  color: "text5",
                  width: ["100%", "100%", "450px"],
                  textAlign: ["center", "center", "left"]
                }}
              >
                Goku’s efficient liquidation mechanism allows users to get the most liquidity for
                their favorite crypto assets.
              </Text>
            </Flex>

            <Flex
              sx={{ gap: 4, flexDirection: "column", alignItems: ["center", "center", "flex-end"] }}
            >
              <Text
                sx={{
                  color: "primary6",
                  fontSize: ["24px", "24px", "48px"]
                }}
                className="card-title"
              >
                DAO Governance
              </Text>
              <Text
                className="card-content"
                sx={{
                  color: "text5",
                  width: ["100%", "100%", "450px"],
                  textAlign: ["center", "center", "right"]
                }}
              >
                On-chain governance that can adjust protocol parameters (e.g. liquidation ratio), add
                collateral assets, make improvement proposals via veGOK token.
              </Text>
            </Flex>

            <Flex
              sx={{
                gap: 4,
                flexDirection: "column",
                alignItems: ["center", "center", "flex-start"]
              }}
            >
              <Text
                sx={{
                  color: "primary6",
                  textAlign: "left",
                  fontSize: ["24px", "24px", "48px"]
                }}
                className="card-title"
              >
                High APY
              </Text>
              <Text
                className="card-content"
                sx={{
                  color: "text5",
                  width: ["100%", "100%", "450px"],
                  textAlign: ["center", "center", "left"]
                }}
              >
                Goku closely collaborates with other DeFi protocols in Manta eocsystem to provide
                optimized yield strategies and higher APY.
              </Text>
            </Flex>
          </Grid>
        </MosaicCard>
        <img
          src={iconCloud}
          alt="cloud"
          style={{ position: "absolute", transform: "translateX(585px) translateY(60px)" }}
        />
        <img
          src={iconCloud}
          alt="cloud"
          style={{ position: "absolute", transform: "translateX(-585px) translateY(280px)" }}
        />
      </Flex>

      {/* parters */}
      <Flex
        sx={{
          width: "100vw",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          bg: "#FFFFFF"
        }}
      >
        <Box
          sx={{
            width: ["80%", "80%", "356px"],
            height: "228px",
            position: "absolute",
            top: "-60px"
          }}
        >
          <img src={iconParters} alt="parters" style={{ width: "100%" }} />
        </Box>

        <Text className="partners-tile" sx={{ mt: "218px", color: "text5" }}>
          Backers and Partners
        </Text>
        <Text className="card-content" sx={{ mt: "24px", color: "text5", textAlign: "center" }}>
          We're supported by leading teams and organizations.
        </Text>
        <Grid
          sx={{
            mt: "57px",
            columnGap: "53px",
            rowGap: "46px",
            paddingBottom: "57px",
            columns: [1, 1, 4],
            gridTemplateColumns: ["1fr", "1fr", "1fr 1fr 1fr 1fr"]
          }}
        >
          {[boxList1, boxList2, boxList3, boxList4, boxList5].map((item, index) => {
            return (
              <Flex
                key={index}
                sx={{
                  width: "260px",
                  height: "260px",
                  background: `url(${bgCardGrey2})`,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img
                  src={item}
                  alt="parters"
                  style={{
                    width: "200px"
                  }}
                />
              </Flex>
            );
          })}
        </Grid>
      </Flex>

      {/* footer */}
      <Flex
        sx={{
          pl: ["0px", "0px", "120px"],
          pr: ["0px", "98px"],
          flexDirection: ["column", "row", "row"],
          alignItems: "center",
          justifyContent: ["center", "space-between"],
          pt: ["24px", "76px", "76px"],
          pb: ["24px", "66px", "66px"],
          bg: "#1D2129"
        }}
      >
        <Flex sx={{ alignItems: "center" }}>
          <Image src={logo} alt="logo" sx={{ width: ["120px", "150px", "217px"] }} />
        </Flex>
        <Flex as="nav" sx={{ gap: "32px", display: ["flex", "flex", "flex"] }}>
          {footerList.map(item => (
            <Box
              sx={{
                textAlign: "center",
                "&:hover": { backgroundColor: "#7DBAB0" },
                width: ["auto", "97px", "97px"],
                height: "48px",
                cursor: "pointer"
              }}
            >
              <a
                style={{ lineHeight: "48px", color: "#fff" }}
                href={item.link}
                target="_blank"
                rel="noreferrer"
              >
                <Text
                  className="header-menu"
                  sx={{
                    lineHeight: "48px",
                    color: "rgba(255, 255, 255, 0.6)",
                    fontSize: ["12px", "24px", "24px"]
                  }}
                >
                  {item.title}
                </Text>
              </a>
            </Box>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LandingPage;
