import { BlockTag } from "@ethersproject/abstract-provider";

import {
  Decimal18,
  Fees,
  AstridDaoStore,
  GOKStake,
  LockedStake,
  ReadableAstridDao,
  StabilityDeposit,
  Vault,
  VaultListingParams,
  VaultWithPendingRedistribution,
  UserVault,
  UserVaultStatus,
  LockedStakesParams,
  CollateralDecimal,
  getCollateralOracle,
  SupportedCollaterals
} from "@astrid-dao/lib-base";

import { MultiVaultGetter, PriceFeedPyth, PriceFeedRedStoneClassicLayerBank } from "../types";

import {
  convertBigNumberPriceToDisplayAcceptablePrice,
  convertBigNumberPriceToDisplayAcceptablePriceRedStoneClassical,
  decimalify18,
  decimalifyCollateralDecimal,
  panic
} from "./_utils";
import { EthersCallOverrides, EthersProvider, EthersSigner } from "./types";

import {
  EthersAstridDaoConnection,
  EthersAstridDaoConnectionOptionalParams,
  EthersAstridDaoStoreOption,
  _connect,
  _getBlockTimestamp,
  _getContracts,
  _requireAddress
} from "./EthersAstridDaoConnection";

import { fetchPriceFeeds } from "./hermes";
import { BlockPolledAstridDaoStore } from "./BlockPolledAstridDaoStore";
import { BigNumber } from "ethers";
import { resolve } from "path";

// TODO: these are constant in the contracts, so it doesn't make sense to make a call for them,
// but to avoid having to update them here when we change them in the contracts, we could read
// them once after deployment and save them to AstridDaoDeployment.
const MINUTE_DECAY_FACTOR = Decimal18.from("0.999037758833783000");
const BETA = Decimal18.from(2);

export enum BackendVaultStatus {
  nonExistent,
  active,
  closedByOwner,
  closedByLiquidation,
  closedByRedemption
}

export const userVaultStatusFrom = (backendStatus: BackendVaultStatus): UserVaultStatus =>
  backendStatus === BackendVaultStatus.nonExistent
    ? "nonExistent"
    : backendStatus === BackendVaultStatus.active
    ? "open"
    : backendStatus === BackendVaultStatus.closedByOwner
    ? "closedByOwner"
    : backendStatus === BackendVaultStatus.closedByLiquidation
    ? "closedByLiquidation"
    : backendStatus === BackendVaultStatus.closedByRedemption
    ? "closedByRedemption"
    : panic(new Error(`invalid backendStatus ${backendStatus}`));

const convertToDate = (timestamp: number) => new Date(timestamp * 1000);

const validSortingOptions = ["ascendingCollateralRatio", "descendingCollateralRatio"];

const expectPositiveInt = <K extends string>(obj: { [P in K]?: number }, key: K) => {
  if (obj[key] !== undefined) {
    if (!Number.isInteger(obj[key])) {
      throw new Error(`${key} must be an integer`);
    }

    if (obj[key] < 0) {
      throw new Error(`${key} must not be negative`);
    }
  }
};

/**
 * Ethers-based implementation of {@link @astrid-dao/lib-base#ReadableAstridDao}.
 *
 * @public
 */
export class ReadableEthersAstridDao implements ReadableAstridDao {
  readonly connection: EthersAstridDaoConnection;

  /** @internal */
  constructor(connection: EthersAstridDaoConnection) {
    this.connection = connection;
  }

  /** @internal */
  static _from(
    connection: EthersAstridDaoConnection & { useStore: "blockPolled" }
  ): ReadableEthersAstridDaoWithStore<BlockPolledAstridDaoStore>;

  /** @internal */
  static _from(connection: EthersAstridDaoConnection): ReadableEthersAstridDao;

  /** @internal */
  static _from(connection: EthersAstridDaoConnection): ReadableEthersAstridDao {
    const readable = new ReadableEthersAstridDao(connection);

    return connection.useStore === "blockPolled"
      ? new _BlockPolledReadableEthersAstridDao(readable)
      : readable;
  }

  /** @internal */
  static connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams: EthersAstridDaoConnectionOptionalParams & { useStore: "blockPolled" }
  ): Promise<ReadableEthersAstridDaoWithStore<BlockPolledAstridDaoStore>>;

  static connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams?: EthersAstridDaoConnectionOptionalParams
  ): Promise<ReadableEthersAstridDao>;

  /**
   * Connect to the AstridDAO protocol and create a `ReadableEthersAstridDao` object.
   *
   * @param signerOrProvider - Ethers `Signer` or `Provider` to use for connecting to the Ethereum
   *                           network.
   * @param optionalParams - Optional parameters that can be used to customize the connection.
   */
  static async connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams?: EthersAstridDaoConnectionOptionalParams
  ): Promise<ReadableEthersAstridDao> {
    return ReadableEthersAstridDao._from(await _connect(signerOrProvider, optionalParams));
  }

  /**
   * Check whether this `ReadableEthersAstridDao` is a {@link ReadableEthersAstridDaoWithStore}.
   */
  hasStore(): this is ReadableEthersAstridDaoWithStore;

  /**
   * Check whether this `ReadableEthersAstridDao` is a
   * {@link ReadableEthersAstridDaoWithStore}\<{@link BlockPolledAstridDaoStore}\>.
   */
  hasStore(
    store: "blockPolled"
  ): this is ReadableEthersAstridDaoWithStore<BlockPolledAstridDaoStore>;

  hasStore(): boolean {
    return false;
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getTotalRedistributed} */
  async getTotalRedistributed(overrides?: EthersCallOverrides): Promise<Vault> {
    const { vaultManager: vaultManager } = _getContracts(this.connection);

    const [debt, collateral] = await Promise.all([
      vaultManager.L_GAIDebt({ ...overrides }).then(decimalify18),
      vaultManager.L_COL({ ...overrides }).then(decimalifyCollateralDecimal)
    ]);

    const e18 = Decimal18.from("10").pow(18);
    const ce18 = CollateralDecimal.from("10").pow(18);
    return new Vault(collateral.div(ce18), debt.div(e18));
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getVaultBeforeRedistribution} */
  async getVaultBeforeRedistribution(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<VaultWithPendingRedistribution> {
    address ??= _requireAddress(this.connection);
    const { vaultManager: vaultManager } = _getContracts(this.connection);

    const [vault, snapshot] = await Promise.all([
      vaultManager.Vaults(address, { ...overrides }),
      vaultManager.rewardSnapshots(address, { ...overrides })
    ]);

    if (vault.status === BackendVaultStatus.active) {
      return new VaultWithPendingRedistribution(
        address,
        userVaultStatusFrom(vault.status),
        decimalifyCollateralDecimal(vault.coll),
        decimalify18(vault.debt),
        decimalifyCollateralDecimal(vault.stake),
        new Vault(decimalifyCollateralDecimal(snapshot.COL), decimalify18(snapshot.GAIDebt))
      );
    } else {
      return new VaultWithPendingRedistribution(address, userVaultStatusFrom(vault.status));
    }
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getVault} */
  async getVault(address?: string, overrides?: EthersCallOverrides): Promise<UserVault> {
    const [vault, totalRedistributed] = await Promise.all([
      this.getVaultBeforeRedistribution(address, overrides),
      this.getTotalRedistributed(overrides)
    ]);

    return vault.applyRedistribution(totalRedistributed);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getNumberOfVaults} */
  async getNumberOfVaults(overrides?: EthersCallOverrides): Promise<number> {
    const { vaultManager: vaultManager } = _getContracts(this.connection);

    return (await vaultManager.getVaultOwnersCount({ ...overrides })).toNumber();
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getPrice} */
  async getPrice(overrides?: EthersCallOverrides): Promise<Decimal18> {
    const { priceFeed, stakeStone } = _getContracts(this.connection);
    const { collateralName, _isDev } = this.connection;

    if (getCollateralOracle(_isDev).PYTH.includes((collateralName as SupportedCollaterals) ?? "")) {
      try {
        // try use price from PriceFeed
        // but if the price feed is not working, use the price from the hermes
        const price = await (priceFeed as PriceFeedPyth).fetchPrice({ ...overrides });
        return convertBigNumberPriceToDisplayAcceptablePrice(price);
      } catch (e) {
        const pythPrice = await fetchPriceFeeds(
          [this.connection.pythConfigs.priceFeedID],
          this.connection._isDev
        ).then(rep => {
          if (rep && rep[0]) {
            const priceData = rep[0].price;
            const price = priceData.price;
            const expo = priceData.expo;
            return Decimal18.from(price).div(Decimal18.from(10).pow(Math.abs(expo)));
          } else {
            throw new Error(`fetch ${this.connection?.collateralName} price failed`);
          }
        });
        // comment due to STONE use redstone classical as price feed now
        // .then(async price => {
        //   if (this.connection.collateralName === "STONE") {
        //     const rate = await stakeStone.tokenPrice();
        //     const rateDecimal = Decimal18.fromBigNumberString(rate.toHexString());
        //     console.log("STONE", rate.toString(), price.toString());
        //     return rateDecimal.mul(price);
        //   }
        //   return price;
        // });
        return pythPrice;
      }
    } else {
      return (priceFeed as PriceFeedRedStoneClassicLayerBank)
        .fetchPrice({ ...overrides })
        .then(price => convertBigNumberPriceToDisplayAcceptablePrice(price));
    }
  }

  /** @internal */
  async _getActivePool(overrides?: EthersCallOverrides): Promise<Vault> {
    const { activePool } = _getContracts(this.connection);

    const [activeCollateral, activeDebt] = await Promise.all([
      activePool.getCOL({ ...overrides }).then(decimalifyCollateralDecimal),
      activePool.getGAIDebt({ ...overrides }).then(decimalify18)
    ]);

    return new Vault(activeCollateral, activeDebt);
  }

  /** @internal */
  async _getDefaultPool(overrides?: EthersCallOverrides): Promise<Vault> {
    const { defaultPool } = _getContracts(this.connection);

    const [liquidatedCollateral, closedDebt] = await Promise.all([
      defaultPool.getCOL({ ...overrides }).then(decimalifyCollateralDecimal),
      defaultPool.getGAIDebt({ ...overrides }).then(decimalify18)
    ]);

    return new Vault(liquidatedCollateral, closedDebt);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getTotal} */
  async getTotal(overrides?: EthersCallOverrides): Promise<Vault> {
    const [activePool, defaultPool] = await Promise.all([
      this._getActivePool(overrides),
      this._getDefaultPool(overrides)
    ]);

    return activePool.add(defaultPool);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getStabilityDeposit} */
  async getStabilityDeposit(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<StabilityDeposit> {
    address ??= _requireAddress(this.connection);
    const { stabilityPool } = _getContracts(this.connection);

    const [initialValue, currentGai, collateralGain, gokReward] = await Promise.all([
      stabilityPool.deposits(address, { ...overrides }),
      stabilityPool.getCompoundedGAIDeposit(address, { ...overrides }),
      stabilityPool.getDepositorCOLGain(address, { ...overrides }),
      stabilityPool.getDepositorGOKGain(address, { ...overrides })
    ]);

    return new StabilityDeposit(
      decimalify18(initialValue),
      decimalify18(currentGai),
      decimalifyCollateralDecimal(collateralGain),
      decimalify18(gokReward)
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getRemainingStabilityPoolGOKReward} */
  async getRemainingStabilityPoolGOKReward(overrides?: EthersCallOverrides): Promise<Decimal18> {
    const { communityIssuance } = _getContracts(this.connection);

    const issuanceCap = this.connection.totalStabilityPoolTokenReward;
    const totalGOKIssued = decimalify18(await communityIssuance.totalGOKIssued({ ...overrides }));

    // totalGOKIssued approaches but never reaches issuanceCap
    return issuanceCap.sub(totalGOKIssued);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getGaiInStabilityPool} */
  getGaiInStabilityPool(overrides?: EthersCallOverrides): Promise<Decimal18> {
    const { stabilityPool } = _getContracts(this.connection);

    return stabilityPool.getTotalGAIDeposits({ ...overrides }).then(decimalify18);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getGaiBalance} */
  getGaiBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal18> {
    address ??= _requireAddress(this.connection);
    const { gaiToken } = _getContracts(this.connection);

    return gaiToken.balanceOf(address, { ...overrides }).then(decimalify18);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getXcGaiBalance} */
  getXcGaiBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal18> {
    address ??= _requireAddress(this.connection);
    const { xcGaiToken } = _getContracts(this.connection);
    if (xcGaiToken.address === "0x0000000000000000000000000000000000000000") {
      return Promise.resolve(Decimal18.ZERO);
    }
    return xcGaiToken.balanceOf(address, { ...overrides }).then(decimalify18);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getGOKBalance} */
  getGOKBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal18> {
    address ??= _requireAddress(this.connection);
    const { gokToken } = _getContracts(this.connection);

    return gokToken.balanceOf(address, { ...overrides }).then(decimalify18);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getWrappedTokenBalance} */
  getWrappedTokenBalance(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<CollateralDecimal> {
    address ??= _requireAddress(this.connection);
    const { colToken } = _getContracts(this.connection);

    return colToken.balanceOf(address, { ...overrides }).then(decimalifyCollateralDecimal);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getWrappedTokenAllowance} */
  getWrappedTokenAllowance(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<CollateralDecimal> {
    address ??= _requireAddress(this.connection);
    const { colToken, borrowerOperations } = _getContracts(this.connection);

    return colToken
      .allowance(address, borrowerOperations.address, { ...overrides })
      .then(decimalifyCollateralDecimal);
  }

  getXcGaiAllowance(address?: string, overrides?: EthersCallOverrides): Promise<CollateralDecimal> {
    address ??= _requireAddress(this.connection);
    const { xcGaiToken, xcGaiWrapper } = _getContracts(this.connection);
    if (xcGaiToken.address === "0x0000000000000000000000000000000000000000") {
      return Promise.resolve(CollateralDecimal.ZERO);
    }
    return xcGaiToken
      .allowance(address, xcGaiWrapper.address, { ...overrides })
      .then(decimalifyCollateralDecimal);
  }

  getGaiAllowance(address?: string, overrides?: EthersCallOverrides): Promise<CollateralDecimal> {
    address ??= _requireAddress(this.connection);
    const { gaiToken, xcGaiWrapper } = _getContracts(this.connection);
    return gaiToken
      .allowance(address, xcGaiWrapper.address, { ...overrides })
      .then(decimalifyCollateralDecimal);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getCollateralSurplusBalance} */
  getCollateralSurplusBalance(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<CollateralDecimal> {
    address ??= _requireAddress(this.connection);
    const { collSurplusPool } = _getContracts(this.connection);

    return collSurplusPool
      .getCollateral(address, { ...overrides })
      .then(decimalifyCollateralDecimal);
  }

  /** @internal */
  getVaults(
    params: VaultListingParams & { beforeRedistribution: true },
    overrides?: EthersCallOverrides
  ): Promise<VaultWithPendingRedistribution[]>;

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.(getVaults:2)} */
  getVaults(params: VaultListingParams, overrides?: EthersCallOverrides): Promise<UserVault[]>;

  async getVaults(
    params: VaultListingParams,
    overrides?: EthersCallOverrides
  ): Promise<UserVault[]> {
    const { multiVaultGetter: multiVaultGetter } = _getContracts(this.connection);

    expectPositiveInt(params, "first");
    expectPositiveInt(params, "startingAt");

    if (!validSortingOptions.includes(params.sortedBy)) {
      throw new Error(
        `sortedBy must be one of: ${validSortingOptions.map(x => `"${x}"`).join(", ")}`
      );
    }

    const [totalRedistributed, backendVaults] = await Promise.all([
      params.beforeRedistribution ? undefined : this.getTotalRedistributed({ ...overrides }),
      multiVaultGetter.getMultipleSortedVaults(
        params.sortedBy === "descendingCollateralRatio"
          ? params.startingAt ?? 0
          : -((params.startingAt ?? 0) + 1),
        params.first,
        { ...overrides }
      )
    ]);

    const vaults = mapBackendVaults(backendVaults);

    if (totalRedistributed) {
      return vaults.map(vault => vault.applyRedistribution(totalRedistributed));
    } else {
      return vaults;
    }
  }

  /** @internal */
  _getBlockTimestamp(blockTag?: BlockTag): Promise<number> {
    return _getBlockTimestamp(this.connection, blockTag);
  }

  /** @internal */
  async _getFeesFactory(
    overrides?: EthersCallOverrides
  ): Promise<(blockTimestamp: number, recoveryMode: boolean) => Fees> {
    const { vaultManager: vaultManager } = _getContracts(this.connection);

    const [lastFeeOperationTime, baseRateWithoutDecay] = await Promise.all([
      vaultManager.lastFeeOperationTime({ ...overrides }),
      vaultManager.baseRate({ ...overrides }).then(decimalify18)
    ]);

    return (blockTimestamp, recoveryMode) =>
      new Fees(
        baseRateWithoutDecay,
        MINUTE_DECAY_FACTOR,
        BETA,
        convertToDate(lastFeeOperationTime.toNumber()),
        convertToDate(blockTimestamp),
        recoveryMode
      );
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getFees} */
  async getFees(overrides?: EthersCallOverrides): Promise<Fees> {
    const [createFees, total, price, blockTimestamp] = await Promise.all([
      this._getFeesFactory(overrides),
      this.getTotal(overrides),
      this.getPrice(overrides),
      this._getBlockTimestamp(overrides?.blockTag)
    ]);

    return createFees(blockTimestamp, total.collateralRatioIsBelowCritical(price));
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getGOKStake} */
  async getGOKStake(address?: string, overrides?: EthersCallOverrides): Promise<GOKStake> {
    address ??= _requireAddress(this.connection);
    const { gokStaking } = _getContracts(this.connection);

    const [collateralGain, gaiGain, weightedStakes, unweightedStakes] = await Promise.all([
      gokStaking.getPendingCOLGain(address, { ...overrides }).then(decimalifyCollateralDecimal),
      gokStaking.getPendingGAIGain(address, { ...overrides }).then(decimalify18),
      gokStaking.weightedStakes(address, { ...overrides }).then(decimalify18),
      gokStaking.unweightedStakes(address, { ...overrides }).then(decimalify18)
    ]);

    return new GOKStake(
      Decimal18.ZERO,
      collateralGain,
      gaiGain,
      Decimal18.ZERO,
      weightedStakes,
      unweightedStakes
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getTotalUnweightedStakedGOK} */
  async getTotalUnweightedStakedGOK(overrides?: EthersCallOverrides): Promise<Decimal18> {
    const { gokStaking } = _getContracts(this.connection);

    return gokStaking.totalUnweightedGOKStaked({ ...overrides }).then(decimalify18);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getTotalWeightedStakedGOK} */
  async getTotalWeightedStakedGOK(overrides?: EthersCallOverrides): Promise<Decimal18> {
    const { gokStaking } = _getContracts(this.connection);

    return gokStaking.totalWeightedGOKStaked({ ...overrides }).then(decimalify18);
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getFirstActiveLockedStakeID} */
  async getFirstActiveLockedStakeID(
    address?: string,
    _overrides?: EthersCallOverrides
  ): Promise<BigNumber> {
    address ??= _requireAddress(this.connection);
    const { gokStaking } = _getContracts(this.connection);
    const firstActiveLockedStakeID = await gokStaking.headLockedStakeIDMap(address);

    return firstActiveLockedStakeID;
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getFirstActiveLockedStakeID} */
  async getTotalLockedStakesCount(
    address?: string,
    _overrides?: EthersCallOverrides
  ): Promise<number> {
    address ??= _requireAddress(this.connection);

    const { gokStaking, multiStakeGetter } = _getContracts(this.connection);
    const firstActiveLockedStakeID = await gokStaking.headLockedStakeIDMap(address);
    const lockedStakeIds = await multiStakeGetter.getLockedStakesIDsFromHead(
      address,
      firstActiveLockedStakeID,
      // Set to a high aribitrary number for now until we find a better solution
      500
    );
    const nonZeroIds = lockedStakeIds.filter(id => !id.isZero());
    const lockedStakes = await Promise.all(
      nonZeroIds.map(id => gokStaking.lockedStakeMap(address!, id))
    );
    const activeLockedStakes = lockedStakes.filter(({ active }) => active);

    return activeLockedStakes.length;
  }

  /** {@inheritDoc @astrid-dao/lib-base#ReadableAstridDao.getLockedStakes} */
  async getLockedStakes(
    { address, startingId, pageSize }: LockedStakesParams,
    _overrides?: EthersCallOverrides
  ): Promise<LockedStake[]> {
    address ??= _requireAddress(this.connection);
    const { gokStaking, multiStakeGetter } = _getContracts(this.connection);

    const lockedStakeIds = await multiStakeGetter.getLockedStakesIDsFromHead(
      address,
      startingId,
      pageSize
    );
    const nonZeroIds = lockedStakeIds.filter(id => !id.isZero());
    const lockedStakes = await Promise.all(
      nonZeroIds.map(id => gokStaking.lockedStakeMap(address, id))
    );
    const activeLockedStakes = lockedStakes.filter(({ active }) => active);
    const formattedLockedStakes = activeLockedStakes.map(
      ({ lockedUntil, amount, ID, stakeWeight, nextID, prevID }) => {
        return {
          lockedUntil: Decimal18.from(lockedUntil.toNumber() * 1000), // TODO: Add constant for magic number
          stakeWeight: Decimal18.from(stakeWeight.toNumber()),
          amount: decimalify18(amount),
          id: ID,
          nextId: nextID,
          prevId: prevID
        };
      }
    );

    return formattedLockedStakes;
  }
}

type Resolved<T> = T extends Promise<infer U> ? U : T;
type BackendVaults = Resolved<ReturnType<MultiVaultGetter["getMultipleSortedVaults"]>>;

const mapBackendVaults = (vaults: BackendVaults): VaultWithPendingRedistribution[] =>
  vaults.map(
    vault =>
      new VaultWithPendingRedistribution(
        vault.owner,
        "open", // These Vaults are coming from the SortedVaults list, so they must be open
        decimalifyCollateralDecimal(vault.coll),
        decimalify18(vault.debt),
        decimalifyCollateralDecimal(vault.stake),
        new Vault(
          decimalifyCollateralDecimal(vault.snapshotCOL),
          decimalify18(vault.snapshotGAIDebt)
        )
      )
  );

/**
 * Variant of {@link ReadableEthersAstridDao} that exposes a {@link @astrid-dao/lib-base#AstridDaoStore}.
 *
 * @public
 */
export interface ReadableEthersAstridDaoWithStore<T extends AstridDaoStore = AstridDaoStore>
  extends ReadableEthersAstridDao {
  /** An object that implements AstridDaoStore. */
  readonly store: T;
}

class _BlockPolledReadableEthersAstridDao
  implements ReadableEthersAstridDaoWithStore<BlockPolledAstridDaoStore>
{
  readonly connection: EthersAstridDaoConnection;
  readonly store: BlockPolledAstridDaoStore;

  private readonly _readable: ReadableEthersAstridDao;

  constructor(readable: ReadableEthersAstridDao) {
    const store = new BlockPolledAstridDaoStore(readable);

    this.store = store;
    this.connection = readable.connection;
    this._readable = readable;
  }

  private _blockHit(overrides?: EthersCallOverrides): boolean {
    return (
      !overrides ||
      overrides.blockTag === undefined ||
      overrides.blockTag === this.store.state.blockTag
    );
  }

  private _userHit(address?: string, overrides?: EthersCallOverrides): boolean {
    return (
      this._blockHit(overrides) &&
      (address === undefined || address === this.store.connection.userAddress)
    );
  }

  hasStore(store?: EthersAstridDaoStoreOption): boolean {
    return store === undefined || store === "blockPolled";
  }

  async getTotalRedistributed(overrides?: EthersCallOverrides): Promise<Vault> {
    return this._blockHit(overrides)
      ? this.store.state.totalRedistributed
      : this._readable.getTotalRedistributed(overrides);
  }

  async getVaultBeforeRedistribution(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<VaultWithPendingRedistribution> {
    return this._userHit(address, overrides)
      ? this.store.state.vaultBeforeRedistribution
      : this._readable.getVaultBeforeRedistribution(address, overrides);
  }

  async getVault(address?: string, overrides?: EthersCallOverrides): Promise<UserVault> {
    return this._userHit(address, overrides)
      ? this.store.state.vault
      : this._readable.getVault(address, overrides);
  }

  async getNumberOfVaults(overrides?: EthersCallOverrides): Promise<number> {
    return this._blockHit(overrides)
      ? this.store.state.numberOfVaults
      : this._readable.getNumberOfVaults(overrides);
  }

  async getPrice(overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._blockHit(overrides) ? this.store.state.price : this._readable.getPrice(overrides);
  }

  async getTotal(overrides?: EthersCallOverrides): Promise<Vault> {
    return this._blockHit(overrides) ? this.store.state.total : this._readable.getTotal(overrides);
  }

  async getStabilityDeposit(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<StabilityDeposit> {
    return this._userHit(address, overrides)
      ? this.store.state.stabilityDeposit
      : this._readable.getStabilityDeposit(address, overrides);
  }

  async getRemainingStabilityPoolGOKReward(overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._blockHit(overrides)
      ? this.store.state.remainingStabilityPoolGOKReward
      : this._readable.getRemainingStabilityPoolGOKReward(overrides);
  }

  async getGaiInStabilityPool(overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._blockHit(overrides)
      ? this.store.state.gaiInStabilityPool
      : this._readable.getGaiInStabilityPool(overrides);
  }

  async getGaiBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._userHit(address, overrides)
      ? this.store.state.gaiBalance
      : this._readable.getGaiBalance(address, overrides);
  }

  async getXcGaiBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._userHit(address, overrides)
      ? this.store.state.xcGaiBalance
      : this._readable.getXcGaiBalance(address, overrides);
  }

  async getGOKBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._userHit(address, overrides)
      ? this.store.state.gokBalance
      : this._readable.getGOKBalance(address, overrides);
  }

  async getWrappedTokenBalance(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<CollateralDecimal> {
    return this._userHit(address, overrides)
      ? this.store.state.wrappedTokenBalance
      : this._readable.getWrappedTokenBalance(address, overrides);
  }

  async getWrappedTokenAllowance(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<CollateralDecimal> {
    return this._userHit(address, overrides)
      ? this.store.state.wrappedTokenAllowance
      : this._readable.getWrappedTokenAllowance(address, overrides);
  }

  async getGaiAllowance(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<CollateralDecimal> {
    return this._userHit(address, overrides)
      ? this.store.state.gaiAllowance
      : this._readable.getGaiAllowance(address, overrides);
  }

  async getXcGaiAllowance(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<CollateralDecimal> {
    return this._userHit(address, overrides)
      ? this.store.state.xcGaiAllowance
      : this._readable.getXcGaiAllowance(address, overrides);
  }

  async getCollateralSurplusBalance(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<CollateralDecimal> {
    return this._userHit(address, overrides)
      ? this.store.state.collateralSurplusBalance
      : this._readable.getCollateralSurplusBalance(address, overrides);
  }

  async _getBlockTimestamp(blockTag?: BlockTag): Promise<number> {
    return this._blockHit({ blockTag })
      ? this.store.state.blockTimestamp
      : this._readable._getBlockTimestamp(blockTag);
  }

  async _getFeesFactory(
    overrides?: EthersCallOverrides
  ): Promise<(blockTimestamp: number, recoveryMode: boolean) => Fees> {
    return this._blockHit(overrides)
      ? this.store.state._feesFactory
      : this._readable._getFeesFactory(overrides);
  }

  async getFees(overrides?: EthersCallOverrides): Promise<Fees> {
    return this._blockHit(overrides) ? this.store.state.fees : this._readable.getFees(overrides);
  }

  async getGOKStake(address?: string, overrides?: EthersCallOverrides): Promise<GOKStake> {
    return this._userHit(address, overrides)
      ? this.store.state.gokStake
      : this._readable.getGOKStake(address, overrides);
  }

  async getTotalUnweightedStakedGOK(overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._blockHit(overrides)
      ? this.store.state.totalUnweightedStakedGOK
      : this._readable.getTotalUnweightedStakedGOK(overrides);
  }

  async getTotalWeightedStakedGOK(overrides?: EthersCallOverrides): Promise<Decimal18> {
    return this._blockHit(overrides)
      ? this.store.state.totalWeightedStakedGOK
      : this._readable.getTotalWeightedStakedGOK(overrides);
  }

  async getFirstActiveLockedStakeID(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<BigNumber> {
    return this._readable.getFirstActiveLockedStakeID(address, overrides);
  }

  async getLockedStakes(
    params: LockedStakesParams,
    overrides?: EthersCallOverrides
  ): Promise<LockedStake[]> {
    return this._readable.getLockedStakes(params, overrides);
  }

  async getTotalLockedStakesCount(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<number> {
    return this._readable.getTotalLockedStakesCount(address, overrides);
  }

  getVaults(
    params: VaultListingParams & { beforeRedistribution: true },
    overrides?: EthersCallOverrides
  ): Promise<VaultWithPendingRedistribution[]>;

  getVaults(params: VaultListingParams, overrides?: EthersCallOverrides): Promise<UserVault[]>;

  getVaults(params: VaultListingParams, overrides?: EthersCallOverrides): Promise<UserVault[]> {
    return this._readable.getVaults(params, overrides);
  }

  _getActivePool(): Promise<Vault> {
    throw new Error("Method not implemented.");
  }

  _getDefaultPool(): Promise<Vault> {
    throw new Error("Method not implemented.");
  }

  _getRemainingLiquidityMiningGOKRewardCalculator(): Promise<(blockTimestamp: number) => Decimal18> {
    throw new Error("Method not implemented.");
  }
}
