import { CollateralDecimal } from "./CollateralDecimal";
import { Decimal18, Decimalish } from "./Decimal18";

/**
 * Represents the change between two Stability Deposit states.
 *
 * @public
 */
export type StabilityDepositChange<T> =
  | { depositGai: T; withdrawGai?: undefined }
  | { depositGai?: undefined; withdrawGai: T; withdrawAllGai: boolean };

/**
 * A Stability Deposit and its accrued gains.
 *
 * @public
 */
export class StabilityDeposit {
  /** Amount of GAIin the Stability Deposit at the time of the last direct modification. */
  readonly initialGai: Decimal18;

  /** Amount of GAIleft in the Stability Deposit. */
  readonly currentGai: Decimal18;

  /** Amount of collateral (e.g. WASTR, USDC, BUSD, DOT, etc.) received in exchange for the used-up GAI. */
  readonly collateralGain: CollateralDecimal;

  /** Amount of GOK rewarded since the last modification of the Stability Deposit. */
  readonly gokReward: Decimal18;

  /** @internal */
  constructor(
    initialGai: Decimal18,
    currentGai: Decimal18,
    collateralGain: CollateralDecimal,
    gokReward: Decimal18
  ) {
    this.initialGai = initialGai;
    this.currentGai = currentGai;
    this.collateralGain = collateralGain;
    this.gokReward = gokReward;

    if (this.currentGai.gt(this.initialGai)) {
      throw new Error("currentGai can't be greater than initialGai");
    }
  }

  get isEmpty(): boolean {
    return (
      this.initialGai.isZero &&
      this.currentGai.isZero &&
      this.collateralGain.isZero &&
      this.gokReward.isZero
    );
  }

  /** @internal */
  toString(): string {
    return (
      `{ initialGai: ${this.initialGai}` +
      `, currentGai: ${this.currentGai}` +
      `, collateralGain: ${this.collateralGain}` +
      `, gokReward: ${this.gokReward}`
    );
  }

  /**
   * Compare to another instance of `StabilityDeposit`.
   */
  equals(that: StabilityDeposit): boolean {
    return (
      this.initialGai.eq(that.initialGai) &&
      this.currentGai.eq(that.currentGai) &&
      this.collateralGain.eq(that.collateralGain) &&
      this.gokReward.eq(that.gokReward)
    );
  }

  /**
   * Calculate the difference between the `currentGai` in this Stability Deposit and `thatGai`.
   *
   * @returns An object representing the change, or `undefined` if the deposited amounts are equal.
   */
  whatChanged(thatGai: Decimalish): StabilityDepositChange<Decimal18> | undefined {
    thatGai = Decimal18.from(thatGai);

    if (thatGai.lt(this.currentGai)) {
      return { withdrawGai: this.currentGai.sub(thatGai), withdrawAllGai: thatGai.isZero };
    }

    if (thatGai.gt(this.currentGai)) {
      return { depositGai: thatGai.sub(this.currentGai) };
    }
  }

  /**
   * Apply a {@link StabilityDepositChange} to this Stability Deposit.
   *
   * @returns The new deposited GAIamount.
   */
  apply(change: StabilityDepositChange<Decimalish> | undefined): Decimal18 {
    if (!change) {
      return this.currentGai;
    }

    if (change.withdrawGai !== undefined) {
      return change.withdrawAllGai || this.currentGai.lte(change.withdrawGai)
        ? Decimal18.ZERO
        : this.currentGai.sub(change.withdrawGai);
    } else {
      return this.currentGai.add(change.depositGai);
    }
  }
}
