import {
  getCollateralOracle,
  DISTRIBUTION_RATIO_SCHEDULED_PER_YEAR,
  REWARD_WEIGHT_PER_COLLATERAL,
  SupportedCollaterals
} from "@astrid-dao/lib-base";
import { Provider } from "@ethersproject/abstract-provider";
import { BigNumber, Contract, Overrides, Signer, ethers } from "ethers";

import {
  ReadonlyVaultMap,
  ReadonlyUserVaultMap,
  GaiInStabilityPoolMap,
  TotalUnweightedGOKStakedValuePerCollateral
} from "../components/Dashboard/hooks/useDashboardData";
import { CollateralDecimalService } from "../components/Dashboard/models/decimals/CollateralDecimalService";
import { DashboardDecimal18 } from "../components/Dashboard/models/decimals/DashboardDecimal18";
import {
  decimalify18,
  scaleUnitOfPrecisionToWad
} from "../components/Dashboard/models/decimals/decimals";
import { ReadonlyVault } from "../components/Dashboard/models/vaults/ReadonlyVault";
import {
  UserReadonlyVault,
  UserReadonlyVaultStatus
} from "../components/Dashboard/models/vaults/UserReadonlyVault";
import { SELECTABLE_COLLATERAL_TYPES, BaseTokenContractNames } from "../config";
import { fetchPriceFeeds } from "../data/hermes";
import { retriableCallWithBackoff } from "../utils/retryCall";
import {
  gokStakingMap,
  assertContractExist,
  activePoolMap,
  baseContractsMap,
  collateralTokenMap,
  currencyPriceMap,
  defaultPoolMap,
  publicSaleContractsMap,
  stabilityPoolMap,
  vaultManagerMap,
  currencyPricePythMap
} from "./contracts";
import { convertBigNumberPriceToDisplayAcceptablePriceRedStoneClassical } from "@astrid-dao/lib-ethers/dist/src/_utils";

type BaseMapTypePerCollateral = Map<SupportedCollaterals, DashboardDecimal18>;
export type Currencies = SupportedCollaterals | "GOK" | "GAI" | "xcGAI" | "GAI-API";
export type CollateralPriceMap = Map<Currencies, DashboardDecimal18>;
export type TotalDepositPerCollateral = BaseMapTypePerCollateral;
export type TotalGOKStakedValueMap = BaseMapTypePerCollateral;
export type AccountBalanceMap = Map<SupportedCollaterals, DashboardDecimal18>;

const logError = (error: unknown) => console.error("Error fetching data:", error);

// Does not throw.
async function waitForAllPromisesOrLogError<T>(promises: Promise<T>[]): Promise<T[]> {
  try {
    return await Promise.all(promises);
  } catch (e) {
    logError(e);
    return [];
  }
}

export async function getTotalUnweightedGOKStakedPerCollateral(): Promise<TotalGOKStakedValueMap> {
  const totalGOKStakedPerCollateral: TotalGOKStakedValueMap = new Map();

  const promises: Promise<any>[] = [];
  for (const [collateral] of gokStakingMap) {
    promises.push(
      (async () => {
        try {
          const gokStakingContract = assertContractExist(gokStakingMap.get(collateral));
          // The received raw totalUnweightedGOKStaked is denoted in Wei.
          // Use Decimal18.fromBigNumberString to reconstruct in Ether repr.
          const totalUnweightedGOKStaked = DashboardDecimal18.fromBigNumberString(
            (
              (await retriableCallWithBackoff(() =>
                gokStakingContract.totalUnweightedGOKStaked()
              )) as BigNumber
            ).toString()
          );

          totalGOKStakedPerCollateral.set(collateral, totalUnweightedGOKStaked);
        } catch (error) {
          logError(error);

          totalGOKStakedPerCollateral.set(collateral, DashboardDecimal18.ZERO);
        }
      })()
    );
  }

  await waitForAllPromisesOrLogError(promises);

  return totalGOKStakedPerCollateral;
}

type ContractCallResult = {
  hasFailed: boolean;
  msg?: string;
};

type CachedCollateralPriceResult = ContractCallResult & {
  price: DashboardDecimal18 | undefined;
};

type MinimumCollateralRatioResult = ContractCallResult & {
  MCR: DashboardDecimal18 | undefined;
};

type CriticalCollateralRatioResult = ContractCallResult & {
  CCR: DashboardDecimal18 | undefined;
};

type GAILiquidationReserveResult = ContractCallResult & {
  GAILiquidationReserve: DashboardDecimal18 | undefined;
};

type SignerERC20TokenBalanceResult = ContractCallResult & {
  SignerERC20TokenBalance: DashboardDecimal18 | undefined;
};

const simpleContractsResultCachePerCollateral = new Map<string, Map<string, unknown>>();
const simpleBaseContractsResultCache = new Map<string, unknown>();

SELECTABLE_COLLATERAL_TYPES.forEach(collateral => {
  simpleContractsResultCachePerCollateral.set(collateral, new Map());
});

export async function getNativeTokenBalance(signer: Signer, provider: Provider) {
  const signerAddress = await retriableCallWithBackoff(() => signer.getAddress());
  const astrBalanceResult = await retriableCallWithBackoff(() => provider.getBalance(signerAddress));

  return decimalify18(astrBalanceResult) ?? DashboardDecimal18.ZERO;
}

export async function getCachedCollateralPrice({
  collateralName,
  isTestnet
}: {
  collateralName: SupportedCollaterals;
  isTestnet: boolean;
}): Promise<CachedCollateralPriceResult> {
  const cacheKey = "price";
  try {
    if (getCollateralOracle(isTestnet).PYTH.includes(collateralName)) {
      const priceFeedParams = currencyPricePythMap.get(collateralName)!.params.id;
      // const stakeStone = stakeStoneContracts.get(collateralName);
      try {
        const fetchedPriceFeeds = await fetchPriceFeeds([priceFeedParams]);

        // READ: This is fetching individually for each collateral while the function supports batch fetching.
        const fetchedPythPrice = fetchedPriceFeeds[0].getPriceUnchecked();
        if (!fetchedPythPrice) {
          throw new Error("Price feeds are not available");
        }

        const unitPrice = DashboardDecimal18.from(fetchedPythPrice.price).div(
          DashboardDecimal18.from(10).pow(Math.abs(fetchedPythPrice.expo))
        );

        let rateDecimal = DashboardDecimal18.from(1);
        // if (stakeStone) {
        //   const rate = await stakeStone.tokenPrice();
        //   console.log("stone rate", rate.toString());
        //   rateDecimal = DashboardDecimal18.fromBigNumberString(rate.toHexString());
        // }

        simpleContractsResultCachePerCollateral
          .get(collateralName)
          ?.set(cacheKey, unitPrice.mul(rateDecimal));

        return { price: unitPrice, hasFailed: false, msg: "Success" };
      } catch (e) {
        console.log("collateralName---", collateralName);
        console.error(e);
        console.log("collateralName---", collateralName);
        return { price: DashboardDecimal18.ZERO, hasFailed: true };
      }
    } else {
      const priceFeedContract = assertContractExist(currencyPriceMap.get(collateralName));
      const price: BigNumber = await priceFeedContract?.fetchPrice?.();
      const unitPrice = convertBigNumberPriceToDisplayAcceptablePriceRedStoneClassical(
        price,
        CollateralDecimalService.getPrecisionByCollateralType(collateralName)
      );
      const dashboardPrice = DashboardDecimal18.from(unitPrice.toString());
      simpleContractsResultCachePerCollateral.get(collateralName)?.set(cacheKey, dashboardPrice);

      return { price: dashboardPrice ?? DashboardDecimal18.ZERO, hasFailed: false, msg: "Success" };
    }
  } catch (error: any) {
    logError(error);

    return {
      price: simpleContractsResultCachePerCollateral
        .get(collateralName)
        ?.get(cacheKey) as DashboardDecimal18,
      hasFailed: true,
      msg: error.message
    };
  }
}

export async function getMinimumCollateralRatio(
  collateral: SupportedCollaterals
): Promise<MinimumCollateralRatioResult> {
  const cacheKey = "MCR";

  try {
    const vaultManagerContract = assertContractExist(vaultManagerMap.get(collateral));
    const MCR: BigNumber = await retriableCallWithBackoff(() => vaultManagerContract.MCR());
    const mcrInWad = decimalify18(MCR);

    simpleContractsResultCachePerCollateral.get(collateral)?.set(cacheKey, mcrInWad);

    return { MCR: mcrInWad, hasFailed: false, msg: "Success" };
  } catch (error: any) {
    logError(error);

    return {
      MCR: simpleContractsResultCachePerCollateral
        .get(collateral)
        ?.get(cacheKey) as DashboardDecimal18,
      hasFailed: true,
      msg: error.message
    };
  }
}

export async function getCriticalCollateralRatio(
  collateral: SupportedCollaterals
): Promise<CriticalCollateralRatioResult> {
  const cacheKey = "CCR";

  try {
    const vaultManagerContract = assertContractExist(vaultManagerMap.get(collateral));
    const CCR: BigNumber = await retriableCallWithBackoff(() => vaultManagerContract.CCR());
    const ccrInWad = decimalify18(CCR);

    simpleContractsResultCachePerCollateral.get(collateral)?.set(cacheKey, ccrInWad);

    return { CCR: ccrInWad, hasFailed: false, msg: "Success" };
  } catch (error: any) {
    logError(error);

    return {
      CCR: simpleContractsResultCachePerCollateral
        .get(collateral)
        ?.get(cacheKey) as DashboardDecimal18,
      hasFailed: true,
      msg: error.message
    };
  }
}

export async function getGAILiquidationReserve(
  collateral: SupportedCollaterals
): Promise<GAILiquidationReserveResult> {
  const cacheKey = "GAILiquidationReserve";

  try {
    const vaultManagerContract = assertContractExist(vaultManagerMap.get(collateral));
    const GAILiquidationReserve: BigNumber = await retriableCallWithBackoff(() =>
      vaultManagerContract.GAI_GAS_COMPENSATION()
    );
    const gaiLiquidationReserveInWad = decimalify18(GAILiquidationReserve);

    simpleContractsResultCachePerCollateral
      .get(collateral)
      ?.set(cacheKey, gaiLiquidationReserveInWad);

    return {
      GAILiquidationReserve: gaiLiquidationReserveInWad,
      hasFailed: false,
      msg: "Success"
    };
  } catch (error: any) {
    logError(error);

    return {
      GAILiquidationReserve: simpleContractsResultCachePerCollateral
        .get(collateral)
        ?.get(cacheKey) as DashboardDecimal18,
      hasFailed: true,
      msg: error.message
    };
  }
}

export async function getSignerERC20TokenBalance(
  token: BaseTokenContractNames,
  signer: Signer
): Promise<SignerERC20TokenBalanceResult> {
  try {
    const tokenContract = assertContractExist(
      baseContractsMap.get(token) ?? collateralTokenMap.get(token as SupportedCollaterals)
    );
    const balance = await retriableCallWithBackoff(async () =>
      tokenContract.balanceOf(await signer.getAddress())
    );
    const decimalifiedResult = decimalify18(balance);

    simpleContractsResultCachePerCollateral.get(token)?.set("balance", decimalifiedResult);

    return {
      SignerERC20TokenBalance: decimalifiedResult,
      hasFailed: false,
      msg: "Success"
    };
  } catch (error: any) {
    logError(error);

    return {
      SignerERC20TokenBalance: simpleContractsResultCachePerCollateral
        .get(token)
        ?.get("balance") as DashboardDecimal18,
      hasFailed: true,
      msg: error.message
    };
  }
}

export async function getAccountBalancePerCollateral(
  address: string,
  collateralTypeToSkip: string
): Promise<AccountBalanceMap> {
  const accountBalancePerCollateral: AccountBalanceMap = new Map<
    SupportedCollaterals,
    DashboardDecimal18
  >();
  const promises: Promise<any>[] = [];

  for (const [collateralName, contract] of collateralTokenMap) {
    if (collateralTypeToSkip !== collateralName) {
      promises.push(
        (async () => {
          try {
            const balance: BigNumber = await retriableCallWithBackoff(() =>
              contract.balanceOf(address)
            );
            const collateralPrecisionDigits =
              CollateralDecimalService.getPrecisionByCollateralType(collateralName);
            const decimalifiedResult = decimalify18(balance);
            const scaledDecimalResult = scaleUnitOfPrecisionToWad(
              decimalifiedResult,
              collateralPrecisionDigits
            );
            console.log(
              `collateralName = ${collateralName}, balance = ${balance}, collateralPrecisionDigits = ${collateralPrecisionDigits} scaledDecimalResult = ${scaledDecimalResult}, decimalifiedResult = ${decimalifiedResult}`
            );
            accountBalancePerCollateral.set(collateralName, scaledDecimalResult);
          } catch (error) {
            logError(error);
          }
        })()
      );
    }
  }

  await waitForAllPromisesOrLogError(promises);

  return accountBalancePerCollateral;
}

export async function getCollateralPriceMap({
  isTestnet = false
}: {
  isTestnet?: boolean;
}): Promise<CollateralPriceMap> {
  const collateralPrices: CollateralPriceMap = new Map<SupportedCollaterals, DashboardDecimal18>();
  const promises: Promise<any>[] = [];

  console.log("currencyPriceMap = ", currencyPriceMap);

  for (const [collateralName] of currencyPriceMap) {
    promises.push(
      (async () => {
        const { price } = await getCachedCollateralPrice({
          collateralName,
          isTestnet
        });

        collateralPrices.set(collateralName, price ?? DashboardDecimal18.ZERO);
      })()
    );
  }

  await waitForAllPromisesOrLogError(promises);

  return collateralPrices;
}

export async function getReadonlyVaultMap(): Promise<ReadonlyVaultMap> {
  const readonlyVaultMap: ReadonlyVaultMap = new Map<SupportedCollaterals, ReadonlyVault>();
  const promises: Promise<any>[] = [];
  const cacheKey = "readOnlyVault";

  for (const [collateralName] of vaultManagerMap) {
    promises.push(
      (async () => {
        try {
          const activePoolContract = assertContractExist(activePoolMap.get(collateralName));
          const defaultPoolContract = assertContractExist(defaultPoolMap.get(collateralName));
          const [
            activePoolCollateral,
            activePoolDebt,
            defaultPoolCollateral,
            defaultPoolDebt,
            MCR,
            CCR,
            GAILiquidationReserve
          ] = await Promise.all([
            retriableCallWithBackoff((): Promise<BigNumber> => activePoolContract.getCOL()),
            retriableCallWithBackoff((): Promise<BigNumber> => activePoolContract.getGAIDebt()),
            retriableCallWithBackoff((): Promise<BigNumber> => defaultPoolContract.getCOL()),
            retriableCallWithBackoff((): Promise<BigNumber> => defaultPoolContract.getGAIDebt()),
            getMinimumCollateralRatio(collateralName),
            getCriticalCollateralRatio(collateralName),
            getGAILiquidationReserve(collateralName)
          ]);

          const collateralPrecisionDigits =
            CollateralDecimalService.getPrecisionByCollateralType(collateralName);
          const totalCollateral = activePoolCollateral.add(defaultPoolCollateral);
          const totalCollateralInCollateralUnit = decimalify18(totalCollateral);
          const totalCollateralInWad = scaleUnitOfPrecisionToWad(
            totalCollateralInCollateralUnit,
            collateralPrecisionDigits
          );
          const debt = activePoolDebt.add(defaultPoolDebt);
          const debtInWad = decimalify18(debt);

          const readOnlyVault: ReadonlyVault = new ReadonlyVault(
            collateralName,
            totalCollateralInWad,
            debtInWad,
            MCR.MCR,
            CCR.CCR,
            GAILiquidationReserve.GAILiquidationReserve
          );

          readonlyVaultMap.set(collateralName, readOnlyVault);
          simpleContractsResultCachePerCollateral.get(collateralName)?.set(cacheKey, readOnlyVault);
        } catch (error) {
          logError(error);

          const cachedResult = simpleContractsResultCachePerCollateral
            .get(collateralName)
            ?.get(cacheKey) as ReadonlyVault;
          const emptyReadOnlyVault: ReadonlyVault = new ReadonlyVault(collateralName);

          readonlyVaultMap.set(collateralName, cachedResult ?? emptyReadOnlyVault);
        }
      })()
    );
  }

  await waitForAllPromisesOrLogError(promises);

  return readonlyVaultMap;
}

export async function getUserReadonlyVaultMap(signerAddress: string): Promise<ReadonlyUserVaultMap> {
  const readonlyUserVaultMap: ReadonlyUserVaultMap = new Map<
    SupportedCollaterals,
    UserReadonlyVault
  >();
  const promises: Promise<any>[] = [];
  const cacheKey = "readonlyUserVault";

  for (const [collateralName, vaultManagerContract] of vaultManagerMap) {
    promises.push(
      (async () => {
        try {
          const [status, collateral, debt] = await Promise.all([
            retriableCallWithBackoff(
              (): Promise<UserReadonlyVaultStatus> =>
                vaultManagerContract.getVaultStatus(signerAddress)
            ),
            retriableCallWithBackoff(
              (): Promise<BigNumber> => vaultManagerContract.getVaultColl(signerAddress)
            ),
            retriableCallWithBackoff(
              (): Promise<BigNumber> => vaultManagerContract.getVaultDebt(signerAddress)
            )
          ]);

          const collateralPrecisionDigits =
            CollateralDecimalService.getPrecisionByCollateralType(collateralName);
          const collateralInCollateralUnit = decimalify18(collateral);
          const collateralInWad = scaleUnitOfPrecisionToWad(
            collateralInCollateralUnit,
            collateralPrecisionDigits
          );
          const debtInWad = decimalify18(debt);

          const readonlyUserVault: UserReadonlyVault = new UserReadonlyVault(
            collateralName,
            signerAddress,
            status,
            collateralInWad,
            debtInWad
          );

          readonlyUserVaultMap.set(collateralName, readonlyUserVault);
          simpleContractsResultCachePerCollateral
            .get(collateralName)
            ?.set(cacheKey, readonlyUserVault);
        } catch (error) {
          logError(error);

          const cachedResult = simpleContractsResultCachePerCollateral
            .get(collateralName)
            ?.get(cacheKey) as UserReadonlyVault;
          const readonlyUserVault: UserReadonlyVault = new UserReadonlyVault(
            collateralName,
            signerAddress,
            "nonExistent"
          );
          readonlyUserVaultMap.set(collateralName, cachedResult ?? readonlyUserVault);
        }
      })()
    );
  }

  await waitForAllPromisesOrLogError(promises);

  return readonlyUserVaultMap;
}

export async function getTotalGAIIssuanceByGAIToken(): Promise<DashboardDecimal18> {
  const GAITokenContract = assertContractExist(baseContractsMap.get("gaiToken"));
  const cacheKey = "totalSupply";

  try {
    const totalSupply: BigNumber = await retriableCallWithBackoff(() =>
      GAITokenContract.totalSupply()
    );
    const totalSupplyInWad = decimalify18(totalSupply);

    simpleBaseContractsResultCache.set(cacheKey, totalSupplyInWad);

    return totalSupplyInWad;
  } catch (error) {
    logError(error);

    return (
      (simpleBaseContractsResultCache.get(cacheKey) as DashboardDecimal18) ?? DashboardDecimal18.ZERO
    );
  }
}

export async function getGAIInStabilityPoolForEachCollateral(): Promise<GaiInStabilityPoolMap> {
  const promises: Promise<any>[] = [];
  const gaiInStabilityPoolPerCollateral: GaiInStabilityPoolMap = new Map();
  const cacheKey = "gaiAmountInPool";

  for (const [collateralName] of vaultManagerMap) {
    promises.push(
      (async () => {
        try {
          const stabilityPoolContract = assertContractExist(stabilityPoolMap.get(collateralName));
          const gaiAmountInPool: BigNumber = await retriableCallWithBackoff(() =>
            stabilityPoolContract.getTotalGAIDeposits()
          );
          const poolGaiAmountInWad = decimalify18(gaiAmountInPool);

          gaiInStabilityPoolPerCollateral.set(collateralName, poolGaiAmountInWad);

          simpleContractsResultCachePerCollateral
            .get(collateralName)
            ?.set(cacheKey, poolGaiAmountInWad);
        } catch (error) {
          logError(error);

          const cachedResult = simpleContractsResultCachePerCollateral
            .get(collateralName)
            ?.get(cacheKey) as DashboardDecimal18;
          gaiInStabilityPoolPerCollateral.set(
            collateralName,
            cachedResult ?? DashboardDecimal18.ZERO
          );
        }
      })()
    );
  }

  await waitForAllPromisesOrLogError(promises);

  return gaiInStabilityPoolPerCollateral;
}

export async function getTotalGAIInAllStabilityPools(): Promise<DashboardDecimal18> {
  let gaiAmount: DashboardDecimal18 = DashboardDecimal18.ZERO;
  const gaiInStabilityPoolPerCollateral = await getGAIInStabilityPoolForEachCollateral();

  for (const [, amount] of gaiInStabilityPoolPerCollateral) {
    gaiAmount = gaiAmount.add(amount);
  }

  return gaiAmount;
}

export async function getTotalUnweightedGOKStakedValueForEachCollateral(
  gokPrice: DashboardDecimal18
): Promise<TotalUnweightedGOKStakedValuePerCollateral> {
  const totalGOKStakedValuePerCollateral: TotalUnweightedGOKStakedValuePerCollateral = new Map();
  const promises: Promise<any>[] = [];
  const cacheKey = "totalGOKStakedValue";

  for (const [collateralName] of vaultManagerMap) {
    promises.push(
      (async () => {
        try {
          const gokStakingContract = assertContractExist(gokStakingMap.get(collateralName));
          // The received raw totalUnweightedGOKStaked is denoted in Wei.
          // Use Decimal18.fromBigNumberString to reconstruct in Ether repr.
          const totalUnweightedGOKStaked = DashboardDecimal18.fromBigNumberString(
            (
              (await retriableCallWithBackoff(() =>
                gokStakingContract.totalUnweightedGOKStaked()
              )) as BigNumber
            ).toString()
          );
          const totalGOKStakedValue = totalUnweightedGOKStaked.mul(gokPrice);

          totalGOKStakedValuePerCollateral.set(collateralName, totalGOKStakedValue);
          simpleContractsResultCachePerCollateral
            .get(collateralName)
            ?.set(cacheKey, totalGOKStakedValue);
        } catch (error) {
          logError(error);

          const cachedResult = simpleContractsResultCachePerCollateral
            .get(collateralName)
            ?.get(cacheKey) as DashboardDecimal18;
          totalGOKStakedValuePerCollateral.set(
            collateralName,
            cachedResult ?? DashboardDecimal18.ZERO
          );
        }
      })()
    );
  }

  await waitForAllPromisesOrLogError(promises);

  return totalGOKStakedValuePerCollateral;
}

export async function getTotalGOKStakedValue(
  gokPrice: DashboardDecimal18
): Promise<DashboardDecimal18> {
  const totalGOKStakedValuePerCollateral = await getTotalUnweightedGOKStakedValueForEachCollateral(
    gokPrice
  );

  let totalUnweightedGOKStakedTotal: DashboardDecimal18 = DashboardDecimal18.ZERO;

  for (const [, value] of totalGOKStakedValuePerCollateral) {
    totalUnweightedGOKStakedTotal = totalUnweightedGOKStakedTotal.add(value);
  }

  return totalUnweightedGOKStakedTotal;
}

export function getStabilityPoolApr({
  gaiInStabilityPool,
  remainingStabilityPoolGOKReward,
  scaledGOKPriceInEthers,
  rewardWeight
}: {
  gaiInStabilityPool: DashboardDecimal18;
  remainingStabilityPoolGOKReward: DashboardDecimal18;
  scaledGOKPriceInEthers: DashboardDecimal18;
  rewardWeight: number;
}): DashboardDecimal18 {
  const hasZeroValue = remainingStabilityPoolGOKReward.isZero || gaiInStabilityPool.isZero;
  let stabilityPoolApr = DashboardDecimal18.ZERO;

  if (!hasZeroValue && !scaledGOKPriceInEthers.isZero) {
    const distributedGOKInOneYear = remainingStabilityPoolGOKReward.mul(
      DISTRIBUTION_RATIO_SCHEDULED_PER_YEAR
    );
    const distributedGOKOneYearInUSD = distributedGOKInOneYear.mul(scaledGOKPriceInEthers);
    stabilityPoolApr = distributedGOKOneYearInUSD.mulDiv(rewardWeight, gaiInStabilityPool);
  }

  return stabilityPoolApr;
}

export async function getRemainingStabilityPoolGOKReward(
  communityIssuanceContract: Contract
): Promise<DashboardDecimal18> {
  let issuanceCapInWad: DashboardDecimal18;
  let totalGOKIssuedInWad: DashboardDecimal18;
  const issuanceCapCacheKey = "issuanceCap";
  const totalGOKIssuedCacheKey = "totalGOKIssued";

  try {
    const gokSupplyCapResult: BigNumber = await retriableCallWithBackoff(() =>
      communityIssuanceContract.GOKSupplyCap()
    );
    issuanceCapInWad = decimalify18(gokSupplyCapResult);

    simpleBaseContractsResultCache.set(issuanceCapCacheKey, issuanceCapInWad);
  } catch (error) {
    logError(error);

    issuanceCapInWad = simpleBaseContractsResultCache.get(issuanceCapCacheKey) as DashboardDecimal18;
  }

  try {
    const totalGOKIssuedResult: BigNumber = await retriableCallWithBackoff(() =>
      communityIssuanceContract.totalGOKIssued()
    );
    totalGOKIssuedInWad = decimalify18(totalGOKIssuedResult);

    simpleBaseContractsResultCache.set(totalGOKIssuedCacheKey, totalGOKIssuedInWad);
  } catch (error) {
    logError(error);

    totalGOKIssuedInWad = simpleBaseContractsResultCache.get(
      totalGOKIssuedCacheKey
    ) as DashboardDecimal18;
  }

  return issuanceCapInWad.sub(totalGOKIssuedInWad);
}

export async function getStabilityPoolPerCollateral(
  gokPrice: DashboardDecimal18
): Promise<Map<SupportedCollaterals, DashboardDecimal18>> {
  const stabilityPoolAprMap = new Map<SupportedCollaterals, DashboardDecimal18>();
  const promises: Promise<any>[] = [];
  const cacheKey = "stabilityPoolApr";

  for (const [collateralName] of vaultManagerMap) {
    promises.push(
      (async () => {
        try {
          const stabilityPoolContract = assertContractExist(stabilityPoolMap.get(collateralName));
          const communityIssuanceContract = assertContractExist(
            baseContractsMap.get("communityIssuance")
          );
          const gaiInStabilityPool: BigNumber = await retriableCallWithBackoff(() =>
            stabilityPoolContract.getTotalGAIDeposits()
          );
          const remainingStabilityPoolGOKReward = await getRemainingStabilityPoolGOKReward(
            communityIssuanceContract
          );

          const stabilityPoolApr = getStabilityPoolApr({
            gaiInStabilityPool: decimalify18(gaiInStabilityPool),
            remainingStabilityPoolGOKReward,
            scaledGOKPriceInEthers: gokPrice,
            rewardWeight: REWARD_WEIGHT_PER_COLLATERAL.get(collateralName) ?? 1
          });

          stabilityPoolAprMap.set(collateralName, stabilityPoolApr);
          simpleContractsResultCachePerCollateral
            .get(collateralName)
            ?.set(cacheKey, stabilityPoolApr);
        } catch (error) {
          logError(error);

          const cachedResult = simpleContractsResultCachePerCollateral
            .get(collateralName)
            ?.get(cacheKey) as DashboardDecimal18;

          stabilityPoolAprMap.set(collateralName, cachedResult ?? DashboardDecimal18.ZERO);
        }
      })()
    );
  }

  await waitForAllPromisesOrLogError(promises);

  return stabilityPoolAprMap;
}

export function approveCeUSDC({
  amount,
  signer,
  overrides
}: {
  amount: DashboardDecimal18;
  signer: Signer;
  overrides: Overrides;
}) {
  // TODO support public sale
  // @ts-ignore
  const ceUsdcContract = assertContractExist(collateralTokenMap.get("ceUSDC")).connect(signer);
  const crowdsale = assertContractExist(publicSaleContractsMap.get("crowdsale"));

  return ceUsdcContract.approve(crowdsale.address, amount.hex, overrides);
}

export function buyGOKTokens({
  account,
  amount,
  signer,
  overrides
}: {
  account: string;
  amount: DashboardDecimal18;
  signer: Signer;
  overrides: Overrides;
}) {
  const crowdsale = assertContractExist(publicSaleContractsMap.get("crowdsale")).connect(signer);

  return crowdsale.buyTokens(account, amount.hex, overrides);
}
