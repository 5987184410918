import {
  CollateralGainTransferDetails,
  Decimalish,
  LiquidationDetails,
  RedemptionDetails,
  SendableAstridDao,
  StabilityDepositChangeDetails,
  StabilityPoolGainsWithdrawalDetails,
  BaseCollateralDepositChangeDetails,
  VaultAdjustmentDetails,
  VaultAdjustmentParams,
  VaultClosureDetails,
  VaultCreationDetails,
  VaultCreationParams,
  Vault
} from "@astrid-dao/lib-base";

import {
  EthersTransactionOverrides,
  EthersTransactionReceipt,
  EthersTransactionResponse
} from "./types";

import {
  BorrowingOperationOptionalParams,
  PopulatableEthersAstridDao as PopulatableEthersAstridDao,
  PopulatedEthersAstridDaoTransaction,
  SentEthersAstridDaoTransaction as SentEthersAstridDaoTransaction
} from "./PopulatableEthersAstridDao";
import { BigNumber } from "ethers";
import { HintHelpers, SortedVaults } from "../types";

const sendTransaction = <T>(tx: PopulatedEthersAstridDaoTransaction<T>) => tx.send();

/**
 * Ethers-based implementation of {@link @astrid-dao/lib-base#SendableAstridDao}.
 *
 * @public
 */
export class SendableEthersAstridDao
  implements SendableAstridDao<EthersTransactionReceipt, EthersTransactionResponse>
{
  private _populate: PopulatableEthersAstridDao;

  constructor(populatable: PopulatableEthersAstridDao) {
    this._populate = populatable;
  }

  async findHints(
    vault: Vault,
    ownAddress?: string,
    externalContracts?: { sortedVaults: SortedVaults; hintHelpers: HintHelpers }
  ): Promise<[string, string]> {
    return this._populate.findHints(vault, ownAddress, externalContracts);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.openVault} */
  async openVault(
    params: VaultCreationParams<Decimalish, Decimalish>,
    maxBorrowingRateOrOptionalParams?: Decimalish | BorrowingOperationOptionalParams,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<VaultCreationDetails>> {
    return this._populate
      .openVault(params, maxBorrowingRateOrOptionalParams, overrides)
      .then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.closeVault} */
  closeVault(
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<VaultClosureDetails>> {
    return this._populate.closeVault(overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.adjustVault} */
  adjustVault(
    params: VaultAdjustmentParams<Decimalish>,
    maxBorrowingRateOrOptionalParams?: Decimalish | BorrowingOperationOptionalParams,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<VaultAdjustmentDetails>> {
    return this._populate
      .adjustVault(params, maxBorrowingRateOrOptionalParams, overrides)
      .then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.depositBaseCollateral} */
  depositBaseCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<BaseCollateralDepositChangeDetails>> {
    return this._populate.depositBaseCollateral(amount, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.withdrawToBaseCollateral} */
  withdrawToBaseCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<BaseCollateralDepositChangeDetails>> {
    return this._populate.withdrawToBaseCollateral(amount, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.depositCollateral} */
  depositCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<VaultAdjustmentDetails>> {
    return this._populate.depositCollateral(amount, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.withdrawCollateral} */
  withdrawCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<VaultAdjustmentDetails>> {
    return this._populate.withdrawCollateral(amount, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.borrowGai} */
  borrowGai(
    amount: Decimalish,
    maxBorrowingRate?: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<VaultAdjustmentDetails>> {
    return this._populate.borrowGai(amount, maxBorrowingRate, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.repayGai} */
  repayGai(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<VaultAdjustmentDetails>> {
    return this._populate.repayGai(amount, overrides).then(sendTransaction);
  }

  /** @internal */
  setPrice(
    price: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<void>> {
    return this._populate.setPrice(price, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.liquidate} */
  liquidate(
    address: string | string[],
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<LiquidationDetails>> {
    return this._populate.liquidate(address, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.liquidateUpTo} */
  liquidateUpTo(
    maximumNumberOfVaultsToLiquidate: number,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<LiquidationDetails>> {
    return this._populate
      .liquidateUpTo(maximumNumberOfVaultsToLiquidate, overrides)
      .then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.depositGaiInStabilityPool} */
  depositGaiInStabilityPool(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<StabilityDepositChangeDetails>> {
    return this._populate.depositGaiInStabilityPool(amount, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.withdrawGaiFromStabilityPool} */
  withdrawGaiFromStabilityPool(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<StabilityDepositChangeDetails>> {
    return this._populate.withdrawGaiFromStabilityPool(amount, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.withdrawGainsFromStabilityPool} */
  withdrawGainsFromStabilityPool(
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<StabilityPoolGainsWithdrawalDetails>> {
    return this._populate.withdrawGainsFromStabilityPool(overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.transferCollateralGainToVault} */
  transferCollateralGainToVault(
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<CollateralGainTransferDetails>> {
    return this._populate.transferCollateralGainToVault(overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.sendGai} */
  sendGai(
    toAddress: string,
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<void>> {
    return this._populate.sendGai(toAddress, amount, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.sendGOK} */
  sendGOK(
    toAddress: string,
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<void>> {
    return this._populate.sendGOK(toAddress, amount, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.redeemGai} */
  redeemGai(
    amount: Decimalish,
    maxRedemptionRate?: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<RedemptionDetails>> {
    return this._populate.redeemGai(amount, maxRedemptionRate, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.claimCollateralSurplus} */
  claimCollateralSurplus(
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<void>> {
    return this._populate.claimCollateralSurplus(overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.stakeGOK} */
  stakeGOK(
    amount: Decimalish,
    lockedUntil: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<void>> {
    return this._populate.stakeGOK(amount, lockedUntil, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.unstakeGOK} */
  unstakeGOK(
    id: BigNumber,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<void>> {
    return this._populate.unstakeGOK(id, overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.withdrawGainsFromStaking} */
  withdrawGainsFromStaking(
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<void>> {
    return this._populate.withdrawGainsFromStaking(overrides).then(sendTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#SendableAstridDao.approveWrappedTokens} */
  approveWrappedTokens(
    allowance?: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<SentEthersAstridDaoTransaction<void>> {
    return this._populate.approveWrappedTokens(allowance, overrides).then(sendTransaction);
  }
}
