import { INITIAL_MS_BEFORE_NEXT_RETRY, MAX_RETRY_ATTEMPT } from "../config";
import { sleep } from "../rpc-contract-service/utils/utils";

/**
 * Wait for the given milliseconds
 * @param {number} milliseconds The given time to wait
 * @returns {Promise} A fulfilled promise after the given time has passed
 */
function waitFor(milliseconds: number): Promise<unknown> {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

/**
 * Execute a promise and retry with exponential backoff
 * based on the maximum retry attempts it can perform
 * @param {Promise} promise promise to be executed
 * @param {function} onRetry callback executed on every retry
 * @param {number} maxRetryAttempt The maximum number of retries to be attempted
 * @returns {Promise} The result of the given promise passed in
 */
export function retriableCallWithBackoff<T>(
  promise: () => Promise<T>,
  onRetry?: Function,
  maxRetryAttempt = MAX_RETRY_ATTEMPT,
  delayMsBeforeNextRetry = INITIAL_MS_BEFORE_NEXT_RETRY
): Promise<T> {
  // Notice that we declare an inner function here
  // so we can encapsulate the retries and don't expose
  // it to the caller. This is also a recursive function
  async function retryWithBackoff(retries: number): Promise<T> {
    try {
      // Make sure we don't wait on the first attempt
      if (retries > 0) {
        // Here is where the magic happens.
        // on every retry, we exponentially increase the time to wait.
        // Here is how it looks for a `maxRetries` = 4
        // (2 ** 1) * 100 = 200 ms
        // (2 ** 2) * 100 = 400 ms
        // (2 ** 3) * 100 = 800 ms
        const timeToWait = 2 ** retries * delayMsBeforeNextRetry;
        console.log(`waiting for ${timeToWait}ms...`);
        await waitFor(timeToWait);
      }
      return await promise();
    } catch (e) {
      // only retry if we didn't reach the limit
      // otherwise, let the caller handle the error
      if (retries < maxRetryAttempt) {
        onRetry && onRetry();
        return retryWithBackoff(retries + 1);
      } else {
        console.warn("Max retries reached. Bubbling the error up");
        throw e;
      }
    }
  }

  return retryWithBackoff(0);
}

// Retry a callback multiple times before failing.
export async function retriableCall<T>(
  cb: () => Promise<T>,
  maxRetryAttempt = MAX_RETRY_ATTEMPT,
  delayMsBeforeNextRetry = INITIAL_MS_BEFORE_NEXT_RETRY
): Promise<T> {
  let error: any;
  for (let i = 0; i < maxRetryAttempt; i++) {
    try {
      return await cb();
    } catch (e: any) {
      error = e;
      console.warn(`Failed call at attempt ${i + 1}. Retrying...`);
      await sleep(delayMsBeforeNextRetry);
    }
  }
  throw error;
}
