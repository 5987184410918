import { SupportedCollaterals } from "@astrid-dao/lib-base";

import ceUsdcLogo from "./assets/images/currencies/ce-usdc.svg";
import ceUsdtLogo from "./assets/images/currencies/ce-usdt.svg";
import gaiLightLogo from "./assets/images/currencies/gai-light.svg";
import mantaLogo from "./assets/images/currencies/manta.png";
import gokLogo from "./assets/images/currencies/gok.svg";
import wethLogo from "./assets/images/currencies/weth.svg";
import tiaLogo from "./assets/images/currencies/tia.svg";
import stoneLogo from "./assets/images/currencies/StakeStone.png";
import usdmLogo from "./assets/images/currencies/USDM.png";

export type AstridDaoTokens = "GOK" | "GAI" | "MANTA";
export type CurrencyIcons = SupportedCollaterals | AstridDaoTokens;

export function getCurrencyIcon(currency: CurrencyIcons, theme = "light"): string {
  // return currency icon from iconCurrencyMap or iconCurrencyLightMap base on theme
  return theme === "light" ? iconCurrencyLightMap[currency] : iconCurrencyMap[currency];
}

export const iconCurrencyMap: Record<CurrencyIcons, string> = {
  TIA: tiaLogo,
  GOK: gokLogo,
  GAI: gaiLightLogo,
  MANTA: mantaLogo,
  WETH: wethLogo,
  USDC: ceUsdcLogo,
  USDT: ceUsdtLogo,
  STONE: stoneLogo,
  wUSDM: usdmLogo
};

export const iconCurrencyLightMap: Record<CurrencyIcons, string> = {
  TIA: tiaLogo,
  GOK: gokLogo,
  GAI: gaiLightLogo,
  MANTA: mantaLogo,
  WETH: wethLogo,
  USDC: ceUsdcLogo,
  USDT: ceUsdtLogo,
  STONE: stoneLogo,
  wUSDM: usdmLogo
};
