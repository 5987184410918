import { Decimal18 } from "./Decimal18";
import { Fees } from "./Fees";
import { GOKStake, LockedStake } from "./GOKStake";
import { StabilityDeposit } from "./StabilityDeposit";
import { Vault, VaultWithPendingRedistribution, UserVault } from "./Vault";
import { LockedStakesParams, ReadableAstridDao, VaultListingParams } from "./ReadableAstridDao";
import { BigNumber } from "@ethersproject/bignumber";
import { CollateralDecimal } from "./CollateralDecimal";

/** @internal */
export type _ReadableAstridDaoWithExtraParamsBase<T extends unknown[]> = {
  [P in keyof ReadableAstridDao]: ReadableAstridDao[P] extends (...params: infer A) => infer R
    ? (...params: [...originalParams: A, ...extraParams: T]) => R
    : never;
};

/** @internal */
export type _AstridDaoReadCacheBase<T extends unknown[]> = {
  [P in keyof ReadableAstridDao]: ReadableAstridDao[P] extends (...args: infer A) => Promise<infer R>
    ? (...params: [...originalParams: A, ...extraParams: T]) => R | undefined
    : never;
};

// Overloads get lost in the mapping, so we need to define them again...

/** @internal */
export interface _ReadableAstridDaoWithExtraParams<T extends unknown[]>
  extends _ReadableAstridDaoWithExtraParamsBase<T> {
  getVaults(
    params: VaultListingParams & { beforeRedistribution: true },
    ...extraParams: T
  ): Promise<VaultWithPendingRedistribution[]>;

  getVaults(params: VaultListingParams, ...extraParams: T): Promise<UserVault[]>;
}

/** @internal */
export interface _AstridDaoReadCache<T extends unknown[]> extends _AstridDaoReadCacheBase<T> {
  getVaults(
    params: VaultListingParams & { beforeRedistribution: true },
    ...extraParams: T
  ): VaultWithPendingRedistribution[] | undefined;

  getVaults(params: VaultListingParams, ...extraParams: T): UserVault[] | undefined;
}

/** @internal */
export class _CachedReadableAstridDao<T extends unknown[]>
  implements _ReadableAstridDaoWithExtraParams<T>
{
  private _readable: _ReadableAstridDaoWithExtraParams<T>;
  private _cache: _AstridDaoReadCache<T>;

  constructor(readable: _ReadableAstridDaoWithExtraParams<T>, cache: _AstridDaoReadCache<T>) {
    this._readable = readable;
    this._cache = cache;
  }

  async getTotalRedistributed(...extraParams: T): Promise<Vault> {
    return (
      this._cache.getTotalRedistributed(...extraParams) ??
      this._readable.getTotalRedistributed(...extraParams)
    );
  }

  async getVaultBeforeRedistribution(
    address?: string,
    ...extraParams: T
  ): Promise<VaultWithPendingRedistribution> {
    return (
      this._cache.getVaultBeforeRedistribution(address, ...extraParams) ??
      this._readable.getVaultBeforeRedistribution(address, ...extraParams)
    );
  }

  async getVault(address?: string, ...extraParams: T): Promise<UserVault> {
    const [vaultBeforeRedistribution, totalRedistributed] = await Promise.all([
      this.getVaultBeforeRedistribution(address, ...extraParams),
      this.getTotalRedistributed(...extraParams)
    ]);

    return vaultBeforeRedistribution.applyRedistribution(totalRedistributed);
  }

  async getNumberOfVaults(...extraParams: T): Promise<number> {
    return (
      this._cache.getNumberOfVaults(...extraParams) ??
      this._readable.getNumberOfVaults(...extraParams)
    );
  }

  async getPrice(...extraParams: T): Promise<Decimal18> {
    return this._cache.getPrice(...extraParams) ?? this._readable.getPrice(...extraParams);
  }

  async getTotal(...extraParams: T): Promise<Vault> {
    return this._cache.getTotal(...extraParams) ?? this._readable.getTotal(...extraParams);
  }

  async getStabilityDeposit(address?: string, ...extraParams: T): Promise<StabilityDeposit> {
    return (
      this._cache.getStabilityDeposit(address, ...extraParams) ??
      this._readable.getStabilityDeposit(address, ...extraParams)
    );
  }

  async getRemainingStabilityPoolGOKReward(...extraParams: T): Promise<Decimal18> {
    return (
      this._cache.getRemainingStabilityPoolGOKReward(...extraParams) ??
      this._readable.getRemainingStabilityPoolGOKReward(...extraParams)
    );
  }

  async getGaiInStabilityPool(...extraParams: T): Promise<Decimal18> {
    return (
      this._cache.getGaiInStabilityPool(...extraParams) ??
      this._readable.getGaiInStabilityPool(...extraParams)
    );
  }

  async getGaiBalance(address?: string, ...extraParams: T): Promise<Decimal18> {
    return (
      this._cache.getGaiBalance(address, ...extraParams) ??
      this._readable.getGaiBalance(address, ...extraParams)
    );
  }

  async getXcGaiBalance(address?: string, ...extraParams: T): Promise<Decimal18> {
    return (
      this._cache.getXcGaiBalance(address, ...extraParams) ??
      this._readable.getXcGaiBalance(address, ...extraParams)
    );
  }

  async getGOKBalance(address?: string, ...extraParams: T): Promise<Decimal18> {
    return (
      this._cache.getGOKBalance(address, ...extraParams) ??
      this._readable.getGOKBalance(address, ...extraParams)
    );
  }

  async getWrappedTokenBalance(address?: string, ...extraParams: T): Promise<CollateralDecimal> {
    return (
      this._cache.getWrappedTokenBalance(address, ...extraParams) ??
      this._readable.getWrappedTokenBalance(address, ...extraParams)
    );
  }

  async getWrappedTokenAllowance(address?: string, ...extraParams: T): Promise<CollateralDecimal> {
    return (
      this._cache.getWrappedTokenAllowance(address, ...extraParams) ??
      this._readable.getWrappedTokenAllowance(address, ...extraParams)
    );
  }

  async getXcGaiAllowance(address?: string, ...extraParams: T): Promise<CollateralDecimal> {
    return (
      this._cache.getXcGaiAllowance(address, ...extraParams) ??
      this._readable.getXcGaiAllowance(address, ...extraParams)
    );
  }

  async getGaiAllowance(address?: string, ...extraParams: T): Promise<CollateralDecimal> {
    return (
      this._cache.getGaiAllowance(address, ...extraParams) ??
      this._readable.getGaiAllowance(address, ...extraParams)
    );
  }

  async getCollateralSurplusBalance(
    address?: string,
    ...extraParams: T
  ): Promise<CollateralDecimal> {
    return (
      this._cache.getCollateralSurplusBalance(address, ...extraParams) ??
      this._readable.getCollateralSurplusBalance(address, ...extraParams)
    );
  }

  getVaults(
    params: VaultListingParams & { beforeRedistribution: true },
    ...extraParams: T
  ): Promise<VaultWithPendingRedistribution[]>;

  getVaults(params: VaultListingParams, ...extraParams: T): Promise<UserVault[]>;

  async getVaults(params: VaultListingParams, ...extraParams: T): Promise<UserVault[]> {
    const { beforeRedistribution, ...restOfParams } = params;

    const [totalRedistributed, vaults] = await Promise.all([
      beforeRedistribution ? undefined : this.getTotalRedistributed(...extraParams),
      this._cache.getVaults({ beforeRedistribution: true, ...restOfParams }, ...extraParams) ??
        this._readable.getVaults({ beforeRedistribution: true, ...restOfParams }, ...extraParams)
    ]);

    if (totalRedistributed) {
      return vaults.map(vault => vault.applyRedistribution(totalRedistributed));
    } else {
      return vaults;
    }
  }

  async getFees(...extraParams: T): Promise<Fees> {
    return this._cache.getFees(...extraParams) ?? this._readable.getFees(...extraParams);
  }

  async getGOKStake(address?: string, ...extraParams: T): Promise<GOKStake> {
    return (
      this._cache.getGOKStake(address, ...extraParams) ??
      this._readable.getGOKStake(address, ...extraParams)
    );
  }

  async getTotalWeightedStakedGOK(...extraParams: T): Promise<Decimal18> {
    return (
      this._cache.getTotalWeightedStakedGOK(...extraParams) ??
      this._readable.getTotalWeightedStakedGOK(...extraParams)
    );
  }

  async getTotalUnweightedStakedGOK(...extraParams: T): Promise<Decimal18> {
    return (
      this._cache.getTotalUnweightedStakedGOK(...extraParams) ??
      this._readable.getTotalUnweightedStakedGOK(...extraParams)
    );
  }

  async getFirstActiveLockedStakeID(address?: string, ...extraParams: T): Promise<BigNumber> {
    return (
      this._cache.getFirstActiveLockedStakeID(address, ...extraParams) ??
      this._readable.getFirstActiveLockedStakeID(address, ...extraParams)
    );
  }

  async getLockedStakes(params: LockedStakesParams, ...extraParams: T): Promise<LockedStake[]> {
    return (
      this._cache.getLockedStakes(params, ...extraParams) ??
      this._readable.getLockedStakes(params, ...extraParams)
    );
  }

  async getTotalLockedStakesCount(address?: string, ...extraParams: T): Promise<number> {
    return (
      this._cache.getTotalLockedStakesCount(address, ...extraParams) ??
      this._readable.getTotalLockedStakesCount(address, ...extraParams)
    );
  }
}
