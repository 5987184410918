import { useEffect, useState } from "react";

import useResponsive, { DisplaySize } from "./useResponsive";

export const useIsMobile = () => {
  const responsive = useResponsive();
  const [isMobile, setIsMobile] = useState<boolean>(responsive < DisplaySize.ComputerS);

  useEffect(() => {
    setIsMobile(responsive < DisplaySize.ComputerS);
  }, [responsive]);

  return isMobile;
};
