import { CHAIN_ID } from "@astrid-dao/lib-base";
import { BigNumber } from "ethers";

export type AddToMetamaskParams = {
  tokenSymbol: string;
  tokenAddress: string;
  tokenImage?: string;
  tokenPrecision?: number;
};

/**
 * Add an ERC20 token to metamask assets menu.
 *
 * @param tokenSymbol Symbol for token (e.g. "GAI")
 * @param tokenAddress Address to the ERC20 token contract.
 * @param tokenImage Optional image URL of the token to display
 * @param tokenPrecision Optional decimal precision value for Collateral tokens
 */
export async function addERC20TokenToMetamask({
  tokenSymbol,
  tokenAddress,
  tokenImage,
  tokenPrecision
}: AddToMetamaskParams) {
  if (!window.ethereum) {
    console.error(`Metamask not available. Cannot add ${tokenSymbol} to Metamask`);
    return;
  }

  const ethereum = window.ethereum! as any;
  const watchAssetOptions = {
    address: tokenAddress, // The address that the token is at.
    symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
    decimals: tokenPrecision ?? 18 // The number of decimals in the token
  };
  if (tokenImage) {
    // TODO: Add token images
    (watchAssetOptions as any).image = tokenImage; // A string url of the token logo
  }
  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: watchAssetOptions
      }
    });

    if (wasAdded) {
      console.log(`${tokenSymbol} added to Metamask`);
    } else {
      console.log(`${tokenSymbol} NOT added to Metamask`);
    }
  } catch (error) {
    console.log(error);
  }
}

export type ChainInfo = {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
};

export const getChainInfo = (chainId: number): ChainInfo => CHIAN_INFO[chainId];

const toChainIdHex = (chainId: number) => `0x${(+BigNumber.from(chainId)).toString(16)}`;

const CHIAN_INFO: Record<number, ChainInfo> = {
  [CHAIN_ID.astar]: {
    chainId: toChainIdHex(CHAIN_ID.astar),
    chainName: "Astar Network",
    nativeCurrency: {
      name: "Astar",
      symbol: "ASTR",
      decimals: 18
    },
    rpcUrls: ["https://astar.api.onfinality.io/rpc?apikey=4dbdfa26-8d93-43d5-9c36-8107f422ed55"],
    blockExplorerUrls: ["https://blockscout.com/astar"]
  },
  [CHAIN_ID.shibuya]: {
    chainId: toChainIdHex(CHAIN_ID.shibuya),
    chainName: "Shibuya Network",
    nativeCurrency: {
      name: "Shibuya",
      symbol: "SBY",
      decimals: 18
    },
    rpcUrls: ["https://evm.shibuya.astar.network"],
    blockExplorerUrls: ["https://blockscout.com/shibuya"]
  }
};

/**
 * Switches the network
 *
 * @param chainId Id for the network
 * @param chainInfo?: Optional param for {@link ChainInfo chain info}
 */
export const switchNetworkByChainId = async (
  chainId: number,
  chainInfo?: ChainInfo
): Promise<{ error?: string }> => {
  if (!window.ethereum) {
    return { error: "Metamask not available. Your wallet needs to switch networks manually" };
  }

  const { ethereum } = window;

  if (!ethereum?.isMetaMask) {
    return { error: "Your wallet needs to switch networks manually." };
  }

  try {
    const chainIdHex = `0x${(+BigNumber.from(chainId)).toString(16)}`;
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainIdHex }]
    });

    return {};
  } catch (e: any) {
    if (e.code === 4902 && chainInfo) {
      return addNetworkToMetamask(chainInfo);
    }

    return { error: "Your wallet needs to switch networks manually." };
  }
};

export const addNetworkToMetamask = async (chainInfo: ChainInfo) => {
  if (!window.ethereum) {
    return { error: "Metamask not available. Your wallet needs to switch networks manually" };
  }

  const { ethereum } = window;

  if (!ethereum?.isMetaMask) {
    return { error: "Your wallet needs to switch networks manually." };
  }

  try {
    if (!chainInfo.rpcUrls.length) throw new Error();
    await ethereum.request({
      method: "wallet_addEthereumChain",
      params: [chainInfo]
    });

    return {};
  } catch (e) {
    return { error: "Your wallet needs to switch networks manually." };
  }
};
