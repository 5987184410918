import React, { ReactNode, useState, useContext, useEffect, useCallback, useReducer } from "react";

import "react-circular-progressbar/dist/styles.css";

import { AstridDaoReceipt, MinedReceipt, SentAstridDaoTransaction } from "@astrid-dao/lib-base";
import { EthersTransactionCancelledError, EthersTransactionOverrides } from "@astrid-dao/lib-ethers";
import { defaultAbiCoder } from "@ethersproject/abi";
import { Provider, TransactionResponse, TransactionReceipt } from "@ethersproject/abstract-provider";
import { hexDataSlice, hexDataLength } from "@ethersproject/bytes";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Flex, Text, Box, Link, Progress, Button, Divider, Spinner } from "theme-ui";

import { BaseProp } from "../@types/base-types";
import { useAstridDao } from "../providers/AstridDaoProvider";
import { useTheme } from "../theme";
import { Icon } from "./Icon";
import ExternalLink from "./SideBar/ExternalNavLink";
import { Tooltip, TooltipProps, Hoverable } from "./Tooltip";
import { EXPLORER_URL, EXPLORER_URL_TESTNET, USE_TESTNET } from "../config";
import MosaicCard from "./MosaicCard";

const strokeWidth = 7;

const circularProgressbarStyle = {
  strokeLinecap: "butt",
  pathColor: "white",
  trailColor: "rgba(255, 255, 255, 1)"
};

// const slowProgress = {
//   strokeWidth,
//   styles: buildStyles({
//     ...circularProgressbarStyle,
//     pathTransitionDuration: 30
//   })
// };

const fastProgress = {
  strokeWidth,
  styles: buildStyles({
    ...circularProgressbarStyle,
    pathTransitionDuration: 0.75
  })
};

const fastProgressConfirm = {
  strokeWidth,
  styles: buildStyles({
    ...circularProgressbarStyle,
    pathColor: "green",
    pathTransitionDuration: 3
  })
};

type TransactionSubmitted = {
  type: "submitted";
  id: string;
  tx?: SentTransaction;
  txRep?: TransactionResponse;
};

type TransactionIdle = {
  type: "idle";
};

type TransactionFailed = {
  type: "failed";
  id: string;
  error: Error;
};

type TransactionWaitingForApproval = {
  type: "waitingForApproval";
  id: string;
};

type TransactionCancelled = {
  type: "cancelled";
  id: string;
};

type TransactionWaitingForConfirmations = {
  type: "waitingForConfirmation";
  id: string;
  tx?: SentTransaction;
  txRep?: TransactionResponse;
};

type TransactionConfirmed = {
  type: "confirmed";
  id: string;
};

type TransactionConfirmedOneShot = {
  type: "confirmedOneShot";
  id: string;
};

type TransactionState =
  | TransactionIdle
  | TransactionSubmitted
  | TransactionFailed
  | TransactionWaitingForApproval
  | TransactionCancelled
  | TransactionWaitingForConfirmations
  | TransactionConfirmed
  | TransactionConfirmedOneShot;

const TransactionContext = React.createContext<
  [TransactionState, (state: TransactionState) => void] | undefined
>(undefined);

export const TransactionProvider: React.FC<BaseProp> = ({ children }) => {
  const transactionState = useState<TransactionState>({ type: "idle" });
  return (
    <TransactionContext.Provider value={transactionState}>{children}</TransactionContext.Provider>
  );
};

export const useTransactionState = () => {
  const transactionState = useContext(TransactionContext);

  if (!transactionState) {
    throw new Error("You must provide a TransactionContext via TransactionProvider");
  }

  return transactionState;
};

export const useMyTransactionState = (myId: string | RegExp): TransactionState => {
  const [transactionState] = useTransactionState();

  return transactionState.type !== "idle" &&
    (typeof myId === "string" ? transactionState.id === myId : transactionState.id.match(myId))
    ? transactionState
    : { type: "idle" };
};

const hasMessage = (error: unknown): error is { message: string } =>
  typeof error === "object" &&
  error !== null &&
  "message" in error &&
  typeof (error as { message: unknown }).message === "string";

type ButtonlikeProps = {
  disabled?: boolean;
  variant?: string;
  onClick?: () => void;
};

type SentTransaction = SentAstridDaoTransaction<
  TransactionResponse,
  AstridDaoReceipt<TransactionReceipt>
>;

export type TransactionFunction = (
  overrides?: EthersTransactionOverrides
) => Promise<SentTransaction | TransactionResponse>;

type TransactionProps<C> = {
  id: string;
  tooltip?: string;
  tooltipPlacement?: TooltipProps<C>["placement"];
  tooltipStrategy?: TooltipProps<C>["strategy"];
  showFailure?: "asTooltip" | "asChildText";
  requires?: readonly (readonly [boolean, string])[];
  send: TransactionFunction;
  children: C;
};

export const useTransactionFunction = (
  id: string,
  send: TransactionFunction
): [sendTransaction: () => Promise<void>, transactionState: TransactionState] => {
  const [transactionState, setTransactionState] = useTransactionState();

  const sendTransaction = useCallback(async () => {
    setTransactionState({ type: "waitingForApproval", id });

    try {
      const tx = await send();

      const rep: any = {};
      // @ts-ignore
      if (tx.hash) {
        rep.txRep = tx;
      } else {
        rep.tx = tx;
      }

      setTransactionState({
        type: "waitingForConfirmation",
        id,
        ...rep
      });
    } catch (error: any) {
      if (
        (hasMessage(error) && (error as any)?.reason?.includes("user rejected transaction")) ||
        error.code === "ACTION_REJECTED"
      ) {
        setTransactionState({ type: "cancelled", id });
      } else {
        console.error(error);

        setTransactionState({
          type: "failed",
          id,
          error: new Error(error?.data?.message ?? "Failed to send transaction")
        });
      }
    }
  }, [send, id, setTransactionState]);

  return [sendTransaction, transactionState];
};

export function Transaction<C extends React.ReactElement<ButtonlikeProps & Hoverable>>({
  id,
  tooltip,
  tooltipPlacement,
  showFailure,
  requires,
  send,
  children
}: TransactionProps<C>) {
  const [sendTransaction, transactionState] = useTransactionFunction(id, send);
  const trigger = React.Children.only<C>(children);

  const failureReasons = (requires || [])
    .filter(([requirement]) => !requirement)
    .map(([, reason]) => reason);

  if (
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation"
  ) {
    failureReasons.push("You must wait for confirmation");
  }

  showFailure =
    failureReasons.length > 0 ? showFailure ?? (tooltip ? "asTooltip" : "asChildText") : undefined;

  const clonedTrigger =
    showFailure === "asChildText"
      ? React.cloneElement(
          trigger,
          {
            disabled: true,
            variant: "danger"
          },
          failureReasons[0]
        )
      : showFailure === "asTooltip"
      ? React.cloneElement(trigger, { disabled: true })
      : React.cloneElement(trigger, { onClick: sendTransaction });

  if (showFailure === "asTooltip") {
    tooltip = failureReasons[0];
  }

  return tooltip ? (
    <>
      <Tooltip message={tooltip} placement={tooltipPlacement || "right"}>
        {clonedTrigger}
      </Tooltip>
    </>
  ) : (
    clonedTrigger
  );
}

// Doesn't work on Kovan:
// https://github.com/MetaMask/metamask-extension/issues/5579
const tryToGetRevertReason = async (provider: Provider, tx: TransactionReceipt) => {
  try {
    const result = await provider.call(tx, tx.blockNumber);

    if (hexDataLength(result) % 32 === 4 && hexDataSlice(result, 0, 4) === "0x08c379a0") {
      return (defaultAbiCoder.decode(["string"], hexDataSlice(result, 4)) as [string])[0];
    }
  } catch {
    return undefined;
  }
};

type TransactionProgress = {
  tx?: SentTransaction;
  txRep?: TransactionResponse;
  id?: string;
  state: TransactionState[];
};

type TransactionProgressAction =
  | { type: "setTransactionProgress"; payload: TransactionProgress | undefined }
  | { type: "updateTransactionProgress"; payload: TransactionProgress };

const initialState: TransactionProgress | undefined = {
  state: []
};

function transactionProgressReducer(
  state: TransactionProgress | undefined,
  action: TransactionProgressAction
): TransactionProgress | undefined {
  switch (action.type) {
    case "setTransactionProgress":
      return action.payload;
    case "updateTransactionProgress":
      if (state) {
        return {
          ...state,
          state: [...state.state, ...action.payload.state]
        };
      }
      return state;
    default:
      return state;
  }
}
export const TransactionMonitor: React.FC = () => {
  const { theme, colorMode } = useTheme();
  const { provider } = useAstridDao();
  const [transactionState, setTransactionState] = useTransactionState();
  const [transactionProgress, dispatchTransactionProgress] = useReducer(
    transactionProgressReducer,
    initialState
  );

  const id = transactionState.type !== "idle" ? transactionState.id : undefined;
  const tx = transactionState.type === "waitingForConfirmation" ? transactionState.tx : undefined;
  const txRep =
    transactionState.type === "waitingForConfirmation" ? transactionState.txRep : undefined;

  useEffect(() => {
    if (id && (tx || txRep)) {
      dispatchTransactionProgress({
        type: "setTransactionProgress",
        payload: {
          tx,
          txRep,
          id,
          state: [
            {
              type: "submitted",
              id,
              tx,
              txRep
            },
            transactionState
          ]
        }
      });
      let cancelled = false;
      let finished = false;

      const txHash = tx?.rawSentTransaction?.hash || txRep?.hash;

      const waitForConfirmation = async () => {
        try {
          let receipt;
          let confirmations = 0;
          let blockNumber = 0;
          let isSuccess = false;

          if (tx) {
            receipt = await tx.waitForReceipt();
            receipt = receipt.rawReceipt;
            confirmations = receipt.confirmations;
            blockNumber = receipt.blockNumber;
            isSuccess = Boolean(receipt.status);
          }

          if (txRep) {
            receipt = await txRep.wait();
            confirmations = receipt.confirmations;
            blockNumber = receipt.blockNumber;
            isSuccess = Boolean(receipt.status);
          }

          // if (cancelled) {
          //   return;
          // }
          blockNumber = blockNumber + confirmations - 1;
          console.log(`Block #${blockNumber} ${confirmations}-confirms tx ${txHash}`);
          console.log(`Finish monitoring tx ${txHash}`);
          finished = true;

          if (isSuccess) {
            console.log(`${receipt}`);

            setTransactionState({
              type: "confirmedOneShot",
              id
            });
            dispatchTransactionProgress({
              type: "updateTransactionProgress",
              payload: { tx, id, state: [{ type: "confirmedOneShot", id }] }
            });
          } else {
            const reason = await tryToGetRevertReason(provider, receipt as TransactionReceipt);

            // if (cancelled) {
            //   return;
            // }

            console.error(`Tx ${txHash} failed`);
            if (reason) {
              console.error(`Revert reason: ${reason}`);
            }

            setTransactionState({
              type: "failed",
              id,
              error: new Error(reason ? `Reverted: ${reason}` : "Failed")
            });
            dispatchTransactionProgress({
              type: "updateTransactionProgress",
              payload: {
                tx,
                id,
                state: [
                  {
                    type: "failed",
                    id,
                    error: new Error(reason ? `Reverted: ${reason}` : "Failed")
                  }
                ]
              }
            });
          }
        } catch (rawError) {
          // if (cancelled) {
          //   return;
          // }

          finished = true;

          if (rawError instanceof EthersTransactionCancelledError) {
            console.log(`Cancelled tx ${txHash}`);
            setTransactionState({ type: "cancelled", id });
            dispatchTransactionProgress({
              type: "updateTransactionProgress",
              payload: {
                tx,
                id,
                state: [{ type: "cancelled", id }]
              }
            });
          } else {
            console.error(`Failed to get receipt for tx ${txHash}`);
            console.error(rawError);

            setTransactionState({
              type: "failed",
              id,
              error: new Error("Failed")
            });
            dispatchTransactionProgress({
              type: "updateTransactionProgress",
              payload: {
                tx,
                id,
                state: [
                  {
                    type: "failed",
                    id,
                    error: new Error("Failed")
                  }
                ]
              }
            });
          }
        }
      };

      console.log(`Start monitoring tx ${txHash}`);
      waitForConfirmation();

      return () => {
        if (!finished) {
          setTransactionState({ type: "idle" });
          // dispatchTransactionProgress({
          //   type: 'setTransactionProgress', payload: undefined
          // });
          console.log(`Cancel monitoring tx ${txHash}`);
          cancelled = true;
        }
      };
    }
  }, [provider, id, tx, txRep, setTransactionState]);

  useEffect(() => {
    if (transactionState.type === "confirmedOneShot" && id) {
      // hack: the txn confirmed state lasts 5 seconds which blocks other states, review with Dani
      setTransactionState({ type: "confirmed", id });
    } else if (
      transactionState.type === "confirmed" ||
      transactionState.type === "failed" ||
      transactionState.type === "cancelled"
    ) {
      // add transaction progress
      if (transactionState.type === "failed") {
        const reason = transactionState.error?.message;
        dispatchTransactionProgress({
          type: "setTransactionProgress",
          payload: {
            id,
            state: [
              {
                type: "failed",
                id: id ?? "",
                error: new Error(reason ? `Reverted: ${reason}` : "Failed")
              }
            ]
          }
        });
      }

      if (transactionState.type === "cancelled") {
        dispatchTransactionProgress({
          type: "setTransactionProgress",
          payload: {
            id,
            state: [
              {
                type: "cancelled",
                id: id ?? ""
              }
            ]
          }
        });
      }

      let cancelled = false;

      setTimeout(() => {
        if (!cancelled) {
          setTransactionState({ type: "idle" });
        }
      }, 5900);

      return () => {
        cancelled = true;
      };
    }
  }, [transactionState.type, setTransactionState, id]);

  // if (transactionState.type === "idle") {
  //   return null;
  // }

  if (transactionState.type === "idle" || transactionState.type === "waitingForApproval") {
    return null;
  }

  const renderContent = (s: TransactionState, isLast: boolean) => {
    const isLoading =
      isLast &&
      s.type !== "confirmed" &&
      s.type !== "failed" &&
      s.type !== "cancelled" &&
      s.type !== "confirmedOneShot";
    const icon = isLoading ? (
      <Spinner size={14} color={theme.colors.primary6} />
    ) : s.type === "failed" ? (
      <Icon name="xmark" size="lg" color={theme.colors.R100} />
    ) : (
      <Icon name="check" size="lg" color={theme.colors.primary6} />
    );

    let title = "Transaction state";
    let subtitle: string | ReactNode = "";
    switch (s.type) {
      case "submitted": {
        title = "Transaction submitted";
        subtitle = s.tx ? s.tx.rawSentTransaction.hash : s.txRep ? s.txRep.hash : "";
        break;
      }
      case "failed": {
        title = "Transaction failed";
        subtitle = s?.error?.message ?? `${s.id} failed`;
        break;
      }
      case "cancelled": {
        title = "Transaction cancelled";
        subtitle = "cancelled";
        break;
      }
      case "waitingForConfirmation": {
        title = "Transaction executing";
        const hash = s.tx ? s.tx.rawSentTransaction.hash : s.txRep ? s.txRep.hash : "";
        subtitle = (
          <>
            View on
            <ExternalLink
              to={USE_TESTNET ? `${EXPLORER_URL_TESTNET}/tx/${hash}` : `${EXPLORER_URL}/tx/${hash}`}
            >
              <Text
                sx={{
                  color: "#0052CC"
                }}
              >
                {" "}
                Blockscout
              </Text>
              <Icon name="external-link-alt" size="xs" color="#0052CC" />
            </ExternalLink>
          </>
        );

        break;
      }
      default: {
        title = "Transaction state";
        subtitle = s.type;
        break;
      }
    }

    return (
      <>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            flex: 1,
            pr: 3
          }}
        >
          <Text
            variant="text.regularH3"
            sx={{
              color: "B80"
            }}
          >
            {title}
          </Text>
          <Text
            variant="text.regularTitleSmall"
            sx={{
              color: "B40",
              mt: 2,
              maxWidth: "217px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            }}
          >
            {subtitle}
          </Text>
        </Flex>

        {icon}
      </>
    );
  };

  return (
    <MosaicCard
      sx={{
        position: "fixed",
        right: ["16px", "16px", "82px"],
        bottom: ["24px", "24px", "52px"],
        // transform: "translate(-50%,-50%)",

        width: "314px",
        height: "auto",
        zIndex: 20000
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",

          // height: '120px',

          p: 4
        }}
      >
        <Flex
          sx={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Text
            variant="text.regularH3"
            sx={{
              color: "B80"
            }}
          >
            Transaction progress
          </Text>
          {/* <Flex
            sx={{
              width: "14px",
              height: "14px",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              setTransactionState({ type: "idle" });
              // dispatchTransactionProgress({
              //   type: 'setTransactionProgress', payload: undefined
              // });
            }}
          >
            <Icon name="times" size="lg" aria-label={"Cancel"} color={theme.colors.primary6} />
          </Flex> */}
        </Flex>

        <Flex
          sx={{
            flexDirection: "row",
            width: "100%",
            height: "100%",
            flex: 1,
            overflowY: "scroll",
            "::-webkit-scrollbar": {
              display: "none"
            },
            mt: 6
          }}
        >
          <Divider
            sx={{
              height: "100%",
              minHeight: "98px",
              width: "2px",
              bg: "primary6"
            }}
          />
          <Flex
            sx={{
              flexDirection: "column",
              flex: 1,
              ml: 4
            }}
          >
            {transactionProgress?.state?.map?.((item, index) => {
              return (
                <Flex
                  key={index}
                  sx={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    mt: index === 0 ? 0 : 6
                  }}
                >
                  {renderContent(item, index === transactionProgress.state.length - 1)}
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
    </MosaicCard>
  );
};
