import { useContext } from "react";

import { AstridDaoStore } from "@astrid-dao/lib-base";

import { AstridDaoStoreContext } from "../providers/AstridDaoStoreProvider";

export const useAstridDaoStore = <T>(): AstridDaoStore<T> => {
  const store = useContext(AstridDaoStoreContext);

  if (!store) {
    throw new Error("You must provide a AstridDaoStore via AstridDaoStoreProvider");
  }

  return store as AstridDaoStore<T>;
};
