import React, { useEffect } from "react";

import { CHAIN_ID } from "@astrid-dao/lib-base";
import {} from "@astrid-dao/lib-ethers";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { Button, Text, Flex, Link } from "theme-ui";
import { useAccount, useNetwork } from "wagmi";

import { BaseProp } from "../@types/base-types";
import { updateGasEstimatorChainId } from "../gasEstimator";
import useWrappedChain from "../hooks/useWrapperNetwork";
import WalletConnectButton from "./ConnectWalletButton";
import LandingPage from "../pages/LandingPage";
import { useLocation } from "react-router-dom";

export type ConnectionState =
  | { type: "inactive" }
  | {
      type: "activating" | "active" | "rejectedByUser" | "alreadyPending" | "failed";
      connector: AbstractConnector;
    };

export type ConnectionAction =
  | { type: "startActivating"; connector: AbstractConnector }
  | { type: "fail"; error: Error }
  | { type: "finishActivating" | "retry" | "cancel" | "deactivate" };

type WalletConnectorProps = { loader?: React.ReactNode } & BaseProp;

export const WalletConnector: React.FC<WalletConnectorProps> = ({ children, loader }) => {
  const { isConnected, isConnecting, isReconnecting } = useAccount();
  const wrappedChainRep = useWrappedChain();
  const { wrappedChain, realChain } = wrappedChainRep ?? {};
  const { chain, chains } = useNetwork();
  const location = useLocation();

  const isNetworkSupported = chains.find(item => item.id === realChain?.id) ? true : false;

  useEffect(() => {
    updateGasEstimatorChainId(wrappedChain?.id ?? CHAIN_ID.astar);
  }, [chain, wrappedChain?.id]);

  if (isConnecting || isReconnecting) {
    return <>{loader}</>;
  }

  if (isNetworkSupported && isConnected) {
    if (location.pathname === "/") {
      return <LandingPage />;
    }
    return <>{children}</>;
  }

  return (
    // <Flex
    //   sx={{
    //     height: "100vh",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     bg: "W100",
    //     padding: "0px 12px"
    //   }}
    // >
    //   <Flex
    //     sx={{
    //       flexDirection: "column",
    //       alignItems: "center",
    //       gap: 3
    //     }}
    //   >
    //     <Flex
    //       sx={{
    //         flexDirection: "column",
    //         justifyConent: "center",
    //         alignItems: "center",
    //         gap: 3
    //       }}
    //     >
    //       <Text variant="text.msTitle24" sx={{ color: "B100" }}>
    //         Connect wallet
    //       </Text>

    //       <WalletConnectButton />
    //     </Flex>

    //     <Flex
    //       sx={{
    //         mt: "24px",
    //         width: "100%",
    //         gap: "24px",
    //         flexDirection: "column",
    //         alignItems: "center",
    //         justifyContent: "center"
    //       }}
    //     >
    //       <Text
    //         variant="text.mediumH3"
    //         sx={{
    //           color: "B80",
    //           textAlign: "justify"
    //         }}
    //       >
    //         By connecting your wallet, you agree that you have acknowledged{" "}
    //         <Link
    //           className="external-nav-link"
    //           href="https://astriddao.gitbook.io/documentation"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //         >
    //           how AstridDAO works, and the risks it might involve.
    //         </Link>{" "}
    //         DYOR and use it at your own risk.
    //       </Text>
    //     </Flex>
    //   </Flex>
    // </Flex>
    <LandingPage />
  );
};
