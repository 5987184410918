import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

import { AppEnvironment, CHAIN_ID, Decimal18, initializeConstants } from "@astrid-dao/lib-base";
import {
  BlockPolledAstridDaoStore,
  EthersAstridDao,
  EthersAstridDaoWithStore,
  getDeploymentConstantsFor,
  _connectByChainId
} from "@astrid-dao/lib-ethers";
import { Provider } from "@ethersproject/abstract-provider";
import { Signer } from "ethers";
import { useAccount, useDisconnect, useSigner, useWebSocketProvider } from "wagmi";

import { BaseProp } from "../@types/base-types";
import {
  isLocalDevelopmentMode,
  astridDaoFrontendConfigProxy,
  stablecoinCollateralTypes,
  SupportedNetworks,
  collateralTypesWithInfiniteAllowance,
  FrontendConfig,
  getShibuyaChainIDMaybeLocal,
  SELECTABLE_COLLATERAL_TYPES
} from "../config";
import useWrappedChain from "../hooks/useWrapperNetwork";
import { connectToContracts } from "../rpc-contract-service/contracts";
import ContractsJson from "../rpc-contract-service/contracts.json";

export type CollateralConfigJson = {
  name: string;
  address: string;
  contracts: {
    activePool: string;
    borrowerOperations: string;
    collSurplusPool: string;
    defaultPool: string;
    gasPool: string;
    hintHelpers: string;
    priceFeed: string;
    sortedVaults: string;
    stabilityPool: string;
    vaultManager: string;
    gokStaking: string;
    multiVaultGetter: string;
    multiStakeGetter: string;
  };
  pythConfigs?: {
    pythContract: string;
    priceFeedID: string;
  };
};

export type BaseContractsConfigJson = {
  communityIssuance: string;
  gaiToken: string;
  xcGaiToken: string;
  xcGaiWrapper: string;
  gokToken: string;
  govToken: string;
  WASTR: string;
};

export type PublicSaleContractsConfigJson = {
  crowdsale: string;
  crowdsaleRecords: string;
};

export type AirdropContractConfigJson = {
  name: string;
  contract: string;
};

export type ContractsConfigJson = {
  baseContracts: BaseContractsConfigJson;
  publicSaleContracts: PublicSaleContractsConfigJson;
  airdropContracts: AirdropContractConfigJson[];
  collaterals: CollateralConfigJson[];
};

type AstridDaoContextValue = {
  account: Readonly<string>;
  astridDao: EthersAstridDaoWithStore<BlockPolledAstridDaoStore>;
  collateralDecimalPrecision: Readonly<number>;
  contractsConfig: ContractsConfigJson;
  criticalCollateralRatio: Decimal18;
  deactivate: () => void;
  filteredCollateralTypes: Readonly<string[]>;
  frontendConfig: FrontendConfig;
  isInfiniteAllowanceMode: Readonly<boolean>;
  isStablecoinMode: Readonly<boolean>;
  isTestnet: boolean;
  minimumCollateralRatio: Decimal18;
  provider: Provider;
  setFrontendConfig: React.Dispatch<React.SetStateAction<FrontendConfig>>;
  signer: Signer;
};

const AstridDaoContext = createContext<AstridDaoContextValue | undefined>(undefined);

type AstridDaoProviderProps = {
  loader?: React.ReactNode;
  environmentInitFailedFallback?: React.ReactNode;
} & BaseProp;

const chainIdNetworkMap: Record<number, SupportedNetworks> = {
  [CHAIN_ID.shibuya]: "shibuya",
  [CHAIN_ID.astar]: "astar",
  [CHAIN_ID.dev]: "shibuya"
};

export const AstridDaoProvider: React.FC<AstridDaoProviderProps> = ({
  children,
  loader,
  environmentInitFailedFallback
}) => {
  const { address: account } = useAccount();
  const provider = useWebSocketProvider();
  const wrappedChainRep = useWrappedChain();
  const { wrappedChain, realChain } = wrappedChainRep ?? {};

  const wrapperedChainId = wrappedChain?.id;
  const realChainId = realChain?.id;
  const { disconnect } = useDisconnect();
  const deactivate = () => {
    disconnect();
  };
  const signer = useSigner();

  const [frontendConfig, setFrontendConfig] = useState(astridDaoFrontendConfigProxy);

  const collateralConstantsFromDeployment = getDeploymentConstantsFor(
    realChainId ?? frontendConfig.chainId,
    frontendConfig.collateralName
  );

  const isInfiniteAllowanceMode = collateralTypesWithInfiniteAllowance.includes(
    astridDaoFrontendConfigProxy.collateralName
  );

  const networkName: SupportedNetworks = wrapperedChainId === CHAIN_ID.shibuya ? "shibuya" : "astar";

  const {
    CRITICAL_COLLATERAL_RATIO: criticalCollateralRatio,
    MINIMUM_COLLATERAL_RATIO: minimumCollateralRatio,
    COLLATERAL_DECIMAL_PRECISION: collateralDecimalPrecision,
    errors: envInitializationErrors
  } = initializeConstants(
    `${networkName}-${frontendConfig.collateralName}` as AppEnvironment,
    collateralConstantsFromDeployment
  );

  const isStablecoinMode = stablecoinCollateralTypes.includes(
    astridDaoFrontendConfigProxy.collateralName
  );

  const wereEnvVariablesSet = Boolean(
    process.env.REACT_APP_ENV && frontendConfig.chainId && frontendConfig.collateralName
  );
  const wasMcrSet = !minimumCollateralRatio?.isZero;
  const wasCcrSet = !criticalCollateralRatio?.isZero;
  const shouldShowEnvInitFailed = !wereEnvVariablesSet || !wasMcrSet || !wasCcrSet;

  console.log(
    "networkName",
    networkName,
    frontendConfig.collateralName,
    collateralConstantsFromDeployment
  );

  /** The list of supported collateral types except for the runtime collateral instance */
  const filteredCollateralTypes: Readonly<string[]> = SELECTABLE_COLLATERAL_TYPES.filter(
    collateral => collateral !== frontendConfig.collateralName
  );
  const RPC_NETWORK = chainIdNetworkMap[frontendConfig.chainId];
  const contractsConfig: ContractsConfigJson = ContractsJson.network[RPC_NETWORK];
  const isTestnet = wrapperedChainId === getShibuyaChainIDMaybeLocal();

  const connection = useMemo(() => {
    if (provider && account && realChainId) {
      const collateralName = frontendConfig.collateralName;
      try {
        return _connectByChainId(
          provider,
          // @ts-ignore
          signer.data,
          realChainId,
          collateralName,
          {
            userAddress: account,
            useStore: "blockPolled"
          }
        );
      } catch (error) {
        console.error("Failed to connect by chain id", error);
      }
    }
  }, [provider, account, realChainId, frontendConfig.collateralName, signer.data]);

  useEffect(() => {
    if (connection && wrapperedChainId) {
      const { provider } = connection;

      try {
        connectToContracts(isTestnet, contractsConfig, provider);
      } catch (error) {
        console.error("Failed to connect to contracts called via RPC", error);
      }
    }
  }, [
    connection,
    wrapperedChainId,
    frontendConfig,
    contractsConfig,
    isTestnet,
    signer.data,
    realChain?.rpcUrls?.public?.webSocket
  ]);

  // Ensure that we don't let users use the app if environment initialization failed
  if (shouldShowEnvInitFailed && !isLocalDevelopmentMode) {
    console.error("Environment failed to initialize! \n", envInitializationErrors);

    return <>{environmentInitFailedFallback}</>;
  }

  if (!provider || !account || !realChainId || !connection || !signer.data) {
    return <>{loader}</>;
  }

  const astridDao = EthersAstridDao._from(connection);
  astridDao.store.logging = false;

  return (
    <AstridDaoContext.Provider
      value={{
        account,
        astridDao,
        collateralDecimalPrecision,
        contractsConfig,
        criticalCollateralRatio,
        deactivate,
        filteredCollateralTypes,
        frontendConfig,
        isInfiniteAllowanceMode,
        isStablecoinMode,
        isTestnet,
        minimumCollateralRatio,
        provider,
        setFrontendConfig,
        // @ts-ignore
        signer: signer.data
      }}
    >
      {children}
    </AstridDaoContext.Provider>
  );
};

export const useAstridDao = () => {
  const astridDaoContext = useContext(AstridDaoContext);

  if (!astridDaoContext) {
    throw new Error("You must provide an AstridDaoContext via AstridDaoProvider");
  }

  return astridDaoContext;
};
