import assert from "assert";

import { BigNumber } from "@ethersproject/bignumber";
import { _COLLATERAL_DECIMAL_PRECISION } from "./constants";
import {
  BaseDecimalish,
  getDigits,
  magnitudes,
  MAX_UINT_256,
  ONE,
  ShortenOptions,
  stringRepresentationFormat,
  TEN,
  trailingZeros
} from "./decimals";
import { Decimal18 } from "./Decimal18";

const DIGITS = getDigits(_COLLATERAL_DECIMAL_PRECISION ?? 6);

const roundedMul = (x: BigNumber, y: BigNumber) =>
  x.mul(y).add(CollateralDecimal.HALF.hex).div(CollateralDecimal.getDigits());

/**
 * Types that can be converted into a CollateralDecimal.
 *
 * @public
 */
export type CollateralDecimalish = BaseDecimalish;

/**
 * Fixed-point decimal bignumber with digits of precision set on app's initialization
 *
 * @remarks
 * Used by AstridDAO libraries to precisely represent collateral type currency ceUSDC, ceUSDT, DOT, KSM
 * amounts, as well as derived metrics like collateral ratios.
 *
 * @public
 */
export class CollateralDecimal {
  static readonly INFINITY = CollateralDecimal.fromBigNumberString(MAX_UINT_256);
  static readonly ZERO = CollateralDecimal.from(0);
  static readonly HALF = CollateralDecimal.from(0.5);
  static readonly ONE = CollateralDecimal.from(1);

  private static _DIGITS: BigNumber = DIGITS;

  public static get DIGITS(): BigNumber {
    return CollateralDecimal._DIGITS;
  }

  public static set DIGITS(value: BigNumber) {
    CollateralDecimal._DIGITS = value;
  }

  static getDigits(): BigNumber {
    return CollateralDecimal.DIGITS ?? DIGITS;
  }

  private static _PRECISION: number;

  public static get PRECISION(): number {
    return CollateralDecimal._PRECISION;
  }

  public static set PRECISION(value: number) {
    CollateralDecimal._PRECISION = value;
  }

  private readonly _bigNumber: BigNumber;

  /** @internal */
  get hex(): string {
    return this._bigNumber.toHexString();
  }

  /** @internal */
  get bigNumber(): string {
    return this._bigNumber.toString();
  }

  private constructor(bigNumber: BigNumber) {
    if (bigNumber.isNegative()) {
      throw new Error("negatives not supported by CollateralDecimal");
    }

    this._bigNumber = bigNumber;
  }

  static fromBigNumberString(bigNumberString: string): CollateralDecimal {
    return new CollateralDecimal(BigNumber.from(bigNumberString));
  }

  private static _fromString(representation: string): CollateralDecimal {
    if (!representation || !representation.match(stringRepresentationFormat)) {
      throw new Error(`bad decimal format: "${representation}"`);
    }

    if (representation.includes("∞")) {
      return CollateralDecimal.INFINITY;
    }

    if (representation.includes("e")) {
      // eslint-disable-next-line prefer-const
      let [coefficient, exponent] = representation.split("e");

      if (exponent.startsWith("-")) {
        return new CollateralDecimal(
          CollateralDecimal._fromString(coefficient)._bigNumber.div(
            TEN.pow(BigNumber.from(exponent.substr(1)))
          )
        );
      }

      if (exponent.startsWith("+")) {
        exponent = exponent.substr(1);
      }

      return new CollateralDecimal(
        CollateralDecimal._fromString(coefficient)._bigNumber.mul(TEN.pow(BigNumber.from(exponent)))
      );
    }

    if (!representation.includes(".")) {
      return new CollateralDecimal(
        BigNumber.from(representation).mul(CollateralDecimal.getDigits())
      );
    }

    // eslint-disable-next-line prefer-const
    let [characteristic, mantissa] = representation.split(".");

    if (mantissa.length < CollateralDecimal.PRECISION) {
      mantissa += "0".repeat(CollateralDecimal.PRECISION - mantissa.length);
    } else {
      mantissa = mantissa.substr(0, CollateralDecimal.PRECISION);
    }

    return new CollateralDecimal(
      BigNumber.from(characteristic || 0)
        .mul(CollateralDecimal.getDigits())
        .add(mantissa)
    );
  }

  static from(decimalish: CollateralDecimalish): CollateralDecimal {
    switch (typeof decimalish) {
      case "object":
        if (decimalish instanceof CollateralDecimal) {
          return decimalish;
        } else {
          return CollateralDecimal._fromString(decimalish.toString());
        }
      case "string":
        return CollateralDecimal._fromString(decimalish);
      case "number":
        return CollateralDecimal._fromString(decimalish.toString());
      default:
        throw new Error("invalid Decimalish value");
    }
  }

  private _toStringWithAutomaticPrecision() {
    const characteristic = this._bigNumber.div(CollateralDecimal.getDigits());
    const mantissa = this._bigNumber.mod(CollateralDecimal.getDigits());

    if (mantissa.isZero()) {
      return characteristic.toString();
    } else {
      const paddedMantissa = mantissa.toString().padStart(CollateralDecimal.PRECISION, "0");
      const trimmedMantissa = paddedMantissa.replace(trailingZeros, "");
      return characteristic.toString() + "." + trimmedMantissa;
    }
  }

  private _roundUp(precision: number) {
    const halfDigit = getDigits(CollateralDecimal.PRECISION - 1 - precision).mul(5);
    return this._bigNumber.add(halfDigit);
  }

  private _toStringWithPrecision(precision: number) {
    if (precision < 0) {
      throw new Error("precision must not be negative");
    }

    const value =
      precision < CollateralDecimal.PRECISION ? this._roundUp(precision) : this._bigNumber;
    const characteristic = value.div(CollateralDecimal.getDigits());
    const mantissa = value.mod(CollateralDecimal.getDigits());

    if (precision === 0) {
      return characteristic.toString();
    } else {
      const paddedMantissa = mantissa.toString().padStart(CollateralDecimal.PRECISION, "0");
      const trimmedMantissa = paddedMantissa.substr(0, precision);
      return characteristic.toString() + "." + trimmedMantissa;
    }
  }

  toString(precision?: number): string {
    if (this.infinite) {
      return "∞";
    } else if (precision !== undefined) {
      return this._toStringWithPrecision(precision);
    } else {
      return this._toStringWithAutomaticPrecision();
    }
  }

  prettify(precision = 2): string {
    const [characteristic, mantissa] = this.toString(precision).split(".");
    const prettyCharacteristic = characteristic.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    return mantissa !== undefined ? prettyCharacteristic + "." + mantissa : prettyCharacteristic;
  }

  /**
   * Allows you to shorten CollateralDecimal values with kMBT magnitudes
   *
   * @param options Allows you to show raw values with precision.
   * For exmaple, 4,999,999.000 would not be rounded up to 5.00M. Instead, it can show 4.9999M
   */
  shorten(options?: ShortenOptions): string {
    const characteristicLength = this.toString(0).length;
    const magnitude = Math.min(Math.floor((characteristicLength - 1) / 3), magnitudes.length - 1);

    const precision = Math.max(3 * (magnitude + 1) - characteristicLength, 0);
    const normalized = this.div(
      new CollateralDecimal(getDigits(CollateralDecimal.PRECISION + 3 * magnitude))
    );

    const shortenedValue = options?.shouldShowRawValue
      ? normalized.toString().substring(0, options?.rawValuePrecision ?? 6)
      : normalized.prettify(precision);

    return `${shortenedValue}${magnitudes[magnitude]}`;
  }

  add(addend: CollateralDecimalish): CollateralDecimal {
    return new CollateralDecimal(this._bigNumber.add(CollateralDecimal.from(addend)._bigNumber));
  }

  sub(subtrahend: CollateralDecimalish): CollateralDecimal {
    return new CollateralDecimal(this._bigNumber.sub(CollateralDecimal.from(subtrahend)._bigNumber));
  }

  mul(multiplier: CollateralDecimalish): CollateralDecimal {
    if (multiplier instanceof Decimal18) {
      return this.mul(multiplier.toString());
    }

    return new CollateralDecimal(
      this._bigNumber
        .mul(CollateralDecimal.from(multiplier)._bigNumber)
        .div(CollateralDecimal.getDigits())
    );
  }

  div(divider: CollateralDecimalish): CollateralDecimal {
    if (typeof divider === "object") {
      if (divider.isZero) {
        return CollateralDecimal.INFINITY;
      }

      if (divider instanceof Decimal18) {
        return this.div(divider.toString());
      }
    }

    return new CollateralDecimal(
      this._bigNumber
        .mul(CollateralDecimal.getDigits())
        .div(CollateralDecimal.from(divider)._bigNumber)
    );
  }

  /** @internal */
  _divCeil(divider: CollateralDecimalish): CollateralDecimal {
    divider = CollateralDecimal.from(divider);

    if (divider.isZero) {
      return CollateralDecimal.INFINITY;
    }

    return new CollateralDecimal(
      this._bigNumber
        .mul(CollateralDecimal.getDigits())
        .add(divider._bigNumber.sub(ONE))
        .div(divider._bigNumber)
    );
  }

  mulDiv(multiplier: CollateralDecimalish, divider: CollateralDecimalish): CollateralDecimal {
    if (multiplier instanceof CollateralDecimal && divider instanceof CollateralDecimal) {
      if (divider.isZero) {
        return CollateralDecimal.INFINITY;
      }

      return new CollateralDecimal(
        this._bigNumber.mul(multiplier._bigNumber).div(divider._bigNumber)
      );
    }

    return CollateralDecimal.from(this.mul(multiplier).div(divider));
  }

  pow(exponent: number): CollateralDecimal {
    assert(Number.isInteger(exponent));
    assert(0 <= exponent && exponent <= 0xffffffff); // Ensure we're safe to use bitwise ops

    if (exponent === 0) {
      return CollateralDecimal.ONE;
    }

    if (exponent === 1) {
      return this;
    }

    let x = this._bigNumber;
    let y = CollateralDecimal.getDigits();

    for (; exponent > 1; exponent >>>= 1) {
      if (exponent & 1) {
        y = roundedMul(x, y);
      }

      x = roundedMul(x, x);
    }

    return new CollateralDecimal(roundedMul(x, y));
  }

  get isZero(): boolean {
    return this._bigNumber.isZero();
  }

  get zero(): this | undefined {
    if (this.isZero) {
      return this;
    }
  }

  get nonZero(): this | undefined {
    if (!this.isZero) {
      return this;
    }
  }

  get infinite(): this | undefined {
    if (this.eq(CollateralDecimal.INFINITY)) {
      return this;
    }
  }

  get finite(): this | undefined {
    if (!this.eq(CollateralDecimal.INFINITY)) {
      return this;
    }
  }

  /** @internal */
  get absoluteValue(): this {
    return this;
  }

  lt(that: CollateralDecimalish): boolean {
    return this._bigNumber.lt(CollateralDecimal.from(that)._bigNumber);
  }

  eq(that: CollateralDecimalish): boolean {
    return this._bigNumber.eq(CollateralDecimal.from(that)._bigNumber);
  }

  gt(that: CollateralDecimalish): boolean {
    return this._bigNumber.gt(CollateralDecimal.from(that)._bigNumber);
  }

  gte(that: CollateralDecimalish): boolean {
    return this._bigNumber.gte(CollateralDecimal.from(that)._bigNumber);
  }

  lte(that: CollateralDecimalish): boolean {
    return this._bigNumber.lte(CollateralDecimal.from(that)._bigNumber);
  }

  static min(a: CollateralDecimalish, b: CollateralDecimalish): CollateralDecimal {
    a = CollateralDecimal.from(a);
    b = CollateralDecimal.from(b);

    return a.lt(b) ? a : b;
  }

  static max(a: CollateralDecimalish, b: CollateralDecimalish): CollateralDecimal {
    a = CollateralDecimal.from(a);
    b = CollateralDecimal.from(b);

    return a.gt(b) ? a : b;
  }
}

type DifferenceRepresentation = { sign: "" | "+" | "-"; absoluteValue: CollateralDecimal };

/** @alpha */
export class CollateralDecimalDifference {
  private _number?: DifferenceRepresentation;

  private constructor(number?: DifferenceRepresentation) {
    this._number = number;
  }

  static between(
    d1: CollateralDecimalish | undefined,
    d2: CollateralDecimalish | undefined
  ): CollateralDecimalDifference {
    if (d1 === undefined || d2 === undefined) {
      return new CollateralDecimalDifference(undefined);
    }

    d1 = CollateralDecimal.from(d1);
    d2 = CollateralDecimal.from(d2);

    if (d1.infinite && d2.infinite) {
      return new CollateralDecimalDifference(undefined);
    } else if (d1.infinite) {
      return new CollateralDecimalDifference({ sign: "+", absoluteValue: d1 });
    } else if (d2.infinite) {
      return new CollateralDecimalDifference({ sign: "-", absoluteValue: d2 });
    } else if (d1.gt(d2)) {
      return new CollateralDecimalDifference({
        sign: "+",
        absoluteValue: CollateralDecimal.from(d1).sub(d2)
      });
    } else if (d2.gt(d1)) {
      return new CollateralDecimalDifference({
        sign: "-",
        absoluteValue: CollateralDecimal.from(d2).sub(d1)
      });
    } else {
      return new CollateralDecimalDifference({ sign: "", absoluteValue: CollateralDecimal.ZERO });
    }
  }

  toString(precision?: number): string {
    if (!this._number) {
      return "N/A";
    }

    return this._number.sign + this._number.absoluteValue.toString(precision);
  }

  prettify(precision?: number): string {
    if (!this._number) {
      return this.toString();
    }

    return this._number.sign + this._number.absoluteValue.prettify(precision);
  }

  mul(multiplier: CollateralDecimalish): CollateralDecimalDifference {
    return new CollateralDecimalDifference(
      this._number && {
        sign: this._number.sign,
        absoluteValue: this._number.absoluteValue.mul(multiplier)
      }
    );
  }

  get nonZero(): this | undefined {
    return this._number?.absoluteValue.nonZero && this;
  }

  get positive(): this | undefined {
    return this._number?.sign === "+" ? this : undefined;
  }

  get negative(): this | undefined {
    return this._number?.sign === "-" ? this : undefined;
  }

  get absoluteValue(): CollateralDecimal | undefined {
    return this._number?.absoluteValue;
  }

  get infinite(): this | undefined {
    return this._number?.absoluteValue.infinite && this;
  }

  get finite(): this | undefined {
    return this._number?.absoluteValue.finite && this;
  }
}

/** @alpha */
export class CollateralDecimalPercent<
  T extends {
    infinite?: T | undefined;
    absoluteValue?: A | undefined;
    mul?(hundred: 100): T;
    toString(precision?: number): string;
  },
  A extends {
    gte(n: string): boolean;
  }
> {
  private _percent: T;

  public constructor(ratio: T) {
    this._percent = ratio.infinite || (ratio.mul && ratio.mul(100)) || ratio;
  }

  nonZeroish(precision: number): this | undefined {
    const zeroish = `0.${"0".repeat(precision)}5`;

    if (this._percent.absoluteValue?.gte(zeroish)) {
      return this;
    }
  }

  toString(precision: number): string {
    return (
      this._percent.toString(precision) +
      (this._percent.absoluteValue && !this._percent.infinite ? "%" : "")
    );
  }

  prettify(): string {
    if (this._percent.absoluteValue?.gte("1000")) {
      return this.toString(0);
    } else if (this._percent.absoluteValue?.gte("10")) {
      return this.toString(1);
    } else {
      return this.toString(2);
    }
  }
}
