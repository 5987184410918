import { useState } from "react";
import { Box, Flex, Text, ThemeUICSSObject } from "theme-ui";

type ButtonType = "primary" | "secondary" | "ghost";
const ColorPrimary = {
  bg: "#FE207E",
  bgLight: "#ed80b0",
  bgDarker: "#741d3f",
  bgHover: "#FE4191",
  color: "#FFFFFF"
};
const ColorSecondary = {
  bg: "#FFE8EE",
  bgLight: "#FFE8EE",
  bgDarker: "#ed80b0",
  bgHover: "#FFDBE4",
  color: "#FE207E"
};
const ColorGhost = {
  bg: "transparent",
  bgLight: "transparent",
  bgDarker: "transparent",
  bgHover: "#8F92A114",
  color: "transparent"
};

const getColorPreset = (type: ButtonType) => {
  switch (type) {
    case "primary":
      return ColorPrimary;
    case "secondary":
      return ColorSecondary;
    case "ghost":
      return ColorGhost;
    default:
      return ColorSecondary;
  }
};

const MosaicButton = ({
  type = "secondary",
  title = "button",
  sx,
  onClick,
  children,
  disabled
}: {
  type?: ButtonType;
  title?: string;
  sx?: ThemeUICSSObject;
  onClick?: (e: any) => void;
  children?: any;
  disabled?: boolean;
}) => {
  const colorPreset = getColorPreset(type);
  const [isHover, updateHoverState] = useState(false);
  return (
    <Box
      sx={{
        width: "180px",
        height: "48px",
        cursor: "pointer",
        opacity: disabled ? 0.3 : 1,
        background: isHover && type === "ghost" ? colorPreset.bgHover : "transparent",
        ...sx
      }}
      onClick={e => {
        if (disabled) return;
        onClick?.(e);
      }}
      onMouseEnter={() => updateHoverState(true)}
      onMouseLeave={() => updateHoverState(false)}
    >
      <Box
        sx={{
          width: "calc(100% - 8px)",
          height: "calc(100%)",
          marginLeft: "4px",
          background: isHover && type !== "ghost" ? colorPreset.bgHover : colorPreset.bg,
          borderBottom: `2px solid ${colorPreset.bgDarker}`,
          borderRight: `2px solid ${colorPreset.bgDarker}`,
          borderTop: `2px solid ${colorPreset.bgLight}`,
          borderLeft: `2px solid ${colorPreset.bgLight}`
        }}
      >
        <Box
          sx={{
            mt: "1px",
            width: "calc(100% + 8px)",
            ml: "-4px",
            mr: "-4px",
            height: "calc(100% - 2px)",
            background: isHover && type !== "ghost" ? colorPreset.bgHover : colorPreset.bg,
            borderBottom: `2px solid ${colorPreset.bg}`,
            borderRight: `2px solid ${colorPreset.bgDarker}`,
            borderTop: `2px solid ${colorPreset.bg}`,
            borderLeft: `2px solid ${colorPreset.bgLight}`
          }}
        >
          <Flex
            sx={{
              mx: "-4px",
              mt: "1px",
              width: "calc(100% + 8px)",
              height: "calc(100% - 2px)",
              background: isHover && type !== "ghost" ? colorPreset.bgHover : colorPreset.bg,
              borderRight: `2px solid ${colorPreset.bgDarker}`,
              borderLeft: `2px solid ${colorPreset.bgLight}`,
              color: `${colorPreset.color}`,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {children ? (
              typeof children === "string" ? (
                <Text variant="mosaicButtonText">{children}</Text>
              ) : (
                children
              )
            ) : (
              <Text variant="mosaicButtonText">{title}</Text>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default MosaicButton;
