import { SupportedCollaterals } from "@astrid-dao/lib-base";

import { DashboardDecimal18 } from "../decimals/DashboardDecimal18";
import { ReadonlyVault } from "./ReadonlyVault";

/**
 * Represents whether a UserVault is open or not, or why it was closed.
 *
 * @public
 */
export type UserReadonlyVaultStatus =
  | "nonExistent"
  | "open"
  | "closedByOwner"
  | "closedByLiquidation"
  | "closedByRedemption";

/**
 * A Readonly Vault that is associated with a single owner.
 *
 * @remarks
 * The SDK uses the base {@link ReadonlyVault} class as a generic container of collateral and debt, for
 * example to represent the total collateral and debt locked up in the protocol.
 *
 * The `UserReadonlyVault` class extends `ReadonlyVault` with extra information that's only available for Vaults
 * that are associated with a single owner (such as the owner's address, or the Vault's status).
 *
 * @public
 */
export class UserReadonlyVault extends ReadonlyVault {
  /** Address that owns this Vault. */
  readonly ownerAddress: string;

  /** Provides more information when the UserVault is empty. */
  readonly status: UserReadonlyVaultStatus;

  /** @internal */
  constructor(
    collateralName: SupportedCollaterals,
    ownerAddress: string,
    status: UserReadonlyVaultStatus,
    collateral?: DashboardDecimal18,
    debt?: DashboardDecimal18
  ) {
    super(collateralName, collateral, debt);

    this.ownerAddress = ownerAddress;
    this.status = status;
  }

  equals(that: UserReadonlyVault): boolean {
    return (
      super.equals(that) && this.ownerAddress === that.ownerAddress && this.status === that.status
    );
  }

  /** @internal */
  toString(): string {
    return (
      `{ ownerAddress: "${this.ownerAddress}"` +
      `, collateralName: ${this.collateralName}` +
      `, collateral: ${this.collateral}` +
      `, debt: ${this.debt}` +
      `, status: "${this.status}" }`
    );
  }
}
