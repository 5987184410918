import { CHAIN_ID, AppEnvironment, SupportedCollaterals, GANACHE_PORT } from "@astrid-dao/lib-base";
import { AstridDaoTokens } from "./currencyIcons";
import { CollateralDecimalPerCollateralType } from "./components/Dashboard/models/decimals/CollateralDecimalService";

export type FrontendConfig = {
  chainId: number;
  collateralName: SupportedCollaterals;
};
export type AstridDaoFrontendConfig = {
  [key in AppEnvironment]: FrontendConfig;
};

export const NETWORK_CONFIG: Readonly<AstridDaoFrontendConfig> = {
  "astar-usdc": { chainId: CHAIN_ID.astar, collateralName: "USDC" },
  "astar-weth": { chainId: CHAIN_ID.astar, collateralName: "WETH" },
  "astar-tia": { chainId: CHAIN_ID.astar, collateralName: "TIA" },
  "astar-usdt": { chainId: CHAIN_ID.astar, collateralName: "USDT" },
  "astar-manta": { chainId: CHAIN_ID.astar, collateralName: "MANTA" },
  "astar-wusdm": { chainId: CHAIN_ID.astar, collateralName: "wUSDM" },
  "astar-stone": { chainId: CHAIN_ID.astar, collateralName: "STONE" },
  "shibuya-stone": { chainId: CHAIN_ID.shibuya, collateralName: "STONE" },
  "shibuya-wusdm": { chainId: CHAIN_ID.shibuya, collateralName: "wUSDM" },
  "shibuya-usdc": { chainId: CHAIN_ID.shibuya, collateralName: "USDC" },
  "shibuya-manta": { chainId: CHAIN_ID.shibuya, collateralName: "MANTA" },
  "shibuya-tia": { chainId: CHAIN_ID.shibuya, collateralName: "TIA" },
  "shibuya-weth": { chainId: CHAIN_ID.shibuya, collateralName: "WETH" }
};

const APP_ENV = process.env.REACT_APP_ENV as Readonly<AppEnvironment>;
export const isLocalDevelopmentMode =
  process.env.NODE_ENV === "development" && window.location.hostname === "localhost";

// NOTE: When you are working locally with (yarn start) and you want to switch from one network
// to another, you can change the config key to any of the instances in the NETWORK_CONFIG map
// e.g. NETWORK_CONFIG["astar-busd"] or NETWORK_CONFIG["astar"];
export const CONFIG_KEY = "FRONTEND_CONFIG";
const frontendConfig = window.localStorage.getItem(CONFIG_KEY);
const DEFAULT_NETWORK_CONFIG = NETWORK_CONFIG[APP_ENV ?? "astar-usdc"];
const storedUserFrontendConfig = frontendConfig
  ? JSON.parse(frontendConfig)
  : DEFAULT_NETWORK_CONFIG;

export const ASTRIDDAO_FRONTEND_CONFIG: FrontendConfig = {
  ...storedUserFrontendConfig
};

export const astridDaoFrontendConfigProxy = new Proxy(ASTRIDDAO_FRONTEND_CONFIG, {
  get: (object, property) => {
    // DEBUG: Uncomment for debugging
    // console.log(`${new Date()} | The ${String(property)} is ${Reflect.get(object, property)}`);

    return Reflect.get(object, property);
  },

  set: (object, property, value) => {
    if (property === "collateralName") {
      if (!value) {
        // console.log("Please provide a valid collateralName.");

        return false;
      }
    }

    // DEBUG: Uncomment for debugging
    // console.log(`${new Date()} | Setting ${String(property)} to ${value}`);

    return Reflect.set(object, property, value);
  }
});

export const collateralTypesWithInfiniteAllowance: SupportedCollaterals[] = [];
export type SupportedNetworks = "shibuya" | "astar" | "dev";
export const POLLING_INTERVAL = 30000;
export const INITIAL_MS_BEFORE_NEXT_RETRY = 500;
export const NON_PROD_SECRET = process.env.REACT_APP_NON_PROD_SECRET;

export type BaseTokenContractNames =
  | "gaiToken"
  | "xcGaiToken"
  | "xcGaiWrapper"
  | "govToken"
  | "gokToken"
  | "communityIssuance"
  | SupportedCollaterals;
export type BaseContractNames = BaseTokenContractNames;
export type TokenClaimingContracts = "giveaway" | "gokStaking" | "collateralDeposit" | "tokenLockup";
export type PublicSaleContracts = "crowdsale" | "crowdsaleRecords" | "crowdsaleWhitelist";

export const LOCAL_FORK_CHAIN_ID: Readonly<Record<string, number>> = {
  shibuya: GANACHE_PORT
};

export const USE_LOCAL_FORK = Boolean(process.env.REACT_APP_USE_LOCAL_FORK);
export const USE_TESTNET = Boolean(process.env.REACT_APP_ENV === "shibuya-stone");

export type FULL_SUPPORT_TOKEN_TYPES = SupportedCollaterals[] | AstridDaoTokens[];
/** The collateral types that are currently deployed and enabled in the dashboard table */
export const SELECTABLE_COLLATERAL_TYPES: Readonly<SupportedCollaterals[]> =
  ASTRIDDAO_FRONTEND_CONFIG.chainId === CHAIN_ID.astar
    ? ["USDC", "WETH", "TIA", "USDT", "MANTA", "STONE", "wUSDM"]
    : ["STONE", "wUSDM", "USDC", "MANTA", "TIA", "WETH"];
/**
 * The list of collateral types that are/ will be supported in the protocol
 * @remarks The list order matters. This determines the datatable's asset order
 */
export const COLLATERAL_TYPES: Readonly<SupportedCollaterals[]> = USE_TESTNET
  ? ["STONE", "wUSDM", "USDC", "MANTA", "TIA", "WETH"]
  : ["USDC", "WETH", "TIA", "USDT", "MANTA", "STONE", "wUSDM"];

export const stablecoinCollateralTypes: SupportedCollaterals[] = ["USDC", "USDT", "wUSDM"];
export const isStablecoinMode = stablecoinCollateralTypes.includes(
  ASTRIDDAO_FRONTEND_CONFIG.collateralName
);

/** Get the chain ID for Shibuya network, considering we might be using a local fork instead. */
export function getShibuyaChainIDMaybeLocal(): number {
  return USE_LOCAL_FORK ? LOCAL_FORK_CHAIN_ID.shibuya : CHAIN_ID.shibuya;
}

export const MAX_RETRY_ATTEMPT = USE_TESTNET ? 2 : USE_LOCAL_FORK ? 2 : 10;

export const collateralNetworkKeyMap: {
  [key: string]: string;
} = {
  ceusdc: "ce-usdc",
  ceusdt: "ce-usdt"
};

export const jsonRpcProviders = [
  {
    isDev: true,
    http: "https://pacific-rpc.sepolia-testnet.manta.network/http",
    webSocket: "wss://pacific-rpc.sepolia-testnet.manta.network/ws"
  },
  {
    isDev: false,
    http: "https://pacific-rpc.manta.network/http",
    webSocket: "wss://pacific-rpc.manta.network/ws"
  },
  {
    isDev: false,
    http: "https://1rpc.io/manta",
    webSocket: "wss://pacific-rpc.manta.network/ws"
  }
];

export const EXPLORER_URL = "https://pacific-explorer.manta.network";
export const EXPLORER_URL_TESTNET = "https://pacific-explorer.sepolia-testnet.manta.network";

export const PRECISION_IN_ = {
  DECIMAL_6: 6,
  DECIMAL_8: 8,
  DECIMAL_10: 10,
  DECIMAL_12: 12,
  WAD: 18
};

export const collateralDecimalPerCollateralType: CollateralDecimalPerCollateralType = {
  WETH: PRECISION_IN_.WAD,
  USDC: PRECISION_IN_.DECIMAL_6,
  USDT: PRECISION_IN_.DECIMAL_6,
  TIA: PRECISION_IN_.DECIMAL_6,
  STONE: PRECISION_IN_.WAD,
  wUSDM: PRECISION_IN_.DECIMAL_6,
  MANTA: PRECISION_IN_.WAD
};
