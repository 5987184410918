import React, { createContext, useContext } from "react";

import {
  SupportedCollaterals,
  Percent,
  HALF_OF_TOTAL_GOK_STAKING_INCENTIVES,
  GOK_STAKING_APR_PERCENTAGE_THRESHOLD
} from "@astrid-dao/lib-base";
import { BigNumber } from "ethers";
import useSWR from "swr";

import { BaseProp } from "../../../@types/base-types";
import { POLLING_INTERVAL } from "../../../config";
import { laggy } from "../../../data/swr-middleware";
import { useTokenPrices } from "../../../hooks/useTokenPrices";
import {
  assertContractExist,
  gokStakingMap,
  stabilityPoolMap,
  vaultManagerMap
} from "../../../rpc-contract-service/contracts";
import {
  getReadonlyVaultMap,
  getUserReadonlyVaultMap,
  getTotalGAIInAllStabilityPools,
  getTotalGOKStakedValue,
  getTotalGAIIssuanceByGAIToken,
  getGAIInStabilityPoolForEachCollateral,
  getTotalUnweightedGOKStakedValueForEachCollateral,
  getStabilityPoolPerCollateral,
  CollateralPriceMap
} from "../../../rpc-contract-service/service";
import {
  promiseAllValues,
  logError,
  waitForAllPromisesOrLogError,
  sleep
} from "../../../rpc-contract-service/utils/utils";
import { retriableCallWithBackoff } from "../../../utils/retryCall";
import { DashboardDecimal18 } from "../models/decimals/DashboardDecimal18";
import { decimalify18 } from "../models/decimals/decimals";
import { ReadonlyVault } from "../models/vaults/ReadonlyVault";
import { UserReadonlyVault } from "../models/vaults/UserReadonlyVault";

export type ReadonlyVaultMap = Map<SupportedCollaterals, ReadonlyVault>;
export type ReadonlyUserVaultMap = Map<SupportedCollaterals, UserReadonlyVault>;
export type GaiInStabilityPoolMap = Map<SupportedCollaterals, DashboardDecimal18>;
export type TotalUnweightedGOKStakedValuePerCollateral = Map<
  SupportedCollaterals,
  DashboardDecimal18
>;
export type TotalWeightedGOKStakedPerCollateral = Map<SupportedCollaterals, DashboardDecimal18>;
export type TotalUnweightedGOKStakedPerCollateral = Map<SupportedCollaterals, DashboardDecimal18>;
export type UserUnweightedGOKStakedPerCollateral = Map<SupportedCollaterals, DashboardDecimal18>;
export type StabilityPoolAprPerCollateral = Map<SupportedCollaterals, DashboardDecimal18>;
export type UserStabilityPoolDepositPerCollateral = Map<SupportedCollaterals, DashboardDecimal18>;
export type RecoveryModeFlagPerCollateral = Map<SupportedCollaterals, boolean>;
export type TotalCollateralRatioPercentagePerCollateral = Map<
  SupportedCollaterals,
  Percent<
    DashboardDecimal18,
    {
      gte(n: string): boolean;
    }
  >
>;
export type StabilityPoolRatioPercentagePerCollateral = Map<
  SupportedCollaterals,
  Percent<
    DashboardDecimal18,
    {
      gte(n: string): boolean;
    }
  >
>;

export interface IDashboardData {
  /** A map of each current price of the native currency (e.g. ASTR, BNB, ETH, etc) in USD. */
  collateralPrices: CollateralPriceMap;

  /** A map of the protocol's readonly vaults for each collateral type.
   *
   * @remarks This can be used to calculate the overall system total value locked
   */
  readonlyVaults: ReadonlyVaultMap;

  /** An map of user's readonly vaults for each collateral type. */
  userReadonlyVaults: ReadonlyUserVaultMap;

  /** The Total System Value Locked */
  totalValueLocked: DashboardDecimal18;

  /** Total amount of GAIissued by the protocol. */
  totalGaiIssuance: DashboardDecimal18;

  /** The Overall calculated Reward APR percentage in the system. */
  overallAPR: DashboardDecimal18;

  /** The Overall calculated collateral ratio in the system. */
  overallCollateralRatio: DashboardDecimal18;

  /** A map of the Total amount of GAIcurrently deposited in the Stability Pool
   * for each collateral type.
   *
   * @remarks This can be used to calculate the total value locked for a collateral type
   */
  gaiInStabilityPoolPerCollateral: GaiInStabilityPoolMap;

  /** A map of the Total Unweighted GOK staked for each collateral type.
   *
   * @remarks This can be used to calculate the total value locked for a collateral type
   */
  totalUnweightedGOKStakedValuePerCollateral: TotalUnweightedGOKStakedValuePerCollateral;

  /** A map of the stability pool APRs for each collateral type. */
  stabilityPoolAprPerCollateral: StabilityPoolAprPerCollateral;

  /** A map of the stability pool APRs for each collateral type. */
  userStabilityPoolDepositPerCollateral: UserStabilityPoolDepositPerCollateral;

  gokStakingAprPercentage: string;
  totalWeightedGOKStakedPerCollateral: TotalWeightedGOKStakedPerCollateral;
  totalUnweightedGOKStakedPerCollateral: TotalUnweightedGOKStakedPerCollateral;
  recoveryModeFlagPerCollateral: RecoveryModeFlagPerCollateral;
  totalCollateralRatioPerCollateral: TotalCollateralRatioPercentagePerCollateral;
  stabilityPoolRatioPctPerCollateral: StabilityPoolRatioPercentagePerCollateral;
  userUnweightedGOKStakedPerCollateral: UserUnweightedGOKStakedPerCollateral;
}

const fallbackData: IDashboardData = {
  collateralPrices: new Map(),
  readonlyVaults: new Map(),
  userReadonlyVaults: new Map(),
  totalValueLocked: DashboardDecimal18.ZERO,
  totalGaiIssuance: DashboardDecimal18.ZERO,
  overallAPR: DashboardDecimal18.ZERO,
  overallCollateralRatio: DashboardDecimal18.ZERO,
  gaiInStabilityPoolPerCollateral: new Map(),
  totalUnweightedGOKStakedValuePerCollateral: new Map(),
  totalWeightedGOKStakedPerCollateral: new Map(),
  totalUnweightedGOKStakedPerCollateral: new Map(),
  stabilityPoolAprPerCollateral: new Map(),
  userStabilityPoolDepositPerCollateral: new Map(),
  gokStakingAprPercentage: "0%",
  recoveryModeFlagPerCollateral: new Map(),
  totalCollateralRatioPerCollateral: new Map(),
  stabilityPoolRatioPctPerCollateral: new Map(),
  userUnweightedGOKStakedPerCollateral: new Map()
} as const;

/** Initializes and updates {@link IDashboardData.readonlyVaults readonlyVaults} */
async function getReadonlyVaults(): Promise<ReadonlyVaultMap> {
  return await getReadonlyVaultMap();
}

/** Initializes and updates {@link IDashboardData.totalValueLocked totalValueLocked} */
async function getTotalValueLocked(
  readonlyVaults: ReadonlyVaultMap,
  collateralPrices: CollateralPriceMap,
  gokPrice: DashboardDecimal18
): Promise<DashboardDecimal18> {
  let combinedTVL = DashboardDecimal18.ZERO;

  if (readonlyVaults?.size) {
    for (const [, vault] of readonlyVaults) {
      const price = collateralPrices.get(vault.collateralName) ?? DashboardDecimal18.ONE;
      combinedTVL = combinedTVL.add(vault.collateral.mul(price));
    }
  }

  const { totalGaiInAllStabilityPools, totalGOKStakedValueInAllGOKStakings } =
    await promiseAllValues({
      totalGaiInAllStabilityPools: getTotalGAIInAllStabilityPools(),
      totalGOKStakedValueInAllGOKStakings: getTotalGOKStakedValue(gokPrice)
    });

  combinedTVL = combinedTVL
    .add(totalGaiInAllStabilityPools)
    .add(
      process.env.REACT_APP_TVL_INCLUDE_GOK === "true"
        ? totalGOKStakedValueInAllGOKStakings
        : DashboardDecimal18.ZERO
    );

  return combinedTVL;
}

/** Initializes and updates {@link IDashboardData.totalGaiIssuance totalGaiIssuance} */
async function getTotalGaiIssuance(): Promise<DashboardDecimal18> {
  return await getTotalGAIIssuanceByGAIToken();
}

/** Initializes and updates {@link IDashboardData.overallAPR overallAPR} */
async function getOverallAPR(
  readonlyVaults: ReadonlyVaultMap,
  stabilityPoolAprPerCollateral: StabilityPoolAprPerCollateral
): Promise<DashboardDecimal18> {
  let overallAPR = DashboardDecimal18.ZERO;

  if (readonlyVaults?.size && stabilityPoolAprPerCollateral?.size) {
    stabilityPoolAprPerCollateral.forEach(apr => {
      // calculate "max()" of aprs
      overallAPR.lt(apr) && (overallAPR = apr);
    });
  }
  return overallAPR;
}

/** Initializes and updates {@link IDashboardData.overallCollateralRatio overallCollateralRatio} */
function getOverallCollateralRatio(
  readonlyVaults: ReadonlyVaultMap,
  collateralPrices: CollateralPriceMap,
  totalGaiIssuance: DashboardDecimal18
): DashboardDecimal18 {
  let totalCollateralAmount = DashboardDecimal18.ZERO;

  readonlyVaults.forEach(vault => {
    const price = collateralPrices.get(vault.collateralName) ?? DashboardDecimal18.ONE;
    totalCollateralAmount = totalCollateralAmount.add(vault.collateral.mul(price));
  });

  return totalCollateralAmount.div(totalGaiIssuance);
}

/** Initializes and updates {@link IDashboardData.overallCollateralRatio overallCollateralRatio} */
export async function getUserStabilityPoolDepositPerCollateral(
  userAddress: string
): Promise<UserStabilityPoolDepositPerCollateral> {
  const promises: Promise<any>[] = [];
  const userStabilityPoolDepositPerCollateral = new Map<SupportedCollaterals, DashboardDecimal18>();

  for (const [collateralName, contract] of stabilityPoolMap) {
    promises.push(
      (async () => {
        try {
          const stabilityPoolContract = assertContractExist(contract);

          const userStabilityPoolDeposit: BigNumber = await retriableCallWithBackoff(() =>
            stabilityPoolContract.getCompoundedGAIDeposit(userAddress)
          );
          const userStabilityPoolDepositInWad = decimalify18(userStabilityPoolDeposit);

          userStabilityPoolDepositPerCollateral.set(collateralName, userStabilityPoolDepositInWad);
        } catch (error) {
          logError(error);
        }
      })()
    );
  }

  await waitForAllPromisesOrLogError(promises);

  return userStabilityPoolDepositPerCollateral;
}

export async function getGOKStakedData(userAddress: string) {
  const totalUnweightedGOKStakedPerCollateral: TotalUnweightedGOKStakedPerCollateral = new Map();
  const totalWeightedGOKStakedPerCollateral: TotalWeightedGOKStakedPerCollateral = new Map();
  const userUnweightedGOKStakedPerCollateral: UserUnweightedGOKStakedPerCollateral = new Map();

  const promises: Promise<any>[] = [];
  for (const [collateral] of vaultManagerMap) {
    promises.push(
      (async () => {
        try {
          const gokStakingContract = assertContractExist(gokStakingMap.get(collateral));
          const totalUnweightedGOKStaked = DashboardDecimal18.fromBigNumberString(
            (
              await retriableCallWithBackoff<BigNumber>(() =>
                gokStakingContract.totalUnweightedGOKStaked()
              )
            ).toString()
          );
          const totalWeightedGOKStaked = DashboardDecimal18.fromBigNumberString(
            (
              await retriableCallWithBackoff<BigNumber>(() =>
                gokStakingContract.totalWeightedGOKStaked()
              )
            ).toString()
          );
          const unweightedGOKStaked = DashboardDecimal18.fromBigNumberString(
            (
              await retriableCallWithBackoff<BigNumber>(() =>
                gokStakingContract.unweightedStakes(userAddress)
              )
            ).toString()
          );

          totalUnweightedGOKStakedPerCollateral.set(collateral, totalUnweightedGOKStaked);
          totalWeightedGOKStakedPerCollateral.set(collateral, totalWeightedGOKStaked);
          userUnweightedGOKStakedPerCollateral.set(collateral, unweightedGOKStaked);
        } catch (error) {
          logError(error);
        }
      })()
    );
  }

  await waitForAllPromisesOrLogError(promises);

  return {
    totalUnweightedGOKStakedPerCollateral,
    totalWeightedGOKStakedPerCollateral,
    userUnweightedGOKStakedPerCollateral
  };
}

function getGOKStakingApr(
  totalUnweightedGOKStakedPerCollateral: TotalUnweightedGOKStakedPerCollateral
) {
  // GOK staking APR calculation
  // 10m / total_goks_staked_currently (we only count stakes with staking_period > 1 year)
  let gokStakingAprPct = new Percent(DashboardDecimal18.ZERO).prettify();
  let grandTotalUnweightedGOKStaked = 0;

  try {
    totalUnweightedGOKStakedPerCollateral.forEach(unweightedGOK => {
      grandTotalUnweightedGOKStaked += Number(unweightedGOK.toString());
    });

    if (grandTotalUnweightedGOKStaked) {
      const gokStakingApr = HALF_OF_TOTAL_GOK_STAKING_INCENTIVES / grandTotalUnweightedGOKStaked;

      if (gokStakingApr !== Infinity) {
        const decimalifiedApy = DashboardDecimal18.from(gokStakingApr);
        if (decimalifiedApy.lte(DashboardDecimal18.from(GOK_STAKING_APR_PERCENTAGE_THRESHOLD))) {
          gokStakingAprPct = `${decimalifiedApy.toString(2)}%`;
        }
      }
    }
  } catch (error) {
    /** No need to do anything since we have a default value */
  }

  return gokStakingAprPct;
}

function getStatisticsFromVaultData({
  readonlyVaults,
  collateralPrices,
  gaiInStabilityPoolPerCollateral
}: {
  readonlyVaults: ReadonlyVaultMap;
  collateralPrices: CollateralPriceMap;
  gaiInStabilityPoolPerCollateral: GaiInStabilityPoolMap;
}): {
  recoveryModeFlagPerCollateral: RecoveryModeFlagPerCollateral;
  totalCollateralRatioPerCollateral: TotalCollateralRatioPercentagePerCollateral;
  stabilityPoolRatioPctPerCollateral: StabilityPoolRatioPercentagePerCollateral;
} {
  const recoveryModeFlagPerCollateral = new Map();
  const totalCollateralRatioPerCollateral = new Map();
  const stabilityPoolRatioPctPerCollateral = new Map();

  readonlyVaults.forEach(readonlyVault => {
    const collateralName = readonlyVault.collateralName;
    const price = collateralPrices.get(collateralName) ?? DashboardDecimal18.ZERO;

    recoveryModeFlagPerCollateral.set(
      collateralName,
      readonlyVault.collateralRatioIsBelowCritical(price)
    );
    totalCollateralRatioPerCollateral.set(
      collateralName,
      new Percent(readonlyVault.collateralRatio(price))
    );

    const gaiInStabilityPool =
      gaiInStabilityPoolPerCollateral.get(collateralName) ?? DashboardDecimal18.ZERO;
    const totalCollateralValue = readonlyVault.collateral.mul(price);
    const stabilityPoolRatioPct = new Percent(gaiInStabilityPool.div(totalCollateralValue));

    stabilityPoolRatioPctPerCollateral.set(collateralName, stabilityPoolRatioPct);
  });
  return {
    recoveryModeFlagPerCollateral,
    totalCollateralRatioPerCollateral,
    stabilityPoolRatioPctPerCollateral
  };
}

async function getDashboardData(
  userAddress: string,
  collateralPrices: CollateralPriceMap
): Promise<IDashboardData> {
  const gokPrice = collateralPrices.get("GOK") ?? DashboardDecimal18.ZERO;
  // const {
  //   readonlyVaults,
  //   userReadonlyVaults,
  //   totalGaiIssuance,
  //   gaiInStabilityPoolPerCollateral,
  //   totalUnweightedGOKStakedValuePerCollateral,
  //   totalGOKStakedPerCollateral,
  //   userStabilityPoolDepositPerCollateral
  // } = await promiseAllValues({
  //   readonlyVaults: getReadonlyVaults(),
  //   userReadonlyVaults: getUserReadonlyVaultMap(userAddress),
  //   totalGaiIssuance: getTotalGaiIssuance(),
  //   gaiInStabilityPoolPerCollateral: getGAIInStabilityPoolForEachCollateral(),
  //   totalUnweightedGOKStakedValuePerCollateral:
  //     getTotalUnweightedGOKStakedValueForEachCollateral(gokPrice),
  //   totalGOKStakedPerCollateral: getGOKStakedData(userAddress),
  //   userStabilityPoolDepositPerCollateral: getUserStabilityPoolDepositPerCollateral(userAddress)
  // });

  const readonlyVaults = await getReadonlyVaults();
  const userReadonlyVaults = await getUserReadonlyVaultMap(userAddress);
  const totalGaiIssuance = await getTotalGaiIssuance();
  const gaiInStabilityPoolPerCollateral = await getGAIInStabilityPoolForEachCollateral();
  const totalUnweightedGOKStakedValuePerCollateral =
    await getTotalUnweightedGOKStakedValueForEachCollateral(gokPrice);
  const totalGOKStakedPerCollateral = await getGOKStakedData(userAddress);
  const userStabilityPoolDepositPerCollateral = await getUserStabilityPoolDepositPerCollateral(
    userAddress
  );

  const totalValueLocked = await getTotalValueLocked(readonlyVaults, collateralPrices, gokPrice);
  const overallCollateralRatio = getOverallCollateralRatio(
    readonlyVaults,
    collateralPrices,
    totalGaiIssuance
  );
  const stabilityPoolAprPerCollateral = await getStabilityPoolPerCollateral(gokPrice);
  const overallAPR = await getOverallAPR(readonlyVaults, stabilityPoolAprPerCollateral);

  const {
    recoveryModeFlagPerCollateral,
    totalCollateralRatioPerCollateral,
    stabilityPoolRatioPctPerCollateral
  } = getStatisticsFromVaultData({
    readonlyVaults,
    collateralPrices,
    gaiInStabilityPoolPerCollateral
  });

  const {
    totalUnweightedGOKStakedPerCollateral,
    totalWeightedGOKStakedPerCollateral,
    userUnweightedGOKStakedPerCollateral
  } = totalGOKStakedPerCollateral;

  const gokStakingAprPercentage = getGOKStakingApr(totalUnweightedGOKStakedPerCollateral);

  return {
    collateralPrices,
    readonlyVaults,
    userReadonlyVaults,
    totalValueLocked,
    totalGaiIssuance,
    overallAPR,
    overallCollateralRatio,
    gaiInStabilityPoolPerCollateral,
    totalUnweightedGOKStakedValuePerCollateral,
    stabilityPoolAprPerCollateral,
    userStabilityPoolDepositPerCollateral,
    gokStakingAprPercentage,
    totalUnweightedGOKStakedPerCollateral,
    totalWeightedGOKStakedPerCollateral,
    recoveryModeFlagPerCollateral,
    totalCollateralRatioPerCollateral,
    stabilityPoolRatioPctPerCollateral,
    userUnweightedGOKStakedPerCollateral
  };
}

export const DashboardDataContext = createContext<IDashboardData | undefined>(undefined);

export const DashboardDataProvider: React.FC<{ address: string } & BaseProp> = ({
  children,
  address
}) => {
  const tokenPriceMap = useTokenPrices();
  const { data } = useSWR([address, tokenPriceMap], getDashboardData, {
    use: [laggy],
    refreshInterval: POLLING_INTERVAL,
    fallbackData: undefined
  });

  return (
    <DashboardDataContext.Provider value={data ?? fallbackData}>
      {children}
    </DashboardDataContext.Provider>
  );
};

export const useDashboardData = () => {
  const dashboardDataContext = useContext(DashboardDataContext);

  if (!dashboardDataContext) {
    throw new Error("You must provide an DashboardDataContext via DashboardDataProvider");
  }

  return dashboardDataContext;
};
