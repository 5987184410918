import React, { useState, useRef } from "react";

import { Placement, VirtualElement, Boundary, PositioningStrategy } from "@popperjs/core";
import { usePopper } from "react-popper";
import { Card, Box } from "theme-ui";

export type Hoverable = {
  onMouseOver: () => void;
  onMouseOut: () => void;
  ref: (instance: Element | VirtualElement | null) => void;
};

export type TooltipProps<C> = {
  children: C;
  closeOnMouseLeaveDelay?: number;
  content?: React.ReactNode;
  customBoundary?: Boundary | React.RefObject<HTMLInputElement>;
  message?: string;
  offset?: number[];
  placement?: Placement;
  strategy?: PositioningStrategy;
};

export function Tooltip<C extends React.ReactElement<Hoverable>>({
  children,
  closeOnMouseLeaveDelay,
  content,
  customBoundary,
  message,
  offset,
  placement,
  strategy
}: TooltipProps<C>) {
  const event = useRef<"over" | "out">();
  const [show, setShow] = useState(false);
  const [referenceElement, setReferenceElement] = useState<Element | VirtualElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>();
  const isStrategyFixed = strategy === "fixed";

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement ?? "auto",
    strategy: strategy,
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowElement,
          ...(isStrategyFixed && {
            padding: ({ popper, reference, placement }) => popper.width / reference.width
          })
        }
      },
      {
        name: "offset",
        options: {
          offset: (offset as any) ?? [0, 4]
        }
      },
      {
        name: "preventOverflow",
        options: {
          ...(customBoundary && { boundary: customBoundary as Boundary }),
          padding: -4,
          altBoundary: true
        }
      }
    ]
  });

  return (
    <>
      {React.cloneElement(React.Children.only<C>(children), {
        // Use a debounced onMouseOver/onMouseOut instead of onMouseEnter/onMouseLeave to
        // work around https://github.com/facebook/react/issues/10109

        onMouseOver: () => {
          event.current = "over";

          if (!show) {
            setShow(true);
          }
        },

        onMouseOut: () => {
          event.current = "out";

          setTimeout(() => {
            if (event.current === "out") {
              setShow(false);
            }
          }, closeOnMouseLeaveDelay ?? 0);
        },

        ref: setReferenceElement
      })}

      {show && (
        <Card
          variant="tooltip"
          id="tooltip"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {message && message}
          {content && content}
          <Box ref={setArrowElement} id="arrow" data-popper-arrow style={styles.arrow}></Box>
        </Card>
      )}
    </>
  );
}
