import { useEffect, useState } from "react";

import { AstridDaoStoreState, SupportedCollaterals } from "@astrid-dao/lib-base";
import { useAccount } from "wagmi";

import { DashboardDecimal18 } from "../components/Dashboard/models/decimals/DashboardDecimal18";
import { useAstridDaoSelector } from "../hooks/useAstridDaoSelector";
import { getAccountBalancePerCollateral } from "../rpc-contract-service/service";
import { COLLAT } from "../strings";
import { CollateralDecimalService } from "../components/Dashboard/models/decimals/CollateralDecimalService";
import {
  decimalify18,
  scaleUnitOfPrecisionToWad,
  scaleWadToUnitOfPrecision
} from "../components/Dashboard/models/decimals/decimals";
import { BigNumber } from "ethers/lib/ethers";
import { fromWrappedTokenBalanceToScaledDecimalBalance } from "../utils/zkEVMFix";

const selector = (state: AstridDaoStoreState) => {
  const { wrappedTokenBalance } = state;
  return {
    wrappedTokenBalance
  };
};

export default function useTokenBalance(symbol: SupportedCollaterals) {
  const [balance, setBalance] = useState(DashboardDecimal18.ZERO);
  const { address } = useAccount();
  const { wrappedTokenBalance } = useAstridDaoSelector(selector);

  useEffect(() => {
    if (address) {
      if (COLLAT() === symbol) {
        // const scaledDecimalResult = fromWrappedTokenBalanceToScaledDecimalBalance(
        //   wrappedTokenBalance,
        //   symbol
        // );
        // setBalance(DashboardDecimal18.fromBigNumberString(scaledDecimalResult.bigNumber));
        setBalance(DashboardDecimal18.from(wrappedTokenBalance.toString()));
      } else {
        getAccountBalancePerCollateral(address, COLLAT())
          .then(rep => {
            setBalance(rep.get(symbol) ?? DashboardDecimal18.ZERO);
          })
          .catch(e => console.log(e));
      }
    }
  }, [address, symbol, wrappedTokenBalance]);

  console.log(
    "symbol",
    symbol,
    balance.toString(),
    "wrappedTokenBalance",
    wrappedTokenBalance.toString()
  );
  return balance;
}
