import React, { useState } from "react";

import { CHAIN_ID, AppEnvironment, SupportedCollaterals } from "@astrid-dao/lib-base";
import Select, { StylesConfig } from "react-select";
import { Flex, Image, Text, Box } from "theme-ui";

import IconArrowDown from "../assets/images/icon-arrow-down.svg";
import IconArrowUp from "../assets/images/icon-arrow-up.svg";
import {
  NETWORK_CONFIG,
  astridDaoFrontendConfigProxy,
  SELECTABLE_COLLATERAL_TYPES,
  CONFIG_KEY
} from "../config";
import { iconCurrencyMap } from "../currencyIcons";
import { useAstridDao } from "../providers/AstridDaoProvider";
import { useTheme, AstridDaoTheme } from "../theme";
import MosaicCard from "./MosaicCard";

interface Option {
  value: string;
  icon: string;
  label: string;
}

function getLabel({ icon, label }: Option) {
  return (
    <Flex sx={{ justifyContent: "flex-start", alignItems: "center", py: 3 }}>
      <Image sx={{ height: "24px", mr: 2 }} src={icon} />
      <Text
        variant="mediumH3"
        sx={{
          color: "text5"
        }}
      >
        {label}
      </Text>
    </Flex>
  );
}

function isArray<T>(arg: unknown): arg is readonly T[] {
  return Array.isArray(arg);
}

// function return a headerSelectStyles object base on the theme
function getHeaderSelectStyles(theme: AstridDaoTheme): StylesConfig<Option, boolean> {
  return {
    container: base => ({
      width: "100%"
    }),
    menu: base => ({
      height: "100%",
      minHeight: `${SELECTABLE_COLLATERAL_TYPES.length * 60}px`
    }),
    menuList: base => ({
      height: "100%"
    }),
    dropdownIndicator: base => ({
      display: "none"
    }),
    control: base => ({
      borderRadius: "12px",
      width: "100%"
    }),
    option: (base, { isSelected, isFocused }) => ({
      marginTop: "12px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      background: "#FFFFFF",
      borderRadius: "12px",
      width: "100%",
      padding: "0 8px",
      cursor: "pointer",
      ":hover": {
        backgroundColor: theme.colors.primary1
      },
      "::after": {
        marginLeft: "4px",
        lineHeight: "24px",
        width: "24px",
        height: "24px",
        content: isSelected ? 'url("/icon/icon-checked.svg")' : '""'
      },
      backgroundColor: isSelected ? undefined : isFocused ? theme.colors.primary1 : undefined
    })
  };
}

const networkKeyMap: {
  [key: string]: string;
} = {
  ceusdc: "ce-usdc",
  ceusdt: "ce-usdt"
};

const options: Option[] = SELECTABLE_COLLATERAL_TYPES.map(collateral => {
  return {
    value: collateral,
    label: collateral,
    icon: iconCurrencyMap[collateral as SupportedCollaterals]
  };
});

options.sort(({ label: labelA }, { label: labelB }) =>
  labelA.toLowerCase().localeCompare(labelB.toLowerCase())
);

export const SelectCollateral: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { setFrontendConfig, frontendConfig } = useAstridDao();
  const { theme } = useTheme();
  const selectedOptionIndex = options.findIndex(
    ({ value }) => value.toLowerCase() === frontendConfig.collateralName.toLowerCase()
  );

  return (
    <MosaicCard sx={{ mt: 5 }} small>
      <Flex
        sx={{
          flexDirection: "column",
          px: 3,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          position: "relative"
        }}
      >
        <Select
          menuIsOpen={isOpen}
          onMenuOpen={() => setIsOpen(true)}
          onMenuClose={() => setIsOpen(false)}
          formatOptionLabel={getLabel}
          options={options}
          isSearchable={false}
          value={options[selectedOptionIndex]}
          styles={getHeaderSelectStyles(theme)}
          components={{
            IndicatorSeparator: () => null
          }}
          onChange={option => {
            if (option && !isArray(option)) {
              const chain = frontendConfig.chainId === CHAIN_ID.astar ? "astar" : "shibuya";
              const key = option?.value.toLowerCase();
              const appEnvToSwitch = `${chain}-${networkKeyMap[key] ?? key}` as AppEnvironment;
              const config = NETWORK_CONFIG[appEnvToSwitch];

              astridDaoFrontendConfigProxy.collateralName = config.collateralName;

              window.localStorage.setItem(CONFIG_KEY, JSON.stringify(config));

              setFrontendConfig(config);
            }
          }}
        />

        <Box
          onClick={() => setIsOpen(!isOpen)}
          sx={{ position: "absolute", right: "24px", top: "16px" }}
        >
          {isOpen ? <Image src={IconArrowUp} /> : <Image src={IconArrowDown} />}
        </Box>
      </Flex>
    </MosaicCard>
  );
};
