import { SupportedCollaterals } from "@astrid-dao/lib-base";

import { panic } from "../../../../rpc-contract-service/utils/utils";
import { DashboardDecimal18, Decimalish } from "../decimals/DashboardDecimal18";

const NOMINAL_COLLATERAL_RATIO_PRECISION = DashboardDecimal18.from(100);

/**
 * A combination of collateral and debt.
 *
 * @public
 */
export class ReadonlyVault {
  /** Amount of native currency (e.g. Ether) collateralized. */
  readonly collateralName: SupportedCollaterals;

  /** Amount of collateral (e.g. WASTR, USDC, BUSD, DOT, etc.) collateralized. */
  readonly collateral: DashboardDecimal18;

  /** Amount of GAIowed. */
  readonly debt: DashboardDecimal18;

  /** Collateral ratio below which a Vault can be liquidated in normal mode.*/
  readonly minimumCollateralRatio: DashboardDecimal18;

  /** Total collateral ratio below which recovery mode is triggered.*/
  readonly criticalCollateralRatio: DashboardDecimal18;

  /** Amount of GAIthat's reserved for compensating the liquidator of a Vault.*/
  readonly gaiLiquidationReserve: DashboardDecimal18;

  /** @internal */
  constructor(
    collateralName: SupportedCollaterals,
    collateral = DashboardDecimal18.ZERO,
    debt = DashboardDecimal18.ZERO,
    minimumCollateralRatio = DashboardDecimal18.ZERO,
    criticalCollateralRatio = DashboardDecimal18.ZERO,
    gaiLiquidationReserve = DashboardDecimal18.from(10)
  ) {
    if (!collateralName) {
      panic(new Error(`Missing collateral name ${collateralName}`));
    }

    this.collateralName = collateralName;
    this.collateral = collateral;
    this.debt = debt;
    this.minimumCollateralRatio = minimumCollateralRatio;
    this.criticalCollateralRatio = criticalCollateralRatio;
    this.gaiLiquidationReserve = gaiLiquidationReserve;
  }

  get isEmpty(): boolean {
    return this.collateral.isZero && this.debt.isZero;
  }

  /**
   * Amount of GAIthat must be repaid to close this Vault.
   *
   * @remarks
   * This doesn't include the liquidation reserve, which is refunded in case of normal closure.
   */
  get netDebt(): DashboardDecimal18 {
    if (this.debt.lt(this.gaiLiquidationReserve)) {
      return this.debt;
    }

    return this.debt.sub(this.gaiLiquidationReserve);
  }

  /** @internal */
  get _nominalCollateralRatio(): DashboardDecimal18 {
    return this.collateral.mulDiv(NOMINAL_COLLATERAL_RATIO_PRECISION, this.debt);
  }

  /** Calculate the Vault's collateralization ratio at a given price. */
  collateralRatio(price: Decimalish): DashboardDecimal18 {
    return this.collateral.mulDiv(price, this.debt);
  }

  /**
   * Whether the Vault is undercollateralized at a given price.
   *
   * @returns
   * `true` if the Vault's collateralization ratio is less than the
   * {@link minimumCollateralRatio}.
   */
  collateralRatioIsBelowMinimum(price: Decimalish): boolean {
    return this.collateralRatio(price).lt(this.minimumCollateralRatio);
  }

  /**
   * Whether the collateralization ratio is less than the {@link criticalCollateralRatio| CCR} at a
   * given price.
   */
  collateralRatioIsBelowCritical(price: Decimalish): boolean {
    return this.collateralRatio(price).lt(this.minimumCollateralRatio);
  }

  /** Whether the Vault is sufficiently collateralized to be opened during recovery mode. */
  isOpenableInRecoveryMode(price: Decimalish): boolean {
    return this.collateralRatio(price).gte(this.minimumCollateralRatio);
  }

  /** @internal */
  toString(): string {
    return `{ collateral: ${this.collateral}, debt: ${this.debt} }`;
  }

  equals(that: ReadonlyVault): boolean {
    return this.collateral.eq(that.collateral) && this.debt.eq(that.debt);
  }
}

/** @internal */
// TODO support wrappered platform coin like ASTR -> WASTR
  // @ts-ignore
export const _emptyVault = new ReadonlyVault("WASTR");
