import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Box, Text, ThemeUICSSObject } from "theme-ui";

import iconMoney from "../assets/images/icon-money.svg";
import MosaicButton from "./MosaicButton";
import { ReactNode } from "react";
import { useIsMobile } from "../hooks/useIsMobile";

const WalletConnectButton = ({
  changeNetworkTitle,
  sx,
  component
}: {
  changeNetworkTitle?: string;
  sx?: ThemeUICSSObject;
  component?: ReactNode;
}) => {
  const isMobile = useIsMobile();
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none"
              }
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <MosaicButton
                    type="primary"
                    onClick={openConnectModal}
                    title={isMobile ? "Connect" : "Connect Wallet"}
                    sx={sx}
                  />
                );
              }

              if (chain.unsupported) {
                return (
                  <MosaicButton onClick={openChainModal} type="primary" sx={sx}>
                    <Text
                      variant="text.msTitle20"
                      sx={{
                        fontSize: ["14px", "14px", "24px"]
                      }}
                    >
                      {changeNetworkTitle ? changeNetworkTitle : "Change Network"}
                    </Text>
                  </MosaicButton>
                );
              }

              return (
                <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
                  {/* <img src={iconMoney} alt="money" />
                  <Text
                    variant="regular24"
                    sx={{ ml: "6px", cursor: "pointer" }}
                    onClick={openAccountModal}
                  >
                    {account.displayBalance}
                  </Text> */}

                  {/* <Text variant="regular24">{account.displayName}</Text> */}

                  {component ? (
                    <Box onClick={() => (window.location.href = "/dashboard")}>{component}</Box>
                  ) : (
                    <MosaicButton
                      type="primary"
                      title="Launch App"
                      sx={sx}
                      onClick={() => (window.location.href = "/dashboard")}
                    />
                  )}
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default WalletConnectButton;
