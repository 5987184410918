import React, { lazy, Suspense } from "react";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Route, Routes } from "react-router-dom";
import { Container } from "theme-ui";

import { DashboardDataProvider } from "./components/Dashboard/hooks/useDashboardData";
import { Header } from "./components/Header";
import { SideBar } from "./components/SideBar/SideBar";
import { TransactionMonitor } from "./components/Transaction";
import useResponsive, { DisplaySize } from "./hooks/useResponsive";
import { TokenPricesProvider } from "./hooks/useTokenPrices";
import { useAstridDao } from "./providers/AstridDaoProvider";
import { AstridDaoStoreProvider } from "./providers/AstridDaoStoreProvider";
import LandingPage from "./pages/LandingPage";

Bugsnag.start({
  apiKey: "a152d59b1c1e16958309cc53ae0225ae",
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_PACKAGE_VERSION
});

// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

type AstridDaoFrontendProps = {
  loader?: React.ReactNode;
};

const GOKStakingPage = lazy(() => import("./pages/GOKStakingPage"));
const ChangelogPage = lazy(() => import("./pages/Changelog"));
const VaultPage = lazy(() => import("./pages/VaultPage"));
const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const StabilityFarmPage = lazy(() => import("./pages/StabilityFarmPage"));
const LiquidationPage = lazy(() => import("./pages/LiquidationPage"));
const RedemptionPage = lazy(() => import("./pages/RedemptionPage"));
const StatisticsPage = lazy(() => import("./pages/StatisticsPage"));
// TODO: Add back when we do the public sale
// const PublicSalePage = lazy(() => import("./pages/PublicSalePage"));
const TokenClaimingPage = lazy(() => import("./pages/TokenClaimingPage"));
const GaiConversionPage = lazy(() => import("./pages/GaiConversionPage"));

export const AstridDaoFrontend: React.FC<AstridDaoFrontendProps> = ({ loader }) => {
  const { astridDao, account, isTestnet } = useAstridDao();
  const responsive = useResponsive();

  return (
    <ErrorBoundary>
      <AstridDaoStoreProvider {...{ loader }} store={astridDao.store}>
        <TokenPricesProvider isTestnet={isTestnet}>
          <Header />
          <Container variant="app">
            {responsive >= DisplaySize.ComputerS ? <SideBar /> : null}
            <Container variant="main">
              <Container variant="content">
                <Suspense fallback={<>{loader}</>}>
                  <DashboardDataProvider address={account}>
                    <Routes>
                      <Route path="/" element={<LandingPage />} />
                      <Route path="/dashboard" element={<DashboardPage />} />
                      <Route path="/token-claiming" element={<TokenClaimingPage />} />
                      <Route path="/vault" element={<VaultPage />} />
                      <Route
                        path="/changelog"
                        element={
                          <div style={{ padding: "24px" }}>
                            <ChangelogPage />
                          </div>
                        }
                      />
                      <Route path="/stability-farm" element={<StabilityFarmPage />} />
                      <Route path="/gok-staking" element={<GOKStakingPage />} />
                      <Route path="/liquidation" element={<LiquidationPage />} />
                      <Route path="/redemption" element={<RedemptionPage />} />
                      <Route path="/statistics" element={<StatisticsPage />} />
                      <Route path="/gai-conversion" element={<GaiConversionPage />} />
                    </Routes>
                  </DashboardDataProvider>
                </Suspense>
              </Container>
            </Container>
          </Container>
        </TokenPricesProvider>

        <TransactionMonitor />
      </AstridDaoStoreProvider>
    </ErrorBoundary>
  );
};
