import { Box, Flex, Text, ThemeUICSSObject } from "theme-ui";

const MosaicCard = ({
  sx,
  children,
  small
}: {
  sx?: ThemeUICSSObject;
  children?: any;
  small?: boolean;
}) => {
  const mosaicUnit = small ? 3 : 7;
  return (
    <Box
      sx={{
        position: "relative",
        ...sx
      }}
    >
      <Box
        sx={{
          left: `${2 * mosaicUnit}px`,
          width: `calc(100% - ${mosaicUnit * 4}px)`,
          height: "100%",
          background: "#FFFFFF",
          position: "absolute",
        }}
      />

      <Box
        sx={{
          left: `${1 * mosaicUnit}px`,
          top: `${1 * mosaicUnit}px`,
          width: `calc(100% - ${mosaicUnit * 2}px)`,
          height: `calc(100% - ${mosaicUnit * 2}px)`,
          background: "#FFFFFF",
          position: "absolute",
        }}
      />

      <Box
        sx={{
          width: "100%",
          top: `${2 * mosaicUnit}px`,
          height: `calc(100% - ${mosaicUnit * 4}px)`,
          background: "#FFFFFF",
          position: "absolute",
        }}
      />

      <Box
        sx={{
          position: "relative",
          height: "100%",
          width: "100%"
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MosaicCard;
