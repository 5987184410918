import React from "react";

import { FlipProp } from "@fortawesome/fontawesome-svg-core";
import { IconName, IconPrefix } from "@fortawesome/free-solid-svg-icons";
import { Container, Flex, Text, Box } from "theme-ui";

import IconLiquidation from "../../assets/images/dashboard/icon-liquidation.svg";
import IconRemdemption from "../../assets/images/dashboard/icon-redemption.svg";
import IconStabilityFarm from "../../assets/images/dashboard/icon-stability-farm.svg";
import IconGOKStaking from "../../assets/images/dashboard/icon-staking.svg";
import IconStatistics from "../../assets/images/dashboard/icon-statistics.svg";
import IconTokenClaim from "../../assets/images/dashboard/icon-token-claiming.svg";
import IconVault from "../../assets/images/dashboard/icon-vault.svg";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Icon } from "../Icon";
import { Link } from "../Link";
import { navIconStyles } from "./SideBar";

type NavItem = {
  name: string;
  to: string;
  icon: string;
  prefix?: IconPrefix;
  flip?: FlipProp;
};

export const SideBarNav = ({
  currentPath,
  onClick
}: {
  currentPath: string;
  onClick?: Function;
}) => {
  const isMobile = useIsMobile();

  const navItems: NavItem[] = [
    {
      name: "Vault",
      to: "/vault",
      icon: IconVault
    },
    {
      name: "Stability Farm",
      to: "/stability-farm",
      icon: IconStabilityFarm
    },
    {
      name: "GOK Staking",
      to: "/gok-staking",
      icon: IconGOKStaking
    },
    {
      name: "Liquidation",
      to: "/liquidation",
      icon: IconLiquidation
    },
    {
      name: "Redemption",
      to: "/redemption",
      icon: IconRemdemption
    },
    {
      name: "Statistics",
      to: "/statistics",
      icon: IconStatistics
    }
    // TODO: Add back when we do the public sale
    // {
    //   name: "Public Sale",
    //   to: "/public-sale",
    //   icon: "cart-shopping"
    // },
    // {
    //   name: "Token Claiming",
    //   to: "/token-claiming",
    //   icon: IconTokenClaim
    // }
  ];

  return (
    <Container
      as="nav"
      sx={{
        width: "100%"
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          mt: 2
        }}
        onClick={() => onClick && onClick()}
      >
        {navItems.map(({ name, to, icon, prefix, flip }) => (
          <Link to={to} key={name}>
            <Flex
              key={name}
              sx={{
                mt: 3,
                p: 3,
                bg: currentPath === to ? "#4B92FF" : "transparent",
                width: isMobile ? "calc(100vw - 24px)" : ["0px", "172px", "220px"],
                borderRadius: "8px",
                ":hover": {
                  background: "G05"
                },
                gap: "8px",
                alignItems: "center"
              }}
            >
              {currentPath === to && (
                <Box sx={{ background: "#FE207E", width: "4px", height: "16px" }} />
              )}

              <img src={icon} alt={icon} />

              <Text
                variant="regularH3"
                sx={{
                  color: currentPath === to ? "W100" : "W60"
                }}
              >
                {name}
              </Text>
            </Flex>
          </Link>
        ))}
      </Flex>
    </Container>
  );
};
