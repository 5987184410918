import assert from "assert";

import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { AddressZero } from "@ethersproject/constants";
import { Log } from "@ethersproject/abstract-provider";
import { ErrorCode } from "@ethersproject/logger";
import { Transaction } from "@ethersproject/transactions";
import { parseUnits } from "@ethersproject/units";

import {
  CollateralGainTransferDetails,
  Decimal18,
  Decimalish,
  LiquidationDetails,
  AstridDaoReceipt,
  GAI_MINIMUM_DEBT,
  GAI_MINIMUM_NET_DEBT,
  MinedReceipt,
  PopulatableAstridDao,
  PopulatedAstridDaoTransaction,
  PopulatedRedemption,
  RedemptionDetails,
  SentAstridDaoTransaction,
  StabilityDepositChangeDetails,
  StabilityPoolGainsWithdrawalDetails,
  BaseCollateralDepositChangeDetails,
  Vault,
  VaultAdjustmentDetails,
  VaultAdjustmentParams,
  VaultClosureDetails,
  VaultCreationDetails,
  VaultCreationParams,
  VaultWithPendingRedistribution,
  _failedReceipt,
  _normalizeVaultAdjustment,
  _normalizeVaultCreation,
  _pendingReceipt,
  _successfulReceipt,
  CollateralDecimal
} from "@astrid-dao/lib-base";

import {
  EthersPopulatedTransaction,
  EthersTransactionOverrides,
  EthersTransactionReceipt,
  EthersTransactionResponse
} from "./types";

import {
  EthersAstridDaoConnection,
  _getContracts,
  _requireAddress,
  _requireSigner
} from "./EthersAstridDaoConnection";

import {
  convertDisplayPriceToContractAcceptablePrice,
  decimalify18,
  decimalifyCollateralDecimal,
  getCurrentTimeInSecondsWithBuffer,
  promiseAllValues
} from "./_utils";
import { logsToString } from "./parseLogs";
import { ReadableEthersAstridDao } from "./ReadableEthersAstridDao";
import { _normalizeERC20TokenConversionChange } from "@astrid-dao/lib-base/dist/src/ERC20TokenConversionChange";
import { HintHelpers, SortedVaults } from "../types";

const bigNumberMax = (a: BigNumber, b?: BigNumber) => (b?.gt(a) ? b : a);

// With 70 iterations redemption costs about ~10M gas, and each iteration accounts for ~138k more
/** @internal */
export const _redeemMaxIterations = 70;

const defaultBorrowingRateSlippageTolerance = Decimal18.from(0.005); // 0.5%
const defaultRedemptionRateSlippageTolerance = Decimal18.from(0.001); // 0.1%
const defaultBorrowingFeeDecayToleranceMinutes = 10;

const noDetails = () => undefined;

const compose =
  <T, U, V>(f: (_: U) => V, g: (_: T) => U) =>
  (_: T) =>
    f(g(_));

const id = <T>(t: T) => t;

// Takes ~6-7K (use 10K to be safe) to update lastFeeOperationTime, but the cost of calculating the
// decayed baseRate increases logarithmically with time elapsed since the last update.
const addGasForBaseRateUpdate =
  (maxMinutesSinceLastUpdate = 10) =>
  (gas: BigNumber) =>
    gas.add(10000 + 1414 * Math.ceil(Math.log2(maxMinutesSinceLastUpdate + 1)));

// First traversal in ascending direction takes ~50K, then ~13.5K per extra step.
// 80K should be enough for 3 steps, plus some extra to be safe.
const addGasForPotentialListTraversal = (gas: BigNumber) => gas.add(80000);

const addGasForGOKIssuance = (gas: BigNumber) => gas.add(50000);

const addGasForERC20TokenConversion = (gas: BigNumber) => gas.add(20000);

// Convert the given BigNumber into a hex string accepted by ethers.js.
// Essentially, returns a hex string that is prefixed with "0x" and has no padding leading zeroes.
const convertBigNumberToEthersAcceptedHexString = (bn: BigNumber) =>
  "0x" + bn.toBigInt().toString(/*radix=*/ 16);

// To get the best entropy available, we'd do something like:
//
// const bigRandomNumber = () =>
//   BigNumber.from(
//     `0x${Array.from(crypto.getRandomValues(new Uint32Array(8)))
//       .map(u32 => u32.toString(16).padStart(8, "0"))
//       .join("")}`
//   );
//
// However, Window.crypto is browser-specific. Since we only use this for randomly picking Vaults
// during the search for hints, Math.random() will do fine, too.
//
// This returns a random integer between 0 and Number.MAX_SAFE_INTEGER
const randomInteger = () => Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

// Maximum number of trials to perform in a single getApproxHint() call. If the number of trials
// required to get a statistically "good" hint is larger than this, the search for the hint will
// be broken up into multiple getApproxHint() calls.
//
// This should be low enough to work with popular public Ethereum providers like Infura without
// triggering any fair use limits.
const maxNumberOfTrialsAtOnce = 2500;

function* generateTrials(totalNumberOfTrials: number) {
  assert(Number.isInteger(totalNumberOfTrials) && totalNumberOfTrials > 0);

  while (totalNumberOfTrials) {
    const numberOfTrials = Math.min(totalNumberOfTrials, maxNumberOfTrialsAtOnce);
    yield numberOfTrials;

    totalNumberOfTrials -= numberOfTrials;
  }
}

/** @internal */
export enum _RawErrorReason {
  TRANSACTION_FAILED = "transaction failed",
  TRANSACTION_CANCELLED = "cancelled",
  TRANSACTION_REPLACED = "replaced",
  TRANSACTION_REPRICED = "repriced"
}

const transactionReplacementReasons: unknown[] = [
  _RawErrorReason.TRANSACTION_CANCELLED,
  _RawErrorReason.TRANSACTION_REPLACED,
  _RawErrorReason.TRANSACTION_REPRICED
];

interface RawTransactionFailedError extends Error {
  code: ErrorCode.CALL_EXCEPTION;
  reason: _RawErrorReason.TRANSACTION_FAILED;
  transactionHash: string;
  transaction: Transaction;
  receipt: EthersTransactionReceipt;
}

/** @internal */
export interface _RawTransactionReplacedError extends Error {
  code: ErrorCode.TRANSACTION_REPLACED;
  reason:
    | _RawErrorReason.TRANSACTION_CANCELLED
    | _RawErrorReason.TRANSACTION_REPLACED
    | _RawErrorReason.TRANSACTION_REPRICED;
  cancelled: boolean;
  hash: string;
  replacement: EthersTransactionResponse;
  receipt: EthersTransactionReceipt;
}

const hasProp = <T, P extends string>(o: T, p: P): o is T & { [_ in P]: unknown } => p in o;

const isTransactionFailedError = (error: Error): error is RawTransactionFailedError =>
  hasProp(error, "code") &&
  error.code === ErrorCode.CALL_EXCEPTION &&
  hasProp(error, "reason") &&
  error.reason === _RawErrorReason.TRANSACTION_FAILED;

const isTransactionReplacedError = (error: Error): error is _RawTransactionReplacedError =>
  hasProp(error, "code") &&
  error.code === ErrorCode.TRANSACTION_REPLACED &&
  hasProp(error, "reason") &&
  transactionReplacementReasons.includes(error.reason);

/**
 * Thrown when a transaction is cancelled or replaced by a different transaction.
 *
 * @public
 */
export class EthersTransactionCancelledError extends Error {
  readonly rawReplacementReceipt: EthersTransactionReceipt;
  readonly rawError: Error;

  /** @internal */
  constructor(rawError: _RawTransactionReplacedError) {
    assert(rawError.reason !== _RawErrorReason.TRANSACTION_REPRICED);

    super(`Transaction ${rawError.reason}`);
    this.name = "TransactionCancelledError";
    this.rawReplacementReceipt = rawError.receipt;
    this.rawError = rawError;
  }
}

/**
 * A transaction that has already been sent.
 *
 * @remarks
 * Returned by {@link SendableEthersAstridDao} functions.
 *
 * @public
 */
export class SentEthersAstridDaoTransaction<T = unknown>
  implements
    SentAstridDaoTransaction<
      EthersTransactionResponse,
      AstridDaoReceipt<EthersTransactionReceipt, T>
    >
{
  /** Ethers' representation of a sent transaction. */
  readonly rawSentTransaction: EthersTransactionResponse;

  private readonly _connection: EthersAstridDaoConnection;
  private readonly _parse: (rawReceipt: EthersTransactionReceipt) => T;

  /** @internal */
  constructor(
    rawSentTransaction: EthersTransactionResponse,
    connection: EthersAstridDaoConnection,
    parse: (rawReceipt: EthersTransactionReceipt) => T
  ) {
    this.rawSentTransaction = rawSentTransaction;
    this._connection = connection;
    this._parse = parse;
  }

  private _receiptFrom(rawReceipt: EthersTransactionReceipt | null) {
    return rawReceipt
      ? rawReceipt.status
        ? _successfulReceipt(rawReceipt, this._parse(rawReceipt), () =>
            logsToString(rawReceipt, _getContracts(this._connection))
          )
        : _failedReceipt(rawReceipt)
      : _pendingReceipt;
  }

  private async _waitForRawReceipt(confirmations?: number) {
    try {
      return await this.rawSentTransaction.wait(confirmations);
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (isTransactionFailedError(error)) {
          return error.receipt;
        }

        if (isTransactionReplacedError(error)) {
          if (error.cancelled) {
            throw new EthersTransactionCancelledError(error);
          } else {
            return error.receipt;
          }
        }
      }

      throw error;
    }
  }

  /** {@inheritDoc @astrid-dao/lib-base#SentAstridDaoTransaction.getReceipt} */
  async getReceipt(): Promise<AstridDaoReceipt<EthersTransactionReceipt, T>> {
    return this._receiptFrom(await this._waitForRawReceipt(0));
  }

  /**
   * {@inheritDoc @astrid-dao/lib-base#SentAstridDaoTransaction.waitForReceipt}
   *
   * @throws
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  async waitForReceipt(): Promise<MinedReceipt<EthersTransactionReceipt, T>> {
    const receipt = this._receiptFrom(await this._waitForRawReceipt());

    assert(receipt.status !== "pending");
    return receipt;
  }
}

/**
 * Optional parameters of a transaction that borrows GAI.
 *
 * @public
 */
export interface BorrowingOperationOptionalParams {
  /**
   * Maximum acceptable {@link @astrid-dao/lib-base#Fees.borrowingRate | borrowing rate}
   * (default: current borrowing rate plus 0.5%).
   */
  maxBorrowingRate?: Decimalish;

  /**
   * Control the amount of extra gas included attached to the transaction.
   *
   * @remarks
   * Transactions that borrow GAI must pay a variable borrowing fee, which is added to the Vault's
   * debt. This fee increases whenever a redemption occurs, and otherwise decays exponentially.
   * Due to this decay, a Vault's collateral ratio can end up being higher than initially calculated
   * if the transaction is pending for a long time. When this happens, the backend has to iterate
   * over the sorted list of Vaults to find a new position for the Vault, which costs extra gas.
   *
   * The SDK can estimate how much the gas costs of the transaction may increase due to this decay,
   * and can include additional gas to ensure that it will still succeed, even if it ends up pending
   * for a relatively long time. This parameter specifies the length of time that should be covered
   * by the extra gas.
   *
   * Default: 10 minutes.
   */
  borrowingFeeDecayToleranceMinutes?: number;
}

const normalizeBorrowingOperationOptionalParams = (
  maxBorrowingRateOrOptionalParams: Decimalish | BorrowingOperationOptionalParams | undefined,
  currentBorrowingRate: Decimal18 | undefined
): {
  maxBorrowingRate: Decimal18;
  borrowingFeeDecayToleranceMinutes: number;
} => {
  if (maxBorrowingRateOrOptionalParams === undefined) {
    return {
      maxBorrowingRate:
        currentBorrowingRate?.add(defaultBorrowingRateSlippageTolerance) ?? Decimal18.ZERO,
      borrowingFeeDecayToleranceMinutes: defaultBorrowingFeeDecayToleranceMinutes
    };
  } else if (
    typeof maxBorrowingRateOrOptionalParams === "number" ||
    typeof maxBorrowingRateOrOptionalParams === "string" ||
    maxBorrowingRateOrOptionalParams instanceof Decimal18
  ) {
    return {
      maxBorrowingRate: Decimal18.from(maxBorrowingRateOrOptionalParams),
      borrowingFeeDecayToleranceMinutes: defaultBorrowingFeeDecayToleranceMinutes
    };
  } else {
    const { maxBorrowingRate, borrowingFeeDecayToleranceMinutes } =
      maxBorrowingRateOrOptionalParams as BorrowingOperationOptionalParams;

    return {
      maxBorrowingRate:
        maxBorrowingRate !== undefined
          ? Decimal18.from(maxBorrowingRate)
          : currentBorrowingRate?.add(defaultBorrowingRateSlippageTolerance) ?? Decimal18.ZERO,

      borrowingFeeDecayToleranceMinutes:
        borrowingFeeDecayToleranceMinutes ?? defaultBorrowingFeeDecayToleranceMinutes
    };
  }
};

/**
 * A transaction that has been prepared for sending.
 *
 * @remarks
 * Returned by {@link PopulatableEthersAstridDao} functions.
 *
 * @public
 */
export class PopulatedEthersAstridDaoTransaction<T = unknown>
  implements
    PopulatedAstridDaoTransaction<EthersPopulatedTransaction, SentEthersAstridDaoTransaction<T>>
{
  /** Unsigned transaction object populated by Ethers. */
  readonly rawPopulatedTransaction: EthersPopulatedTransaction;

  /**
   * Extra gas added to the transaction's `gasLimit` on top of the estimated minimum requirement.
   *
   * @remarks
   * Gas estimation is based on blockchain state at the latest block. However, most transactions
   * stay in pending state for several blocks before being included in a block. This may increase
   * the actual gas requirements of certain AstridDAO transactions by the time they are eventually
   * mined, therefore the AstridDAO SDK increases these transactions' `gasLimit` by default (unless
   * `gasLimit` is {@link EthersTransactionOverrides | overridden}).
   *
   * Note: even though the SDK includes gas headroom for many transaction types, currently this
   * property is only implemented for {@link PopulatableEthersAstridDao.openVault | openVault()},
   * {@link PopulatableEthersAstridDao.adjustVault | adjustVault()} and its aliases.
   */
  readonly gasHeadroom?: number;

  private readonly _connection: EthersAstridDaoConnection;
  private readonly _parse: (rawReceipt: EthersTransactionReceipt) => T;

  /** @internal */
  constructor(
    rawPopulatedTransaction: EthersPopulatedTransaction,
    connection: EthersAstridDaoConnection,
    parse: (rawReceipt: EthersTransactionReceipt) => T,
    gasHeadroom?: number
  ) {
    this.rawPopulatedTransaction = rawPopulatedTransaction;
    this._connection = connection;
    this._parse = parse;

    if (gasHeadroom !== undefined) {
      this.gasHeadroom = gasHeadroom;
    }
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatedAstridDaoTransaction.send} */
  async send(): Promise<SentEthersAstridDaoTransaction<T>> {
    return new SentEthersAstridDaoTransaction(
      await _requireSigner(this._connection).sendTransaction(this.rawPopulatedTransaction),
      this._connection,
      this._parse
    );
  }
}

/**
 * {@inheritDoc @astrid-dao/lib-base#PopulatedRedemption}
 *
 * @public
 */
export class PopulatedEthersRedemption
  extends PopulatedEthersAstridDaoTransaction<RedemptionDetails>
  implements
    PopulatedRedemption<
      EthersPopulatedTransaction,
      EthersTransactionResponse,
      EthersTransactionReceipt
    >
{
  /** {@inheritDoc @astrid-dao/lib-base#PopulatedRedemption.attemptedGaiAmount} */
  readonly attemptedGaiAmount: Decimal18;

  /** {@inheritDoc @astrid-dao/lib-base#PopulatedRedemption.redeemableGaiAmount} */
  readonly redeemableGaiAmount: Decimal18;

  /** {@inheritDoc @astrid-dao/lib-base#PopulatedRedemption.isTruncated} */
  readonly isTruncated: boolean;

  private readonly _increaseAmountByMinimumNetDebt?: (
    maxRedemptionRate?: Decimalish
  ) => Promise<PopulatedEthersRedemption>;

  /** @internal */
  constructor(
    rawPopulatedTransaction: EthersPopulatedTransaction,
    connection: EthersAstridDaoConnection,
    attemptedGaiAmount: Decimal18,
    redeemableGaiAmount: Decimal18,
    increaseAmountByMinimumNetDebt?: (
      maxRedemptionRate?: Decimalish
    ) => Promise<PopulatedEthersRedemption>
  ) {
    const { vaultManager: vaultManager } = _getContracts(connection);

    super(
      rawPopulatedTransaction,
      connection,

      ({ logs }) =>
        vaultManager
          .extractEvents(logs, "Redemption")
          .map(({ args: { _COLSent, _COLFee, _actualGAIAmount, _attemptedGAIAmount } }) => ({
            attemptedGaiAmount: decimalify18(_attemptedGAIAmount),
            actualGaiAmount: decimalify18(_actualGAIAmount),
            collateralTaken: decimalify18(_COLSent),
            fee: decimalify18(_COLFee)
          }))[0]
    );

    this.attemptedGaiAmount = attemptedGaiAmount;
    this.redeemableGaiAmount = redeemableGaiAmount;
    this.isTruncated = redeemableGaiAmount.lt(attemptedGaiAmount);
    this._increaseAmountByMinimumNetDebt = increaseAmountByMinimumNetDebt;
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatedRedemption.increaseAmountByMinimumNetDebt} */
  increaseAmountByMinimumNetDebt(
    maxRedemptionRate?: Decimalish
  ): Promise<PopulatedEthersRedemption> {
    if (!this._increaseAmountByMinimumNetDebt) {
      throw new Error(
        "PopulatedEthersRedemption: increaseAmountByMinimumNetDebt() can " +
          "only be called when amount is truncated"
      );
    }

    return this._increaseAmountByMinimumNetDebt(maxRedemptionRate);
  }
}

/** @internal */
export interface _VaultChangeWithFees<T> {
  params: T;
  newVault: Vault;
  fee: Decimal18;
}

/**
 * Ethers-based implementation of {@link @astrid-dao/lib-base#PopulatableAstridDao}.
 *
 * @public
 */
export class PopulatableEthersAstridDao
  implements
    PopulatableAstridDao<
      EthersTransactionReceipt,
      EthersTransactionResponse,
      EthersPopulatedTransaction
    >
{
  private readonly _readable: ReadableEthersAstridDao;

  constructor(readable: ReadableEthersAstridDao) {
    this._readable = readable;
  }

  private _wrapSimpleTransaction(
    rawPopulatedTransaction: EthersPopulatedTransaction
  ): PopulatedEthersAstridDaoTransaction<void> {
    return new PopulatedEthersAstridDaoTransaction(
      rawPopulatedTransaction,
      this._readable.connection,
      noDetails
    );
  }

  private _wrapVaultChangeWithFees<T>(
    params: T,
    rawPopulatedTransaction: EthersPopulatedTransaction,
    gasHeadroom?: number
  ): PopulatedEthersAstridDaoTransaction<_VaultChangeWithFees<T>> {
    const { borrowerOperations } = _getContracts(this._readable.connection);

    return new PopulatedEthersAstridDaoTransaction(
      rawPopulatedTransaction,
      this._readable.connection,

      ({ logs }) => {
        const [newVault] = borrowerOperations
          .extractEvents(logs, "VaultUpdated")
          .map(
            ({ args: { _coll, _debt } }) =>
              new Vault(decimalifyCollateralDecimal(_coll), decimalify18(_debt))
          );

        const [fee] = borrowerOperations
          .extractEvents(logs, "GAIBorrowingFeePaid")
          .map(({ args: { _GAIFee } }) => decimalify18(_GAIFee));

        return {
          params,
          newVault,
          fee
        };
      },

      gasHeadroom
    );
  }

  private async _wrapVaultClosure(
    rawPopulatedTransaction: EthersPopulatedTransaction
  ): Promise<PopulatedEthersAstridDaoTransaction<VaultClosureDetails>> {
    const { activePool, gaiToken } = _getContracts(this._readable.connection);

    return new PopulatedEthersAstridDaoTransaction(
      rawPopulatedTransaction,
      this._readable.connection,

      ({ logs, from: userAddress }) => {
        const [repayGai] = gaiToken
          .extractEvents(logs, "Transfer")
          .filter(({ args: { from, to } }) => from === userAddress && to === AddressZero)
          .map(({ args: { value } }) => decimalify18(value));

        const [withdrawCollateral] = activePool
          .extractEvents(logs, "COLSent")
          .filter(({ args: { _to } }) => _to === userAddress)
          .map(({ args: { _amount } }) => decimalify18(_amount));

        return {
          params: repayGai.nonZero ? { withdrawCollateral, repayGai } : { withdrawCollateral }
        };
      }
    );
  }

  private _wrapLiquidation(
    rawPopulatedTransaction: EthersPopulatedTransaction
  ): PopulatedEthersAstridDaoTransaction<LiquidationDetails> {
    const { vaultManager } = _getContracts(this._readable.connection);

    return new PopulatedEthersAstridDaoTransaction(
      rawPopulatedTransaction,
      this._readable.connection,

      ({ logs }) => {
        const liquidatedAddresses = vaultManager
          .extractEvents(logs, "VaultLiquidated")
          .map(({ args: { _borrower } }) => _borrower);

        const [totals] = vaultManager
          .extractEvents(logs, "Liquidation")
          .map(
            ({
              args: { _GAIGasCompensation, _collGasCompensation, _liquidatedColl, _liquidatedDebt }
            }) => ({
              collateralGasCompensation: decimalifyCollateralDecimal(_collGasCompensation),
              gaiGasCompensation: decimalify18(_GAIGasCompensation),
              totalLiquidated: new Vault(
                decimalifyCollateralDecimal(_liquidatedColl),
                decimalify18(_liquidatedDebt)
              )
            })
          );

        return {
          liquidatedAddresses,
          ...totals
        };
      }
    );
  }

  private _extractStabilityPoolGainsWithdrawalDetails(
    logs: Log[]
  ): StabilityPoolGainsWithdrawalDetails {
    const { stabilityPool } = _getContracts(this._readable.connection);

    const [newGaiDeposit] = stabilityPool
      .extractEvents(logs, "UserDepositChanged")
      .map(({ args: { _newDeposit } }) => decimalify18(_newDeposit));

    const [[collateralGain, gaiLoss]] = stabilityPool
      .extractEvents(logs, "COLGainWithdrawn")
      .map(({ args: { _COL, _GAILoss } }) => [
        decimalifyCollateralDecimal(_COL),
        decimalify18(_GAILoss)
      ]);

    const [gokReward] = stabilityPool
      .extractEvents(logs, "GOKPaidToDepositor")
      .map(({ args: { _GOK } }) => decimalify18(_GOK));

    return {
      gaiLoss,
      newGaiDeposit,
      collateralGain,
      gokReward
    };
  }

  private _wrapStabilityPoolGainsWithdrawal(
    rawPopulatedTransaction: EthersPopulatedTransaction
  ): PopulatedEthersAstridDaoTransaction<StabilityPoolGainsWithdrawalDetails> {
    return new PopulatedEthersAstridDaoTransaction(
      rawPopulatedTransaction,
      this._readable.connection,
      ({ logs }) => this._extractStabilityPoolGainsWithdrawalDetails(logs)
    );
  }

  private _wrapStabilityDepositTopup(
    change: { depositGai: Decimal18 },
    rawPopulatedTransaction: EthersPopulatedTransaction
  ): PopulatedEthersAstridDaoTransaction<StabilityDepositChangeDetails> {
    return new PopulatedEthersAstridDaoTransaction(
      rawPopulatedTransaction,
      this._readable.connection,

      ({ logs }) => ({
        ...this._extractStabilityPoolGainsWithdrawalDetails(logs),
        change
      })
    );
  }

  private async _wrapStabilityDepositWithdrawal(
    rawPopulatedTransaction: EthersPopulatedTransaction
  ): Promise<PopulatedEthersAstridDaoTransaction<StabilityDepositChangeDetails>> {
    const { stabilityPool, gokToken } = _getContracts(this._readable.connection);

    return new PopulatedEthersAstridDaoTransaction(
      rawPopulatedTransaction,
      this._readable.connection,

      ({ logs, from: userAddress }) => {
        const gainsWithdrawalDetails = this._extractStabilityPoolGainsWithdrawalDetails(logs);

        const [withdrawGai] = gokToken
          .extractEvents(logs, "Transfer")
          .filter(({ args: { from, to } }) => from === stabilityPool.address && to === userAddress)
          .map(({ args: { value } }) => decimalify18(value));

        return {
          ...gainsWithdrawalDetails,
          change: { withdrawGai, withdrawAllGai: gainsWithdrawalDetails.newGaiDeposit.isZero }
        };
      }
    );
  }

  private _wrapCollateralGainTransfer(
    rawPopulatedTransaction: EthersPopulatedTransaction
  ): PopulatedEthersAstridDaoTransaction<CollateralGainTransferDetails> {
    const { borrowerOperations } = _getContracts(this._readable.connection);

    return new PopulatedEthersAstridDaoTransaction(
      rawPopulatedTransaction,
      this._readable.connection,

      ({ logs }) => {
        const [newVault] = borrowerOperations
          .extractEvents(logs, "VaultUpdated")
          .map(
            ({ args: { _coll, _debt } }) =>
              new Vault(decimalifyCollateralDecimal(_coll), decimalify18(_debt))
          );

        return {
          ...this._extractStabilityPoolGainsWithdrawalDetails(logs),
          newVault
        };
      }
    );
  }

  private async _findHintsForNominalCollateralRatio(
    nominalCollateralRatio: CollateralDecimal,
    ownAddress?: string,
    externalContracts?: { sortedVaults: SortedVaults; hintHelpers: HintHelpers }
  ): Promise<[string, string]> {
    const { sortedVaults: sortedVaults, hintHelpers } = externalContracts
      ? externalContracts
      : _getContracts(this._readable.connection);
    const numberOfVaults = await this._readable.getNumberOfVaults();

    if (!numberOfVaults) {
      return [AddressZero, AddressZero];
    }

    if (nominalCollateralRatio.infinite) {
      return [AddressZero, await sortedVaults.getFirst()];
    }

    const totalNumberOfTrials = Math.ceil(10 * Math.sqrt(numberOfVaults));
    const [firstTrials, ...restOfTrials] = generateTrials(totalNumberOfTrials);

    const collectApproxHint = (
      {
        latestRandomSeed,
        results
      }: {
        latestRandomSeed: BigNumberish;
        results: { diff: BigNumber; hintAddress: string }[];
      },
      numberOfTrials: number
    ) =>
      hintHelpers
        .getApproxHint(nominalCollateralRatio.hex, numberOfTrials, latestRandomSeed)
        .then(({ latestRandomSeed, ...result }) => ({
          latestRandomSeed,
          results: [...results, result]
        }));

    const { results } = await restOfTrials.reduce(
      (p, numberOfTrials) => p.then(state => collectApproxHint(state, numberOfTrials)),
      collectApproxHint({ latestRandomSeed: randomInteger(), results: [] }, firstTrials)
    );

    const { hintAddress } = results.reduce((a, b) => (a.diff.lt(b.diff) ? a : b));

    let [prev, next] = await sortedVaults.findInsertPosition(
      nominalCollateralRatio.hex,
      hintAddress,
      hintAddress
    );

    if (ownAddress) {
      // In the case of reinsertion, the address of the Vault being reinserted is not a usable hint,
      // because it is deleted from the list before the reinsertion.
      // "Jump over" the Vault to get the proper hint.
      if (prev === ownAddress) {
        prev = await sortedVaults.getPrev(prev);
      } else if (next === ownAddress) {
        next = await sortedVaults.getNext(next);
      }
    }

    // Don't use `address(0)` as hint as it can result in huge gas cost.
    // (See https://github.com/liquity/dev/issues/600).
    if (prev === AddressZero) {
      prev = next;
    } else if (next === AddressZero) {
      next = prev;
    }

    return [prev, next];
  }

  async findHints(
    vault: Vault,
    ownAddress?: string,
    externalContracts?: { sortedVaults: SortedVaults; hintHelpers: HintHelpers }
  ): Promise<[string, string]> {
    return this._findHints(vault, ownAddress, externalContracts);
  }

  private async _findHints(
    vault: Vault,
    ownAddress?: string,
    externalContracts?: { sortedVaults: SortedVaults; hintHelpers: HintHelpers }
  ): Promise<[string, string]> {
    if (vault instanceof VaultWithPendingRedistribution) {
      throw new Error("Rewards must be applied to this Vault");
    }

    return this._findHintsForNominalCollateralRatio(
      vault._nominalCollateralRatio,
      ownAddress,
      externalContracts
    );
  }

  private async _findRedemptionHints(
    amount: Decimal18
  ): Promise<
    [
      truncatedAmount: Decimal18,
      firstRedemptionHint: string,
      partialRedemptionUpperHint: string,
      partialRedemptionLowerHint: string,
      partialRedemptionHintNICR: BigNumber
    ]
  > {
    const { hintHelpers } = _getContracts(this._readable.connection);
    const descaledPrice = await this._readable.getPrice();
    const price = convertDisplayPriceToContractAcceptablePrice(descaledPrice);

    const { firstRedemptionHint, partialRedemptionHintNICR, truncatedGAIamount } =
      await hintHelpers.getRedemptionHints(amount.hex, price.hex, _redeemMaxIterations);

    const [partialRedemptionUpperHint, partialRedemptionLowerHint] =
      partialRedemptionHintNICR.isZero()
        ? [AddressZero, AddressZero]
        : await this._findHintsForNominalCollateralRatio(
            decimalifyCollateralDecimal(partialRedemptionHintNICR)
            // XXX: if we knew the partially redeemed Vault's address, we'd pass it here
          );

    return [
      decimalify18(truncatedGAIamount),
      firstRedemptionHint,
      partialRedemptionUpperHint,
      partialRedemptionLowerHint,
      partialRedemptionHintNICR
    ];
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.openVault} */
  async openVault(
    params: VaultCreationParams<Decimalish, Decimalish>,
    maxBorrowingRateOrOptionalParams?: Decimalish | BorrowingOperationOptionalParams,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<VaultCreationDetails>> {
    const { borrowerOperations } = _getContracts(this._readable.connection);

    const normalizedParams = _normalizeVaultCreation(params);
    const { depositCollateral, borrowGai } = normalizedParams;

    const [fees, blockTimestamp, total, price] = await Promise.all([
      this._readable._getFeesFactory(),
      this._readable._getBlockTimestamp(),
      this._readable.getTotal(),
      this._readable.getPrice()
    ]);

    const recoveryMode = total.collateralRatioIsBelowCritical(price);

    const decayBorrowingRate = (seconds: number) =>
      fees(blockTimestamp + seconds, recoveryMode).borrowingRate();

    const currentBorrowingRate = decayBorrowingRate(0);
    const newVault = Vault.create(normalizedParams, currentBorrowingRate);
    const hints = await this._findHints(newVault);

    const { maxBorrowingRate, borrowingFeeDecayToleranceMinutes } =
      normalizeBorrowingOperationOptionalParams(
        maxBorrowingRateOrOptionalParams,
        currentBorrowingRate
      );

    const txParams = (borrowGai: Decimal18): Parameters<typeof borrowerOperations.openVault> => {
      return [
        maxBorrowingRate.hex,
        depositCollateral.hex,
        borrowGai.hex,
        ...hints,
        { ...overrides }
      ];
    };

    let gasHeadroom: number | undefined;

    if (overrides?.gasLimit === undefined) {
      const decayedBorrowingRate = decayBorrowingRate(60 * borrowingFeeDecayToleranceMinutes);
      const decayedVault = Vault.create(normalizedParams, decayedBorrowingRate);
      const { borrowGai: borrowGaiSimulatingDecay } = Vault.recreate(
        decayedVault,
        currentBorrowingRate
      );

      if (decayedVault.debt.lt(GAI_MINIMUM_DEBT)) {
        throw new Error(
          `Vault's debt might fall below ${GAI_MINIMUM_DEBT} ` +
            `within ${borrowingFeeDecayToleranceMinutes} minutes`
        );
      }

      const [gasNow, gasLater] = await Promise.all([
        borrowerOperations.estimateGas.openVault(...txParams(borrowGai)),
        borrowerOperations.estimateGas.openVault(...txParams(borrowGaiSimulatingDecay))
      ]);

      const gasLimit = addGasForBaseRateUpdate(borrowingFeeDecayToleranceMinutes)(
        bigNumberMax(addGasForPotentialListTraversal(gasNow), gasLater)
      );

      gasHeadroom = gasLimit.sub(gasNow).toNumber();
      overrides = {
        ...overrides,
        gasLimit: gasLimit.mul(BigNumber.from("15")).div(BigNumber.from("10"))
      };
    }

    return this._wrapVaultChangeWithFees(
      normalizedParams,
      await borrowerOperations.populateTransaction.openVault(...txParams(borrowGai)),
      gasHeadroom
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.closeVault} */
  async closeVault(
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<VaultClosureDetails>> {
    const { borrowerOperations } = _getContracts(this._readable.connection);

    return this._wrapVaultClosure(
      await borrowerOperations.estimateAndPopulate.closeVault({ ...overrides }, id)
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.depositBaseCollateral} */
  async depositBaseCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<BaseCollateralDepositChangeDetails>> {
    const { WASTR } = _getContracts(this._readable.connection);
    const { depositBaseCollateral } = _normalizeERC20TokenConversionChange({
      depositBaseCollateral: amount
    });
    const value = depositBaseCollateral ?? Decimal18.ZERO;

    return this._wrapERC20DespositTransaction(
      {
        depositBaseCollateral: value
      },
      await WASTR.estimateAndPopulate.deposit(
        {
          value: value.hex,
          ...overrides
        },
        addGasForERC20TokenConversion
      )
    );
  }

  private _wrapERC20DespositTransaction(
    change: { depositBaseCollateral: Decimal18 },
    rawPopulatedTransaction: EthersPopulatedTransaction
  ): PopulatedEthersAstridDaoTransaction<BaseCollateralDepositChangeDetails> {
    return new PopulatedEthersAstridDaoTransaction<BaseCollateralDepositChangeDetails>(
      rawPopulatedTransaction,
      this._readable.connection,
      () => {
        return {
          change
        };
      }
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.withdrawToBaseCollateral} */
  async withdrawToBaseCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<BaseCollateralDepositChangeDetails>> {
    const { WASTR } = _getContracts(this._readable.connection);
    const { withdrawToBaseCollateral } = _normalizeERC20TokenConversionChange({
      withdrawToBaseCollateral: amount
    });

    const value = withdrawToBaseCollateral ?? Decimal18.ZERO;

    return this._wrapERC20WithdrawalTransaction(
      { withdrawToBaseCollateral: value },
      await WASTR.estimateAndPopulate.withdraw(
        { ...overrides },
        addGasForERC20TokenConversion,
        value.hex
      )
    );
  }

  private _wrapERC20WithdrawalTransaction(
    change: { withdrawToBaseCollateral: Decimal18 },
    rawPopulatedTransaction: EthersPopulatedTransaction
  ): PopulatedEthersAstridDaoTransaction<BaseCollateralDepositChangeDetails> {
    return new PopulatedEthersAstridDaoTransaction<BaseCollateralDepositChangeDetails>(
      rawPopulatedTransaction,
      this._readable.connection,
      () => {
        return {
          change
        };
      }
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.depositCollateral} */
  depositCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<VaultAdjustmentDetails>> {
    return this.adjustVault({ depositCollateral: amount }, undefined, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.withdrawCollateral} */
  withdrawCollateral(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<VaultAdjustmentDetails>> {
    return this.adjustVault({ withdrawCollateral: amount }, undefined, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.borrowGai} */
  borrowGai(
    amount: Decimalish,
    maxBorrowingRate?: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<VaultAdjustmentDetails>> {
    return this.adjustVault({ borrowGai: amount }, maxBorrowingRate, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.repayGai} */
  repayGai(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<VaultAdjustmentDetails>> {
    return this.adjustVault({ repayGai: amount }, undefined, overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.adjustVault} */
  async adjustVault(
    params: VaultAdjustmentParams<Decimalish, Decimalish>,
    maxBorrowingRateOrOptionalParams?: Decimalish | BorrowingOperationOptionalParams,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<VaultAdjustmentDetails>> {
    const address = _requireAddress(this._readable.connection, overrides);
    const { borrowerOperations } = _getContracts(this._readable.connection);

    const normalizedParams = _normalizeVaultAdjustment(params);
    const { depositCollateral, withdrawCollateral, borrowGai, repayGai } = normalizedParams;

    const [vault, feeVars] = await Promise.all([
      this._readable.getVault(address),
      borrowGai &&
        promiseAllValues({
          fees: this._readable._getFeesFactory(),
          blockTimestamp: this._readable._getBlockTimestamp(),
          total: this._readable.getTotal(),
          price: this._readable.getPrice()
        })
    ]);

    const decayBorrowingRate = (seconds: number) =>
      feeVars
        ?.fees(
          feeVars.blockTimestamp + seconds,
          feeVars.total.collateralRatioIsBelowCritical(feeVars.price)
        )
        .borrowingRate();

    const currentBorrowingRate = decayBorrowingRate(0);
    const adjustedVault = vault.adjust(normalizedParams, currentBorrowingRate);
    const hints = await this._findHints(adjustedVault, address);

    const { maxBorrowingRate, borrowingFeeDecayToleranceMinutes } =
      normalizeBorrowingOperationOptionalParams(
        maxBorrowingRateOrOptionalParams,
        currentBorrowingRate
      );

    const txParams = (borrowGai?: Decimal18): Parameters<typeof borrowerOperations.adjustVault> => {
      const collateral = depositCollateral ?? withdrawCollateral ?? CollateralDecimal.ZERO;

      return [
        maxBorrowingRate.hex,
        collateral.hex,
        Boolean(depositCollateral),
        (borrowGai ?? repayGai ?? Decimal18.ZERO).hex,
        Boolean(borrowGai),
        ...hints,
        { ...overrides }
      ];
    };

    let gasHeadroom: number | undefined;

    if (overrides?.gasLimit === undefined) {
      const decayedBorrowingRate = decayBorrowingRate(60 * borrowingFeeDecayToleranceMinutes);
      const decayedVault = vault.adjust(normalizedParams, decayedBorrowingRate);
      const { borrowGai: borrowGaiSimulatingDecay } = vault.adjustTo(
        decayedVault,
        currentBorrowingRate
      );

      if (decayedVault.debt.lt(GAI_MINIMUM_DEBT)) {
        throw new Error(
          `Vault's debt might fall below ${GAI_MINIMUM_DEBT} ` +
            `within ${borrowingFeeDecayToleranceMinutes} minutes`
        );
      }

      const [gasNow, gasLater] = await Promise.all([
        borrowerOperations.estimateGas.adjustVault(...txParams(borrowGai)),
        borrowGai &&
          borrowerOperations.estimateGas.adjustVault(...txParams(borrowGaiSimulatingDecay))
      ]);

      let gasLimit = bigNumberMax(addGasForPotentialListTraversal(gasNow), gasLater);

      if (borrowGai) {
        gasLimit = addGasForBaseRateUpdate(borrowingFeeDecayToleranceMinutes)(gasLimit);
      }

      gasHeadroom = gasLimit.sub(gasNow).toNumber();
      overrides = {
        ...overrides,
        gasLimit
      };
    }

    return this._wrapVaultChangeWithFees(
      normalizedParams,
      await borrowerOperations.populateTransaction.adjustVault(...txParams(borrowGai)),
      gasHeadroom
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.claimCollateralSurplus} */
  async claimCollateralSurplus(
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<void>> {
    const { borrowerOperations } = _getContracts(this._readable.connection);

    return this._wrapSimpleTransaction(
      await borrowerOperations.estimateAndPopulate.claimCollateral({ ...overrides }, id)
    );
  }

  /** @internal */
  async setPrice(
    price: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<void>> {
    // const { priceFeed } = _getContracts(this._readable.connection);

    // if (!_priceFeedIsTestnet(priceFeed)) {
      throw new Error("setPrice() unavailable on this deployment of AstridDAO");
    // }

    // return this._wrapSimpleTransaction(
    //   await priceFeed.estimateAndPopulate.setPrice({ ...overrides }, id, Decimal18.from(price).hex)
    // );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.liquidate} */
  async liquidate(
    address: string | string[],
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<LiquidationDetails>> {
    const { vaultManager: vaultManager } = _getContracts(this._readable.connection);

    if (Array.isArray(address)) {
      return this._wrapLiquidation(
        await vaultManager.estimateAndPopulate.batchLiquidateVaults(
          { ...overrides },
          addGasForGOKIssuance,
          address
        )
      );
    } else {
      return this._wrapLiquidation(
        await vaultManager.estimateAndPopulate.liquidate(
          { ...overrides },
          addGasForGOKIssuance,
          address
        )
      );
    }
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.liquidateUpTo} */
  async liquidateUpTo(
    maximumNumberOfVaultsToLiquidate: number,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<LiquidationDetails>> {
    const { vaultManager: vaultManager } = _getContracts(this._readable.connection);

    return this._wrapLiquidation(
      await vaultManager.estimateAndPopulate.liquidateVaults(
        { ...overrides },
        addGasForGOKIssuance,
        maximumNumberOfVaultsToLiquidate
      )
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.depositGaiInStabilityPool} */
  async depositGaiInStabilityPool(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<StabilityDepositChangeDetails>> {
    const { stabilityPool } = _getContracts(this._readable.connection);
    const depositGai = Decimal18.from(amount);

    return this._wrapStabilityDepositTopup(
      { depositGai },
      await stabilityPool.estimateAndPopulate.provideToSP(
        { ...overrides },
        addGasForGOKIssuance,
        depositGai.hex
      )
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.withdrawGaiFromStabilityPool} */
  async withdrawGaiFromStabilityPool(
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<StabilityDepositChangeDetails>> {
    const { stabilityPool } = _getContracts(this._readable.connection);

    return this._wrapStabilityDepositWithdrawal(
      await stabilityPool.estimateAndPopulate.withdrawFromSP(
        { ...overrides },
        addGasForGOKIssuance,
        Decimal18.from(amount).hex
      )
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.withdrawGainsFromStabilityPool} */
  async withdrawGainsFromStabilityPool(
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<StabilityPoolGainsWithdrawalDetails>> {
    const { stabilityPool } = _getContracts(this._readable.connection);

    return this._wrapStabilityPoolGainsWithdrawal(
      await stabilityPool.estimateAndPopulate.withdrawFromSP(
        { ...overrides },
        addGasForGOKIssuance,
        Decimal18.ZERO.hex
      )
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.transferCollateralGainToVault} */
  async transferCollateralGainToVault(
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<CollateralGainTransferDetails>> {
    const address = _requireAddress(this._readable.connection, overrides);
    const { stabilityPool } = _getContracts(this._readable.connection);

    const [initialVault, stabilityDeposit] = await Promise.all([
      this._readable.getVault(address),
      this._readable.getStabilityDeposit(address)
    ]);

    const finalVault = initialVault.addCollateral(stabilityDeposit.collateralGain);

    return this._wrapCollateralGainTransfer(
      await stabilityPool.estimateAndPopulate.withdrawCOLGainToVault(
        { ...overrides },
        compose(addGasForPotentialListTraversal, addGasForGOKIssuance),
        ...(await this._findHints(finalVault, address))
      )
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.sendGai} */
  async sendGai(
    toAddress: string,
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<void>> {
    const { gaiToken } = _getContracts(this._readable.connection);

    return this._wrapSimpleTransaction(
      await gaiToken.estimateAndPopulate.transfer(
        { ...overrides },
        id,
        toAddress,
        Decimal18.from(amount).hex
      )
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.sendGOK} */
  async sendGOK(
    toAddress: string,
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<void>> {
    const { gokToken } = _getContracts(this._readable.connection);

    return this._wrapSimpleTransaction(
      await gokToken.estimateAndPopulate.transfer(
        { ...overrides },
        id,
        toAddress,
        Decimal18.from(amount).hex
      )
    );
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.redeemGai} */
  async redeemGai(
    amount: Decimalish,
    maxRedemptionRate?: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersRedemption> {
    const { vaultManager: vaultManager } = _getContracts(this._readable.connection);
    const attemptedGaiAmount = Decimal18.from(amount);

    const [fees, total, [truncatedAmount, firstRedemptionHint, ...partialHints]] = await Promise.all(
      [
        this._readable.getFees(),
        this._readable.getTotal(),
        this._findRedemptionHints(attemptedGaiAmount)
      ]
    );

    if (truncatedAmount.isZero) {
      throw new Error(`redeemGai: amount too low to redeem (try at least ${GAI_MINIMUM_NET_DEBT})`);
    }

    const defaultMaxRedemptionRate = (amount: Decimal18) =>
      Decimal18.min(
        fees.redemptionRate(amount.div(total.debt)).add(defaultRedemptionRateSlippageTolerance),
        Decimal18.ONE
      );

    const populateRedemption = async (
      attemptedGaiAmount: Decimal18,
      maxRedemptionRate?: Decimalish,
      truncatedAmount: Decimal18 = attemptedGaiAmount,
      partialHints: [string, string, BigNumberish] = [AddressZero, AddressZero, 0]
    ): Promise<PopulatedEthersRedemption> => {
      const maxRedemptionRateOrDefault =
        maxRedemptionRate !== undefined
          ? Decimal18.from(maxRedemptionRate)
          : defaultMaxRedemptionRate(truncatedAmount);

      // TODO remove this test code
      // console.log("------------------------------");
      // console.log("funciton name", "redeemCollateral");
      // console.log("_GAIamount", attemptedGaiAmount.prettify(4));
      // console.log("_firstRedemptionHint", firstRedemptionHint);
      // console.log("partialHints", partialHints);
      // console.log("_overrides", { ...overrides });
      // console.log("truncatedAmount", truncatedAmount.hex);
      // console.log("_redeemMaxIterations", _redeemMaxIterations);
      // console.log("maxRedemptionRateOrDefault.hex", maxRedemptionRateOrDefault.hex);
      // console.log("------------------------------");

      return new PopulatedEthersRedemption(
        // await vaultManager.populateTransaction.redeemCollateral(
        await vaultManager.estimateAndPopulate.redeemCollateral(
          { ...overrides },
          addGasForBaseRateUpdate(),
          truncatedAmount.hex,
          firstRedemptionHint,
          ...partialHints,
          _redeemMaxIterations,
          maxRedemptionRateOrDefault.hex
          // {
          //   gasPrice: overrides?.gasPrice,
          //   gasLimit: BigNumber.from("0x0799b1")
          // }
        ),

        this._readable.connection,
        attemptedGaiAmount,
        truncatedAmount,

        truncatedAmount.lt(attemptedGaiAmount)
          ? newMaxRedemptionRate =>
              populateRedemption(
                truncatedAmount.add(GAI_MINIMUM_NET_DEBT),
                newMaxRedemptionRate ?? maxRedemptionRate
              )
          : undefined
      );
    };

    return populateRedemption(attemptedGaiAmount, maxRedemptionRate, truncatedAmount, partialHints);
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.stakeGOK} */
  async stakeGOK(
    amount: Decimalish,
    lockedUntil: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<void>> {
    const { gokStaking } = _getContracts(this._readable.connection);

    const currentTimeInSecondsWithBuffer = getCurrentTimeInSecondsWithBuffer(10);
    const stakeLockedUntil = Decimal18.from(lockedUntil).add(currentTimeInSecondsWithBuffer);

    const rawPopulatedTransaction = await gokStaking.estimateAndPopulate.stakeLocked(
      { ...overrides },
      id,
      Decimal18.from(amount).hex,
      stakeLockedUntil.toString()
    );

    return this._wrapSimpleTransaction(rawPopulatedTransaction);
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.unstakeGOK} */
  async unstakeGOK(
    id: BigNumber,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<void>> {
    const { gokStaking } = _getContracts(this._readable.connection);

    const tx = await gokStaking.estimateAndPopulate.unstakeLocked(
      { ...overrides },
      addGasForGOKIssuance,
      // DO NOT use id.toHexString(), which stupidly add padding zeros to the beginning of number...
      // For example, BigNumber.from(1).toHexString() gives "0x01" instead of "0x1", which is unrecognized by Ethers.js.
      convertBigNumberToEthersAcceptedHexString(id)
    );

    return this._wrapSimpleTransaction(tx);
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.withdrawGainsFromStaking} */
  withdrawGainsFromStaking(
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<void>> {
    return this.unstakeGOK(BigNumber.from(0), overrides);
  }

  /** {@inheritDoc @astrid-dao/lib-base#PopulatableAstridDao.approveWrappedTokens} */
  async approveWrappedTokens(
    allowance?: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<PopulatedEthersAstridDaoTransaction<void>> {
    const { colToken, borrowerOperations } = _getContracts(this._readable.connection);

    return this._wrapSimpleTransaction(
      await colToken.estimateAndPopulate.approve(
        { ...overrides },
        id,
        borrowerOperations.address,
        CollateralDecimal.from(allowance ?? CollateralDecimal.INFINITY).hex
      )
    );
  }
}
