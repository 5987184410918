import React, { useState, useRef } from "react";

import { useLocation } from "react-router-dom";
import { Button, Container, Flex, Text } from "theme-ui";

import { useTheme } from "../theme";
import { Icon } from "./Icon";
import { Link } from "./Link";
import { Logo } from "./LogoArea";
import { SelectCollateral } from "./SelectCollateral";
import { SideBarExternalLinks } from "./SideBar/SideBarExternalLinks";
import { SideBarNav } from "./SideBar/SideBarNav";

const logoHeight = "37px";

export const SideNav: React.FC = () => {
  const { theme } = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const overlay = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const currentPath = location.pathname;

  if (!isVisible) {
    return (
      <Button
        variant="icon"
        onClick={() => setIsVisible(true)}
        sx={{ display: ["block", "block", "none"] }}
      >
        <Icon name="bars" size="lg" color={theme.colors.W100} />
      </Button>
    );
  }
  return (
    <Container
      variant="mobileOverlay"
      ref={overlay}
      onClick={e => {
        if (e.target === overlay.current) {
          setIsVisible(false);
        }
      }}
    >
      <Flex variant="layout.sidenav">
        <Container variant="center" sx={{ justifyContent: "space-between", px: 3, pt: 6 }}>
          <Logo height={logoHeight} />
          <Button variant="icon" onClick={() => setIsVisible(false)}>
            <Icon name="times" style={{ fontSize: "24px" }} color={theme.colors.W100} />
          </Button>
        </Container>

        {/* <Flex
          as="nav"
          sx={{ flexDirection: "column", width: "100%", alignItems: "center", gap: "20px" }}
          onClick={() => setIsVisible(false)}
        >
          <Link to="/">Dashboard</Link>
          <Link to="/vault">Vault</Link>
          <Link to="/stability-farm">Stability Farm</Link>
          <Link to="/atid-staking">ATID Staking</Link>
          <Link to="/liquidation">Liquidation</Link>
          <Link to="/redemption">Redemption</Link>
          <Link to="/statistics">Statistics</Link>
          <Link to="/token-claiming">Token Claiming</Link>
        </Flex> */}

        <Flex variant="layout.sidebar">
          <Flex sx={{ flexDirection: "column", width: "100%" }}>
            <Link to="/dashboard">
              <Flex
                sx={{
                  bg: currentPath === "/dashboard" ? "#4B92FF" : "transparent",
                  borderRadius: "8px",
                  width: "100%",
                  height: "48px",
                  alignItems: "center",
                  pl: 3
                }}
                onClick={() => setIsVisible(false)}
              >
                <Text
                  variant="mediumH3"
                  sx={{
                    color: currentPath === "/" ? "W100" : "W60"
                  }}
                >
                  Dashboard
                </Text>
              </Flex>
            </Link>

            <SelectCollateral />

            <SideBarNav currentPath={currentPath} onClick={() => setIsVisible(false)} />
          </Flex>

          {/* <SideBarExternalLinks currentPath={currentPath} onClick={() => setIsVisible(false)} /> */}
        </Flex>
      </Flex>
    </Container>
  );
};
