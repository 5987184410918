import { AddressZero } from "@ethersproject/constants";

import {
  Decimal18,
  AstridDaoStoreState,
  AstridDaoStoreBaseState,
  VaultWithPendingRedistribution,
  StabilityDeposit,
  GOKStake,
  AstridDaoStore,
  Fees,
  CollateralDecimal,
  Vault,
  getCollateralOracle,
  SupportedCollaterals
} from "@astrid-dao/lib-base";

import { decimalify18, decimalifyCollateralDecimal, promiseAllValues } from "./_utils";
import { ReadableEthersAstridDao } from "./ReadableEthersAstridDao";
import { EthersAstridDaoConnection, _getProvider } from "./EthersAstridDaoConnection";
import { EthersCallOverrides, EthersProvider } from "./types";
import { ContractCallContext, ContractCallResults, Multicall } from "./libs/multicall/";
import { getAbi } from "./contracts";
import { BigNumber } from "@ethersproject/bignumber";
import { getTotalRedistributed, getVault, getVaultBeforRedistribution } from "./DaoStoreHelper";

/**
 * Extra state added to {@link @astrid-dao/lib-base#AstridDaoStoreState} by
 * {@link BlockPolledAstridDaoStore}.
 *
 * @public
 */
export interface BlockPolledAstridDaoStoreExtraState {
  /**
   * Number of block that the store state was fetched from.
   *
   * @remarks
   * May be undefined when the store state is fetched for the first time.
   */
  blockTag?: number;

  /**
   * Timestamp of latest block (number of seconds since epoch).
   */
  blockTimestamp: number;

  /** @internal */
  _feesFactory: (blockTimestamp: number, recoveryMode: boolean) => Fees;
}

/**
 * The type of {@link BlockPolledAstridDaoStore}'s
 * {@link @astrid-dao/lib-base#AstridDaoStore.state | state}.
 *
 * @public
 */
export type BlockPolledAstridDaoStoreState =
  AstridDaoStoreState<BlockPolledAstridDaoStoreExtraState>;

/**
 * Ethers-based {@link @astrid-dao/lib-base#AstridDaoStore} that updates state whenever there's a new
 * block.
 *
 * @public
 */
export class BlockPolledAstridDaoStore extends AstridDaoStore<BlockPolledAstridDaoStoreExtraState> {
  readonly connection: EthersAstridDaoConnection;

  private readonly _readable: ReadableEthersAstridDao;
  private readonly _provider: EthersProvider;

  constructor(readable: ReadableEthersAstridDao) {
    super();

    this.connection = readable.connection;
    this._readable = readable;
    this._provider = _getProvider(readable.connection);
  }

  private async _getRiskiestVaultBeforeRedistribution(
    overrides?: EthersCallOverrides
  ): Promise<VaultWithPendingRedistribution> {
    const riskiestVaults = await this._readable.getVaults(
      { first: 1, sortedBy: "ascendingCollateralRatio", beforeRedistribution: true },
      overrides
    );

    if (riskiestVaults.length === 0) {
      return new VaultWithPendingRedistribution(AddressZero, "nonExistent");
    }

    return riskiestVaults[0];
  }

  private async _get(
    blockTag?: number
  ): Promise<[baseState: AstridDaoStoreBaseState, extraState: BlockPolledAstridDaoStoreExtraState]> {
    const { userAddress, provider, _isDev, addresses, collateralName, _priceFeedIsTestnet } =
      this.connection;

    const batchFetchedValue = {
      numberOfVaults: 0,
      totalRedistributed: new Vault(CollateralDecimal.ZERO, Decimal18.ZERO),
      total: new Vault(CollateralDecimal.ZERO, Decimal18.ZERO),
      gaiInStabilityPool: Decimal18.ZERO,
      totalWeightedStakedGOK: Decimal18.ZERO,
      totalUnweightedStakedGOK: Decimal18.ZERO,
      // related to user
      gaiBalance: Decimal18.ZERO,
      xcGaiBalance: Decimal18.ZERO,
      gokBalance: Decimal18.ZERO,
      wrappedTokenBalance: CollateralDecimal.ZERO,
      wrappedTokenAllowance: CollateralDecimal.ZERO,
      xcGaiAllowance: CollateralDecimal.ZERO,
      gaiAllowance: CollateralDecimal.ZERO,
      collateralSurplusBalance: CollateralDecimal.ZERO,
      vaultBeforeRedistribution: new VaultWithPendingRedistribution(AddressZero, "nonExistent"),
      stabilityDeposit: new StabilityDeposit(
        Decimal18.ZERO,
        Decimal18.ZERO,
        CollateralDecimal.ZERO,
        Decimal18.ZERO
      )
    };

    const abi = getAbi(
      _isDev,
      _priceFeedIsTestnet,
      getCollateralOracle(_isDev).PYTH.includes((collateralName as SupportedCollaterals) ?? "")
    );
    const multicall = new Multicall({
      ethersProvider: provider,
      tryAggregate: true,
      multicallCustomContractAddress: _isDev
        ? "0xe12FA0a01107959d8db1c96c16D89c31E19dc55A"
        : "0xcA11bde05977b3631167028862bE2a173976CA11"
    });

    const contractCallContext: ContractCallContext[] = [
      // VaultManager
      {
        reference: "VaultManager",
        contractAddress: addresses["vaultManager"],
        abi: abi.vaultManager,
        calls: [
          {
            reference: "getVaultOwnersCount",
            methodName: "getVaultOwnersCount",
            methodParameters: []
          },
          {
            reference: "L_GAIDebt",
            methodName: "L_GAIDebt",
            methodParameters: []
          },
          {
            reference: "L_COL",
            methodName: "L_COL",
            methodParameters: []
          }
        ]
      },
      // ActivePool
      {
        reference: "ActivePool",
        contractAddress: addresses["activePool"],
        abi: abi.activePool,
        calls: [
          {
            reference: "getGAIDebt",
            methodName: "getGAIDebt",
            methodParameters: []
          },
          {
            reference: "getCOL",
            methodName: "getCOL",
            methodParameters: []
          }
        ]
      },
      // DefaultPool
      {
        reference: "DefaultPool",
        contractAddress: addresses["defaultPool"],
        abi: abi.defaultPool,
        calls: [
          {
            reference: "getGAIDebt",
            methodName: "getGAIDebt",
            methodParameters: []
          },
          {
            reference: "getCOL",
            methodName: "getCOL",
            methodParameters: []
          }
        ]
      },
      // StabilityPool
      {
        reference: "StabilityPool",
        contractAddress: addresses["stabilityPool"],
        abi: abi.stabilityPool,
        calls: [
          {
            reference: "getTotalGAIDeposits",
            methodName: "getTotalGAIDeposits",
            methodParameters: []
          }
        ]
      },
      // GokStaking
      {
        reference: "GokStaking",
        contractAddress: addresses["gokStaking"],
        abi: abi.gokStaking,
        calls: [
          {
            reference: "totalWeightedGOKStaked",
            methodName: "totalWeightedGOKStaked",
            methodParameters: []
          },
          {
            reference: "totalUnweightedGOKStaked",
            methodName: "totalUnweightedGOKStaked",
            methodParameters: []
          }
        ]
      }
    ];

    const results: ContractCallResults = await multicall.call(contractCallContext);
    console.log("ContractCallResults", results);

    // [Done] numberOfVaults
    const numberOfVaults = BigNumber.from(
      results.results["VaultManager"].callsReturnContext.find(
        item => item.reference === "getVaultOwnersCount"
      )?.returnValues?.[0] ?? 0
    );
    batchFetchedValue.numberOfVaults = numberOfVaults.toNumber();
    // [Done] totalRedistributed
    const L_GAIDebt = BigNumber.from(
      results.results["VaultManager"].callsReturnContext.find(item => item.reference === "L_GAIDebt")
        ?.returnValues?.[0] ?? 0
    );

    const L_COL = BigNumber.from(
      results.results["VaultManager"].callsReturnContext.find(item => item.reference === "L_COL")
        ?.returnValues?.[0] ?? 0
    );

    batchFetchedValue.totalRedistributed = getTotalRedistributed(L_GAIDebt, L_COL);

    // [Done] total
    const activePoolDebt = BigNumber.from(
      results.results["ActivePool"].callsReturnContext.find(item => item.reference === "getGAIDebt")
        ?.returnValues?.[0] ?? 0
    );
    const activePoolCol = BigNumber.from(
      results.results["ActivePool"].callsReturnContext.find(item => item.reference === "getCOL")
        ?.returnValues?.[0] ?? 0
    );
    const activeVault = getVault(activePoolCol, activePoolDebt);

    const defaultPoolDebt = BigNumber.from(
      results.results["DefaultPool"].callsReturnContext.find(item => item.reference === "getGAIDebt")
        ?.returnValues?.[0] ?? 0
    );
    const defaultPoolCol = BigNumber.from(
      results.results["DefaultPool"].callsReturnContext.find(item => item.reference === "getCOL")
        ?.returnValues?.[0] ?? 0
    );
    const defaultVault = getVault(defaultPoolCol, defaultPoolDebt);
    batchFetchedValue.total = activeVault.add(defaultVault);

    // [Done] gaiInStabilityPool
    const gaiInStabilityPool = BigNumber.from(
      results.results["StabilityPool"].callsReturnContext.find(
        item => item.reference === "getTotalGAIDeposits"
      )?.returnValues?.[0] ?? 0
    );
    batchFetchedValue.gaiInStabilityPool = decimalify18(gaiInStabilityPool);

    // [Done] totalWeightedStakedGOK
    const totalWeightedStakedGOK = BigNumber.from(
      results.results["GokStaking"].callsReturnContext.find(
        item => item.reference === "totalWeightedGOKStaked"
      )?.returnValues?.[0] ?? 0
    );
    batchFetchedValue.totalWeightedStakedGOK = decimalify18(totalWeightedStakedGOK);

    // [Done] totalWeightedStakedGOK
    const totalUnweightedStakedGOK = BigNumber.from(
      results.results["GokStaking"].callsReturnContext.find(
        item => item.reference === "totalUnweightedGOKStaked"
      )?.returnValues?.[0] ?? 0
    );
    batchFetchedValue.totalUnweightedStakedGOK = decimalify18(totalUnweightedStakedGOK);

    if (userAddress) {
      const userContractCallContext: ContractCallContext[] = [
        // GaiToken
        {
          reference: "GaiToken",
          abi: abi.gaiToken,
          contractAddress: addresses["gaiToken"],
          calls: [
            {
              reference: "balanceOf",
              methodName: "balanceOf",
              methodParameters: [userAddress]
            },
            {
              reference: "allowance",
              methodName: "allowance",
              methodParameters: [userAddress, "0x0000000000000000000000000000000000000000"]
            }
          ]
        },
        // GokToken
        {
          reference: "GokToken",
          abi: abi.gokToken,
          contractAddress: addresses["gokToken"],
          calls: [
            {
              reference: "balanceOf",
              methodName: "balanceOf",
              methodParameters: [userAddress]
            }
          ]
        },
        // ColToken
        {
          reference: "ColToken",
          abi: abi.colToken,
          contractAddress: addresses["colToken"],
          calls: [
            {
              reference: "balanceOf",
              methodName: "balanceOf",
              methodParameters: [userAddress]
            },
            {
              reference: "allowance",
              methodName: "allowance",
              methodParameters: [userAddress, addresses["borrowerOperations"]]
            }
          ]
        },
        // collSurplusPool
        {
          reference: "CollSurplusPool",
          abi: abi.collSurplusPool,
          contractAddress: addresses["collSurplusPool"],
          calls: [
            {
              reference: "getCollateral",
              methodName: "getCollateral",
              methodParameters: [userAddress]
            }
          ]
        },
        // VaultManager
        {
          reference: "VaultManager",
          contractAddress: addresses["vaultManager"],
          abi: abi.vaultManager,
          calls: [
            {
              reference: "rewardSnapshots",
              methodName: "rewardSnapshots",
              methodParameters: [userAddress]
            },
            {
              reference: "Vaults",
              methodName: "Vaults",
              methodParameters: [userAddress]
            }
          ]
        },
        // StabilityPool
        {
          reference: "StabilityPool",
          contractAddress: addresses["stabilityPool"],
          abi: abi.stabilityPool,
          calls: [
            {
              reference: "getDepositorGOKGain",
              methodName: "getDepositorGOKGain",
              methodParameters: [userAddress]
            },
            {
              reference: "getDepositorCOLGain",
              methodName: "getDepositorCOLGain",
              methodParameters: [userAddress]
            },
            {
              reference: "getCompoundedGAIDeposit",
              methodName: "getCompoundedGAIDeposit",
              methodParameters: [userAddress]
            },
            {
              reference: "deposits",
              methodName: "deposits",
              methodParameters: [userAddress]
            }
          ]
        },
        // GokStaking
        {
          reference: "GokStaking",
          contractAddress: addresses["gokStaking"],
          abi: abi.gokStaking,
          calls: [
            {
              reference: "unweightedStakes",
              methodName: "unweightedStakes",
              methodParameters: [userAddress]
            },
            {
              reference: "weightedStakes",
              methodName: "weightedStakes",
              methodParameters: [userAddress]
            },
            {
              reference: "getPendingGAIGain",
              methodName: "getPendingGAIGain",
              methodParameters: [userAddress]
            },
            {
              reference: "getPendingCOLGain",
              methodName: "getPendingCOLGain",
              methodParameters: [userAddress]
            }
          ]
        }
      ];

      const userContractResults: ContractCallResults = await multicall.call(userContractCallContext);
      console.log("userContractResults", userContractResults);

      // [Done] gaiBalance
      const gaiBalance = BigNumber.from(
        userContractResults.results["GaiToken"].callsReturnContext.find(
          item => item.reference === "balanceOf"
        )?.returnValues?.[0] ?? 0
      );
      batchFetchedValue.gaiBalance = decimalify18(gaiBalance);
      // [Done] gaiAllowance
      const gaiAllowance = BigNumber.from(
        userContractResults.results["GaiToken"].callsReturnContext.find(
          item => item.reference === "allowance"
        )?.returnValues?.[0] ?? 0
      );
      batchFetchedValue.gaiAllowance = decimalifyCollateralDecimal(gaiAllowance);
      // [Done] gokBalance
      const gokBalance = BigNumber.from(
        userContractResults.results["GokToken"].callsReturnContext.find(
          item => item.reference === "balanceOf"
        )?.returnValues?.[0] ?? 0
      );
      batchFetchedValue.gokBalance = decimalify18(gokBalance);
      // [Done] wrappedTokenBalance
      const wrappedTokenBalance = BigNumber.from(
        userContractResults.results["ColToken"].callsReturnContext.find(
          item => item.reference === "balanceOf"
        )?.returnValues?.[0] ?? 0
      );
      batchFetchedValue.wrappedTokenBalance = decimalifyCollateralDecimal(wrappedTokenBalance);
      // [Done] wrappedTokenAllowance
      const wrappedTokenAllowance = BigNumber.from(
        userContractResults.results["ColToken"].callsReturnContext.find(
          item => item.reference === "allowance"
        )?.returnValues?.[0] ?? 0
      );
      batchFetchedValue.wrappedTokenAllowance = decimalifyCollateralDecimal(wrappedTokenAllowance);
      // [Done] collateralSurplusBalance
      const collateralSurplusBalance = BigNumber.from(
        userContractResults.results["CollSurplusPool"].callsReturnContext.find(
          item => item.reference === "getCollateral"
        )?.returnValues?.[0] ?? 0
      );
      batchFetchedValue.collateralSurplusBalance =
        decimalifyCollateralDecimal(collateralSurplusBalance);
      // [Done] stabilityDeposit
      const getDepositorGOKGain = BigNumber.from(
        userContractResults.results["StabilityPool"].callsReturnContext.find(
          item => item.reference === "getDepositorGOKGain"
        )?.returnValues?.[0] ?? 0
      );
      const getDepositorCOLGain = BigNumber.from(
        userContractResults.results["StabilityPool"].callsReturnContext.find(
          item => item.reference === "getDepositorCOLGain"
        )?.returnValues?.[0] ?? 0
      );
      const getCompoundedGAIDeposit = BigNumber.from(
        userContractResults.results["StabilityPool"].callsReturnContext.find(
          item => item.reference === "getCompoundedGAIDeposit"
        )?.returnValues?.[0] ?? 0
      );
      const deposits = BigNumber.from(
        userContractResults.results["StabilityPool"].callsReturnContext.find(
          item => item.reference === "deposits"
        )?.returnValues?.[0] ?? 0
      );

      batchFetchedValue.stabilityDeposit = new StabilityDeposit(
        decimalify18(deposits),
        decimalify18(getCompoundedGAIDeposit),
        decimalifyCollateralDecimal(getDepositorCOLGain),
        decimalify18(getDepositorGOKGain)
      );
      // [Done] vaultBeforeRedistribution
      const valutDebt = BigNumber.from(
        userContractResults.results["VaultManager"].callsReturnContext.find(
          item => item.reference === "Vaults"
        )?.returnValues?.[0] ?? 0
      );
      const valutColl = BigNumber.from(
        userContractResults.results["VaultManager"].callsReturnContext.find(
          item => item.reference === "Vaults"
        )?.returnValues?.[1] ?? 0
      );
      const valutStake = BigNumber.from(
        userContractResults.results["VaultManager"].callsReturnContext.find(
          item => item.reference === "Vaults"
        )?.returnValues?.[2] ?? 0
      );
      const valutStatus =
        userContractResults.results["VaultManager"].callsReturnContext.find(
          item => item.reference === "Vaults"
        )?.returnValues?.[3] ?? 0;
      const valutArrayIndex = BigNumber.from(
        userContractResults.results["VaultManager"].callsReturnContext.find(
          item => item.reference === "Vaults"
        )?.returnValues?.[4] ?? 0
      );

      const snapshotCol = BigNumber.from(
        userContractResults.results["VaultManager"].callsReturnContext.find(
          item => item.reference === "rewardSnapshots"
        )?.returnValues?.[0] ?? 0
      );

      const snapshotDebt = BigNumber.from(
        userContractResults.results["VaultManager"].callsReturnContext.find(
          item => item.reference === "rewardSnapshots"
        )?.returnValues?.[1] ?? 0
      );

      batchFetchedValue.vaultBeforeRedistribution = getVaultBeforRedistribution(
        userAddress,
        {
          debt: valutDebt,
          coll: valutColl,
          stake: valutStake,
          status: valutStatus,
          arrayIndex: valutArrayIndex
        },
        {
          COL: snapshotCol,
          GAIDebt: snapshotDebt
        }
      );
    }

    const { blockTimestamp, _feesFactory, ...baseState } = await promiseAllValues({
      blockTimestamp: this._readable._getBlockTimestamp(blockTag),
      _feesFactory: this._readable._getFeesFactory({ blockTag }),

      price: this._readable.getPrice({ blockTag }),
      // numberOfVaults: this._readable.getNumberOfVaults({ blockTag }),
      numberOfVaults: Promise.resolve(batchFetchedValue.numberOfVaults),
      // totalRedistributed: this._readable.getTotalRedistributed({ blockTag }),
      totalRedistributed: Promise.resolve(batchFetchedValue.totalRedistributed),
      // total: this._readable.getTotal({ blockTag }),
      total: Promise.resolve(batchFetchedValue.total),
      // gaiInStabilityPool: this._readable.getGaiInStabilityPool({ blockTag }),
      gaiInStabilityPool: Promise.resolve(batchFetchedValue.gaiInStabilityPool),
      // totalWeightedStakedGOK: this._readable.getTotalWeightedStakedGOK({ blockTag }),
      totalWeightedStakedGOK: Promise.resolve(batchFetchedValue.totalWeightedStakedGOK),
      // totalUnweightedStakedGOK: this._readable.getTotalUnweightedStakedGOK({ blockTag }),
      totalUnweightedStakedGOK: Promise.resolve(batchFetchedValue.totalUnweightedStakedGOK),
      _riskiestVaultBeforeRedistribution: this._getRiskiestVaultBeforeRedistribution({ blockTag }),
      remainingStabilityPoolGOKReward: this._readable.getRemainingStabilityPoolGOKReward({
        blockTag
      }),
      ...(userAddress
        ? {
            accountBalance: this._provider.getBalance(userAddress, blockTag).then(decimalify18),
            gaiBalance: Promise.resolve(batchFetchedValue.gaiBalance),
            xcGaiBalance: Promise.resolve(Decimal18.ZERO),
            xcGaiAllowance: Promise.resolve(CollateralDecimal.ZERO),
            gaiAllowance: Promise.resolve(batchFetchedValue.gaiAllowance),
            gokBalance: Promise.resolve(batchFetchedValue.gokBalance),
            wrappedTokenBalance: Promise.resolve(batchFetchedValue.wrappedTokenBalance),
            wrappedTokenAllowance: Promise.resolve(batchFetchedValue.wrappedTokenAllowance),
            collateralSurplusBalance: Promise.resolve(batchFetchedValue.collateralSurplusBalance),
            vaultBeforeRedistribution: Promise.resolve(batchFetchedValue.vaultBeforeRedistribution),
            stabilityDeposit: Promise.resolve(batchFetchedValue.stabilityDeposit),
            // gokStake: Promise.resolve(new GOKStake()),
            // totalLockedStakesCount: Promise.resolve(0),
            // gaiBalance: this._readable.getGaiBalance(userAddress, { blockTag }),
            // xcGaiBalance: this._readable
            //   .getXcGaiBalance(userAddress, { blockTag })
            //   .catch(_ => Decimal18.ZERO),
            // xcGaiAllowance: this._readable
            //   .getXcGaiAllowance(userAddress, { blockTag })
            //   .catch(_ => CollateralDecimal.ZERO),
            // gaiAllowance: this._readable
            //   .getGaiAllowance(userAddress, { blockTag })
            //   .catch(_ => CollateralDecimal.ZERO),
            // gokBalance: this._readable
            //   .getGOKBalance(userAddress, { blockTag })
            //   .catch(_ => Decimal18.ZERO),
            // wrappedTokenBalance: this._readable
            //   .getWrappedTokenBalance(userAddress, { blockTag })
            //   .catch(_ => CollateralDecimal.ZERO),
            // wrappedTokenAllowance: this._readable
            //   .getWrappedTokenAllowance(userAddress, {
            //     blockTag
            //   })
            //   .catch(_ => CollateralDecimal.ZERO),
            // collateralSurplusBalance: this._readable
            //   .getCollateralSurplusBalance(userAddress, {
            //     blockTag
            //   })
            //   .catch(_ => CollateralDecimal.ZERO),
            // vaultBeforeRedistribution: this._readable
            //   .getVaultBeforeRedistribution(userAddress, {
            //     blockTag
            //   })
            //   .catch(_ => new VaultWithPendingRedistribution(AddressZero, "nonExistent")),
            // stabilityDeposit: this._readable
            //   .getStabilityDeposit(userAddress, { blockTag })
            //   .catch(
            //     _ =>
            //       new StabilityDeposit(
            //         Decimal18.ZERO,
            //         Decimal18.ZERO,
            //         CollateralDecimal.ZERO,
            //         Decimal18.ZERO
            //       )
            //   ),
            gokStake: this._readable
              .getGOKStake(userAddress, { blockTag })
              .catch(_ => new GOKStake()),
            totalLockedStakesCount: this._readable
              .getTotalLockedStakesCount(userAddress, {
                blockTag
              })
              .catch(_ => 0)
          }
        : {
            accountBalance: Decimal18.ZERO,
            gaiBalance: Decimal18.ZERO,
            xcGaiBalance: Decimal18.ZERO,
            gokBalance: Decimal18.ZERO,
            wrappedTokenBalance: CollateralDecimal.ZERO,
            wrappedTokenAllowance: CollateralDecimal.ZERO,
            xcGaiAllowance: CollateralDecimal.ZERO,
            gaiAllowance: CollateralDecimal.ZERO,
            collateralSurplusBalance: CollateralDecimal.ZERO,
            vaultBeforeRedistribution: new VaultWithPendingRedistribution(
              AddressZero,
              "nonExistent"
            ),
            stabilityDeposit: new StabilityDeposit(
              Decimal18.ZERO,
              Decimal18.ZERO,
              CollateralDecimal.ZERO,
              Decimal18.ZERO
            ),
            gokStake: new GOKStake(),
            totalLockedStakesCount: 0
          })
    });

    return [
      {
        ...baseState,
        _feesInNormalMode: _feesFactory(blockTimestamp, false)
      },
      {
        blockTag,
        blockTimestamp,
        _feesFactory
      }
    ];
  }

  /** @internal @override */
  protected _doStart(): () => void {
    this._get().then(state => {
      if (!this._loaded) {
        this._load(...state);
      }
    });

    const blockListener = async (blockTag: number) => {
      const state = await this._get(blockTag);
      if (this._loaded) {
        this._update(...state);
      } else {
        this._load(...state);
      }
    };

    this._provider.on("block", blockListener);

    return () => {
      this._provider.off("block", blockListener);
    };
  }

  /** @internal @override */
  protected _reduceExtra(
    oldState: BlockPolledAstridDaoStoreExtraState,
    stateUpdate: Partial<BlockPolledAstridDaoStoreExtraState>
  ): BlockPolledAstridDaoStoreExtraState {
    return {
      blockTag: stateUpdate.blockTag ?? oldState.blockTag,
      blockTimestamp: stateUpdate.blockTimestamp ?? oldState.blockTimestamp,
      _feesFactory: stateUpdate._feesFactory ?? oldState._feesFactory
    };
  }
}
