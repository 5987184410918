import { Decimal18 } from "@astrid-dao/lib-base";
import { BigNumber } from "@ethersproject/bignumber";

export const numberify = (bigNumber: BigNumber): number => bigNumber.toNumber();

export const decimalify = (bigNumber: BigNumber): Decimal18 =>
  Decimal18.fromBigNumberString(bigNumber.toHexString());

export const panic = <T>(e: unknown): T => {
  throw e;
};

export type Resolved<T> = T extends Promise<infer U> ? U : T;
export type ResolvedValues<T> = { [P in keyof T]: Resolved<T[P]> };

export const promiseAllValues = <T extends object>(object: T): Promise<ResolvedValues<T>> => {
  const keys = Object.keys(object);

  return Promise.all(Object.values(object)).then(values =>
    Object.fromEntries(values.map((value, i) => [keys[i], value]))
  ) as Promise<ResolvedValues<T>>;
};

/**
 * Sleep for some milliseconds.
 *
 * @param ms number of milliseconds to sleep for
 */
export const sleep = (ms: number) => new Promise(res => setTimeout(res, ms));

export function ensure<T>(
  argument: T | undefined | null,
  message: string = "This value was promised to be there."
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

export const logError = (error: unknown, message: string = "Something went wrong: ") =>
  console.error(message, error);

export async function waitForAllPromisesOrLogError<T>(promises: Promise<T>[]): Promise<T[]> {
  try {
    return await Promise.all(promises);
  } catch (e) {
    logError(e);
    return [];
  }
}

export const usdCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  compactDisplay: "short",
  maximumFractionDigits: 2
});
