import React, { createContext, useEffect, useState } from "react";

import { AstridDaoStore } from "@astrid-dao/lib-base";

import { BaseProp } from "../@types/base-types";

export const AstridDaoStoreContext = createContext<AstridDaoStore | undefined>(undefined);

type AstridDaoStoreProviderProps = {
  store: AstridDaoStore;
  loader?: React.ReactNode;
} & BaseProp;

export const AstridDaoStoreProvider: React.FC<AstridDaoStoreProviderProps> = ({
  store,
  loader,
  children
}) => {
  const [loadedStore, setLoadedStore] = useState<AstridDaoStore>();

  useEffect(() => {
    store.onLoaded = () => setLoadedStore(store);
    const stop = store.start();

    return () => {
      store.onLoaded = undefined;
      setLoadedStore(undefined);
      stop();
    };
  }, [store]);

  if (!loadedStore) {
    return <>{loader}</>;
  }

  return (
    <AstridDaoStoreContext.Provider value={loadedStore}>{children}</AstridDaoStoreContext.Provider>
  );
};
