import { getCollateralOracle, SupportedCollaterals } from "@astrid-dao/lib-base";
import { Contract, ethers } from "ethers";

import { BaseContractNames, PublicSaleContracts, TokenClaimingContracts } from "../config";
import { ContractsConfigJson } from "../providers/AstridDaoProvider";
import ActivePoolAbi from "./abi/astar/ActivePool.json";
import CommunityIssuanceAbi from "./abi/astar/CommunityIssuance.json";
import CrowdsaleAbi from "./abi/astar/Crowdsale.json";
import CrowdsaleRecordsAbi from "./abi/astar/CrowdsaleRecords.json";
import CrowdsaleWhitelistAbi from "./abi/astar/CrowdsaleWhitelist.json";
import DefaultPoolAbi from "./abi/astar/DefaultPool.json";
import GAITokenAbi from "./abi/astar/GAIToken.json";
import GOKStakingAbi from "./abi/astar/GOKStaking.json";
import GOKTokenAbi from "./abi/astar/GOKToken.json";
import GovTokenAbi from "./abi/astar/GovToken.json";
import TokenLockupAbi from "./abi/astar/LockupContract.json";
import PriceFeedAbi from "./abi/astar/PriceFeed.json";
import PriceFeedPythAbi from "./abi/astar/PriceFeedPyth.json";
import PriceFeedRedStoneClassicalAbi from "./abi/astar/PriceFeedRedStoneClassicLayerBank.json";
import StabilityPoolAbi from "./abi/astar/StabilityPool.json";
import TokenAirdropAbi from "./abi/astar/TokenAirdrop.json";
import VaultManagerAbi from "./abi/astar/VaultManager.json";
import WASTRAbi from "./abi/astar/WASTR.json";
import XcGaiWrapperAbi from "./abi/astar/XcGaiWrapper.json";
import ERC20Abi from "./abi/IERC20.json";
import ERC20PlusAbi from "./abi/IERC20Plus.json";
import IPythTesterAbi from "./abi/IPyth.json";
import ActivePoolTesterAbi from "./abi/shibuya/ActivePoolTester.json";
import CommunityIssuanceTesterAbi from "./abi/shibuya/CommunityIssuanceTester.json";
import CrowdsaleRecordsTesterAbi from "./abi/shibuya/CrowdsaleRecordsTester.json";
import CrowdsaleTesterAbi from "./abi/shibuya/CrowdsaleTester.json";
import CrowdsaleWhitelistTesterAbi from "./abi/shibuya/CrowdsaleWhitelistTester.json";
import DefaultPoolTesterAbi from "./abi/shibuya/DefaultPoolTester.json";
import GAITokenTesterAbi from "./abi/shibuya/GAITokenTester.json";
import GOKStakingTesterAbi from "./abi/shibuya/GOKStakingTester.json";
import GOKTokenTesterAbi from "./abi/shibuya/GOKTokenTester.json";
import GovTokenTesterAbi from "./abi/shibuya/GovTokenTester.json";
import PriceFeedTestnetAbi from "./abi/shibuya/PriceFeedTester.json";
import StabilityPoolTesterAbi from "./abi/shibuya/StabilityPoolTester.json";
import TokenLockupTesterAbi from "./abi/shibuya/TokenLockupTester.json";
import VaultManagerTesterAbi from "./abi/shibuya/VaultManagerTester.json";
import WASTRTesterAbi from "./abi/shibuya/WASTRTester.json";
import StakeStoneAbi from "./abi/StakeStone.json";

type GetAbiParams = { isTestnet: boolean };

export const getAbi = ({ isTestnet }: GetAbiParams) => ({
  tokenLockup: isTestnet ? TokenLockupTesterAbi : TokenLockupAbi,
  tokenAirdrop: isTestnet ? TokenAirdropAbi : TokenAirdropAbi,
  astarPriceFeed: isTestnet ? PriceFeedTestnetAbi : PriceFeedAbi,
  activePool: isTestnet ? ActivePoolTesterAbi : ActivePoolAbi,
  stabilityPool: isTestnet ? StabilityPoolTesterAbi : StabilityPoolAbi,
  defaultPool: isTestnet ? DefaultPoolTesterAbi : DefaultPoolAbi,
  vaultManager: isTestnet ? VaultManagerTesterAbi : VaultManagerAbi,
  gaiToken: isTestnet ? GAITokenTesterAbi : GAITokenAbi,
  govToken: isTestnet ? GovTokenTesterAbi : GovTokenAbi,
  gokToken: isTestnet ? GOKTokenTesterAbi : GOKTokenAbi,
  WASTR: isTestnet ? WASTRTesterAbi : WASTRAbi,
  ERC20: ERC20Abi,
  gokStaking: isTestnet ? GOKStakingTesterAbi : GOKStakingAbi,
  communityIssuance: isTestnet ? CommunityIssuanceTesterAbi : CommunityIssuanceAbi,
  crowdsale: isTestnet ? CrowdsaleTesterAbi : CrowdsaleAbi,
  crowdsaleRecords: isTestnet ? CrowdsaleRecordsTesterAbi : CrowdsaleRecordsAbi,
  crowdsaleWhitelist: isTestnet ? CrowdsaleWhitelistTesterAbi : CrowdsaleWhitelistAbi
});

export const stakeStoneContracts = new Map<SupportedCollaterals, Contract>();
export const currencyPricePythMap = new Map<
  SupportedCollaterals,
  { contract: Contract; params: { id: string } }
>();
export const currencyPriceMap = new Map<SupportedCollaterals, Contract>();
export const baseContractsMap = new Map<BaseContractNames, Contract>();
export const vaultManagerMap = new Map<SupportedCollaterals, Contract>();
export const collateralTokenMap = new Map<SupportedCollaterals, Contract>();
export const activePoolMap = new Map<SupportedCollaterals, Contract>();
export const stabilityPoolMap = new Map<SupportedCollaterals, Contract>();
export const gokStakingMap = new Map<SupportedCollaterals, Contract>();
export const defaultPoolMap = new Map<SupportedCollaterals, Contract>();
export const publicSaleContractsMap = new Map<PublicSaleContracts, Contract>();
export const tokenClaimingContracts = new Map<TokenClaimingContracts, Contract>();

export const connectToContracts = (
  isTestnet: boolean,
  contractsConfig: ContractsConfigJson,
  web3Provider: any
) => {
  const abiMap = getAbi({
    isTestnet
  });

  const { baseContracts, airdropContracts, collaterals, publicSaleContracts } = contractsConfig;

  collaterals.forEach(collateralContracts => {
    const { name, contracts, address, pythConfigs } = collateralContracts;
    const collateralName = name as SupportedCollaterals;

    const abiToUseForCollateralTokenContract =
      // TODO support wrappered platform coin like ASTR -> WASTR
      // @ts-ignore
      collateralName === "WASTR" ? abiMap.WASTR : abiMap.ERC20;

    collateralTokenMap.set(
      collateralName,
      new Contract(address, abiToUseForCollateralTokenContract, web3Provider)
    );

    if (collateralName === "STONE") {
      stakeStoneContracts.set(collateralName, new Contract(address, StakeStoneAbi, web3Provider));
    }

    currencyPricePythMap.set(collateralName, {
      params: { id: pythConfigs?.priceFeedID ?? "" }, // TODO 整顿类型
      contract: new Contract(
        pythConfigs?.pythContract ?? "",
        new ethers.utils.Interface(IPythTesterAbi),
        web3Provider
      )
    });

    currencyPriceMap.set(
      collateralName,
      new Contract(
        contracts.priceFeed,
        new ethers.utils.Interface(
          getCollateralOracle(isTestnet).PYTH.includes(collateralName)
            ? PriceFeedPythAbi
            : PriceFeedRedStoneClassicalAbi
        ),
        web3Provider
      )
    );

    vaultManagerMap.set(
      collateralName,
      new Contract(contracts.vaultManager, abiMap.vaultManager, web3Provider)
    );

    activePoolMap.set(
      collateralName,
      new Contract(contracts.activePool, abiMap.activePool, web3Provider)
    );

    stabilityPoolMap.set(
      collateralName,
      new Contract(contracts.stabilityPool, abiMap.stabilityPool, web3Provider)
    );

    gokStakingMap.set(
      collateralName,
      new Contract(contracts.gokStaking, abiMap.gokStaking, web3Provider)
    );

    defaultPoolMap.set(
      collateralName,
      new Contract(contracts.defaultPool, abiMap.defaultPool, web3Provider)
    );
  });

  baseContractsMap.set(
    "gaiToken",
    new Contract(baseContracts.gaiToken, abiMap.gaiToken, web3Provider)
  );

  baseContractsMap.set(
    "xcGaiToken",
    new Contract(baseContracts.xcGaiToken, ERC20PlusAbi, web3Provider)
  );

  baseContractsMap.set(
    "xcGaiWrapper",
    new Contract(baseContracts.xcGaiWrapper, XcGaiWrapperAbi, web3Provider)
  );

  baseContractsMap.set(
    "govToken",
    new Contract(baseContracts.govToken, abiMap.govToken, web3Provider)
  );

  baseContractsMap.set(
    "communityIssuance",
    new Contract(baseContracts.communityIssuance, abiMap.communityIssuance, web3Provider)
  );

  if (airdropContracts.length) {
    airdropContracts.forEach(airdropContract => {
      const { name, contract } = airdropContract;

      tokenClaimingContracts.set(
        name as TokenClaimingContracts,
        new Contract(contract, abiMap.tokenAirdrop, web3Provider)
      );
    });
  }

  publicSaleContractsMap.set(
    "crowdsale",
    new Contract(publicSaleContracts.crowdsale, abiMap.crowdsale, web3Provider)
  );
  publicSaleContractsMap.set(
    "crowdsaleRecords",
    new Contract(publicSaleContracts.crowdsaleRecords, abiMap.crowdsaleRecords, web3Provider)
  );
};

export function assertContractExist(contract: Contract | undefined): Contract {
  if (!contract) {
    throw new Error("Contract does not exist");
  }
  return contract;
}
