import { BigNumber } from "@ethersproject/bignumber";
import { Event } from "@ethersproject/contracts";

import {
  Decimal18,
  ObservableAstridDao,
  StabilityDeposit,
  Vault,
  VaultWithPendingRedistribution
} from "@astrid-dao/lib-base";

import { _getContracts, _requireAddress } from "./EthersAstridDaoConnection";
import { ReadableEthersAstridDao } from "./ReadableEthersAstridDao";

const debouncingDelayMs = 50;

const debounce = (listener: (latestBlock: number) => void) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
  let latestBlock = 0;

  return (...args: unknown[]) => {
    const event = args[args.length - 1] as Event;

    if (event.blockNumber !== undefined && event.blockNumber > latestBlock) {
      latestBlock = event.blockNumber;
    }

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      listener(latestBlock);
      timeoutId = undefined;
    }, debouncingDelayMs);
  };
};

/** @alpha */
export class ObservableEthersAstridDao implements ObservableAstridDao {
  private readonly _readable: ReadableEthersAstridDao;

  constructor(readable: ReadableEthersAstridDao) {
    this._readable = readable;
  }

  watchTotalRedistributed(
    onTotalRedistributedChanged: (totalRedistributed: Vault) => void
  ): () => void {
    const { activePool, defaultPool } = _getContracts(this._readable.connection);
    const etherSent = activePool.filters.COLSent();

    const redistributionListener = debounce((blockTag: number) => {
      this._readable.getTotalRedistributed({ blockTag }).then(onTotalRedistributedChanged);
    });

    const etherSentListener = (toAddress: string, _amount: BigNumber, event: Event) => {
      if (toAddress === defaultPool.address) {
        redistributionListener(event);
      }
    };

    activePool.on(etherSent, etherSentListener);

    return () => {
      activePool.removeListener(etherSent, etherSentListener);
    };
  }

  watchVaultWithoutRewards(
    onVaultChanged: (vault: VaultWithPendingRedistribution) => void,
    address?: string
  ): () => void {
    address ??= _requireAddress(this._readable.connection);

    const { vaultManager: vaultManager, borrowerOperations } = _getContracts(
      this._readable.connection
    );
    const vaultUpdatedByVaultManager = vaultManager.filters.VaultUpdated(address);
    const vaultUpdatedByBorrowerOperations = borrowerOperations.filters.VaultUpdated(address);

    const vaultListener = debounce((blockTag: number) => {
      this._readable.getVaultBeforeRedistribution(address, { blockTag }).then(onVaultChanged);
    });

    vaultManager.on(vaultUpdatedByVaultManager, vaultListener);
    borrowerOperations.on(vaultUpdatedByBorrowerOperations, vaultListener);

    return () => {
      vaultManager.removeListener(vaultUpdatedByVaultManager, vaultListener);
      borrowerOperations.removeListener(vaultUpdatedByBorrowerOperations, vaultListener);
    };
  }

  watchNumberOfVaults(onNumberOfVaultsChanged: (numberOfVaults: number) => void): () => void {
    const { vaultManager: vaultManager } = _getContracts(this._readable.connection);
    const { VaultUpdated } = vaultManager.filters;
    const vaultUpdated = VaultUpdated();

    const vaultUpdatedListener = debounce((blockTag: number) => {
      this._readable.getNumberOfVaults({ blockTag }).then(onNumberOfVaultsChanged);
    });

    vaultManager.on(vaultUpdated, vaultUpdatedListener);

    return () => {
      vaultManager.removeListener(vaultUpdated, vaultUpdatedListener);
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  watchPrice(onPriceChanged: (price: Decimal18) => void): () => void {
    // TODO revisit
    // We no longer have our own PriceUpdated events. If we want to implement this in an event-based
    // manner, we'll need to listen to aggregator events directly. Or we could do polling.
    throw new Error("Method not implemented.");
  }

  watchTotal(onTotalChanged: (total: Vault) => void): () => void {
    const { vaultManager: vaultManager } = _getContracts(this._readable.connection);
    const { VaultUpdated } = vaultManager.filters;
    const vaultUpdated = VaultUpdated();

    const totalListener = debounce((blockTag: number) => {
      this._readable.getTotal({ blockTag }).then(onTotalChanged);
    });

    vaultManager.on(vaultUpdated, totalListener);

    return () => {
      vaultManager.removeListener(vaultUpdated, totalListener);
    };
  }

  watchStabilityDeposit(
    onStabilityDepositChanged: (stabilityDeposit: StabilityDeposit) => void,
    address?: string
  ): () => void {
    address ??= _requireAddress(this._readable.connection);

    const { activePool, stabilityPool } = _getContracts(this._readable.connection);
    const { UserDepositChanged } = stabilityPool.filters;
    const { COLSent } = activePool.filters;

    const userDepositChanged = UserDepositChanged(address);
    const colSent = COLSent();

    const depositListener = debounce((blockTag: number) => {
      this._readable.getStabilityDeposit(address, { blockTag }).then(onStabilityDepositChanged);
    });

    const etherSentListener = (toAddress: string, _amount: BigNumber, event: Event) => {
      if (toAddress === stabilityPool.address) {
        // Liquidation while Stability Pool has some deposits
        // There may be new gains
        depositListener(event);
      }
    };

    stabilityPool.on(userDepositChanged, depositListener);
    activePool.on(colSent, etherSentListener);

    return () => {
      stabilityPool.removeListener(userDepositChanged, depositListener);
      activePool.removeListener(colSent, etherSentListener);
    };
  }

  watchGaiInStabilityPool(
    onGaiInStabilityPoolChanged: (gaiInStabilityPool: Decimal18) => void
  ): () => void {
    const { gaiToken, stabilityPool } = _getContracts(this._readable.connection);
    const { Transfer } = gaiToken.filters;

    const transferGaiFromStabilityPool = Transfer(stabilityPool.address);
    const transferGaiToStabilityPool = Transfer(null, stabilityPool.address);

    const stabilityPoolGaiFilters = [transferGaiFromStabilityPool, transferGaiToStabilityPool];

    const stabilityPoolGaiListener = debounce((blockTag: number) => {
      this._readable.getGaiInStabilityPool({ blockTag }).then(onGaiInStabilityPoolChanged);
    });

    stabilityPoolGaiFilters.forEach(filter => gaiToken.on(filter, stabilityPoolGaiListener));

    return () =>
      stabilityPoolGaiFilters.forEach(filter =>
        gaiToken.removeListener(filter, stabilityPoolGaiListener)
      );
  }

  watchGaiBalance(onGaiBalanceChanged: (balance: Decimal18) => void, address?: string): () => void {
    address ??= _requireAddress(this._readable.connection);

    const { gaiToken } = _getContracts(this._readable.connection);
    const { Transfer } = gaiToken.filters;
    const transferGaiFromUser = Transfer(address);
    const transferGaiToUser = Transfer(null, address);

    const gaiTransferFilters = [transferGaiFromUser, transferGaiToUser];

    const gaiTransferListener = debounce((blockTag: number) => {
      this._readable.getGaiBalance(address, { blockTag }).then(onGaiBalanceChanged);
    });

    gaiTransferFilters.forEach(filter => gaiToken.on(filter, gaiTransferListener));

    return () =>
      gaiTransferFilters.forEach(filter => gaiToken.removeListener(filter, gaiTransferListener));
  }
}
