import React from "react";

import { Link } from "theme-ui";

import { BaseProp } from "../../@types/base-types";

export type ExternalNavLinkProps = {
  to: string;
} & BaseProp;

const ExternalLink: React.FC<ExternalNavLinkProps> = ({ to, children }) => {
  return (
    <Link target="_blank" rel="noopener noreferrer" href={to}>
      {children}
    </Link>
  );
};

export default ExternalLink;
