import { BigNumber } from "@ethersproject/bignumber";
import { CollateralDecimal } from "./CollateralDecimal";
import { Decimal18 } from "./Decimal18";

export const MAX_UINT_256 = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
export const ONE = BigNumber.from(1);
export const TEN = BigNumber.from(10);
export const stringRepresentationFormat = /^[0-9]*(\.[0-9]*)?(e[-+]?[0-9]+)?([∞])?$/;
export const trailingZeros = /0*$/;
export const magnitudes = ["", "K", "M", "B", "T"];

export const getDigits = (numDigits: number): BigNumber => TEN.pow(numDigits);

/**
 * Types that can be converted into a Decimal18 or CollateralDecimal.
 *
 * @public
 */
export type BaseDecimalish = CollateralDecimal | Decimal18 | number | string;

export interface ShortenOptions {
  /** A flag to format the Decimal18 to raw values */
  shouldShowRawValue?: boolean;
  /** Specifies the precision */
  rawValuePrecision?: number;
}
