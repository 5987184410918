/** @jsxImportSource theme-ui */
import React from "react";

import { CHAIN_ID } from "@astrid-dao/lib-base";
import { connectorsForWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { walletConnectWallet, metaMaskWallet, safeWallet } from "@rainbow-me/rainbowkit/wallets";
import { Flex, Spinner, Heading, ThemeProvider, Paragraph, Link } from "theme-ui";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import { AstridDaoFrontend } from "./AstridDaoFrontend";
import { Icon } from "./components/Icon";
import { TransactionProvider } from "./components/Transaction";
import { WalletConnector } from "./components/WalletConnector";
import { astridDaoFrontendConfigProxy, USE_TESTNET, jsonRpcProviders } from "./config";
import { ChainId, CHAIN_CONFIG } from "./config/chains";
import { AstridDaoProvider } from "./providers/AstridDaoProvider";
import { DisposableWalletProvider } from "./testUtils/DisposableWalletProvider";
import { astridDaoTheme } from "./theme";
import "./index.css";

import "@rainbow-me/rainbowkit/styles.css";

console.log("app version : ", process.env.REACT_APP_PACKAGE_VERSION);

const { chains, provider, webSocketProvider } = configureChains(
  [...(USE_TESTNET ? [CHAIN_CONFIG[ChainId.Shibuya]] : [CHAIN_CONFIG[ChainId.Astar]])],
  [
    ...jsonRpcProviders
      .filter(item => item.isDev === USE_TESTNET)
      .map(item =>
        jsonRpcProvider({
          rpc: () => ({
            http: item.http,
            webSocket: item.webSocket
          }),
          priority: 1
        })
      )
  ]
);

// const { connectors } = getDefaultWallets({
//   appName: 'AstridDAO',
//   projectId: 'a272e2b3b92da1d53328a18a292b063d',
//   chains
// });

export const appName = "Goku Money";
const projectId = "e6aab53b7b53db270750de37a1cc053d";

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      ...(safeWallet({ chains }).installed
        ? [safeWallet({ chains })]
        : [
            metaMaskWallet({ chains, projectId }),
            walletConnectWallet({ chains, projectId })
            // coinbaseWallet({ appName, chains }),
            // rainbowWallet({ chains, projectId })
          ])
    ]
  }
]);

const client = createClient({
  autoConnect: true,
  connectors: connectors,
  provider,
  webSocketProvider
});

if (window.ethereum) {
  // Silence MetaMask warning in console
  Object.assign(window.ethereum, { autoRefreshOnNetworkChange: false });
}

// NOTE: Workaround for React18 + wallet connect
window.Buffer = window.Buffer || require("buffer").Buffer;

if (astridDaoFrontendConfigProxy.chainId === CHAIN_ID.dev) {
  const ethereum = new DisposableWalletProvider(
    `http://${window.location.hostname}:8545`,
    "0x4d5db4107d237df6a3d58ee5f70ae63d73d7658d4026f2eefd2f204c81682cb7"
  );

  Object.assign(window, { ethereum });
}

if (process.env.NODE_ENV === "production") {
  const noop = (): void => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === "object") {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === "function" ? noop : null;
    }
  }
}

const EnvironmentInitFailedFallback: React.FC = () => (
  <Flex
    sx={{
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      textAlign: "center",
      bg: "W100"
    }}
  >
    <Heading sx={{ mb: 3 }}>
      <Icon name="exclamation-triangle" /> Oops something went wrong!
    </Heading>

    <Paragraph sx={{ mb: 3 }}>Please contact AstridDAO support:</Paragraph>
    <Link href="mailto:info@astriddao.xyz">Email</Link>
    <Link href="https://discord.gg/sarYAHuwNg" target="_blank" rel="noopener noreferrer">
      Discord
    </Link>
    <Link href="https://t.me/+rCJV1CbvTZlhNTg1" target="_blank" rel="noopener noreferrer">
      Telegram
    </Link>
  </Flex>
);

const App = () => {
  const loader = (
    <Flex sx={{ alignItems: "center", justifyContent: "center", height: "100vh", bg: "W100" }}>
      <Spinner sx={{ m: 2, color: "B80" }} size="32px" />
      <Heading sx={{ color: "B80" }}>Loading...</Heading>
    </Flex>
  );

  return (
    <ThemeProvider theme={astridDaoTheme}>
      <WagmiConfig client={client}>
        <RainbowKitProvider modalSize="compact" chains={chains}>
          <WalletConnector loader={loader}>
            <AstridDaoProvider
              loader={loader}
              environmentInitFailedFallback={<EnvironmentInitFailedFallback />}
            >
              <TransactionProvider>
                <AstridDaoFrontend loader={loader} />
              </TransactionProvider>
            </AstridDaoProvider>
          </WalletConnector>
        </RainbowKitProvider>
      </WagmiConfig>
    </ThemeProvider>
  );
};

export default App;
