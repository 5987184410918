import { Box, Flex, Image, Text } from "theme-ui";

import bgNet from "../../assets/images/landing/bg-network.svg";
import bgGround from "../../assets/images/landing/bg-ground.svg";
import bgGreenLight from "../../assets/images/landing/bg-green-light.svg";
import bgStarNight from "../../assets/images/landing/tv.svg";
import iconPad from "../../assets/images/landing/pad.svg";
import iconControls from "../../assets/images/landing/controls.svg";
import iconGok1 from "../../assets/images/landing/gok-1.svg";
import iconGok2 from "../../assets/images/landing/gok-2.svg";
import iconFlag1 from "../../assets/images/landing/flag-1.svg";
import iconFlag2 from "../../assets/images/landing/flag-2.svg";
import iconScanLine from "../../assets/images/landing/scan-line.svg";
import "./MainHero.css";
import WalletConnectButton from "../ConnectWalletButton";
import bgPadAndMonitor from "../../assets/images/landing/bg-pad-monitor.svg";
import { useIsMobile } from "../../hooks/useIsMobile";

const MainHero = () => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        width: "100vw",
        height: ["40vh", "60vh", "calc(100vh - 80px)"],
        bg: "text5",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Image
        src={bgNet}
        alt="net"
        sx={{
          position: "absolute",
          height: "100%",
          top: 0,
          filter: ["none", "brightness(0.5)"]
        }}
      />
      {/* <Box
        sx={{
          width: "100%",
          height: ["87px", "87px", "187px"],
          position: "absolute",
          top: ["20%", "56%", "56%"],
          background: `url(${bgGreenLight})`
          // "linear-gradient(to bottom, rgba(0, 208, 158, 1) 0%, rgba(0, 208, 158, 0.75) 35%, rgba(0, 208, 158, 0.5) 70%, rgba(0, 208, 158, 0.25) 100%)"
        }}
      /> */}

      {/* <Image
        src={bgGround}
        alt="ground"
        sx={{
          position: "absolute",
          top: ["35%", "30%", "60%"],
          width: ["100%", "100%", "1156px"],
          height: "120px",
          left: ["0px", "0px", "calc(50% - 578px)"]
        }}
      />
      <Box
        sx={{
          width: ["100%", "100%", "1156px"],
          height: "134px",
          position: "absolute",
          top: ["calc(29.5% + 120px)", "calc(24.5% + 120px)", "calc(60% + 120px)"],
          left: ["0%", "0%", "calc(50% - 578px)"],
          background: "linear-gradient(180deg, #007156 0%, rgba(0, 113, 86, 0.00) 100%)"
        }}
      /> */}
      <Image
        src={bgPadAndMonitor}
        alt="bg"
        sx={{
          position: "absolute",
          maxHeight: "100%",
          width: ["100%", "100%", "100%"],
          maxWidth: ["100%", "100%", "1156px"],
          transform: ["scale(1.2)", "none"],
          mt: [4, 0]
        }}
      />

      <Image
        src={iconScanLine}
        alt="scan line"
        sx={{
          position: "absolute",
          animation: "scan-line 3s linear infinite",
          marginTop: ["240px", "240px", "-630px"],
          width: ["100%", "100%", "660px"],
          maxWidth: ["56%", "56%", "660px"]
        }}
      />
      <Flex
        sx={{
          width: ["60%", "60%", "555px"],
          height: ["150px", "336px"],
          flexDirection: "column",
          gap: ["10px", "30px"],
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          marginTop: ["-70px", "-100px", "-230px"]
        }}
      >
        <Text
          className="tv-title"
          sx={{
            fontSize: ["24px", "24px", "58.018px"]
          }}
        >
          Goku Money is a Decentralized Reserve Bank
        </Text>
        <Flex
          sx={{
            alignItems: "center",
            gap: "16px",
            alignSelf: "center",
            cursor: "pointer",
          }}
        >
          <svg
            width="20"
            height="25"
            viewBox="0 0 20 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="14.75" y="9.98993" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="10" y="9.98993" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="10" y="5.24286" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="10" y="14.7369" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="5.25781" y="9.98993" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="5.25781" y="5.24286" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="5.25781" y="0.49585" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="5.25781" y="19.484" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="5.25781" y="14.7369" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="0.507812" y="9.98993" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="0.507812" y="5.24286" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="0.507812" y="0.49585" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="0.507812" y="19.484" width="4.74703" height="4.74703" fill="#EDC845" />
            <rect x="0.507812" y="14.7369" width="4.74703" height="4.74703" fill="#EDC845" />
          </svg>
          <WalletConnectButton
            component={<Text className="tv-start">GAME START</Text>}
            sx={{
              width: ["150px", "180px", "180px"],
              height: ["30px", "40px", "40px"],
              fontSize: ["20px"]
            }}
          />
        </Flex>
      </Flex>

      {/* <Image
        src={iconPad}
        alt="pad"
        sx={{
          width: ["60px", "60px", "176.35px"],
          position: "absolute",
          top: ["38%", "38%", "51%"],
          left: ["10%", "10%", "30%"]
        }}
      />
      <Image
        src={iconControls}
        alt="controls"
        sx={{
          width: ["120px", "120px", "176.35px"],
          position: "absolute",
          top: ["42%", "42%", "63%"],
          left: "60%"
        }}
      /> */}
      <Image
        className="floating"
        src={iconGok1}
        alt="gok-1"
        sx={{
          position: "absolute",
          top: ["30%", "18%"],
          width: ["48px", "32px", "180px"],
          left: ["calc(50% - 175px)", "calc(50% - 175px)", "calc(50% - 400px)"]
        }}
      />
      <Image
        className="floating-2"
        src={iconGok2}
        alt="gok-2"
        sx={{
          position: "absolute",
          top: ["25%", "30%"],
          left: ["calc(50% + 120px)", "calc(60% + 75px)", "calc(50% + 260px)"],
          width: ["48px", "32px", "158.722px"],
          height: "222.927px"
        }}
      />
      <Image
        className="floating-small"
        src={iconFlag1}
        alt="gok-1"
        sx={{
          position: "absolute",
          top: "22%",
          left: "15%",
          width: ["16px", "16px", "87px"]
        }}
      />
      <Image
        className="floating-small"
        src={iconFlag2}
        alt="gok-2"
        sx={{
          position: "absolute",
          top: "46%",
          left: "80%",
          width: ["16px", "16px", "70px"]
        }}
      />
    </Box>
  );
};

export default MainHero;
