import {
  Theme,
  ThemeUIStyleObject,
  ColorModesScale,
  ThemeUIContextValue,
  useThemeUI
} from "theme-ui";

const makeTheme = <T extends Theme>(t: T) => t;
const headerHeight = "80px";
const sidebarWidth = "268px";

const newBaseColors = {
  maizeCrayola: "#FFCF65",
  mango: "#FFC23C",
  lightYellow: "#F9C53A",
  darkGoldenrod: "#C48700",
  goldenBrown: "#9A6A00"
};

const baseColors = {
  blue: "#1542CD",
  purple: "#745DDF",
  cyan: "#2EB6EA",
  green: "#49BD7A",
  yellow: "#FD9D28",
  red: "#FF4646",
  lightRed: "rgba(255, 0, 0, 0.20)",
  darkCharcoal: "#333333"
};

const RobotoMonoRegular = {
  fontFamily: "Teko",
  fontStyle: "normal",
  fontWeight: 400
};

const baseRegularFonts = {
  fontFamily: "Rubik",
  letterSpacing: "0px",
  fontWeight: 400
};

const baseMediumFonts = {
  fontFamily: "Rubik",
  letterSpacing: "0px",
  fontWeight: 500
};

const commonMsTitleFonts = {
  fontFamily: "Montserrat Subrayada",
  letterSpacing: "0%",
  fontWeight: 700
};

const primaryGradient = "linear-gradient(262.97deg, #0BB7B5 59.64%, #FFD951 100.24%)";
const primaryGradient40 = "linear-gradient(262.97deg, #0BB7B566 59.64%, #FFD95166 100.24%)";

const V03Color = {
  R100: "#DE350B",
  G100: "#8F92A1",
  G80: "#8F92A1CC",
  G60: "#8F92A199",
  G60Solid: "#B7B8C1",
  G40: "#8F92A166",
  G12: "#8F92A11F",
  G08: "#8F92A114",
  G05: "#8F92A10D",
  W100: "#ffffff",
  W80: "#ffffffCC",
  W60: "#ffffff99",
  W40: "#ffffff66",
  W12: "#ffffff1F",
  W08: "#ffffff14",
  W05: "#ffffff0D",
  B100: "#171717",
  B80: "#171717CC",
  // B60: "#17171799",
  B60: "#757B81",
  B40: "#17171766",
  B12: "#1717171F",
  B08: "#17171714",
  B05: "#1717170D",
  LG100: "#F3F6F8",
  LG80: "#F3F6F8CC",
  LG60: "#F3F6F899",
  LG60Solid: "#F3F4F6",
  LG40: "#F3F6F866",
  LG12: "#F3F6F81F",
  LG08: "#F3F6F814",
  LG05: "#F3F6F80D",
  // ignored in theme change
  W100C: "#ffffff",
  W80C: "#ffffffCC",
  W60C: "#ffffff99",
  W40C: "#ffffff66",
  W12C: "#ffffff1F",
  W08C: "#ffffff14",
  W05C: "#ffffff0D",
  G100C: "#8F92A1",
  G80C: "#8F92A1CC",
  G60C: "#8F92A199",
  G60SolidC: "#F8FAFB",
  G40C: "#8F92A166",
  G12C: "#8F92A11F",
  G08C: "#8F92A114",
  G05C: "#8F92A10D",
  B100C: "#171717",
  B80C: "#171717CC",
  B60C: "#17171799",
  B40C: "#17171766",
  B12C: "#1717171F",
  B08C: "#17171714",
  B05C: "#1717170D",
  LG100C: "#F3F6F8",
  LG80C: "#F3F6F8CC",
  LG60C: "#F3F6F899",
  LG40C: "#F3F6F866",
  LG12C: "#F3F6F81F",
  LG08C: "#F3F6F814",
  LG05C: "#F3F6F80D"
};

// light to dark rules
// 1. G <-> LG
// 2. B <-> W
const V03DarkColor = {
  LG100: "#8F92A1",
  LG80: "#8F92A1CC",
  LG60: "#8F92A199",
  LG60Solid: "#B7B8C1",
  LG40: "#8F92A166",
  LG12: "#8F92A11F",
  LG08: "#8F92A114",
  LG05: "#8F92A10D",
  B100: "#ffffff",
  B80: "#ffffffCC",
  B60: "#ffffff99",
  B40: "#ffffff66",
  B12: "#ffffff1F",
  B08: "#ffffff14",
  B05: "#ffffff0D",
  W100: "#171717",
  W80: "#171717CC",
  W60: "#17171799",
  W40: "#17171766",
  W12: "#1717171F",
  W08: "#17171714",
  W05: "#1717170D",
  G100: "#F3F6F8",
  G80: "#F3F6F8CC",
  G60: "#F3F6F899",
  G60Solid: "#F3F4F6",
  G40: "#F3F6F866",
  G12: "#F3F6F81F",
  G08: "#F3F6F814",
  G05: "#F3F6F80D"
};

const buttonBase: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "16px",
  height: "60px",

  ":enabled": { cursor: "pointer" }
};

const button: ThemeUIStyleObject = {
  ...buttonBase,

  px: ["15px", "10px", "20px", "24px"],
  py: "12px",

  fontWeight: "heading",
  fontSize: 3,
  fontFamily: "body",

  ":disabled": {
    opacity: 0.4
  }
};

const iconButton: ThemeUIStyleObject = {
  ...buttonBase,

  padding: 0,
  width: "40px",
  height: "40px",

  background: "none",

  ":disabled": {
    color: "text",
    opacity: 0.25
  }
};

const cardGapX = [1, 3, 3];
const cardGapY = [3, 3, 3];

const card: ThemeUIStyleObject = {
  position: "relative",
  borderRadius: "12px",
  border: "1px solid transparent",
  background: "card.background",
  p: "24px",
  h2: {
    fontSize: 5,
    pb: 0,
    mb: 0
  }
};

const infoCard: ThemeUIStyleObject = {
  ...card
};

const formBase: ThemeUIStyleObject = {
  display: "block",
  width: "auto",
  flexShrink: 0,
  pb: "12px",
  fontSize: 2,
  fontWeight: "medium"
};

const formCell: ThemeUIStyleObject = {
  ...formBase,

  border: 1,
  borderColor: "text",
  borderRadius: 0
  // boxShadow: [1, 2]
};

const overlay: ThemeUIStyleObject = {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
};

const modalOverlay: ThemeUIStyleObject = {
  position: "fixed",
  left: 0,
  top: 0,
  width: "100vw",
  height: "100vh",
  zIndex: 4
};

const flexCenter: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

const centered: ThemeUIStyleObject = {
  ...flexCenter,
  flexGrow: 1,
  flexDirection: "column"
};

const activeLinkStyles: ThemeUIStyleObject = {
  color: "primary",
  fontWeight: "heading"
};

const listStyles: ThemeUIStyleObject = {
  listStyle: "none",
  padding: 0
};

const description: ThemeUIStyleObject = {
  justifyContent: "center",
  alignItems: "center",
  p: "10px 16px",

  borderRadius: "12px",
  height: "100%",
  width: "100%",
  whiteSpace: "pre-wrap"
};

export const astridDaoTheme = makeTheme({
  config: {
    initialColorModeName: "light"
  },
  breakpoints: ["425px", "1280px", "1920px"],

  sizes: {},

  radii: {
    default: "8px",
    card: "16px",
    circle: "50%"
  },

  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 128, 256, 512],

  fonts: {
    body: "Inter, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace"
  },

  // Usage     0,  1,  2,  3,  4,  5,  6,  7,  8,  9
  fontSizes: [12, 14, 16, 18, 20, 24, 28, 32, 48, 64],

  fontWeights: {
    body: 400,
    heading: 700,

    light: 200,
    medium: 500,
    bold: 600
  },

  lineHeights: {
    body: 1.5,
    heading: 1.25
  },

  colors: {
    ...V03Color,
    // ...V03DarkColor,
    R100: "#DE350B",
    primaryGradient,
    // primary: newBaseColors.lightYellow,
    accent: newBaseColors.darkGoldenrod,
    // goku color start
    primary6: "#FE207E",
    primary1: "#FFE8EE",
    text1: "#FFFFFF",
    text3: "#757B81",
    text4: "#4E5969",
    text5: "#1D2129",
    b20: "rgba(23, 23, 23, 0.20)",
    functionBlue: "#306FE9",
    functionPrimary1: "#FFE8EE",
    w100: "#FFF",
    b100: "#000",
    error: "#F53F3F",
    tipsBg: "#F7F8FA",
    // goku color ends

    success: baseColors.green,
    warning: baseColors.yellow,
    danger: baseColors.red,
    dangerHover: baseColors.red,
    info: baseColors.blue,
    invalid: baseColors.red,

    text: "white",
    "text-65": "rgba(255, 255, 255, 0.65)",
    textReverse: baseColors.darkCharcoal,
    background: "black",
    muted: "#eaebed",
    table: {
      hover: baseColors.darkCharcoal
    },
    button: {
      primary: {
        background: `linear-gradient(262.97deg, #0BB7B5 59.64%, #FFD951 100.24%)`,
        hover: `linear-gradient(262.97deg, #FFD951 59.64%, #0BB7B5 100.24%)`
        // hover: "linear-gradient(262.45deg, rgba(255, 253, 249, 0.27) 0%, #F2B13E 108.41%)"
      },
      secondary: {
        background: "none",
        hover: "#FFFFFF33"
      }
    },
    description: {
      action:
        "linear-gradient(90.7deg, rgba(255, 255, 255, 0.2) 25.77%, rgba(255, 255, 255, 0) 111.07%)",
      error: "linear-gradient(90.7deg, rgba(255, 0, 0, 0.2) 25.77%, rgba(255, 255, 255, 0) 111.07%)"
    },
    card: {
      baseBackground: "#16161A",
      background:
        "linear-gradient(#16161A 0 0) padding-box, linear-gradient(97.18deg, rgba(0, 255, 240, 0.5) 5.67%, rgba(250, 0, 255, 0.5) 98%) border-box"
    },
    tooltip: {
      background:
        "linear-gradient(#FFFFFF 0 0) padding-box, linear-gradient(97.18deg, rgba(0, 255, 240, 0.5) 5.67%, rgba(250, 0, 255, 0.5) 98%) border-box"
    },
    cardInformation: "rgba(243, 246, 248, 0.4)",
    cardOperationBg: "#FFFFFF",
    cardOperationBorder: "#FFFFFF",
    modes: {
      // dark: {
      //   ...V03DarkColor,
      //   cardInformation: "#1F2021",
      //   cardOperationBg:
      //     "radial-gradient(96.32% 96.32% at 45.72% -26.15%, rgba(47, 47, 47, 0.64) 0%, #000000 100%)",
      //   cardOperationBorder: "rgba(255, 255, 255, 0.63)"
      // }
    }
  },

  borders: [0, "1px solid", "2px solid"],

  shadows: ["0", "0px 4px 8px rgba(41, 49, 71, 0.1)", "0px 8px 16px rgba(41, 49, 71, 0.1)"],

  text: {
    mosaicButtonText: {
      /* VT323/24 表单标题 */
      fontFamily: "VT323",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px" /* 100% */
    },
    mosaic20: {
      /* VT323/24 表单标题 */
      fontFamily: "VT323",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px" /* 100% */
    },
    mosaic14: {
      /* VT323/24 表单标题 */
      fontFamily: "VT323",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px" /* 100% */
    },
    mobileTableHeader: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "10px",
      color: "W100",
      lineHeight: "12px"
    },
    mobileTableCell: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10px",
      color: "B100",
      lineHeight: "12px"
    },

    tableHeader: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      color: "w100"
    },
    mobbileTableCell: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      color: "b100",
      lineHeight: "14px"
    },
    tableCell: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      color: "b100"
    },
    cardTitle36: {
      ...commonMsTitleFonts,
      fontSize: "36px"
    },
    msTitle32: {
      ...commonMsTitleFonts,
      fontSize: 40
    },
    msTitle24: {
      ...commonMsTitleFonts,
      fontSize: 32
    },
    msTitle20: {
      ...commonMsTitleFonts,
      fontSize: "30px"
    },
    msTitle16: {
      ...commonMsTitleFonts,
      fontSize: 22
    },
    mediumH0: {
      ...baseMediumFonts,
      fontSize: "60px",
      lineHeight: "72px"
    },
    mediumH1: {
      ...baseMediumFonts,
      fontSize: "30px",
      lineHeight: "36px"
    },
    mediumH2: {
      ...baseMediumFonts,
      fontSize: "22px",
      lineHeight: "30px"
    },
    mediumH3: {
      ...baseMediumFonts,
      fontSize: "16px",
      lineHeight: "22px"
    },
    mediumH4: {
      ...baseMediumFonts,
      fontSize: "14px",
      lineHeight: "20px"
    },
    mediumH4B40: {
      ...baseMediumFonts,
      fontSize: "14px",
      lineHeight: "20px",
      color: "B40"
    },
    mediumTitleLarge: {
      ...baseMediumFonts,
      fontSize: "14px",
      lineHeight: "20px"
    },
    mediumTitleSmall: {
      ...baseMediumFonts,
      fontSize: "12px",
      lineHeight: "18px"
    },
    mediumBodyLarge: {
      ...baseMediumFonts,
      fontSize: "16px",
      lineHeight: "24px"
    },
    mediumBodySmall: {
      ...baseMediumFonts,
      fontSize: "12px",
      lineHeight: "20px"
    },
    mediumLabel: {
      ...baseMediumFonts,
      fontSize: "12px",
      lineHeight: "20px"
    },
    regularH0: {
      fontSize: "60px",
      lineHeight: "72px",
      ...baseRegularFonts
    },
    regularH1: {
      fontSize: "30px",
      lineHeight: "36px",
      ...baseRegularFonts
    },
    regularH2: {
      fontSize: "22px",
      lineHeight: "30px",
      ...baseRegularFonts
    },
    regularH3: {
      fontSize: "16px",
      lineHeight: "22px",
      ...baseRegularFonts
    },
    regularH4: {
      fontSize: "14px",
      lineHeight: "20px",
      ...baseRegularFonts
    },
    regularTitleLarge: {
      fontSize: "14px",
      lineHeight: "20px",
      ...baseRegularFonts
    },
    regularTitleSmall: {
      fontSize: "12px",
      lineHeight: "18px",
      ...baseRegularFonts
    },
    regularBodyLarge: {
      fontSize: "16px",
      lineHeight: "24px",
      ...baseRegularFonts
    },
    regularBodySmall: {
      fontSize: "12px",
      lineHeight: "20px",
      ...baseRegularFonts
    },
    regularLabel: {
      fontSize: "12px",
      lineHeight: "20px",
      ...baseRegularFonts
    },
    regular90: {
      fontSize: "90px",
      lineHeight: "normal",
      ...baseRegularFonts
    },
    regular72: {
      fontSize: "72px",
      lineHeight: "normal",
      ...baseRegularFonts
    },
    regular48: {
      fontSize: "48px",
      lineHeight: "normal",
      ...baseRegularFonts
    },
    regular36: {
      fontSize: "36px",
      lineHeight: "normal",
      ...baseRegularFonts
    },
    regular24: {
      fontSize: "24px",
      lineHeight: "26px",
      ...baseRegularFonts
    },
    regular18: {
      fontSize: "18px",
      lineHeight: "normal",
      ...baseRegularFonts
    },
    regular14: {
      fontSize: "14px",
      lineHeight: "16px",
      ...baseRegularFonts
    },
    medium18: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 700
    },
    chartTitle24: {
      fontFamily: "VT323",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px"
    },
    gkRegular18: {
      fontFamily: "Roboto Mono Regular",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px"
    },
    gkRegular16: {
      fontFamily: "Roboto Mono Regular",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px"
    },
    gkRegular14: {
      fontFamily: "Roboto Mono Regular",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18px"
    },
    gkLight12: {
      fontFamily: "Roboto Mono",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px"
    },
    medium32: {
      fontFamily: "Roboto Mono",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "125%"
    },
    tips: {
      color: "B80",
      textAlign: "center",
      fontFamily: "Roboto Mono Regular",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: "-0.48px"
    },
    pageHeader: {
      variant: "text.msTitle32",
      color: "B100"
    },
    cardHeader: {
      variant: "text.msTitle20",
      color: "B100"
    },

    address: {
      fontFamily: "monospace",
      fontSize: 1
    },
    radio: {
      position: "relative",
      left: "-32px",
      top: 1,
      fontWeight: "heading",
      fontSize: 5
    },
    heading: {
      color: "text",
      fontSize: 5,
      fontWeight: "heading"
    },
    stat: {
      fontWeight: "heading",
      fontSize: 5,
      lineHeight: "29px",
      color: "#FFFFFF"
    },
    block: {
      variant: "paragraph",
      my: 2,
      textAlign: "justify",
      textAlignLast: "start",
      textJustify: "auto"
    },
    gradient: {
      background: primaryGradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent"
    },
    gradient40: {
      background: primaryGradient40,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent"
    }
  },

  buttons: {
    primary: {
      ...button,

      background: "button.primary.background",
      border: 0,
      color: "W100",

      "&:hover": {
        // background: "button.primary.hover",
        color: "B80"
      },
      "&:active": {
        color: "B100"
      }
    },

    secondary: {
      ...button,

      color: "primary",
      borderColor: "primary",
      background: "none",

      ":enabled:hover": {
        color: "primary",
        bg: "button.secondary.hover",
        borderColor: "primary"
      }
    },

    danger: {
      ...button,

      bg: "danger",
      borderColor: "danger",

      ":enabled:hover": {
        bg: "dangerHover",
        borderColor: "dangerHover"
      }
    },

    icon: {
      ...iconButton,
      color: "body",
      p: 3,
      borderRadius: "8px",
      ":enabled:hover": {
        color: "G12",
        background: "G08"
      }
    },

    brandIcon: {
      color: "white",
      ":enabled:hover": { color: "primary" }
    },

    dangerIcon: {
      ...iconButton,
      color: "danger",
      ":enabled:hover": { color: "dangerHover" }
    },

    titleIcon: {
      ...iconButton,
      color: "text",
      ":enabled:hover": { color: "primary" }
    },

    borderButton: {
      ...baseMediumFonts,
      p: 4,
      textAlign: "center",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "B100",
      borderRadius: "16px",
      background: "transparent",
      cursor: "pointer",
      color: "B80",
      ":hover": {
        background: "G08"
      },
      ":active": {
        background: "G12"
      }
    },
    borderButtonDisabled: {
      ...baseMediumFonts,
      p: 4,
      textAlign: "center",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "B40",
      borderRadius: "16px",
      background: "transparent",
      cursor: "pointer",
      color: "B40"
    },

    borderGradientButton: {
      ...baseMediumFonts,
      cursor: "pointer",
      textAlign: "center",
      p: 4,
      position: "relative",
      borderRadius: "16px",
      background: "transparent",
      color: "B80",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "16px",
        border: "1px solid transparent",
        background: `${primaryGradient} border-box`,
        WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "destination-out",
        maskComposite: "exclude"
      },
      ":hover": {
        background: "G08"
      },
      ":active": {
        background: "G12"
      }
    },
    borderGradientButtonDisabled: {
      ...baseMediumFonts,
      cursor: "pointer",
      textAlign: "center",
      p: 4,
      position: "relative",
      borderRadius: "16px",
      background: "transparent",
      color: "B80",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "16px",
        border: "1px solid transparent",
        background: `${primaryGradient40} border-box`,
        WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "destination-out",
        maskComposite: "exclude"
      }
    },

    gradientButtonBig: {
      ...baseMediumFonts,
      cursor: "pointer",
      textAlign: "center",
      p: "4px 24px",
      position: "relative",
      borderRadius: "16px",
      background: "transparent",
      color: "B80",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "16px",
        border: "1px solid transparent",
        background: `${primaryGradient} border-box`,
        WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "destination-out",
        maskComposite: "exclude"
      },
      ":enabled:hover": {
        background: "button.primary.hover",
        color: "W100",
        border: "none"
      }
    },
    gradientButton: {
      ...baseMediumFonts,
      cursor: "pointer",
      textAlign: "center",
      p: "4px 24px",
      position: "relative",
      borderRadius: "8px",
      background: "transparent",
      color: "B80",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "8px",
        border: "1px solid transparent",
        background: `${primaryGradient} border-box`,
        WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "destination-out",
        maskComposite: "exclude"
      },
      ":enabled:hover": {
        background: "button.primary.hover",
        color: "W100",
        border: "none"
      }
    },
    gradientBorderAndText: {
      "--r": "40px",
      "--b": "5px",
      background: "linear-gradient(to right, #006175 0%, #00a950 100%)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      color: "transparent",
      borderRadius: "var(--r)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "1.5rem",
      fontFamily: "Oswald, Arial, sans-serif",
      height: "80px",
      margin: "0 auto",
      position: "relative",
      zIndex: 0,
      textDecoration: "none",
      width: "264px",
      "&::before": {
        content: '""',
        position: "absolute",
        zIndex: -1,
        inset: 0,
        border: "var(--b) solid transparent",
        borderRadius: "var(--r)",
        background: "pink",
        backgroundOrigin: "border-box",
        backgroundClip: "border-box",
        WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
        WebkitMaskRepeat: "no-repeat"
      },
      "&:hover": {
        color: "#fff",
        WebkitTextFillColor: "#fff",
        WebkitBackgroundClip: "border-box",
        backgroundClip: "border-box"
      },
      "&:hover::before": {
        WebkitMask: "none"
      }
    }
  },

  cards: {
    information: {
      background: "cardInformation",
      borderRadius: "20px",
      p: 6,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "G12"
    },
    informationAlwaysDark: {
      background: "#1F2021",
      borderRadius: "20px",
      p: 6,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "G12"
    },
    operation: {
      borderRadius: "20px",
      p: 6,
      background: "cardOperationBg",
      boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.08)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "cardOperationBorder"
    },
    modal: {
      width: ["100vw", "590px", "590px"],
      borderRadius: "20px",
      maxHeight: "90vh",
      overflowY: "scroll",
      pl: [3, 6, 6],
      pt: [3, 6, 6],
      pr: [3, 6, 6],
      pb: ["120px", 6, 6]
    },
    dialog: {
      width: "100%"
    },
    // NOTICE: Cards which use this style should have a z-index of 1 set
    gradient: {
      p: 6,
      background: "cardInformation",
      position: "relative",
      borderRadius: "12px",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "12px",
        border: "1px solid transparent",
        background:
          "linear-gradient(45deg,rgba(0, 255, 240, 0.5),rgba(250, 0, 255, 0.5)) border-box",
        WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "destination-out",
        maskComposite: "exclude"
      }
    },
    primary: {
      ...card,
      h2: {
        color: "primary"
      }
    },

    outline: {
      ...card,
      h2: {
        color: "white",
        fontWeight: "medium"
      },
      background: "#16161A",
      border: "1px solid rgba(255, 255, 255, 0.26)",
      borderRadius: "12px"
    },

    form: {
      width: "100%",
      background: "#16161A",
      border: "none",
      borderRadius: "12px 12px 0px 0px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },

    "radio-card": {
      ...card,
      border: "1px solid #535355",
      padding: "24px",

      hr: {
        color: "rgba(255, 255, 255, 0.13)",
        margin: 0
      },

      h2: {
        color: "white",
        fontWeight: "medium"
      }
    },

    "hover-card": {
      // ...card,
      border: "1px solid G12",
      padding: "24px",

      hr: {
        color: "rgba(255, 255, 255, 0.13)",
        margin: 0
      },

      h2: {
        color: "white",
        fontWeight: "medium"
      },

      button: {
        width: "100%"
      }
    },

    info: {
      ...infoCard,
      width: "1200px",
      display: "block"
    },

    wallet: {
      display: "flex",
      bg: "W100",
      flexDirection: "column",
      alignItems: "center",
      p: 3,

      width: ["100vw", "348px", "348px"],
      position: "fixed",
      top: ["auto", "60px", "60px"],
      bottom: ["0", "auto", "auto"],
      right: [0, 4, 4],
      overflowY: "scroll",
      overflowX: "hidden",
      height: "auto",
      maxHeight: ["calc(100vh - 60px)", "800px", "800px"],
      borderRadius: "20px"
    },

    publicSale: {
      ...infoCard,
      width: "480px",
      height: "600px",
      display: "block"
    },

    tokenClaiming: {
      ...infoCard,
      width: "640px",
      display: "block"
    },

    tooltip: {
      p: "10px",
      borderRadius: "8px",
      background: "W100",
      color: "B100",
      zIndex: 3,
      maxWidth: "480px",
      ...baseRegularFonts,
      fontSize: "12px",
      lineHeight: "20px",
      boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.15)"
    }
  },

  forms: {
    label: {
      ...formBase
    },

    unit: {
      ...formCell,
      marginLeft: 10,
      borderTopLeftRadius: 13,
      borderBottomLeftRadius: 13,
      textAlign: "center"
    },

    input: {
      ...formCell,
      borderRadius: 8,
      flex: 1,
      ":focus": {
        outline: "none",
        borderColor: "primary"
      }
    },
    radio: {
      flexGrow: 1,
      p: "20px",
      mr: 0,
      border: 1,
      borderRadius: "8px",
      borderColor: "text",
      ":checked, :focus, :hover": {
        background: "button.primary.background",
        borderColor: "transparent"
      }
    }
  },

  layout: {
    "wallet-balance": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: ["calc(100vw - 24px)", "324px", "324px"],
      px: "12px",
      height: "60px",
      ":hover": {
        background: "G12",
        borderRadius: "8px"
      }
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      height: [headerHeight, headerHeight, headerHeight],
      px: [3, 6, 6],
      background: "W100"
    },

    divider: {
      mx: -4,
      my: 2,
      borderBottom: "1px solid rgba(0, 255, 240, .6)"
    },

    app: {
      display: "flex",
      alignItems: "stretch",
      height: `calc(100vh - ${headerHeight})`,
      width: "100vw"
    },

    footer: {
      ...flexCenter,

      mt: cardGapY,
      px: 3,
      minHeight: "72px",

      bg: "muted"
    },

    center: {
      ...flexCenter
    },

    "centered--mb-xs": {
      ...centered,
      mb: ["10%"]
    },

    main: {
      width: "100%",
      height: `calc(100vh - ${headerHeight})`,
      bg: "#D9F0F9"
    },

    content: {
      height: ["auto", `calc(100vh - ${headerHeight})`, `calc(100vh - ${headerHeight})`],
      width: "100%",
      overflow: ["auto", "scroll", "scroll"]
    },

    "public-sale": {
      width: "100%",
      maxWidth: "964px",
      mx: "auto",
      mt: ["40px", 0],
      mb: ["40px", "40px"],
      px: cardGapX,
      pb: ["120px", "0"],
      ...flexCenter
    },

    "token-claiming": {
      width: "100%",
      maxWidth: "964px",
      mx: "auto",
      mt: ["40px", 0],
      mb: ["40px", "40px"],
      px: cardGapX,
      pb: ["120px", "0"],
      ...flexCenter
    },

    banner: {
      width: "100%",
      height: ["46vw", "46vw", "165px", "210px"],
      mx: "auto",
      px: "30px",
      display: "flex",
      flexGrow: 1,
      cursor: "grab",
      flexDirection: "column",
      justifyContent: "center"
    },

    pageContent: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      p: [3, 6, 6],
      height: [`calc(100vh - ${headerHeight})`, "auto", "auto"]
    },

    columns: {
      display: "flex",
      flexWrap: "wrap",
      justifyItems: "center"
    },

    left: {
      width: ["100%", "50%"]
    },

    right: {
      pl: cardGapX,
      width: ["100%", "50%"]
    },

    "sidebar-block": {
      m: 0,
      color: "sidebar.text",
      width: ["0px", "0px", "268px"],
      minWidth: ["0px", "0px", "268px"],
      overflow: "scroll",
      background: "linear-gradient(180deg, #2A65D9 0%, #58C3E8 100%)",
      height: `calc(100vh - ${headerHeight})`
    },

    sidebar: {
      display: "flex",
      flexDirection: "column",
      background: ["transparent", "transparent", "G05"],
      alignItems: "center",
      justifyContent: "space-between",
      py: 6,
      m: 0,
      height: "100vh"
    },

    selectCollateral: {
      width: ["156px", "196px"]
    },

    half: {
      width: ["100%", "50%"]
    },

    actions: {
      justifyContent: "flex-end",
      mt: 3,

      button: {
        ml: 3
      }
    },

    actionDescription: {
      ...description,
      background: "LG40"
    },

    errorDescription: {
      ...description,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "R100",
      // color: "B80",
      ...baseRegularFonts,
      fontSize: "16px",
      lineHeight: "22px"
    },

    disabledOverlay: {
      ...overlay,

      bg: "rgba(255, 255, 255, 0.5)"
    },

    modalOverlay: {
      ...modalOverlay,

      bg: "rgba(0, 0, 0, 0.3)",
      backdropFilter: "blur(15px)",
      display: "flex",

      justifyContent: "center",
      alignItems: "center"
    },

    modal: {
      width: "auto"
    },

    infoOverlay: {
      ...modalOverlay,

      display: "block",
      bg: "rgba(0, 0, 0, 0.3)",
      backdropFilter: "blur(15px)"
    },

    mobileOverlay: {
      ...modalOverlay,

      display: "block",
      background: "linear-gradient(180deg, #2A65D9 0%, #58C3E8 100%)",
      overflow: "scroll"
    },

    infoMessage: {
      ...flexCenter,
      m: 3,
      minWidth: "128px"
    },

    sidenav: {
      display: "flex",
      flexDirection: "column",
      p: 3,
      m: 0,
      height: "100vh"
    },

    badge: {
      border: 1,
      borderRadius: 13,
      p: 1,
      px: 2,
      borderColorColor: "text",
      color: "slate",
      fontSize: 1,
      fontWeight: "body"
    },

    carouselItem: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      userSelect: "none",
      color: "#fff",
      width: "100%"
    }
  },

  styles: {
    root: {
      background: V03Color.W100,

      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",

      height: "100%",

      "#root": {
        height: "100vh"
      },

      body: {
        background: V03Color.W100
      },

      ".apexcharts-tooltip": {
        padding: 2,
        borderRadius: "12px",
        background:
          "linear-gradient(#FFFFFF 0 0) padding-box, linear-gradient(97.18deg, rgba(0, 255, 240, 0.5) 5.67%, rgba(250, 0, 255, 0.5) 98%) border-box !important",
        boxShadow: 2,
        border: "1px solid transparent !important",
        fontSize: 0,
        color: "black !important",
        fontWeight: "body !important"
      },

      ".public-sale": {
        "&__details": {
          ...listStyles
        }
      },

      ".token-claiming": {
        "&__details": {
          ...listStyles
        }
      },

      ".dashboardTable": {
        tbody: {
          tr: {
            "&:hover": {
              bg: "G05"
            }
          }
        }
      },

      table: {
        background: ["transparent"],
        width: "100%",
        borderRadius: "8px",
        borderSpacing: 0,
        tr: {
          height: "60px"
        },
        "tbody > tr:not(.disabled)": {
          "&:hover": {
            border: "1px solid rgba(0, 255, 240, .6)",
            borderRadius: "10px",
            background: "functionPrimary1"
          },
          "&:active": {
            border: "1px solid rgba(0, 255, 240, .6)",
            borderRadius: "10px"
          }
        },
        "tbody > tr.disabled": {
          opacity: "0.5"
        },
        "th, td": {
          variant: "text.regularH4",
          color: "B60"
        },
        thead: {
          overflowY: "auto",
          overflowX: "hidden",
          // position: "sticky",
          top: 0,
          my: 2,
          zIndex: 1,
          background: "#306FE9"
        }
      },

      ".redemption-link": {
        color: "#0052CCCC",
        // textDecoration: "underline",
        ":hover": { color: "#0052CCCC" }
      },

      ".external-nav-link": {
        color: "primary",
        ":hover": { ...activeLinkStyles, color: "accent" }
      },

      a: {
        color: "#8F9BB3",
        ":hover": { ...activeLinkStyles },
        textDecoration: "none",
        fontWeight: "bold",
        "&.active": {
          ...activeLinkStyles
        }
      }
    },

    hr: {
      width: "100%",
      borderBottom: "1px solid rgba(255, 255, 255, 0.13);"
    },
    spinner: {
      color: "#FFEEF5"
    }
  },

  links: {
    nav: {
      px: 2,
      py: 1,
      fontFamily: "body",
      fontWeight: 400,
      fontStyle: "heading",
      fontSize: [4, 0, 2, 3],
      width: ["100%", "auto"],
      minWidth: "fit-content",
      mt: [3, "auto"]
    }
  }
});
export type AstridDaoTheme = typeof astridDaoTheme;

interface ExactContextValue extends Omit<ThemeUIContextValue, "theme"> {
  theme: AstridDaoTheme;
}
export const useTheme = useThemeUI as unknown as () => ExactContextValue;
