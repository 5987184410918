import React from "react";

import { Box, Image, useThemeUI } from "theme-ui";

import AstridDaoLogoImage from "../assets/logo.svg";

type AstridDaoLogoProps = React.ComponentProps<typeof Box> & {
  height?: number | string;
  width?: number | string;
};

export const Logo: React.FC<AstridDaoLogoProps> = ({ height, width, ...boxProps }) => {
  const { colorMode } = useThemeUI();
  return (
    <Box
      sx={{
        lineHeight: 0,
        cursor: "pointer",
        width: "100%"
      }}
      {...boxProps}
      onClick={() => (window.location.href = "/")}
    >
      <Image src={AstridDaoLogoImage} sx={{ height, width }} />
    </Box>
  );
};
