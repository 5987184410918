import { useEffect, useReducer } from "react";

import { AstridDaoStoreState } from "@astrid-dao/lib-base";

import { equals } from "../rpc-contract-service/utils/equals";
import { useAstridDaoStore } from "./useAstridDaoStore";

export const useAstridDaoSelector = <S, T>(select: (state: AstridDaoStoreState<T>) => S): S => {
  const store = useAstridDaoStore<T>();
  const [, rerender] = useReducer(() => ({}), {});

  useEffect(
    () =>
      store.subscribe(({ newState, oldState }) => {
        if (!equals(select(newState), select(oldState))) {
          rerender();
        }
      }),
    [store, select]
  );

  return select(store.state);
};
