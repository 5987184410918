import isEqual from "lodash.isequal";
import { CHAIN_ID, GAS_ESTIMATOR_POLL_INTERVAL, RPC_URL, RPC_URL_TESTNET } from "./constants";

let instance: GasEstimator;

export interface EstimatedGasPrice {
  average: string;
}

interface EstimatedGasPriceResponse {
  id: number;
  jsonrpc: string;
  result: string;
}

/**
 * Parameters passed to {@link GasEstimator} observers.
 *
 * @remarks
 * Use the {@link GasEstimator.subscribe | subscribe()} function to register a observer.

 * @public
 */
export interface GasEstimatorObserverParams {
  /** A flag to tell the observers that the price has changed. */
  didPriceChange: boolean;
  estimatedGasPrice: EstimatedGasPrice;
}

const wrap =
  <A extends unknown[], R>(f: (...args: A) => R) =>
  (...args: A) =>
    f(...args);

/**
 * Sleep for some milliseconds.
 *
 * @param ms number of milliseconds to sleep for
 */
const sleep = (ms: number) => new Promise(res => setTimeout(res, ms));

export class GasEstimator {
  private chainId: number;
  private hasStartedPolling = false;
  private estimatedGasPrice!: EstimatedGasPrice;

  constructor(chainId: number) {
    if (instance) {
      throw new Error("You can only create one GasEstimator instance!");
    }

    this.chainId = chainId;
    instance = this;

    this.initializeGasEstimator();
  }

  private _observers = new Set<(params: GasEstimatorObserverParams) => void>();

  private _notify(params: GasEstimatorObserverParams) {
    // Iterate on a copy of `_observers`, to avoid notifying any new observers subscribed by
    // existing observers, as that could result in infinite loops.
    //
    // Before calling a observer from our copy of `_observers`, check if it has been removed from
    // the original set. This way we avoid calling observers that have already been unsubscribed
    // by an earlier observer callback.
    [...this._observers].forEach(observer => {
      if (this._observers.has(observer)) {
        observer(params);
      }
    });
  }

  private async fetchSuggestedGasPrice(): Promise<EstimatedGasPriceResponse> {
    const network = CHAIN_ID.astar === this.chainId ? RPC_URL : RPC_URL_TESTNET;
    const body = {
      jsonrpc: "2.0",
      method: "eth_gasPrice",
      params: [],
      id: 1
    };

    const response = await fetch(network, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      return Promise.reject(`${response.status} ${response.statusText}`);
    }

    if (response.status === 200) {
      return await response.json();
    } else {
      return Promise.reject("Oops! Something went wrong!");
    }
  }

  private async pollForSuggestedGasPrice(pollInterval: number = GAS_ESTIMATOR_POLL_INTERVAL) {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        const response: EstimatedGasPriceResponse = await this.fetchSuggestedGasPrice();

        const oldPrice = this.estimatedGasPrice;

        this.estimatedGasPrice = { average: response.result };

        this._notify({
          didPriceChange: !isEqual(this.estimatedGasPrice, oldPrice),
          estimatedGasPrice: this.estimatedGasPrice
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.hasStartedPolling = true;

        await sleep(pollInterval);
      }
    }
  }

  private async initializeGasEstimator(): Promise<void> {
    if (!this.hasStartedPolling) {
      await this.pollForSuggestedGasPrice();
    }
  }

  /**
   * Register a gas estimator observer.
   *
   * @param observer - Function that will be called whenever state changes.
   * @returns Function to unregister this observer.
   */
  subscribe(observer: (params: GasEstimatorObserverParams) => void): () => void {
    const uniqueObserver = wrap(observer);

    this._observers.add(uniqueObserver);

    return () => {
      this._observers.delete(uniqueObserver);
    };
  }

  updateChainId(id: number): void {
    this.chainId = id;
  }

  toString(): string {
    const { average } = this.estimatedGasPrice;

    return `estimatedGasPrice = {
      average: ${average};
    }`;
  }
}
